import axios from 'axios'
import FileDownload from 'js-file-download'
import ImageAssets from './ImageAssets'
import { appConfig } from '../constants/AppConfig'
import setAuthToken from '../interceptors/SetAuthToken'
import setLoading from './LoadingManager'
import moment from 'moment'

export const generateRequestBody = (propsObject) => {
  let requestObject = {
    step: propsObject.currentStep,
    isReturnBefore: appConfig.FILLED_RETURN_BEFORE[0] === propsObject.hasReturnBefore ? true : false,
    isCraFirstTime: appConfig.CRA_ACCOUNT_OPTIONS[0] === propsObject.filingReturnFirstTime ? true : false,
    dateOfLandingCanadaVal: moment(propsObject.dateOfLanding).format(appConfig.DEFAULT_API_FORMAT),
    taxYears: propsObject.taxYears,
    firstName: propsObject.firstname.trim(),
    lastName: propsObject.lastname.trim(),
    sin: propsObject.sin,
    dobVal: moment(propsObject.dateOfBirth).format(appConfig.DEFAULT_API_FORMAT),
    unit: propsObject.unit,
    streetNumber: propsObject.streetNumber,
    streetName: propsObject.streetName,
    city: propsObject.city,
    province: propsObject.province,
    postalCode: propsObject.postalCode,
    email: propsObject.email,
    phoneCode: '+1',
    phoneNumber: propsObject.phone ? propsObject.phone.replace(/-/g, '') : propsObject.phone,
    familyDetails: {
      isDependant: appConfig.HAS_DEPENDENTS[0] === propsObject.hasDependants ? true : false,
      maritalStatus: appConfig.MARITAL_STATUS[0] === propsObject.maritalStatus ? true : false,
    },
    referenceDetails: {
      name: propsObject.referenceDetails ? propsObject.referenceDetails.name.trim() : '',
      email: propsObject.referenceDetails ? propsObject.referenceDetails.email : ''
    },
    residentDetails: {
      isMoved: appConfig.HAS_MOVE[0] === propsObject.hasMove ? true : false,
      oldProvince: propsObject.provinceTerritory,
      mailingAddressSame: appConfig.SAME_HOME_ADDRESS[0] === propsObject.sameHomeAddress ? true : false,
      liveProvince: propsObject.currentlyLive,
      immigrationStatus: propsObject.immigrationStatus
    },
    craDetails: {
      isRegCra: appConfig.CRA_ACCOUNT_OPTIONS[0] === propsObject.CRAAccount ? true : false,
      rentTaxAmount: propsObject.rentTaxAmount,
      isInvesting: appConfig.INVESTING_RRSP_TFSA[0] === propsObject.isInvesting ? true : false,
      donationAmount: propsObject.donationAmount,
      isMedicalExpenses: appConfig.MEDICAL_EXPENSES[0] === propsObject.isMedicalExpenses ? true : false
    },
    depositInformation: {
      transit: propsObject.transitInformation,
      institution: propsObject.institutionInformation,
      account: propsObject.accountInformation
    },
    notes: propsObject.notes,
    documents: propsObject.documents,
    documentsDelete: propsObject.deletedDocuments
  }

  if (requestObject.familyDetails.maritalStatus) {
    requestObject.familyDetails = {
      ...requestObject.familyDetails,
      domVal: moment(propsObject.dateOfMarriage).format(appConfig.DEFAULT_API_FORMAT),
      firstName: propsObject.spouseFirstname,
      lastName: propsObject.spouseLastname,
      isReturnBefore: appConfig.SPOUSE_FILLED_RETURN_BEFORE[0] === propsObject.spouseHasFilledReturnBefore ? true : false,
      isCraFirstTime: appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0] === propsObject.spouseFilingReturnFirstTime ? true : false,
      sin: propsObject.spouseSIN,
      dobVal: moment(propsObject.spouseDateOfBirth).format(appConfig.DEFAULT_API_FORMAT),
      email: propsObject.spouseEmail,
      phoneCode: '+1',
      phoneNumber: propsObject.spouseContactNumber ? propsObject.spouseContactNumber.replace(/-/g, '') : propsObject.spouseContactNumber
    }
    if (appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0] === propsObject.spouseFilingReturnFirstTime) {
      requestObject.familyDetails = {
        ...requestObject.familyDetails,
        dateOfLandingCanadaVal: moment(propsObject.spouseDateOfLanding).format(appConfig.DEFAULT_API_FORMAT)
      }
    } else {
      requestObject.familyDetails = {
        ...requestObject.familyDetails,
        taxYears: propsObject.spouseTaxYear
      }
    }
  }
  if (requestObject.familyDetails.isDependant) {
    let allDependents = []
    propsObject.dependants.forEach((eachDependent) => {
      allDependents.push({
        id: eachDependent.id,
        firstName: eachDependent.firstName,
        lastName: eachDependent.lastName,
        dobVal: moment(eachDependent.dob).format(appConfig.DEFAULT_API_FORMAT),
        sin: eachDependent.sin
      })
    })
    requestObject.familyDetails = {
      ...requestObject.familyDetails,
      dependents: allDependents
    }
  }


  if (requestObject.residentDetails.isMoved) {
    requestObject.residentDetails = {
      ...requestObject.residentDetails,
      oldProvinceChangeDateVal: moment(propsObject.dateOfMove).format(appConfig.DEFAULT_API_FORMAT)
    }
  }

  return requestObject
}

export const generatePropsObject = (responseObject) => {
  let propsObject = {

    hasReturnBefore: responseObject.isReturnBefore ? appConfig.FILLED_RETURN_BEFORE[0] : appConfig.FILLED_RETURN_BEFORE[1],
    filingReturnFirstTime: responseObject.isCraFirstTime ? appConfig.CRA_ACCOUNT_OPTIONS[0] : appConfig.CRA_ACCOUNT_OPTIONS[1],
    dateOfLanding: moment(responseObject.dateOfLandingCanadaVal),
    taxYears: responseObject.taxYears,
    firstname: responseObject.firstName,
    lastname: responseObject.lastName,
    sin: responseObject.sin,
    dateOfBirth: moment(responseObject.dobVal),
    unit: responseObject.unit,
    streetNumber: responseObject.streetNumber,
    streetName: responseObject.streetName,
    city: responseObject.city,
    province: responseObject.province,
    postalCode: responseObject.postalCode,
    email: responseObject.email,
    phone: responseObject.phoneNumber,
    maritalStatus: responseObject.familyDetails.maritalStatus ? appConfig.MARITAL_STATUS[0] : appConfig.MARITAL_STATUS[1],
    dateOfMarriage: moment(responseObject.familyDetails.domVal),
    spouseHasFilledReturnBefore: responseObject.familyDetails.isReturnBefore ? appConfig.SPOUSE_FILLED_RETURN_BEFORE[0] : appConfig.SPOUSE_FILLED_RETURN_BEFORE[1],
    spouseFilingReturnFirstTime: responseObject.familyDetails.isCraFirstTime ? appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0] : appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[1],
    spouseDateOfLanding: moment(responseObject.familyDetails.dateOfLandingCanadaVal),
    spouseTaxYear: responseObject.familyDetails.taxYears,
    spouseFirstname: responseObject.familyDetails.firstName,
    spouseLastname: responseObject.familyDetails.lastName,
    spouseSIN: responseObject.familyDetails.sin,
    spouseDateOfBirth: moment(responseObject.familyDetails.dobVal),
    spouseEmail: responseObject.familyDetails.email,
    spouseContactNumber: responseObject.familyDetails.phoneNumber,
    hasDependants: responseObject.familyDetails.isDependant ? appConfig.HAS_DEPENDENTS[0] : appConfig.HAS_DEPENDENTS[1],
    dependantsNumber: responseObject.familyDetails.isDependant ? responseObject.familyDetails.dependents.length : '',
    dependants: [],
    referenceDetails: responseObject.referenceDetails,
    // editDependants: [],
    hasMove: responseObject.residentDetails.isMoved ? appConfig.HAS_MOVE[0] : appConfig.HAS_MOVE[1],
    dateOfMove: moment(responseObject.residentDetails.oldProvinceChangeDateVal),
    provinceTerritory: responseObject.residentDetails.oldProvince,
    sameHomeAddress: responseObject.residentDetails.mailingAddressSame ? appConfig.SAME_HOME_ADDRESS[0] : appConfig.SAME_HOME_ADDRESS[1],
    currentlyLive: responseObject.residentDetails.liveProvince,
    immigrationStatus: responseObject.residentDetails.immigrationStatus,
    CRAAccount: responseObject.craDetails.isRegCra ? appConfig.CRA_ACCOUNT_OPTIONS[0] : appConfig.CRA_ACCOUNT_OPTIONS[1],
    rentTaxAmount: responseObject.craDetails.rentTaxAmount,
    isInvesting: responseObject.craDetails.isInvesting ? appConfig.INVESTING_RRSP_TFSA[0] : appConfig.INVESTING_RRSP_TFSA[1],
    donationAmount: responseObject.craDetails.donationAmount,
    isMedicalExpenses: responseObject.craDetails.isMedicalExpenses ? appConfig.MEDICAL_EXPENSES[0] : appConfig.MEDICAL_EXPENSES[1],
    unpaidAmount: responseObject.unpaidAmount,
    notes: responseObject.notes || '',
    paidAmount: responseObject.paidAmount,
    isRecommended: responseObject.isRecommended,
    isPayEnable: responseObject.isPayEnable,
    packageId: responseObject.packageId,
    displayAppId: responseObject.applicationId,
    planName: responseObject.planName,
    documents: responseObject.documents,
    documentsDownloadLink: responseObject.documentsZipLink,
    applicationLink: responseObject.applicationLink,
    paymentTransactions: responseObject.paymentTransactions || [],

  }

  if (responseObject.familyDetails.isDependant) {
    let allDependents = []
    responseObject.familyDetails.dependents.forEach((eachDependent) => {
      allDependents.push({
        id: eachDependent.id,
        _id: eachDependent._id,
        firstName: eachDependent.firstName,
        lastName: eachDependent.lastName,
        dob: moment(eachDependent.dobVal),
        sin: eachDependent.sin
      })
    })
    propsObject['dependants'] = allDependents
  }

  if (responseObject.depositInformation) {
    propsObject = {
      ...propsObject,
      transitInformation: responseObject.depositInformation.transit,
      institutionInformation: responseObject.depositInformation.institution,
      accountInformation: responseObject.depositInformation.account
    }
  }

  // if (responseObject.familyDetails.isDependant) {
  //   responseObject.familyDetails.dependents.forEach((eachDependent, index) => {
  //     propsObject.dependants.push({
  //       firstName: { value: eachDependent.firstName, error: null },
  //       lastName: { value: eachDependent.lastName, error: null },
  //       sin: { value: eachDependent.sin, error: null },
  //       dob: { value: eachDependent.dob, error: null }
  //     })
  //   })
  // }

  return propsObject
}

export const getDocumentThumbnail = (documentType) => {
  let thumbnail = ''
  if (appConfig.ALLOWED_FILE_TYPES.IMAGE.includes(documentType)) {
    thumbnail = ImageAssets.taxform.imgIcon
  } else if (appConfig.ALLOWED_FILE_TYPES.PDF.includes(documentType)) {
    thumbnail = ImageAssets.taxform.pdfIcon
  } else if (appConfig.ALLOWED_FILE_TYPES.EXCEL.includes(documentType)) {
    thumbnail = ImageAssets.taxform.excelIcon
  } else {
    thumbnail = ImageAssets.taxform.docIcon
  }
  return thumbnail
}

export const getStatusClassNames = (status) => {
  let className = ''
  if (appConfig.APPLICATION_STATUS_CLASS[status]) {
    className = appConfig.APPLICATION_STATUS_CLASS[status]
  } else {
    className = appConfig.APPLICATION_STATUS_CLASS['New']
  }
  return className
}

export const getPaymentStatusClassNames = (status) => {
  let className = ''
  if (appConfig.PAYMENT_STATUS[status]) {
    className = appConfig.PAYMENT_STATUS[status]
  } else {
    className = appConfig.PAYMENT_STATUS['Unpaid']
  }
  return className
}

export const mobileNumberFormat = (number) => {
  if (!number) { number = '' }
  number = number.slice(0, 12)
  let formatedNumber = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  number = !formatedNumber[2] ? formatedNumber[1] : formatedNumber[1] + '-' + formatedNumber[2] + (formatedNumber[3] ? '-' + formatedNumber[3] : '')
  return number
}

export const downloadDocument = (link, fileName) => () => {
  setAuthToken(false)
  setLoading(true)
  axios({
    url: link,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    FileDownload(response.data, fileName)
    setLoading(false)
    setAuthToken(localStorage.token)
  })
}

export const downloadZipFile = async (link, fileName) => {
  let response = await getDocument(link)
  FileDownload(response.data, fileName)
}

export const getDocument = async (link) => {
  setAuthToken(false)
  setLoading(true)
  let response = await axios({
    url: link,
    method: 'GET',
    responseType: 'blob'
  })
  // .then((response) => {
  setLoading(false)
  setAuthToken(localStorage.token)
  return response
  // })
}

export const downloadDoc = (link, fileName) => {
  setAuthToken(false)
  setLoading(true)
  axios({
    url: link,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    FileDownload(response.data, fileName)
    setLoading(false)
    setAuthToken(localStorage.token)
  })
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}