import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Button from '../common/Button'
import ImageAssets from '../../utils/ImageAssets'
import setLoading from '../../utils/LoadingManager'
import { Labels, Messages, TooltipMessages, Placeholders, InputIds } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { getDocumentThumbnail, getKeyByValue } from '../../utils/Utils'
import UploadDocumentPopup from '../screen-popup/DocumentUploadPopup'
import { getDocumentTypes } from '../../actions/documentTypesActions'
import TextField from '../common/TextField'

class UploadDocuments extends Component {

  constructor(props) {
    super(props)
    let ownDocuments = []
    let spouseDocuments = []
    let dependant1Documents = []
    let dependant2Documents = []
    let dependant3Documents = []
    let dependant4Documents = []
    let dependant5Documents = []
    let dependant6Documents = []
    let adminDocuments = []
    this.props.applicationData.documents.forEach((eachDocument) => {
      if (eachDocument.uploadLink !== '') {
        if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
          ownDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
          spouseDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
          dependant1Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
          dependant2Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
          dependant3Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
          dependant4Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
          dependant5Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
          dependant6Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.ADMIN) {
          adminDocuments.push(eachDocument)
        }
      }
    })
    this.state = {
      open: false,
      document: {
        type: '',
        file: '',
        fileType: '',
        fileSize: '',
        fileName: '',
        uploadedFor: ''
      },
      ownDocuments: ownDocuments,
      spouseDocuments: spouseDocuments,
      dependant1Documents: dependant1Documents,
      dependant2Documents: dependant2Documents,
      dependant3Documents: dependant3Documents,
      dependant4Documents: dependant4Documents,
      dependant5Documents: dependant5Documents,
      dependant6Documents: dependant6Documents,
      adminDocuments: adminDocuments,
      deletedDocuments: [],
      uplaodDocuments: [],

      depositInformation: {
        transitInformation: this.props.applicationData.transitInformation ? this.props.applicationData.transitInformation : '',
        institutionInformation: this.props.applicationData.institutionInformation ? this.props.applicationData.institutionInformation : '',
        accountInformation: this.props.applicationData.accountInformation ? this.props.applicationData.accountInformation : ''
      },
      notes: this.props.applicationData.notes,
      activeBlockIndex: '',
      hasError: false
    }
  }

  componentDidMount() {
    let requestObject = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortHeader: appConfig.DEFAULT_SORTING_FIELD,
      sortDirection: appConfig.DEFAULT_SORTING,
      isPagination: false
    }
    this.props.getDocumentTypes(this.props.userId, requestObject)
  }

  onOpenPopupClick = () => () => {
    this.setState({ open: true })
  }

  onCancelClick = () => {
    this.setState({ open: false, uplaodDocuments: [], hasError: false })
  }

  onFileTypeChange = (value) => () => {
    let allDocuments = this.state.uplaodDocuments
    if (allDocuments.length > 0 && !this.state.document.type) {
      allDocuments.map(eachObj => {
        eachObj.type = value
        return (eachObj)
      })
    }
    this.setState({ document: { ...this.state.document, type: value }, uplaodDocuments: allDocuments })
  }

  onFileForChange = (value) => () => {
    let allDocuments = this.state.uplaodDocuments
    if (allDocuments.length > 0 && !this.state.document.type) {
      allDocuments.map(eachObj => {
        eachObj.uploadedFor = value
        return (eachObj)
      })
    }
    this.setState({ document: { ...this.state.document, uploadedFor: value }, uplaodDocuments: allDocuments })
  }

  onFileLoad = (event) => {
    event.preventDefault()
    var target = event.target || event.srcElement
    if (target.value.length !== 0) {
      // let reader = new FileReader()
      let files = target.files
      let fileArray = []

      if (this.props.role) {
        let totalDocs = this.state.ownDocuments.length + this.state.spouseDocuments.length + this.state.dependant1Documents.length + this.state.dependant2Documents.length + this.state.dependant3Documents.length + this.state.dependant4Documents.length + this.state.dependant5Documents.length + this.state.dependant6Documents.length + files.length + this.state.uplaodDocuments.length
        if ((this.props.role === appConfig.ROLE.USER) && (totalDocs === appConfig.MAX_LENGTH.DOCUMENTS)) {
          toast.error(ErrorMessages.MAXIMUM_FILE_LIMIT_REACHED)
        }
      }

      for (let index in files) {
        let reader = new FileReader()
        let file = files[index]
        //let error = false


        if (typeof (file) === "object") {

          reader.onloadend = () => {
            if (appConfig.ALLOWED_FILE_TYPES.ALL.includes(file.type)) {
              if (appConfig.ALLOWED_FILE_SIZE > file.size) {
                let fileObject = {
                  file: reader.result,
                  fileType: file.type,
                  fileSize: file.size,
                  fileName: file.name,
                  type: this.state.document.type,
                  uploadedFor: this.state.document.uploadedFor
                }
                fileArray.push(fileObject)
                setTimeout(() => {
                  setLoading(true)
                  if (this.props.role) {
                    if (!this.state.hasError && (Number(index) + 1 === files.length)) {
                      this.setState({ document: { ...this.state.document, ...fileObject }, uplaodDocuments: [...this.state.uplaodDocuments, ...fileArray] })
                    }
                  }
                  setLoading(false)
                }, 1000)
              } else {
                this.setState({
                  hasError: false
                })
                toast.error(ErrorMessages.FILE_SIZE_NOT_ALLOWED)
              }
            } else {
              this.setState({
                hasError: false
              })
              toast.error(ErrorMessages.FILE_TYPE_NOT_ALLOWED)
            }
          }
          reader.readAsDataURL(file)

        }
      }

    }
  }

  onSelectedFileDelete = (items, index) => (e) => {
    let allDocuments = this.state.uplaodDocuments
    allDocuments.splice(index, 1)
    this.setState({ document: { ...this.state.document, file: '', fileType: '', fileSize: '', fileName: '' }, uplaodDocuments: allDocuments })
  }

  onSubmitClick = () => {

    let uplaodedItems = []
    this.state.uplaodDocuments.map(eachItem => {
      const object = {
        uploadLink: eachItem.file,
        fileName: eachItem.fileName,
        fileSize: eachItem.fileSize,
        type: eachItem.fileType,
        documentTypeId: getKeyByValue(this.props.documentTypesList, this.state.document.type),
        uploadedFor: this.state.document.uploadedFor
      }
      uplaodedItems.push(object)
      return uplaodedItems
    })
    // let swithId = '0';
    // switch (this.state.document.uploadedFor) {
    //   case appConfig.DOCUMENTS_FOR.APPLICANT:
    //     swithId = '0'
    //     break;
    //   case appConfig.DOCUMENTS_FOR.SPOUSE:
    //     swithId = '1'
    //     break;
    //   case appConfig.DOCUMENTS_FOR.ADMIN:
    //     swithId = '2'
    //     break;
    //   default:
    //     swithId = '3'
    //     break;
    // }
    // this.onBlockChange(swithId);
    let ownDocuments = []
    let spouseDocuments = []
    let dependent1 = []
    let dependent2 = []
    let dependent3 = []
    let dependent4 = []
    let dependent5 = []
    let dependent6 = []
    let adminDocuments = []

    uplaodedItems.map(item => {

      let document = {}
      if (item.uploadedFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
        ownDocuments.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
        spouseDocuments.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
        dependent1.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
        dependent2.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
        dependent3.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
        dependent4.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
        dependent5.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
        dependent6.push(item)
      } else if (item.uploadedFor === appConfig.DOCUMENTS_FOR.ADMIN) {
        adminDocuments.push(item)
      }
      return document
    })

    this.setState({
      // ...document,
      document: { type: '', file: '', fileType: '', fileSize: '', fileName: '', uploadedFor: '' },
      open: false,
      ownDocuments: [...this.state.ownDocuments, ...ownDocuments],
      spouseDocuments: [...this.state.spouseDocuments, ...spouseDocuments],
      dependant1Documents: [...this.state.dependant1Documents, ...dependent1],
      dependant2Documents: [...this.state.dependant2Documents, ...dependent2],
      dependant3Documents: [...this.state.dependant3Documents, ...dependent3],
      dependant4Documents: [...this.state.dependant4Documents, ...dependent4],
      dependant5Documents: [...this.state.dependant5Documents, ...dependent5],
      dependant6Documents: [...this.state.dependant6Documents, ...dependent6],
      adminDocuments: [...this.state.adminDocuments, ...adminDocuments],
      uplaodDocuments: [],
      hasError: false
    })

  }

  onFileDelete = (documentFor, fileId) => () => {
    let files = []
    // if (this.props.role === appConfig.ROLE.USER) {
    //   files = this.state.ownDocuments
    // } else {
    //   files = this.state.adminDocuments
    // }
    if (documentFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
      files = this.state.ownDocuments
    } else if (documentFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
      files = this.state.spouseDocuments
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
      files = this.state.dependant1Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
      files = this.state.dependant2Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
      files = this.state.dependant3Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
      files = this.state.dependant4Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
      files = this.state.dependant5Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
      files = this.state.dependant6Documents
    } else if (documentFor === appConfig.DOCUMENTS_FOR.ADMIN) {
      files = this.state.adminDocuments
    }
    let document = files[fileId]
    files.splice(fileId, 1)
    let deletedDocuments = this.state.deletedDocuments
    if (document.uploadLink.includes('https://')) {
      deletedDocuments.push(document)
    }
    let updatedDocuments = {}
    if (documentFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
      updatedDocuments = { ownDocuments: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
      updatedDocuments = { spouseDocuments: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
      updatedDocuments = { dependant1Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
      updatedDocuments = { dependant2Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
      updatedDocuments = { dependant3Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
      updatedDocuments = { dependant4Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
      updatedDocuments = { dependant5Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
      updatedDocuments = { dependant6Documents: files }
    } else if (documentFor === appConfig.DOCUMENTS_FOR.ADMIN) {
      updatedDocuments = { adminDocuments: files }
    }
    // if (this.props.role === appConfig.ROLE.USER) {
    //   updatedDocuments = { ownDocuments: files }
    // } else {
    //   updatedDocuments = { adminDocuments: files }
    // }
    this.setState({ ...updatedDocuments, deletedDocuments: deletedDocuments })
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.TRANSIT) {
      this.setState({ depositInformation: { ...this.state.depositInformation, transitInformation: event.target.value } })
    } else if (inputId === InputIds.INSTITUTION) {
      this.setState({ depositInformation: { ...this.state.depositInformation, institutionInformation: event.target.value } })
    } else if (inputId === InputIds.ACCOUNT) {
      this.setState({ depositInformation: { ...this.state.depositInformation, accountInformation: event.target.value } })
    }
  }

  onNotesChange = (inputId) => (event) => {
    if (inputId === InputIds.NOTES) {
      this.setState({ notes: event.target.value })
    }
  }

  onBlockChange = (id) => () => {
    if (this.state.activeBlockIndex === id) {
      this.setState({ activeBlockIndex: '' })
    } else {
      this.setState({ activeBlockIndex: id })
    }
  }

  onSaveClick = () => {
    this.props.onClick({
      documents: [...this.state.ownDocuments, ...this.state.spouseDocuments, ...this.state.dependant1Documents, ...this.state.dependant2Documents, ...this.state.dependant3Documents, ...this.state.dependant4Documents, ...this.state.dependant5Documents, ...this.state.dependant6Documents, ...this.state.adminDocuments],
      deletedDocuments: this.state.deletedDocuments,
      transitInformation: this.state.depositInformation.transitInformation,
      institutionInformation: this.state.depositInformation.institutionInformation,
      accountInformation: this.state.depositInformation.accountInformation,
      notes: this.state.notes,
      hasError: false
    })
  }

  render() {
    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="step-heading">
            <span className="step-heading-text font-700">{Messages.UPLOAD_DOCUMENTS}</span>
          </div>
          <div className="form-container">
            <div className="direct-deposit-panel mb-50">
              <div><span className="large-font font-600 black-text">Direct Deposit Information :</span></div>
              <div className="deposit-input-panel d-flex mt-20">
                <div className="deposit-inner-panel mr-20">
                  <div>
                    <TextField
                      parentClassName='form-group'
                      labelText={Labels.TRANSIT}
                      labelClassName='small-font black-text font-600'
                      type='text'
                      className='form-input'
                      disabled={this.props.disabled}
                      placeholder={Placeholders.TYPE_HERE}
                      onChange={this.onInputChange(InputIds.TRANSIT)}
                      value={this.state.depositInformation.transitInformation}
                    />
                  </div>
                </div>
                <div className="deposit-inner-panel mr-20">
                  <div>
                    <TextField
                      parentClassName='form-group'
                      labelText={Labels.INSTITUTION}
                      disabled={this.props.disabled}
                      labelClassName='small-font black-text font-600'
                      type='text'
                      className='form-input'
                      placeholder={Placeholders.TYPE_HERE}
                      onChange={this.onInputChange(InputIds.INSTITUTION)}
                      value={this.state.depositInformation.institutionInformation}
                    />
                  </div>
                </div>
                <div className="deposit-inner-panel">
                  <div>
                    <TextField
                      parentClassName='form-group'
                      labelText={Labels.ACCOUNT}
                      disabled={this.props.disabled}
                      labelClassName='small-font black-text font-600'
                      type='text'
                      className='form-input'
                      placeholder={Placeholders.TYPE_HERE}
                      onChange={this.onInputChange(InputIds.ACCOUNT)}
                      value={this.state.depositInformation.accountInformation}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="upload-title mb-20">
                <span className="large-font font-600 black-text">{Labels.UPLOAD}</span>
              </div>
              <div className="tootltip-container ml-20">
                <div className="tootltip-icon">
                  <img src={ImageAssets.formsvg.tooltipIcon} alt="tooltip-icon" />
                  <div className="tooltip-text secondary-tootltip">
                    <span className="tooltip-text-panel">
                      {TooltipMessages.SUPPORTED_FILES}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {
              this.props.role === appConfig.ROLE.USER || this.props.role === appConfig.ROLE.ADMIN || this.props.role === appConfig.ROLE.OWNER_ADMIN
                ?
                <div className="upload-block">
                  <div className="upload-container">
                    <div className="center drop-icon">
                      <img src={ImageAssets.formsvg.uploadDocumentIcon} alt="drag and drop"></img>
                    </div>
                    <div className="drop-text text-center">
                      <span className="default-font light-gray-text font-600">{Messages.UPLOAD_A_FILE}</span>
                    </div>
                    <Button
                      type='submit'
                      parentClassName='center select-from-computer'
                      className='btn btn-primary btn-medium'
                      text={Messages.UPLOAD_TEXT}
                      onClick={this.onOpenPopupClick()}
                    />
                  </div>
                </div>
                :
                null
            }
            <div className="upload-accordion">
              <Accordion>
                {this.state.ownDocuments.length > 0
                  ?
                  <Card>
                    <Card.Header className={this.state.activeBlockIndex === '0' ? "active" : ''} onClick={this.onBlockChange('0')}>
                      <Accordion.Toggle variant="link" eventKey="0">
                        {Messages.APPLICANT_DOCUMENTS}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="custom-scrollbar">
                        <div className="upload-block">
                          {
                            this.state.ownDocuments.length
                              ?
                              <div className="document-display-container d-flex">
                                {
                                  this.state.ownDocuments.map((eachDocument, index) => {
                                    return (
                                      <div className="document-box" key={index}>
                                        <div className="document-type-name">
                                          <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                          <div className="name-tooltip">
                                            <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                          </div>
                                        </div>
                                        <div className="position-relative" key={index}>
                                          <div className="image-overlay"></div>
                                          <div className="center document-block">
                                            <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                          </div>
                                          <div className="cancel-icon" onClick={this.onFileDelete(appConfig.DOCUMENTS_FOR.APPLICANT, index)}></div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                              :
                              null
                          }
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  : null
                }
                {
                  this.state.spouseDocuments.length > 0
                    ?
                    <Card>
                      <Card.Header className={this.state.activeBlockIndex === '1' ? "active" : ''} onClick={this.onBlockChange('1')}>
                        <Accordion.Toggle variant="link" eventKey="1">
                          {Messages.SPOUSE_DOCUMENTS}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="custom-scrollbar">
                          <div className="upload-block">
                            {
                              this.state.spouseDocuments.length
                                ?
                                <div className="document-display-container d-flex">
                                  {
                                    this.state.spouseDocuments.map((eachDocument, index) => {
                                      return (
                                        <div className="mr-20 mb-20 document-box" key={index}>
                                          <div className="document-type-name">
                                            <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                            <div className="name-tooltip">
                                              <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                            </div>
                                          </div>
                                          <div className="position-relative" key={index}>
                                            <div className="image-overlay"></div>
                                            <div className="center document-block">
                                              <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                            </div>
                                            <div className="cancel-icon" onClick={this.onFileDelete(appConfig.DOCUMENTS_FOR.SPOUSE, index)}></div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                :
                                null
                            }
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    : null
                }
                {this.state.dependant1Documents.length > 0 || this.state.dependant2Documents.length > 0 || this.state.dependant3Documents.length > 0 || this.state.dependant4Documents.length > 0 || this.state.dependant5Documents.length > 0 || this.state.dependant6Documents.length > 0
                  ?
                  <Card>
                    <Card.Header className={this.state.activeBlockIndex === '2' ? "active" : ''} onClick={this.onBlockChange('2')}>
                      <Accordion.Toggle variant="link" eventKey="2">
                        {Messages.DEPENDENT_DOCUMENTS}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="custom-scrollbar">
                        <div className="row">
                          {
                            this.props.applicationData.dependants.map((eachValue, index) => {
                              let documents = 'dependant' + (index + 1) + 'Documents'
                              return (
                                this.state[documents].length > 0 ?
                                  <div className="col-lg-12 mb-20" key={index}>
                                    <div className="dependent-title">
                                      <span className="dependent-title-text font-700">
                                        Dependant {index + 1}'s Documents : 
                                      </span>
                                    </div>
                                    <div className="upload-block">
                                      {
                                        this.state[documents]
                                          ?
                                          <div className="document-display-container d-flex">
                                            {
                                              this.state[documents].map((eachDocument, subIndex) => {
                                                return (
                                                  <div className="mr-20 mb-20 document-box" key={subIndex}>
                                                    <div className="document-type-name">
                                                      <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                      <div className="name-tooltip">
                                                        <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                      </div>
                                                    </div>
                                                    <div className="position-relative" key={index}>
                                                      <div className="image-overlay"></div>
                                                      <div className="center document-block">
                                                        <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                                      </div>
                                                      <div className="cancel-icon" onClick={this.onFileDelete(eachDocument.uploadedFor, subIndex)}></div>
                                                    </div>
                                                  </div>
                                                )
                                              })
                                            }
                                          </div>
                                          :
                                          null
                                      }
                                    </div>
                                  </div>
                                  : null
                              )
                            })
                          }
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  : null
                }
                {this.state.adminDocuments.length
                  ?
                  <Card>
                    <Card.Header className={this.state.activeBlockIndex === '3' ? "active" : ''} onClick={this.onBlockChange('3')}>
                      <Accordion.Toggle variant="link" eventKey="3">
                        {Messages.ADMIN_DOCUMENTS}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="custom-scrollbar">
                        <div className="upload-block">
                          {
                            this.state.adminDocuments.length
                              ?
                              <div>
                                <div className="document-display-container d-flex">
                                  {
                                    this.state.adminDocuments.map((eachDocument, index) => {
                                      return (
                                        <div className="mr-20 mb-20 document-box" key={index}>
                                          <div className="document-type-name">
                                            <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                            <div className="name-tooltip">
                                              <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                            </div>
                                          </div>
                                          <div className="position-relative">
                                            <div className="image-overlay"></div>
                                            <div className="center document-block">
                                              <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                            </div>
                                            {
                                              this.props.role === appConfig.ROLE.ADMIN || this.props.role === appConfig.ROLE.OWNER_ADMIN
                                                ?
                                                <div className="cancel-icon" onClick={this.onFileDelete(appConfig.DOCUMENTS_FOR.ADMIN, index)}></div>
                                                :
                                                null
                                            }
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              :
                              null
                          }
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  : null
                }
              </Accordion>
            </div>
            <div className="document-checklist-block">
              <div className="document-checklist-heading">
                <span className="document-checklist-heading-text">Document Checklist</span>
              </div>
              <div className="document-checklist-subheading">
                <span className="document-checklist-subheading-text">It's very important you can upload any of the following documents you have using step 1 above.</span>
              </div>
              <div className="document-point-block">
                <div className="document-point-panel">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Any T- slips (T3, T4, T5 etc).
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Receipts for medical (medication, chiropractic etc).
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Copy of last years tax return.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Annual student loan interest statement. If you have student loan interest.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="document-point-panel">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Receipts for donations.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Rent Receipts or property taxes.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          T2202A (tuition receipt).
                        </span>
                      </div>
                      <div className="point-panel green-tick-icon second-point">
                        <span className="point-panel-text">
                          Any R or RL Slips (RL8, R1, RL31 etc.)
                          </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel green-tick-icon">
                        <span className="point-panel-text">
                          Mortgage statement, Property taxes, repairs and maintenance & insurance (only required if you have a small business or working from home).
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="document-point-panel">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="point-panel blue-tick-icon">
                        <span className="point-panel-text">
                          For business returns bring your income (either a listing or spreadsheet which includes income for year, expenses, and if your HST registered your access code), list of expenses, your home expenses (your utilities, mortgage statements, pro taxes, if you are HST registered, we need your HST return as well), if applicable bring your vehicle expenses.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel blue-tick-icon">
                        <span className="point-panel-text">
                          For business returns and/or employment expenses for your vehicle expenses we will need the following: gas, insurance, repairs/maintenance, vehicle sticker registration, lease payments or purchase agreements, logbook of mileage, parking expenses.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel blue-tick-icon">
                        <span className="point-panel-text">
                          For rental returns please bring your income statement, mortgage statements, utilities, property tax, condo fees, repairs maintenance, insurance, legal and accounting fees.
                        </span>
                      </div>

                    </div>
                    <div className="col-lg-3">
                      <div className="point-panel blue-tick-icon">
                        <span className="point-panel-text">
                          If you have investments or stock trading we need the gain/loss report.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="notes-panel mt-30">
              <label className="default-font black-text font-600">Notes & Comments</label>
              <div className="form-group">
                <textarea className="form-input h-auto" placeholder="Enter Text" rows="5" disabled={this.props.role === appConfig.ROLE.ACCOUNTANT} onChange={this.onNotesChange(InputIds.NOTES)} value={this.state.notes}></textarea>
              </div>
            </div>
            <Button
              type='submit'
              parentClassName='mt-30'
              className='btn btn-primary btn-large'
              text={Messages.SAVE_AND_SUBMIT}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
        <UploadDocumentPopup
          open={this.state.open}
          document={this.state.document}
          onFileTypeChange={this.onFileTypeChange}
          onFileLoad={this.onFileLoad}
          onFileDelete={this.onSelectedFileDelete}
          onCancelClick={this.onCancelClick}
          onSubmitClick={this.onSubmitClick}
          onFileForChange={this.onFileForChange}
          disabledButton={this.state.uplaodDocuments.length > 0 && this.state.document.type}
          depedentCount={this.props.applicationData.dependants.length}
          applicationData={this.props.applicationData}
          role={this.props.role}
          uplaodDocuments={this.state.uplaodDocuments}
        />
      </div >
    )
  }
}

UploadDocuments.propTypes = {}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  role: state.auth.role,
  documentTypesList: state.list.documentTypesList
})

export default connect(mapStateToProps, { getDocumentTypes })(UploadDocuments)
