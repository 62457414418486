import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { GET_DOCUMENT_TYPES } from '../constants/ActionTypes'
import {  getSingleApplicationForDocument } from './applicationActions'

export const getDocumentTypes = (userId, requestObject , applicationId , reqBody) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.DOCUMENT_TYPES.GET + userId + '/list', requestObject)
    .then(res => {
      dispatch({
        type: GET_DOCUMENT_TYPES,
        payload: res.data.result.data
      })
      if(applicationId){
        getSingleApplicationForDocument(userId, applicationId, dispatch ,reqBody )
      }else {
        setLoading(false)
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getDocumentTypesOnRefresh = (userId, requestObject, dispatch) => {
 setLoading(true)
  axios.post(APIRoutes.DOCUMENT_TYPES.GET + userId + '/list', requestObject)
    .then(res => {
      dispatch({
        type: GET_DOCUMENT_TYPES,
        payload: res.data.result.data
      })
    setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}