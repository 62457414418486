import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { appConfig } from '../constants/AppConfig'
import { UIRoutes } from '../constants/FrontendRoutes'

const OwnerAdminPrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated && (auth.role === appConfig.ROLE.OWNER_ADMIN||auth.role === appConfig.ROLE.ADMIN)
        ?
        <Component {...props} />
        :
        <Redirect to={UIRoutes.LOGIN} />
    }
  />
)

OwnerAdminPrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(OwnerAdminPrivateRoute)