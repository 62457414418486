import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ImageAssets from '../../utils/ImageAssets'
import Button from '../common/Button'
import { Messages } from '../../constants/StaticTexts'

class ChooseStatus extends Component {

  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="application-confirmation-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onOkClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="center">
            <img src={ImageAssets.popup.applicationConfirmationIcon} alt="logo"></img>
          </div>
          <div className="title-panel text-center mt-20">
            <span className="title-panel-text font-600">
              {Messages.APP_CONFIRMATION_POPUP}
            </span>
          </div>
          <Button
            type='submit'
            parentClassName="mt-30 center"
            className={'btn btn-small btn-primary'}
            text={Messages.OKAY}
            onClick={this.props.onOkClick}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

ChooseStatus.propTypes = {}

export default ChooseStatus
