import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ImageAssets from '../../utils/ImageAssets';

class SessionSelection extends Component {

  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="session-selection-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="text-center">
            <span className="large-font black-text font-600">
              Your previous session was incomplete.
            </span>
          </div>
          <div className="text-center mt-10">
            <span className="large-font black-text font-600">
              Do you want start new or continue with same ?
            </span>
          </div>
          <div className="select-block">
            <div className="row no-gutters align-items-center">
              <div className="col-6 center" onClick={this.props.onSubmitClick(false)}>
                <div className="selection-container incomplete">
                </div>
              </div>
              <div className="col-6 center" onClick={this.props.onSubmitClick(true)}>
                <div className="selection-container new">
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

SessionSelection.propTypes = {}

export default SessionSelection
