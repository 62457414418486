export const ErrorMessages = {
  COMMON_ERROR_MESSAGE: 'Something Went Wrong. Please Try After Sometime',
  EMAIL_OR_PHONE_NOT_VALID: 'Please enter a valid email address or mobile number',
  PASSWORD_NOT_VALID: 'Password must be 8-16 characters with at least one uppercase, one lowercase, one number and one of special character !@#$%^&*',
  FIRSTNAME_NOT_VALID: 'First name must be between 1-50 characters',
  LASTNAME_NOT_VALID: 'Last name must be between 1-50 characters',
  NAME_NOT_VALID: 'Full name must be between 1-50 characters',
  EMAIL_NOT_VALID: 'Email address is not valid',
  MOBILE_NOT_VALID: 'Mobile number is not valid',
  DATE_OF_BIRTH_NOT_VALID: 'Date of birth is not valid',
  SIN_IS_NOT_VALID: 'SIN is not valid',
  UNIT_IS_NOT_VALID: 'Unit is not valid',
  STREET_NUMBER_NOT_VALID: 'Street number is not valid',
  STREET_NAME_NOT_VALID: 'Street name is not valid',
  POSTAL_CODE_NOT_VALID: 'Postal code is not valid',
  DATE_OF_MARRIAGE_NOT_VALID: 'Date of marriage is not valid',
  SPOUSE_FIRSTNAME_NOT_VALID: 'Spouse first name must be 1-50 characters',
  SPOUSE_LASTNAME_NOT_VALID: 'Spouse last name must be 1-50 characters',
  SPOUSE_SIN_IS_NOT_VALID: 'Spouse SIN is not valid',
  SPOUSE_DATE_OF_BIRTH_NOT_VALID: 'Spouse date of birth is not valid',
  SPOUSE_EMAIL_NOT_VALID: 'Spouse email address is not valid',
  SPOUSE_CONTACT_NOT_VALID: 'Spouse contact number is not valid',
  DEPENDENT_FIRSTNAME_NOT_VALID: 'Dependent first name must be 1-50 characters',
  DEPENDENT_LASTNAME_NOT_VALID: 'Dependent last name must be 1-50 characters',
  DEPENDENT_SIN_NOT_VALID: 'Dependent SIN is not valid',
  DEPENDENT_DOB_NOT_VALID: 'Dependent date of birth is not valid',
  DATE_OF_MOVE_NOT_VALID: 'Date of move is not valid',
  DATE_OF_LANDING_NOT_VALID: 'Date of landing is not valid',
  MAXIMUM_FILE_LIMIT_REACHED: 'You can add upto 20 files only',
  FILE_TYPE_NOT_ALLOWED: 'File type not supported',
  FILE_SIZE_NOT_ALLOWED: 'File size should be less then or equal to 5 MB',
  MESSAGE_NOT_VALID: 'Message must be between 10-100 characters',
  ADDRESS_NOT_VALID: 'Address is not valid',
  AMOUNT_NOT_VALID: 'Entered amount is not valid',
  FILL_RETURN_WITH_US : 'select the ans',
  ENTER_AMOUNT : 'enter Tax'
}