import React, { Component } from 'react'
import { CHANGE_STEP } from '../../constants/ActionTypes'
import { appConfig } from '../../constants/AppConfig'
import { Messages } from '../../constants/StaticTexts'
import store from '../../store'

class Steps extends Component {

  onStepClick = (step) => () => {
    store.dispatch({
      type: CHANGE_STEP,
      payload: { currentStep: step }
    })
  }

  render() {
    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="stepper-block">
            <div className='d-flex justify-content-between align-items-center stepper-heading'>
              <div className="heading-panel">
                <span className="heading-panel-text black-text font-700">
                  {Messages.YOUR_TAX_RETURN}
                </span>
              </div>
              <div className="bullet-point">
                <span className="small-font black-text bullet-point-text"><span className="font-700 default-font">Note:</span> You can Revise Application to Submit the Documents or Missing Details.</span>
              </div>
            </div>
            <div className="center stepper-container justify-content-between">
              {
                appConfig.APPLICATION_STEPS.map((eachStep, index) => {
                  let className = eachStep.CLASSNAME + (index === this.props.currentStep ? ' in-progress' : (this.props.isEditing ? ' completed' : (index < this.props.step ? ' completed' : '')))
                  return (
                    <div className={"step-block " + className + (index <= this.props.step ? ' cursor-pointer' : '')} key={index} onClick={index <= this.props.step ? this.onStepClick(index) : null}>
                      <span className="step-block-text font-600">
                        {eachStep.NAME}
                      </span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Steps.propTypes = {}

export default Steps
