import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import CustomDropDownWithSearchBox from '../common/CustomDropDownWithSearchBox'
import { Placeholders, Messages, InputIds } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import { appConfig } from '../../constants/AppConfig'
import { usersListAction } from '../../actions/userActions'

class AssignApplicationPopup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: ''
    }
  }

  componentDidMount() {
    let requestBody = {
      limit: appConfig.PAGINATION_LIMIT,
      page: 1,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: '' },
        { columnName: 'role', type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: [appConfig.ROLE.ACCOUNTANT] }
      ],
      filterBy: 'All Time',
      sortDirection: appConfig.DEFAULT_SORTING,
      sortHeader: appConfig.DEFAULT_SORTING_FIELD,
      isPagination: false
    }
    this.props.usersListAction(this.props.userId, requestBody)
  }

  onSelectedUserChange = (value) => {
    let accountantId = ''
    for (let eachUser of this.props.usersList.list) {
      if (value === (eachUser.firstName + ' ' + eachUser.lastName)) {
        accountantId = eachUser._id
        break
      }
    }
    this.setState({ selectedUser: value })
    this.props.onSelectedUserChange(accountantId)
  }

  onSubmitClick = () => {
    this.props.onSubmitClick()
    this.setState({ selectedUser: '' })
  }

  render() {
    let disabledButton = this.props.selectedAccountantId
    let allAccountantList = []
    this.props.usersList.list.forEach((eachUser) => {
      allAccountantList.push(eachUser.firstName + ' ' + eachUser.lastName)
    })
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="assign-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="upload-popup-heading">
            <span className="upload-popup-heading-text font-700">
              Assign to Accountant
              </span>
          </div>
          <div className="cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="upload-popup">
            <div className="form-field-row mb-50">
              <div className="form-field">
                <CustomDropDownWithSearchBox
                  parentClassName='form-group'
                  labelText='Select Accountant'
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  onChange={this.onSelectedUserChange}
                  placeholder={Placeholders.SELECT}
                  list={allAccountantList}
                  value={this.state.selectedUser}
                />
              </div>
            </div>
            <Button
              type='submit'
              parentClassName='yes-btn center'
              className={'btn btn-primary btn-small' + (disabledButton ? '' : ' disabled')}
              text={Messages.SUBMIT}
              disabled={!disabledButton}
              onClick={this.onSubmitClick}
            />
          </div>
        </DialogContent>
      </Dialog>

    )
  }
}

AssignApplicationPopup.propTypes = {}

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userId: state.auth.userId,
  usersList: state.list.usersList
})

export default connect(mapStateToProps, { usersListAction })(AssignApplicationPopup)
