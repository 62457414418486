import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { appConfig } from '../constants/AppConfig'
import { UIRoutes } from '../constants/FrontendRoutes'

const RedirectProfileRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated
        ?
        <>
          {
            [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(auth.role)
              ?
              <Redirect to={UIRoutes.APPLICATIONS} />
              :
              <Redirect to={UIRoutes.VIEW_ALL_APPLICATION} />
          }
        </>
        :
        <Component {...props} />
    }
  />
)

RedirectProfileRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(RedirectProfileRoute)