import React, { Component } from 'react'
import ReactExport from 'react-export-excel'
import { connect } from 'react-redux'
import { Labels } from '../../constants/StaticTexts'

class DownloadExcel extends Component {

  render() {
    let ExcelFile = ReactExport.ExcelFile
    let ExcelSheet = ReactExport.ExcelFile.ExcelSheet
    let ExcelColumn = ReactExport.ExcelFile.ExcelColumn
    return (
      this.props.invalidMembers.length
        ?
        <ExcelFile hideElement={true} filename={Labels.EXCEL_FIELDS.FILE_NAME}>
          <ExcelSheet data={this.props.invalidMembers} name={Labels.EXCEL_FIELDS.SHEET_NAME}>
            <ExcelColumn label={Labels.EXCEL_FIELDS.FIRSTNAME} value='firstName' />
            <ExcelColumn label={Labels.EXCEL_FIELDS.LASTNAME} value='lastName' />
            <ExcelColumn label={Labels.EXCEL_FIELDS.EMAIL} value='email' />
            <ExcelColumn label={Labels.EXCEL_FIELDS.PHONE} value='phoneNumber' />
            <ExcelColumn label={Labels.EXCEL_FIELDS.REASON} value='notes' />
          </ExcelSheet>
        </ExcelFile>
        :
        null
    )
  }
}

const mapStateToProps = (state) => ({
  invalidMembers: state.list.invalidMembers
})

export default connect(mapStateToProps, {})(DownloadExcel)