import React, { Component } from 'react'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { Labels, Placeholders, InputIds, Messages, TooltipMessages } from '../../constants/StaticTexts'
import Button from '../common/Button'
import CustomDropDown from '../common/CustomDropDown'
import TextField from '../common/TextField'

class CRAMyAccount extends Component {

  constructor(props) {
    super(props)
    this.state = {
      CRAAccount: this.props.applicationData.CRAAccount,
      isInvesting: this.props.applicationData.isInvesting,
      isMedicalExpenses: this.props.applicationData.isMedicalExpenses,
      rentTaxAmount: {
        value: this.props.applicationData.rentTaxAmount,
        error: null
      },
      donationAmount: {
        value: this.props.applicationData.donationAmount,
        error: null
      }
    }
  }

  onInputChange = (inputId) => (dropdownValue) => (event) => {
    if (inputId === InputIds.CRA_MY_ACCOUNT) {
      if (dropdownValue) {
        this.setState({ CRAAccount: dropdownValue })
      } else {
        this.setState({ CRAAccount: '' })
      }
    } else if (inputId === InputIds.INVESTING_IN_RRSP_TFSA) {
      if (dropdownValue) {
        this.setState({ isInvesting: dropdownValue })
      } else {
        this.setState({ isInvesting: '' })
      }
    } else if (inputId === InputIds.TOTAL_RENT_PROPERTY) {
      if (event.target.value) {
        this.setState({
          rentTaxAmount: {
            value: event.target.value,
            error: event.target.value ? null : ErrorMessages.ENTER_AMOUNT
          }
        })
      } else {
        this.setState({
          rentTaxAmount: {
            value: null,
            error: null
          }
        })
      }
    } else if (inputId === InputIds.TOTAL_CHARITABLE_AMOUNT) {
      if (event.target.value) {
        this.setState({
          donationAmount: {
            value: event.target.value,
            error: null
          }
        })
      } else {
        this.setState({
          donationAmount: {
            value: null,
            error: null
          }
        })
      }
    } else if (inputId === InputIds.HAVE_MEDICAL_EXPANSE) {
      if (dropdownValue) {
        this.setState({ isMedicalExpenses: dropdownValue })
      } else {
        this.setState({ isMedicalExpenses: '' })
      }
    }
  }

  onSaveClick = () => {
    this.props.onClick({
      CRAAccount: this.state.CRAAccount,
      rentTaxAmount: this.state.rentTaxAmount.value,
      isInvesting: this.state.isInvesting,
      donationAmount: this.state.donationAmount.value,
      isMedicalExpenses: this.state.isMedicalExpenses
    })
  }

  render() {

    let disabledButton = this.state.CRAAccount && this.state.isInvesting && this.state.isMedicalExpenses

    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="step-heading">
            <span className="step-heading-text font-700">
              {Messages.CRA_MY_ACCOUNT}
            </span>
          </div>
          <div className="form-container">
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.CRA_MY_ACCOUNT}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.SELECT}
                  disabled={this.props.disabled}
                  onChange={this.onInputChange(InputIds.CRA_MY_ACCOUNT)}
                  list={appConfig.CRA_ACCOUNT_OPTIONS}
                  value={this.state.CRAAccount}
                />
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <div className="tootltip-field">
                  <TextField
                    parentClassName='form-group'
                    labelText={Labels.TOTAL_RENT_TAX}
                    disabled={this.props.disabled}
                    labelClassName='default-font black-text font-600'
                    type='text'
                    className='form-input'
                    inputDivClassName='input-panel'
                    placeholder={Placeholders.TOTAL_RENT_TAX}
                    onChange={this.onInputChange(InputIds.TOTAL_RENT_PROPERTY)}
                    tooltipText={TooltipMessages.ACCOMODATION_SHARED}
                    {...this.state.rentTaxAmount}
                  />
                </div>
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.INVESTING_RRSP_TFSA}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.SELECT}
                  disabled={this.props.disabled}
                  onChange={this.onInputChange(InputIds.INVESTING_IN_RRSP_TFSA)}
                  list={appConfig.INVESTING_RRSP_TFSA}
                  value={this.state.isInvesting}
                />
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.CHARITABLE_DONATION}
                  disabled={this.props.disabled}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.TOTAL_DONATION}
                  onChange={this.onInputChange(InputIds.TOTAL_CHARITABLE_AMOUNT)}
                  {...this.state.donationAmount}
                />
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.MEDICAL_EXPENSE}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.SELECT}
                  disabled={this.props.disabled}
                  onChange={this.onInputChange(InputIds.HAVE_MEDICAL_EXPANSE)}
                  list={appConfig.MEDICAL_EXPENSES}
                  value={this.state.isMedicalExpenses}
                />
              </div>
            </div>
            <Button
              type='submit'
              parentClassName='mt-30'
              className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
              text={Messages.SAVE_AND_CONTINUE}
              disabled={!disabledButton}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

CRAMyAccount.propTypes = {}

export default CRAMyAccount
