import axios from 'axios'
import { toast } from 'react-toastify'
import { ADMIN_EDIT_APPLICATION, APPLICATION_UPDATE, DELETE_APPLICATION, EDIT_APPLICATION, GET_ALL_APPLICATION, GET_SINGLE_APPLICATION, REMOVE_APPLICATION_SETUP, SET_APPLICATION_FILTERS, GET_REFRANCES_LIST } from '../constants/ActionTypes'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { generateRequestBody, generatePropsObject, downloadZipFile } from '../utils/Utils'
import setLoading from '../utils/LoadingManager'
import { UIRoutes } from '../constants/FrontendRoutes'
import { getPlanList } from './paymentActions'

export const increaseApplicationStep = (applicationData) => (dispatch) => {
  setLoading(true)
  let requestBody = generateRequestBody(applicationData)
  if (applicationData.applicationId) {
    axios.put(APIRoutes.APPLICATION.UPDATE + applicationData.userId + '/' + applicationData.applicationId, requestBody)
      .then(res => {
        dispatch({
          type: APPLICATION_UPDATE,
          payload: applicationData
        })
        if (applicationData.step === 5) {
          toast.success(res.data.message)
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  } else {
    axios.post(APIRoutes.APPLICATION.CREATE + applicationData.userId, requestBody)
      .then(res => {
        applicationData['applicationId'] = res.data.result._id
        dispatch({
          type: APPLICATION_UPDATE,
          payload: applicationData
        })
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  }
}

export const updateDocuments = (requestBody, userId, applicationId) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.APPLICATION.UPDATE + userId + '/' + applicationId, requestBody)
    .then(res => {
      getSingleApplicationForDocument(userId, applicationId, dispatch)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getAllApplications = (userId, requestBody) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.APPLICATION.GET_ALL_APPLICATION + userId + '/list', requestBody)
    .then(res => {
      dispatch({
        type: GET_ALL_APPLICATION,
        payload: res.data.result,
        currentPage: requestBody.page
      })
      setLoading(false)
      //getDocumentTypes()
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getSingleApplication = (userId, applicationId, isForEdit, step, currentStep) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.APPLICATION.GET_SINGLE_APPLICATION + userId + '/' + applicationId)
    .then(res => {
      let propsObject = generatePropsObject(res.data.result)
      propsObject['applicationStatus'] = res.data.result.applicationStatus
      propsObject['paymentStatus'] = res.data.result.paymentStatus
      propsObject['createdAt'] = res.data.result.createdAt
      propsObject['applicationId'] = res.data.result._id
      propsObject['userId'] = userId
      dispatch({
        type: isForEdit ? EDIT_APPLICATION : GET_SINGLE_APPLICATION,
        payload: propsObject,
        step: step,
        currentStep: currentStep
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getSingleApplicationForDocument = (userId, applicationId, dispatch , type , reqBody ) => {
  setLoading(true)
  axios.get(APIRoutes.APPLICATION.GET_SINGLE_APPLICATION + userId + '/' + applicationId)
    .then(res => {
      let propsObject = generatePropsObject(res.data.result)
      propsObject['applicationStatus'] = res.data.result.applicationStatus
      propsObject['paymentStatus'] = res.data.result.paymentStatus
      propsObject['createdAt'] = res.data.result.createdAt
      propsObject['applicationId'] = res.data.result._id
      propsObject['userId'] = userId
      dispatch({
        type: GET_SINGLE_APPLICATION,
        payload: propsObject
      })
      if(reqBody){
        getPlanList(reqBody , userId , dispatch)
      }else {
        setLoading(false)
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

// export const onEditApplication = (history) => (dispatch) => {
//   setLoading(true)
//   axios.get(APIRoutes.APPLICATION.GET_ALL_APPLICATION)
//   dispatch({ type: EDIT_APPLICATION })
//   history.push(UIRoutes.TAX_APPLICATION)
// }

export const onDeleteApplication = (userId, applicationId, index) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.APPLICATION.DELETE + userId + '/' + applicationId)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: DELETE_APPLICATION,
        payload: index
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const onAdminEditApplication = (applicationId, userId, history) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.APPLICATION.GET_SINGLE_APPLICATION + userId + '/' + applicationId)
    .then(res => {
      let propsObject = generatePropsObject(res.data.result)
      propsObject['applicationId'] = res.data.result._id
      propsObject['applicationStatus'] = res.data.result.applicationStatus
      dispatch({
        type: ADMIN_EDIT_APPLICATION,
        payload: propsObject
      })
      history.push(UIRoutes.TAX_APPLICATION)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updateApplicationStatus = (userId, applicationId, status) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.APPLICATION.UPDATE_STATUS + userId + '/' + applicationId + '/track/status/' + status)
    .then(res => {
      toast.success(res.data.message)
      getSingleApplicationForDocument(userId, applicationId, dispatch)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const removeApplicationSetup = () => (dispatch) => {
  dispatch({ type: REMOVE_APPLICATION_SETUP })
}

export const getApplicationFilters = (userId) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.APPLICATION.GET_FILTERS + userId + '/filters/list')
    .then(res => {
      dispatch({
        type: SET_APPLICATION_FILTERS,
        payload: res.data.result
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const assignToAccountant = (userId, applicationId, requestBody) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.APPLICATION.ASSIGN_TO_ACCOUNTANT + userId + '/' + applicationId + '/grant/access', requestBody)
    .then(res => {
      toast.success(res.data.message)
      getSingleApplicationForDocument(userId, applicationId, dispatch)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updateApplicationSin = (userId, applicationId, requestBody) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.APPLICATION.UPDATE_SIN + userId + '/' + applicationId + '/update/sin', requestBody)
    .then(res => {
      getSingleApplicationForDocument(userId, applicationId, dispatch)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getRefrancesList = (userId, applicationId, reqBody) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.APPLICATION.GET_REFRANCE + userId + '/reference/list', reqBody)
    .then(res => {
      // getSingleApplicationForDocument(userId, applicationId, dispatch)
      dispatch({
        type: GET_REFRANCES_LIST,
        payload: res.data.result
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const downloadZip = (userId, applicationId ) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.DOWNLOAD_ZIP.GET + userId + '/' + applicationId + '/download/zip')
    .then(async res => {
      if (res.data['result']['documentsZipLink']) {
        setTimeout(async() => {
          await downloadZipFile(res.data['result']['documentsZipLink'], res.data['result']['fileName'])
        },1000)
      }else {
        toast.error("Please try again")
        setLoading(false)
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })

}