import React, { Component } from 'react'
import ImageAssets from '../../utils/ImageAssets'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Newsletter from '../common/Newsletter'
import { Messages } from '../../constants/StaticTexts'

class Services extends Component {

  render() {
    return (
      <div>
        <Header page={Messages.SERVICES} />
        <div className="banner-section inner-banner-section services-banner-section">
          <div className="lotus-pattern-panel"><img src={ImageAssets.home.lotusImg} alt="lotus" className="image-responsive"></img></div>
          <div className="slant-img-panel"><img src={ImageAssets.aboutUs.slantImg} alt="slant" className="image-responsive"></img></div>
          <div className="container-block">
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="section-heading-panel">
                  <h1 className="banner-heading-text light-green-text font-900 mb-20">Services</h1>
                  <h3 className="banner-subheading-text light-gray-text large-font">Whether you want to transform your business, advance your career, or simply find out more about what we are doing, this is the place</h3>
                </div>
              </div>
              <div className="col-lg-7 d-lg-block d-md-none d-none">
                <div className="banner-description text-right">
                  <h1 className="white-text font-900 banner-heading">We help entrepreneurs master their financial lives.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-mobile-section service-mobile-section inner-mobile-section d-lg-none d-md-block">
          <div className="banner-description">
            <h1 className="white-text font-900 banner-heading text-right mb-0">We help entrepreneurs master their financial lives.</h1>
          </div>
        </div>
        <div className="account-service-section section-padding service-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading-panel display-lg-block text-center">
                <h4 className="preheading-text mb-20">ACCOUNTING SERVICES</h4>
              </div>
            </div>
          </div>
          <div className="container-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="service-img-wrapper center h-100">
                  <img src={ImageAssets.services.accountServiceImg} alt="service" className="image-responsive"></img>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-heading-panel display-lg-none">
                  <h4 className="preheading-text mb-20">ACCOUNTING SERVICES</h4>
                </div>
                <div>
                  <p className="default-font service-description">Providing financial information to our clients in a timely and accurate manner is a commitment that we feel cannot be compromised. Meaningful, well-organized financial records ensure that your business operations will run more efficiently on a daily basis. Our firm provides a full range of cost effective accounting services including the following:</p>
                </div>
                <div className="service-list">
                  <div className="service-list-item"><span className="default-font font-700">General ledger & financial statement preparation</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Bookkeeping (Monthly/Quarterly/Annual)</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Accounting system setup for new businesses</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Compilation of financial statements</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Preparation of GST/HST & EHT returns</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Payroll processing</span></div>
                </div>
                <div className="services-block mt-25">
                  <div><span className="light-green-text font-700 large-font">Compilations of Financial Statements</span></div>
                  <div>
                    <p className="default-font mt-10 service-description">We view our role in the financial reporting process as an opportunity to provide constructive solutions for maximizing your company's profitability and efficiency. We provide financial reporting on Compilation basis which is based upon client information provided and used primarily for internal use.</p>
                  </div>
                </div>
                <div className="services-block mt-25">
                  <div><span className="light-green-text font-700 large-font">Cash Flow & Budgeting Analysis</span></div>
                  <div>
                    <p className="default-font mt-10 service-description">A business can be profitable and still run into trouble due to cash flow issues. We can forecast and evaluate your company's financial condition, estimate financing requirements, and track cash-flow sources and uses.</p>
                  </div>
                </div>
                <div className="services-block mt-25">
                  <div><span className="light-green-text font-700 large-font">Financial Forecasts & Projections</span></div>
                  <div>
                    <p className="default-font mt-10 service-description">Forecasting is a necessity when trying to effectively manage and lead a business to profitability. From simple projections to complex financial modeling, our experienced staff can assist you in achieving your company's financial goals.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tax-service-section section-padding service-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-heading-panel">
                  <h4 className="preheading-text mb-20">TAX SERVICES</h4>
                </div>
                <div className="service-img-wrapper black-wrapper h-100 display-lg-block">
                  <img src={ImageAssets.services.taxServiceImg} alt="service" className="image-responsive"></img>
                </div>
                <div>
                  <p className="default-font service-description">We prefer to take a proactive vs. reactive approach to tax services. By keeping current on new tax laws and legislation, we are in a position to identify key tax planning opportunities that minimize both your current and future tax liabilities. We provide our individual and business clients with the taxation expertise and knowledge that they deserve throughout the year. Tax services offered include, but are not limited to:</p>
                </div>
                <div className="service-list">
                  <div className="service-list-item"><span className="default-font font-700">Tax planning for individuals and corporations</span></div>
                  <div className="service-list-item mb-5">
                    <div><span className="default-font font-700">Return preparation for  :</span></div>
                    <div className="d-flex flex-wrap return-for-list">
                      <div className="return-for-list-item"><span>Individuals</span></div>
                      <div className="return-for-list-item"><span>Corporations</span></div>
                      <div className="return-for-list-item"><span>Estates and trusts</span></div>
                      <div className="return-for-list-item"><span>Not-for-profit organizations</span></div>
                    </div>
                  </div>
                  <div className="service-list-item"><span className="default-font font-700">Provincial and federal commodity tax planning and compliance</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Tax considerations in matrimonial settlements</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Tax effects of buying/selling a business</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Tax effective retirement and estate planning</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Tax accounting services (calculation of current and deferred taxes)</span></div>
                  <div className="service-list-item"><span className="default-font font-700">Assistance with CRA or other Government audit</span></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-img-wrapper black-wrapper h-100 display-lg-none">
                  <img src={ImageAssets.services.taxServiceImg} alt="service" className="image-responsive"></img>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="other-service-section section-padding service-section">
          <div className="container-block">
            <div className="row">
              <div className="col-xl-6">
                <div className="service-main-wrapper">
                  <div className="service-img-wrapper top-wrapper black-wrapper">
                    <img src={ImageAssets.services.consultingServiceImg} alt="service" className="image-responsive"></img>
                  </div>
                  <div className="pl-70 flex1">
                    <div className="section-heading-panel">
                      <h4 className="preheading-text mb-20">CONSULTING SERVICES</h4>
                    </div>
                    <div>
                      <p className="default-font service-description">We prefer to take a proactive vs. reactive approach to tax services. By keeping current on new tax laws and legislation, we are in a position to identify key tax planning opportunities that minimize both your current and future tax liabilities. We provide our individual and business clients with the taxation expertise and knowledge that they deserve throughout the year. Tax services offered include, but are not limited to:</p>
                    </div>
                    <div className="service-list">
                      <div className="service-list-item"><span className="default-font font-700">Accounting software selection and implementation</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Buying/selling a business</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Business process improvement</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Computer systems</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Financial & retirement planning</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Estate planning</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="service-main-wrapper reverse-column">
                  <div className="service-img-wrapper top-wrapper">
                    <img src={ImageAssets.services.otherServiceImg} alt="service" className="image-responsive"></img>
                  </div>
                  <div className="pl-70 flex1">
                    <div className="section-heading-panel">
                      <h4 className="preheading-text mb-20">OTHER SERVICES</h4>
                    </div>
                    <div>
                      <p className="default-font service-description">Due to our diverse client base, we encounter a wide array of financial and tax issues. As a result, we have gained valuable experience over the years in numerous specialized areas, many of which are listed below. We feel our ability to provide such a variety of services reflects our versatility and competency, as well as our dedication to quality client service.</p>
                    </div>
                    <div className="service-list">
                      <div className="service-list-item"><span className="default-font font-700">Mergers & acquisitions</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Corporate recovery</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Asset protection</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Debt Management</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Business start-up or expansion</span></div>
                      <div className="service-list-item"><span className="default-font font-700">Cash flow forecasting</span></div>
                    </div>
                    <div className="services-block mt-25">
                      <div><span className="light-green-text font-700 large-font">Small Business Financing</span></div>
                      <div>
                        <p className="default-font mt-10 service-description">We help you to assess and prepare for getting the business financing.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
        <Footer />
      </div>
    )
  }
}

Services.propTypes = {}

export default Services