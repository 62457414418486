import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import { appConfig } from '../../constants/AppConfig'
import CustomDropDown from '../common/CustomDropDown'
import { Placeholders, Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import { getDocumentThumbnail } from '../../utils/Utils'

class DocumentUploadPopup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      documentFor: []
    }
  }
  componentDidMount() {

    if (this.props.role === appConfig.ROLE.USER) {
      this.state.documentFor.push(appConfig.DOCUMENTS_FOR.APPLICANT)
      if (this.props.applicationData && this.props.applicationData.maritalStatus !== 'Unmarried') { this.state.documentFor.push(appConfig.DOCUMENTS_FOR.SPOUSE); }
      for (let index = 1; index <= this.props.depedentCount; index++) {
        this.state.documentFor.push(appConfig.DOCUMENTS_FOR['DEPENDENT_' + index])
      }
    }
    else if (this.props.role === appConfig.ROLE.ADMIN || this.props.role === appConfig.ROLE.OWNER_ADMIN) {
      this.state.documentFor.push(appConfig.DOCUMENTS_FOR.ADMIN);
    }
    // else if (this.props.role === appConfig.ROLE.ACCOUNTANT) {
    //   this.state.documentFor.push({
    //     label: appConfig.DOCUMENTS_FOR.ADMIN,
    //     value: appConfig.DOCUMENTS_FOR.ADMIN
    //   });
    // }
  }
  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open}>
        <DialogTitle id="customized-dialog-title">
          <div className="upload-popup-heading text-center">
            <span className="upload-popup-heading-text">
              Upload Document
              </span>
          </div>
          <div className="cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="upload-popup">
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText='Select Document Type'
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  onChange={this.props.onFileTypeChange}
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  list={Object.values(this.props.documentTypesList)}
                  value={this.props.document.type}
                />
              </div>
            </div>
            <div className="form-field-row mt-20">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText='Select Document For'
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  onChange={this.props.onFileForChange}
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  list={Object.values(this.state.documentFor)}
                  value={this.props.document.uploadedFor}
                />
              </div>
            </div>
            {/* {
              this.props.document.file
                ?
                <div className="document-display-container d-flex justify-content-center">
                  <div className="position-relative mb-30">
                    <div className="image-overlay"></div>
                    <div className="center document-block">
                      <img src={getDocumentThumbnail(this.props.document.fileType)} alt="tax-document" />
                    </div>
                    <div className="cancel-icon" onClick={this.props.onFileDelete}></div>
                  </div>
                </div>
                :
                null
            } */}

            <div className="upload-block">
              <div className="upload-container">
                <div className="center drop-icon mt-0">
                  <img src={ImageAssets.formsvg.selectFileIcon} alt="drag and drop"></img>
                </div>
                <Button
                  type='submit'
                  parentClassName='center select-from-computer'
                  className='btn btn-primary btn-medium'
                  text={Messages.SELECT_FROM_COMPUTER}
                />
                <div><input type="file" multiple="multiple" className="file-input" accept='.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.xls,.XLS,.xlsx,.XLSX' onClick={(event) => { event.target.value = null }} onChange={this.props.onFileLoad} /></div>
              </div>
            </div>
            {
              this.props.uplaodDocuments && this.props.uplaodDocuments.length > 0
                ?
                <div className="document-display-container d-flex">
                  {
                    this.props.uplaodDocuments.map((eachItem, i) => {
                      return (

                        <div className="document-single-view position-relative" key={i}>
                          <div className="image-overlay"></div>
                          <div className="center document-block">
                            <img src={getDocumentThumbnail(eachItem.fileType)} alt="tax-document" />
                          </div>
                          <div className="cancel-icon" onClick={this.props.onFileDelete(eachItem, i)}></div>
                        </div>

                      )
                    })
                  }
                </div>
                :
                null
            }

            <Button
              type='submit'
              parentClassName='yes-btn center'
              className={'btn btn-primary btn-small' + (this.props.disabledButton ? '' : ' disabled')}
              text={Messages.SUBMIT}
              disabled={!this.props.disabledButton}
              onClick={this.props.onSubmitClick}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

DocumentUploadPopup.propTypes = {}

const mapStateToProps = (state) => ({
  documentTypesList: state.list.documentTypesList
})

export default connect(mapStateToProps, {})(DocumentUploadPopup)
