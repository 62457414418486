// Authentication
export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const ACCEPT_INFORMATIVE_MESSAGE = 'ACCEPT_INFORMATIVE_MESSAGE'

// Loader
export const SET_LOADING = 'SET_LOADING'

// Tax Application
export const APPLICATION_UPDATE = 'APPLICATION_UPDATE'
export const GET_ALL_APPLICATION = 'GET_ALL_APPLICATION'
export const GET_SINGLE_APPLICATION = 'GET_SINGLE_APPLICATION'
export const EDIT_APPLICATION = 'EDIT_APPLICATION'
export const ADMIN_EDIT_APPLICATION = 'ADMIN_EDIT_APPLICATION'
export const DELETE_APPLICATION = 'DELETE_APPLICATION'
export const REMOVE_APPLICATION_SETUP = 'REMOVE_APPLICATION_SETUP'
export const CHANGE_STEP = 'CHANGE_STEP'
export const SET_APPLICATION_FILTERS = 'SET_APPLICATION_FILTERS'
export const GET_APPLICATION_COUNTERS = 'GET_APPLICATION_COUNTERS'
export const GET_REFRANCES_LIST= 'GET_REFRANCES_LIST'

// Popups
export const ACCOUNT_VERIFICATION_POPUP = 'ACCOUNT_VERIFICATION_POPUP'
export const CHOOSE_STATUS_POPUP = 'CHOOSE_STATUS_POPUP'
export const HAVE_ACCOUNT_POPUP = 'HAVE_ACCOUNT_POPUP'
export const EMAIL_VERIFICATION_POPUP = 'EMAIL_VERIFICATION_POPUP'
export const MOBILE_VERIFICATION_POPUP = 'MOBILE_VERIFICATION_POPUP'
export const SESSION_SELECTION_POPUP = 'SESSION_SELECTION_POPUP'

// Subscription
export const GET_PLANS_LIST = 'GET_PLANS_LIST'
export const GET_TAX_OPTIONS_LIST = 'GET_TAX_OPTIONS_LIST'
export const GET_RECOMMENDED_PLAN = 'GET_RECOMMENDED_PLAN'
export const GET_SINGLE_PLAN = 'GET_SINGLE_PLAN'

// User
export const GET_USER_LIST = 'GET_USER_LIST'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const INVALID_MEMBERS_LIST = 'INVALID_MEMBERS_LIST'
export const RESET_INVALID_MEMBERS_LIST = 'RESET_INVALID_MEMBERS_LIST'
export const ADD_OTHER_EMAIL_PHONE = 'ADD_OTHER_EMAIL_PHONE'
export const DELETE_OTHER_EMAIL_PHONE = 'DELETE_OTHER_EMAIL_PHONE'
export const VERIFY_OTHER_EMAIL_PHONE = 'VERIFY_OTHER_EMAIL_PHONE'
export const GET_OTHER_EMAIL_PHONE = 'GET_OTHER_EMAIL_PHONE'


// Newsletter
export const GET_NEWSLETTER_LIST = 'GET_NEWSLETTER_LIST'
export const DELETE_NEWSLETTER = 'DELETE_NEWSLETTER'

// Document Types
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'

// Address
export const GET_SUGGESTED_ADDRESS = 'GET_SUGGESTED_ADDRESS'
export const REMOVE_SUGGESTED_ADDRESS = 'REMOVE_SUGGESTED_ADDRESS'

// View Docuemnt
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
export const RESET_DOCUMENT = 'RESET_DOCUMENT'

//User Details
export const GET_USER_DETAILS = 'GET_USER_DETAILS'