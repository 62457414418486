import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { VIEW_DOCUMENT } from '../constants/ActionTypes'
import setAuthToken from '../interceptors/SetAuthToken'

export const consultantAction = (requestObject) => () => {
  setLoading(true)
  axios.post(APIRoutes.COMMON.CONSULTANT, requestObject)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const contactUsAction = (requestObject) => () => {
  setLoading(true)
  axios.post(APIRoutes.COMMON.CONTACT_US, requestObject)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getDocument = (link, type) => (dispatch) => {
  setAuthToken(false)
  axios({
    url: link,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    setAuthToken(localStorage.token)
    var reader = new FileReader()
    reader.readAsDataURL(response.data)
    reader.onloadend = function () {
      var base64data = reader.result
      dispatch({
        type: VIEW_DOCUMENT,
        payload: { file: base64data, type: type }
      })
    }
  })
}