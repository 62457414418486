import moment from 'moment'
import { appConfig } from './AppConfig'
let yearEndDate = moment().utc().subtract(1, 'year').endOf('year').format(appConfig.DATE_FORMAT)
let yearEnd = moment().utc().subtract(1, 'year').format(appConfig.YEAR_FORMAT)

export const Labels = {
  REGISTERED_EMAIL_OR_MOBILE: 'Registered Email Address or Mobile Number*',
  PASSWORD: 'Password*',
  DELETE: 'Delete',
  ENTER_PASSWORD: 'Enter Password*',
  FILLED_RETURN: 'Have you Filled Return with us Before ?*',
  TAX_YEARS: 'How Many Tax Years you are Filling return for ?*',
  FIRSTNAME: 'First Name*',
  LASTNAME: 'Last Name*',
  EMAIL: 'Email Address*',
  RFERENCE_NAME: 'Reference Name',
  RFERENCE_EMAIL: 'Reference Email',
  MOBILE_NUMBER: 'Mobile Number*',
  FORGOT_EMAIL_OR_PHONE: 'Enter registered email address or mobile number below to recover it*',
  ENTER_EMAIL_OR_PHONE: 'Enter registered email address or mobile number*',
  OTP_SENT_TO: 'The OTP has been sent to ',
  NEW_PASSWORD: 'Enter New Password*',
  SIN: 'SIN#',
  DATE_OF_BIRTH: 'Date of Birth*',
  UNITS: 'Unit',
  ADDRESS: 'Address',
  STREET_NUMBER: 'Street Number',
  STREET_NAME: 'Street Name*',
  PROVINCE: 'Province*',
  CITY: 'City*',
  POSTAL_CODE: 'Postal Code*',
  MARITAL_STATUS: 'Marital status on ' + yearEndDate + '*',
  DATE_OF_MARRIAGE: 'Date of Marriage*',
  SPOUSE_FIRSTNAME: 'Spouse First Name*',
  SPOUSE_LASTNAME: 'Spouse Last Name*',
  SPOUSE_FILLED_RETURN: 'Have your Spouse Filled Return with us Before ?*',
  SPOUSE_FILING_RETURN_FOR_FIRST_TIME: 'Are your Spouse filing an income tax return with the CRA for the first time?*',
  // SPOUSE_SIN: 'Spouse SIN*',
  SPOUSE_SIN: 'Spouse SIN',
  SPOUSE_DATE_OF_BIRTH: 'Spouse Date of Birth*',
  SPOUSE_EMAIL_ADDRESS: 'Spouse Email Address*',
  SPOUSE_CONTACT_NUMBER: 'Spouse Contact Number*',
  HAVE_DEPENDENTS: 'Do you have any dependants ?',
  DEPENDENTS_NUMBER: 'Number of Dependents',
  DEPENDENT_FIRSTNAME: 'First Name*',
  DEPENDENT_LASTNAME: 'Last Name*',
  DEPENDENT_SIN: 'SIN#',
  DEPENDENT_DATE_OF_BIRTH: 'Date of Birth*',
  DEPENDENT: 'Depend',
  HAS_MOVE: 'Have you moved from different province in year ' + yearEnd + ' ?*',
  PROVINCE_TERRITORY_CHANGE: 'Select the date of your move*',
  PROVINCE_TERRITORY_LIVE: 'In which province or territory did you live on ' + yearEndDate + '?*',
  SAME_HOME_ADDRESS: 'Is your home address the same as your mailing address?',
  CURRENTLY_LIVE: 'In which province or territory do you currently live?',
  IMMIGRATION_STATUS: 'Immigration Status',
  FILING_RETURN_FOR_FIRST_TIME: 'Are you filing an income tax return with the CRA for the first time?*',
  DATE_OF_LANDING: 'Date of landing in Canada*',
  CRA_MY_ACCOUNT: 'Are you registered for CRA my Account?*',
  INVESTING_RRSP_TFSA: 'Are you Investing with RRSP/TFSA ?*',
  TOTAL_RENT_TAX: 'Total Rent/Property Tax Paid for the Year',
  CHARITABLE_DONATION: 'Total Charitable Donation for the Year',
  MEDICAL_EXPENSE: 'Do you have Medical Expense ?*',
  TRANSIT: 'TRANSIT#',
  INSTITUTION: 'INSTITUTION#',
  ACCOUNT: 'ACCOUNT#',
  UPLOAD: 'Upload',
  ADD: 'Add',
  REMOVE: 'Remove',
  FORM_PREVIEW: {
    ID: 'Application ID',
    NAME: 'Applicant Name',
    ONLY_NAME: 'Name',
    ONLY_SIN: 'SIN',
    FIRST_NAME: 'Applicant First Name',
    LAST_NAME: 'Applicant Last Name',
    FILLED_RETURN: 'Have you Filled Return with us Before ?',
    FILING_RETURN_FOR_FIRST_TIME: 'Are you filing an income tax return with the CRA for the first time?',
    TAX_YEARS: 'How Many Tax Years you are Filling return for ?',
    SIN: 'SIN#',
    EMAIL: 'Email Address',
    DATE_OF_BIRTH: 'Date of Birth',
    APPLICATION_ID: 'Application Id',
    UNIT: 'Unit',
    STREET_NUMBER: 'Street Number',
    STREET_NAME: 'Street Name',
    CITY: 'City',
    PROVINCE: 'Province',
    POSTAL_CODE: 'Postal Code',
    CONTACT_NUMBER: 'Contact Number',
    MARITAL_STATUS_ON: 'Marital status on ' + yearEndDate,
    HAVE_DEPENDENTS: 'Do you have any dependants?',
    DATE_OF_MARRIAGE: 'Date of Marriage',
    SPOUSE_NAME: 'Spouse Name',
    SPOUSE_FIRST_NAME: 'Spouse First Name',
    SPOUSE_LAST_NAME: 'Spouse Last Name',
    SPOUSE_SIN: 'Spouse SIN#',
    SPOUSE_FILLED_RETURN: 'Have your Spouse Filled Return with us Before ?',
    SPOUSE_FILING_RETURN_FOR_FIRST_TIME: 'Are your Spouse filing an income tax return with the CRA for the first time?',
    SPOUSE_DATE_OF_BIRTH: 'Spouse Date of Birth',
    SPOUSE_CONTACT_NUMBER: 'Spouse Contact Number',
    SPOUSE_EMAIL_ADDRESS: 'Spouse Email Address',
    DEPENDANTS: 'Do you have any dependants?',
    DEPEND: 'Depend ',
    HAS_MOVE: 'Have you moved from different province in year ' + yearEnd + ' ?',
    PROVINCE_TERRITORY_CHANGE: 'Select the date of your move',
    PROVINCE_TERRITORY_LIVE: 'In which province or territory did you live on ' + yearEndDate + '?',
    SAME_HOME_ADDRESS: 'Is your home address the same as your mailing address?',
    CURRENTLY_LIVE: 'In which province or territory do you currently live?',
    IMMIGRATION_STATUS: 'Immigration Status',
    DATE_OF_LANDING: 'Date of landing in Canada',
    DOCUMENTS: 'Documents',
    CRA_MY_ACCOUNT: 'Are you registered for CRA my Account?*',
    INVESTING_RRSP_TFSA: 'Are you Investing with RRSP/TFSA ?*',
    TOTAL_RENT_TAX: 'Total Rent/Property Tax Paid for the Year',
    CHARITABLE_DONATION: 'Total Charitable Donation for the Year',
    MEDICAL_EXPENSE: 'Do you have Medical Expense ?*',
    TRANSIT: 'TRANSIT#',
    INSTITUTION: 'INSTITUTION#',
    ACCOUNT: 'ACCOUNT#',
  },
  EMAIL_ADDRESS: 'EMAIL ADDRESS',
  ABOUT_US: {
    FULLNAME: 'Full Name*',
    EMAIL_ADDRESS: 'Email Address*',
    CONTACT_NUMBER: 'Contact Number',
    MESSAGE: 'Message*'
  },
  APPLICATION_DATE: 'Application Date',
  APPLICATION_STATUS: 'Application Status',
  PAYMENT_STATUS: 'Payment Status',
  APPLICATION_ID: 'Application Id',
  EXCEL_FIELDS: {
    FILE_NAME: 'Invalid Members List',
    SHEET_NAME: 'Invalid Members',
    FIRSTNAME: 'First Name',
    LASTNAME: 'Last Name',
    EMAIL: 'Email',
    PHONE: 'Phone Number',
    REASON: 'Reason for Failure'
  },
  AMOUNT: 'Amount',
  DESCRIPTION: 'Description',
  PAYMENT_PLAN: 'Payment Plan',
  PAID_AMOUNT: 'Paid Amount',
  UNPAID_AMOUNT: 'Unpaid Amount',
  SELECTED_PLAN: 'Selected Plan',
  EDIT: 'Edit',
  SAVE: 'Save'
}
export const EditSin = {
  applicantSin: 'applicantSin',
  spouseSin: 'spouseSin',
  dependentSin_1: 'dependentSin_1',
  dependentSin_2: 'dependentSin_2',
  dependentSin_3: 'dependentSin_3',
  dependentSin_4: 'dependentSin_4',
  dependentSin_5: 'dependentSin_5',
  dependentSin_6: 'dependentSin_6'
}
export const Placeholders = {
  EMAIL: 'Enter Email Address',
  PASSWORD: 'Enter Password',
  FIRSTNAME: 'Enter First Name',
  LASTNAME: 'Enter Last Name',
  MOBILE_NUMBER: '000-000-0000',
  EMAIL_OR_PHONE: 'Enter Email Address or Mobile Number',
  TYPE_HERE: 'Type here',
  TYPE_HERE_SUGGESTION: 'Type here for suggestion',
  DATE: 'yyyy-mm-dd',
  PROVINCE: 'Select Province',
  CITY: 'Select City',
  MARITAL_STATUS: 'Select Marital Status',
  SELECT: 'Select',
  DEPENDENTS_NUMBER: 'Select Number of Dependents',
  FILTER_BY_NAME_AND_ID: 'Filter By Name & Application ID',
  FILTER_BY_NAME: 'Filter By Name',
  ABOUT_US: {
    NAME: 'Enter your name',
    EMAIL: 'Enter your email address',
    CONTACT: 'Enter your contact number',
    MESSAGE: 'Write your message here'
  },
  CONTACT_US: {
    FULLNAME: 'Full Name',
    EMAIL_ADDRESS: 'Email Address',
    CONTACT_NUMBER: 'Contact Number',
    MESSAGE: 'Your Message Here'
  },
  SIN: '000-000-000',
  TOTAL_RENT_TAX: 'Enter Tax',
  TOTAL_DONATION: 'Enter Donation',
  CHARITABLE_DONATION: 'Enter Amount',
  ENTER_AMOUNT: 'Enter Amount',
  ENTER_DESCRIPTION: 'Enter Description'
}
export const NotificationButton = {
  New_Message: "New Message",
  New_Document: "New Document",
  Status_File: "Status File",
}
export const Messages = {
  PLEASE_LOGIN_TO_ACCOUNT: 'Please Login to your account',
  PLEASE_SIGN_TO_ACCOUNT: 'Please Register to your account',
  FORGOT_PASSWORD: 'Forgot Password ?',
  LOGIN: 'Login',
  REGISTER: 'Sign Up',
  COUNTINUE: 'continue',
  LOGOUT: 'Logout',
  ACCEPT: 'Accept',
  CREATE_ACCOUNT: 'Create Account',
  NOT_ACCOUNT: 'Not have an account?',
  HAVE_ACCOUNT: 'Already have an account?',
  FORGOT_YOUR_PASSWORD: 'Forgot Your Password',
  CREATE_YOUR_PASSWORD: 'Create Your Password',
  CREATE_PASSWORD: 'Create Password',
  GET_OTP: 'Get OTP',
  BACK_TO: 'Back to?',
  ENTER_OTP: 'Enter OTP',
  SUBMIT: 'Submit',
  CREATE_APPLICATION: 'Create Application',
  OKAY: 'Okay',
  DIDNT_RECEIVE_OTP: 'You didn\'t receive OTP ?',
  RESEND_OTP: 'Resend OTP',
  SAVE_AND_CONTINUE: 'Save & Continue',
  PAYMENT: 'Payment',
  NOTES: 'Notes & Comments',
  PAYMENT_HISTORY: 'Payment History',
  ABOUT_YOU: 'About you',
  YOUR_FAMILY: 'Your Family',
  YOUR_RESIDENCE: 'About your Residence',
  CRA_MY_ACCOUNT: 'CRA my Account',
  DIRECT_DEPOSITE: 'Direct Deposit Information',
  UPLOAD_DOCUMENTS: 'Upload Your Documents',
  UPLOAD_DOCS: 'Upload Documents',
  SAVE_AND_SUBMIT: 'Save & Submit',
  UPLOAD_A_FILE: 'Upload Document',
  APPLICANT_DOCUMENTS: 'Applicant\'s Documents',
  SPOUSE_DOCUMENTS: 'Spouse\'s Documents',
  DEPENDENT_DOCUMENTS: 'Dependant\'s Documents',
  ADMIN_DOCUMENTS: 'Admin\'s Documents',
  SELECT_FROM_COMPUTER: 'Select From Computer',
  UPLOAD_TEXT: 'Upload',
  FORM_PREVIEW: 'Form Preview',
  APPLICATION_STATUS: 'Application Status',
  PAYMENT_STATUS: 'Payment Status',
  YOUR_TAX_RETURN: 'Your Tax Return',
  CANCEL: 'Cancel',
  GET_STARTED: 'Get Started Now',
  READ_MORE: 'Read More',
  EXPLORE_MORE: 'Explore More',
  HOME_PAGE: {
    FIND_MORE: 'Find out more about us on',
    ACCOUNTING_AND_TAX: 'Accounting and Taxation Solutions',
    WHO_WE_ARE: 'WHO WE ARE',
    LEAD_COMPANY: 'Leading Accounting & Taxation Company',
    WHAT_WE_OFFER: 'WHAT WE OFFER',
    OUR_SERVICES: 'See Our Successful Services'
  },
  START_RETURN: 'Start your ' + yearEnd + ' tax return',
  FILE_RETURN: 'File your ' + yearEnd + ' tax return today',
  YES: 'Yes',
  NO: 'No',
  NEWSLETTER: 'Newsletter',
  SUBSCRIBE: 'SUBSCRIBE',
  DEPENDANT_DETAILS: 'Dependants Details',
  DOCUMENTS: 'Documents',
  EDIT: 'Edit',
  PAY_NOW: 'Pay Now',
  PAY_LATER: 'Pay Later',
  NAME: 'Name',
  MAKE_PAYMENT: 'Make Payment',
  BACK: 'Back',
  HOME: 'Home',
  ABOUT_US: 'About Us',
  SERVICES: 'Services',
  RESOURCES: 'Resources',
  TAX: 'Tax',
  CONTACT_US: 'Contact Us',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SUBMIT_NOW: 'Submit Now',
  IMPORT_USERS: 'Import Users',
  APPLICATIONS: 'Tax Applications',
  USERS: 'Users',
  SUBSCRIPTION: 'Subscription',
  REFRENCES: 'References',
  VIEW_APPLICATIONS: 'View Applications',
  ADMIN_PANEL: 'Admin',
  PROFILE_DROPDOWN: {
    LOGIN: 'Login',
    SIGNUP: 'Sign Up',
    MY_PROFILE: 'My Profile',
    MY_APPLICATION: 'My Applications',
    ADMINISTRATOR: 'Administrator',
    LOGOUT: 'Logout'
  },
  APP_CONFIRMATION_POPUP: 'Please Add us to safe Sender and Make sure to check your Promotion or Spam Folder just in case for application confirmation email.',
  NEXT: 'Next',
  EXPORT_AS_PDF: 'Export as PDF',
  ASSIGN_ACCOUNTANT: 'Assign Application',
  EXPORT_AS_ZIP: 'Export Documents',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  PARTIALLY_PAID: 'Partially Paid',
  NEW: 'New'
}

export const InputIds = {
  EMAIL: 'email',
  PASSWORD: 'password',
  ENTER: 'Enter',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  PHONE: 'phone',
  SIN: 'sin',
  DATE_OF_BIRTH: 'dateOfBirth',
  ADDRESS: 'address',
  UNIT: 'unit',
  STREET_NUMBER: 'streetNumber',
  STREET_NAME: 'streetName',
  PROVINCE: 'province',
  CITY: 'city',
  REFRENCES_NAME: 'ReferenceName',
  REFRENCES_EMAIL: 'ReferenceEmail',
  POSTAL_CODE: 'postalCode',
  MARITAL_STATUS: 'maritalStatus',
  DATE_OF_MARRIAGE: 'dateOfMarriage',
  SPOUSE_FIRSTNAME: 'spouseFirstname',
  SPOUSE_LASTNAME: 'spouseLastname',
  SPOUSE_SIN: 'spouseSIN',
  SPOUSE_DATE_OF_BIRTH: 'spouseDateOfBirth',
  SPOUSE_EMAIL_ADDRESS: 'spouseEmail',
  SPOUSE_CONTACT_NUMBER: 'spouseContact',
  HAS_DEPENDENTS: 'hasDependents',
  TAX_YEARS: 'taxYears',
  HAS_SPOUSE_FILLED_RETURN_BEFORE: 'hasSpouseFilledReturnBefore',
  HAS_SPOUSE_FILINF_RETURN_FOR_FIRST_TIME: 'hasSpouseFilingReturnFirstTime',
  SPOUSE_DATE_OF_LANDING: 'spouseDateOfLanding',
  SPOUSE_TAX_YEAR: 'spouseTaxYears',
  DEPENDENTS_NUMBER: 'dependentsNumber',
  DEPENDENT_FIRSTNAME: 'dependentFirstname',
  DEPENDENT_LASTNAME: 'dependentLastname',
  DEPENDENT_SIN: 'dependentSIN',
  DEPENDENT_DATE_OF_BIRTH: 'dependentDateOfBirth',
  HAS_MOVE: 'hasMove',
  DATE_OF_MOVE: 'dateOfMove',
  PROVINCE_TERRITORY: 'provinceTerritory',
  SAME_HOME_ADDRESS: 'sameHomeAddress',
  CURRENTLY_LIVE: 'currentlyLive',
  IMMIGRATION_STATUS: 'immigrationStatus',
  FILING_RETURN_FOR_FIRST_TIME: 'filingReturnFirstTime',
  FILLED_RETURN_BEFORE: 'filledReturnBefore',
  DATE_OF_LANDING: 'dateOfLanding',
  CRA_MY_ACCOUNT: 'CRAAccount',
  TOTAL_RENT_PROPERTY: 'totalRent',
  TOTAL_CHARITABLE_AMOUNT: 'totalCharitableAmount',
  HAVE_MEDICAL_EXPANSE: 'haveMedicalExpanse',
  INVESTING_IN_RRSP_TFSA: "investingInRrspTfsa",
  TRANSIT: 'transit',
  INSTITUTION: 'institution',
  ACCOUNT: 'account',
  NOTES: 'notes',
  NAME: 'name',
  MESSAGE: 'message',
  NEXT: 'next',
  PREVIOUS: 'previous',
  FILTER: 'filter',
  SEARCH: 'search',
  REQUEST_BODY_FIELDS: {
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    APPLICATION_ID: 'applicationId',
    SEARCH: 'search',
    APPLICATION_STATUS: 'applicationStatus',
    FILTERNAME: 'filterName',
    PAYMENT_STATUS: 'paymentStatus',
    CITY: 'city',
    ROLE: 'role'
  },
  FILTER_TIME: 'filterTime',
  APPLICATION_STATUS: 'applicationStatus',
  PAYMENT_STATUS: 'paymentStatus',
  NEW: 'new',
  PROFILE: 'profile',
  AMOUNT: 'amount',
  DESCRIPTION: 'description',
  PAYMENT_PLAN: 'paymentPlan',
  PALN_AMOUNT: 'planAmount',
  LOGIN_PARAMS: {
    EMAIL: 'email',
    PASSWORD: 'password',
    NEW_PASSWORD: 'new-password',
    TWO_STEP_AUTH: 'two-step-auth'
  },
  ENTER_MANUAL: 'enterManual'
}

export const TooltipMessages = {
  SIN_NUMBER: 'If SIN# is not available type 000-000-000',
  STREET_NAME: 'If you have a PO Box or RR number, enter it in the “Street name” field',
  NON_RESIDENT: 'If you emigrated or are a non-resident of Canada, you can\'t use Bliss Services.',
  CURRENTLY_LIVE: 'This is where you currently live. It can be the same or different than your current mailing address.',
  SUPPORTED_FILES: 'File format : ( .jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx ) Upload Limit : 20',
  ACCOMODATION_SHARED: 'If Shared accommodation, just give your portion'
}