import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logoutUser } from '../../actions/authActions'
import { appConfig } from '../../constants/AppConfig'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import ProfileDropDown from './ProfileDropDown'

class FormHeader extends Component {

  constructor(props) {
    super(props)
    this.state = { showMenu: false }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  onLogoutClick = () => {
    this.props.logoutUser()
  }

  render() {

    let dropdownOptions = []
    if (this.props.isAuthenticated) {
      if (this.props.role === appConfig.ROLE.OWNER_ADMIN || this.props.role === appConfig.ROLE.ADMIN) {
        // dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_PROFILE)
        dropdownOptions.push(Messages.PROFILE_DROPDOWN.ADMINISTRATOR)
      } else {
        dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_APPLICATION)

      }
      dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_PROFILE)
      dropdownOptions.push(Messages.LOGOUT)
    } else {
      dropdownOptions.push(Messages.REGISTER)
      dropdownOptions.push(Messages.LOGIN)
    }

    return (
      <div className="header-section form-header-section">
        <div className="container-block">
          <div className="navbar-panel center justify-content-between">
            {/* <Link to={UIRoutes.HOME} className="logo-panel"> */}
            <img src={ImageAssets.common.siteLogo} alt="logo" />
            {/* </Link> */}
            <div className="d-flex align-items-center">
              {
                this.props.role === appConfig.ROLE.OWNER_ADMIN || this.props.role === appConfig.ROLE.ADMIN || this.props.role === appConfig.ROLE.ACCOUNTANT
                  ?
                  <div className="toggle-menu" onClick={this.toggleMenu}>
                    <img src={ImageAssets.common.toggleIcon} alt="toggleIcon" />
                  </div>
                  :
                  null
              }
              <div className={"form-nav-panel nav-panel d-flex" + (this.state.showMenu ? ' open-menu' : '')}>
                <div className="d-flex align-items-center form-nav-panel">
                  {/* <Link to={UIRoutes.HOME} className="nav-link default-font">{Messages.HOME}</Link> */}
                  {
                    [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                      ?
                      <>
                        <Link to={UIRoutes.APPLICATIONS} className={"nav-link default-font" + (this.props.page === Messages.APPLICATIONS ? ' active-link' : '')}>{Messages.APPLICATIONS}</Link>
                        {
                          [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                            ?
                            <>
                              <Link to={UIRoutes.USERS} className={"nav-link default-font" + (this.props.page === Messages.USERS ? ' active-link' : '')}>{Messages.USERS}</Link>
                              <Link to={UIRoutes.SUBSCRIPTION} className={"nav-link default-font" + (this.props.page === Messages.SUBSCRIPTION ? ' active-link' : '')}>{Messages.SUBSCRIPTION}</Link>
                              <Link to={UIRoutes.REFRENCES} className={"nav-link default-font" + (this.props.page === Messages.REFRENCES ? ' active-link' : '')}>{Messages.REFRENCES}</Link>
                            </>
                            :
                            null
                        }
                      </>
                      :
                      null
                  }
                </div>
              </div>
              {
                this.props.isPartialLogin
                  ?
                  <div className="d-flex align-items-center flex-wrap auth-redirection-panel">
                    {/* <div className="text-right ml-20">
                      <span className="default-font black-text">To save your tax return</span>
                    </div> */}
                    <div className="header-buttons-panel">
                      <ProfileDropDown
                        parentClassName='profile-dropdown'
                        onClick={this.onLogoutClick}
                        list={dropdownOptions}
                      />
                      {/* {
                        localStorage.token
                          ?
                          null
                          :
                          <div className="header-btn">
                            <Link to={UIRoutes.REGISTER} onClick={this.onLogoutClick} className="btn btn-small btn-primary">{Messages.REGISTER}</Link>
                          </div>
                      }
                      <div className="header-btn">
                        <Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="btn btn-small btn-primary">{Messages.LOGIN}</Link>
                      </div> */}
                    </div>
                  </div>
                  :
                  <>

                    <ProfileDropDown
                      parentClassName='profile-dropdown'
                      onClick={this.onLogoutClick}
                      list={dropdownOptions}
                      isAuthenticated={this.props.isAuthenticated}
                    />
                  </>
                // <div className="header-btn ml-30">
                //   <Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="btn btn-small btn-primary">{Messages.LOGOUT}</Link>
                // </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FormHeader.propTypes = {}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPartialLogin: state.auth.partialLogin,
  role: state.auth.role
})

export default connect(mapStateToProps, { logoutUser })(FormHeader)
