import React, { Component } from 'react'
import validator from 'validator'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel3'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import ImageAssets from '../../utils/ImageAssets'
import Button from '../common/Button'
import { appConfig } from '../../constants/AppConfig'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { InputIds, Messages } from '../../constants/StaticTexts'
import DoYouHaveAccount from '../screen-popup/DoYouHaveAccount'
import EmailVerification from '../screen-popup/EmailVerification'
import ChooseStatus from '../screen-popup/ChooseStatus'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { ErrorMessages } from '../../constants/ErrorMessages'
import setLoading from '../../utils/LoadingManager'
import { APIRoutes } from '../../constants/BackendRoutes'
import { getErrorMessage } from '../../utils/GetErrorMessage'
import store from '../../store'
import { AUTH_LOGOUT, AUTH_SET_USER } from '../../constants/ActionTypes'
import setAuthToken from '../../interceptors/SetAuthToken'

const clientReviews = {
    loop: false,
    margin: 20,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    stagePadding: 10,
    dots: true,
    autoplay: false,
    smartSpeed: 1500,
    autoplayHoverPause: false,
    center: false,
    navText: [],
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 1
        },
        768: {
            items: 2,
        },
        992: {
            items: 2
        },
        1199: {
            items: 3
        },
        1280: {
            items: 3
        },
        1440: {
            items: 3
        }
    }
}

const bannerSlider = {
    loop: true,
    margin: 0,
    nav: true,
    mouseDrag: false,
    touchDrag: true,
    dots: false,
    navText: [],
    autoplay: true,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1
        },
        1440: {
            items: 1
        }
    }
}

class Tax extends Component {

    constructor(props) {
        super(props)
        this.state = {
            haveAccount: false,
            verifyAccount: false,
            haveUseInPast: false,
            email: { value: '', error: null },
            otp: '',
            showOTP: false
        }
    }

    onStartClick = () => {
        if (this.props.isPartialLogin) {
            this.setState({ haveAccount: true })
        } else {
            this.props.history.push(UIRoutes.TAX_APPLICATION)
        }
    }

    onCancelClick = () => {
        this.setState({
            haveAccount: false,
            verifyAccount: false,
            email: { value: '', error: null },
            otp: '',
            showOTP: false,
            haveUseInPast: false
        })
    }

    onHaveAccountNoClick = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        store.dispatch({ type: AUTH_LOGOUT })
        this.props.history.push(UIRoutes.TAX_APPLICATION)
    }

    onHaveAccountYesClick = () => {
        this.setState({ haveAccount: false, verifyAccount: true })
    }

    onInputChange = (inputId) => () => (event) => {
        if (inputId === InputIds.EMAIL) {
            if (event.target.value) {
                this.setState({
                    email: {
                        value: event.target.value,
                        error: validator.isEmail(event.target.value) || validator.isMobilePhone(event.target.value, 'en-CA') ? null : ErrorMessages.EMAIL_OR_PHONE_NOT_VALID
                    },
                    showOTP: false,
                    otp: ''
                })
            } else {
                this.setState({
                    email: {
                        value: '',
                        error: null
                    },
                    showOTP: false,
                    otp: ''
                })
            }
        }
    }

    onGetOTPClick = () => {
        let requestObject = {
            emailOrPhone: validator.isNumeric(this.state.email.value) ? (appConfig.MOBILE_CODE + ' ' + this.state.email.value) : this.state.email.value
        }
        setLoading(true)
        axios.post(APIRoutes.AUTH.PARTIALLY_LOGIN_OTP, requestObject)
            .then(res => {
                setLoading(false)
                this.setState({ showOTP: true, otp: '' })
            }).catch(err => {
                setLoading(false)
                toast.error(getErrorMessage(err))
            })
    }

    onOTPChange = (otpValue) => {
        this.setState({ otp: otpValue })
    }

    onOTPSubmit = () => {
        let requestObject = {
            emailOrPhone: validator.isNumeric(this.state.email.value) ? (appConfig.MOBILE_CODE + ' ' + this.state.email.value) : this.state.email.value,
            otp: this.state.otp
        }
        setLoading(true)
        axios.post(APIRoutes.AUTH.PATIALLY_LOGIN, requestObject)
            .then(res => {
                setLoading(false)
                this.setState({ verifyAccount: false, showOTP: false, haveUseInPast: true })
                let { token, refreshToken } = res.data.result
                localStorage.setItem('token', token)
                localStorage.setItem('refreshToken', refreshToken)
                setAuthToken(token)
                store.dispatch({
                    type: AUTH_SET_USER,
                    payload: res.data.result
                })
            }).catch(err => {
                setLoading(false)
                toast.error(getErrorMessage(err))
            })
    }

    onUsedClick = () => {
        this.props.history.push(UIRoutes.VIEW_ALL_APPLICATION)
    }

    onNewClick = () => {
        this.setState({ haveUseInPast: false })
        this.props.history.push(UIRoutes.TAX_APPLICATION)
    }

    render() {

        return (
            <div>
                <Header page={Messages.TAX} />
                <div className="banner-section tax-banner-section">
                    <div className="banner-wave-panel"><img src={ImageAssets.home.waveImg} alt="wave-img" className="image-responsive w-100"></img></div>
                    <div className="row align-items-end no-gutters">
                        <div className="col-lg-12">
                            <OwlCarousel
                                className="banner-carousel owl-carousel owl-theme"
                                {...bannerSlider}
                            >
                                <div className="banner-slider-img-block">
                                    <div className="h-100 banner-img-block">
                                        <img src={ImageAssets.home.bannerSliderImages.sliderImg2} alt="slider-img" className="image-responsive" />
                                    </div>
                                    <div className="slider-description-panel left-description-panel">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="banner-description">
                                                    <h1 className="font-900 banner-heading">Accurate, easy, secure.</h1>
                                                    <h3 className="large-font light-green-text slider-subheading font-700">File with confidence your return is done right.</h3>
                                                    <div className="banner-subheading-panel">
                                                        <div className="banner-feature-list mt-30 mb-50">
                                                            <div className="banner-feature-list-item"><span className="large-font">Easy to use</span></div>
                                                            <div className="banner-feature-list-item"><span className="large-font">Maximum Refund</span></div>
                                                            <div className="banner-feature-list-item"><span className="large-font">Secure & Private</span></div>
                                                        </div>
                                                        <Button
                                                            type='button'
                                                            className='btn btn-large btn-primary'
                                                            text={Messages.GET_STARTED}
                                                            onClick={this.onStartClick}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-slider-img-block price-plan-slider-imgblock">
                                    <div className="h-100 banner-img-block center">
                                        <img src={ImageAssets.home.bannerSliderImages.sliderImg4} alt="slider-img" className="image-responsive h-auto m-auto package-img" onClick={this.onStartClick} />
                                    </div>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                <div className="bliss-about-section">
                    <div className="container-block">
                        <div className="row no-gutters align-items-center">
                            <div className="col-md-6">
                                <div className="about-bliss-content-block">
                                    <div className="about-heading-panel">
                                        <span className="about-heading-panel-text">
                                            100% convenience, zero confusion
                    </span>
                                    </div>
                                    <div className="about-desc-panel">
                                        <span className="about-desc-panel-text">
                                            Tax forms make sense to CRA, but can be convoluted and confusing for the average person. That’s where we come in. We’ll use simple terms when we ask questions, and be as motivated and excited as you are to get you a refund. And, we’ll do this entirely online so you don’t have to leave the house or commute. With the confusion gone, no more late filing or putting it off, CRA will love you!
                    </span>
                                    </div>
                                    <Button
                                        type='button'
                                        className='btn btn-large btn-primary mt-30'
                                        text={Messages.GET_STARTED}
                                        onClick={this.onStartClick}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bliss-about-img center">
                                    <img src={ImageAssets.tax.aboutBliss.aboutImg} className="image-responsive" alt="about"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial-section section-padding tax-testimonial-section">
                    <div className="container-block">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="heading-panel text-center">
                                    <div><h2 className="taxPage-heading-text font-800">What Our Client Say</h2></div>
                                    {/* <div><p className="taxPage-subheading-text light-gray-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  </p></div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <OwlCarousel
                                    className="client-reviews-carousel owl-carousel owl-theme"
                                    {...clientReviews}
                                >
                                    {
                                        appConfig.TESTIMONIALS.map((eachTestimonial, index) => {
                                            return (
                                                <div className="client-review-panel" key={index}>
                                                    <div className="review-panel">
                                                        <p className="client-review-text font-600">{eachTestimonial.DESCRIPTION}</p>
                                                    </div>
                                                    <div className="client-info d-flex align-items-center mt-50">
                                                        <div className="client-img-panel center mr-25">
                                                            <div className="client-name-char">
                                                                <span className="white-text large-font font-600">{eachTestimonial.USER_CHAR}</span>
                                                            </div>
                                                            {/* <img src={ImageAssets.home.clientReview.clientProfileImg} alt="profileImg" className="image-responsive"></img> */}
                                                        </div>
                                                        <div className="client-detail-panel">
                                                            <div><span className="client-name default-font font-600">{eachTestimonial.NAME}</span></div>
                                                            {/* <div className="mt-5"><span className="client-role small-font">{eachTestimonial.DESIGNATION}</span></div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="file-tax-section section-padding">
                    <div className="container-block">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="heading-panel text-center mb-30">
                                    <div><h2 className="taxPage-heading-text white-text font-800">{Messages.FILE_RETURN}</h2></div>
                                </div>
                                <Button
                                    type='button'
                                    className='btn btn-large btn-primary'
                                    text={Messages.START_RETURN}
                                    onClick={this.onStartClick}
                                />
                                <DoYouHaveAccount
                                    open={this.state.haveAccount}
                                    onNoClick={this.onHaveAccountNoClick}
                                    onYesClick={this.onHaveAccountYesClick}
                                    onCancelClick={this.onCancelClick}
                                />
                                <EmailVerification
                                    open={this.state.verifyAccount}
                                    email={this.state.email}
                                    showOTP={this.state.showOTP}
                                    onInputChange={this.onInputChange}
                                    onGetOTPClick={this.onGetOTPClick}
                                    onOTPChange={this.onOTPChange}
                                    onOTPSubmit={this.onOTPSubmit}
                                    onCancelClick={this.onCancelClick}
                                    otp={this.state.otp}
                                />
                                <ChooseStatus
                                    open={this.state.haveUseInPast}
                                    onCancelClick={this.onCancelClick}
                                    onUsedClick={this.onUsedClick}
                                    onNewClick={this.onNewClick}
                                />
                                <div className="mt-20"><span className="white-text">This year, enjoy doing your taxes. Seriously.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

Tax.propTypes = {}

const mapStateToProps = (state) => ({
    isPartialLogin: state.auth.partialLogin
})

export default connect(mapStateToProps)(Tax)