import { AUTH_SET_USER , ACCEPT_INFORMATIVE_MESSAGE, GET_USER_DETAILS } from '../constants/ActionTypes'

const initialState = {
  isAuthenticated: false,
  email: '',
  firstName: '',
  lastName: '',
  userId: '',
  role: '',
  partialLogin: true,
  informativeMessageAccepted: false,
  otherEmailPhoneNumber: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        isAuthenticated: action.payload.email ? true : false,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userId: action.payload._id,
        role: action.payload.role,
        partialLogin: action.payload.partialLogin || false,
        otherEmailPhoneNumber: action.payload.otherEmailPhoneNumber
      }

      case ACCEPT_INFORMATIVE_MESSAGE:
        return {
          ...state,
          informativeMessageAccepted: true
        }

      case GET_USER_DETAILS: 
        return {
          ...state, 
          email: action.payload.email,
          phoneNumber: action.payload.phoneNumber,
          phoneCode: action.payload.phoneCode
        }
    default:
      return state
  }
}