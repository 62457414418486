import React, { Component } from 'react'
import { UIRoutes } from '../../constants/FrontendRoutes';
import ImageAssets from '../../utils/ImageAssets'
// import Header from '../common/Header';
import Button from '../common/Button'
class PageNotFound extends Component {

  onHomeClick = () => {
    this.props.history.push(UIRoutes.HOME)
  }

  render() {
    return (
      <div>
        <div className="error-wrapper">
          <div className="container-block">
            <div className="error-section">
              <div className="row">
                <div className="col-md-8 col-12">
                  <div className="animated-box large-box mb-30"></div>
                  <div className="animated-box large-box mb-30"></div>
                  <div className="animated-box large-box mb-30"></div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="animated-box large-box mb-30"></div>
                  <div className="animated-box large-box mb-30"></div>
                </div>
              </div>
              <div className="animated-box small-box mb-30"></div>
              <div className="animated-box small-box"></div>
              <div className="under-construction-panel">
                <div className="image-section">
                  <div className="image-block center">
                    <img src={ImageAssets.errorpage.errorImageUpper} alt="error" />
                  </div>
                  <div className="image-block1 center">
                    <img src={ImageAssets.errorpage.errorImageLower} alt="error" />
                  </div>
                </div>
                <div className="text-block">
                  <div className="mb-10"><span className="error-message NunitoSans-Black">OOPS !</span></div>
                  <div><span className="suberror-message">The page you requested could not be found</span></div>
                  <Button
                    type='button'
                    className="btn btn-small btn-primary mt-30"
                    text="Back to Home"
                    onClick={this.onHomeClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PageNotFound.propTypes = {}

export default PageNotFound
