import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import StripeCardComponent from '../common/StripeCardComponent'
import Button from '../common/Button'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { makePaymentAction } from '../../actions/paymentActions'
import { getPlanDetails } from '../../actions/paymentActions'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { appConfig } from '../../constants/AppConfig'
import { Messages } from '../../constants/StaticTexts'

class StripePayment extends Component {

  componentDidMount() {
    if (!this.props.location.planId) {
      this.props.history.push(UIRoutes.VIEW_ALL_APPLICATION)
    } else {
      this.props.getPlanDetails(this.props.userId, this.props.location.planId)
    }
  }

  makePayment = (paymentMethodId) => {
    let requestBody = {
      packageId: this.props.location.planId,
      currency: appConfig.DEFAULT_CURRENCY,
      amount: this.props.location.amount,
      paymentMethodId: paymentMethodId
    }
    this.props.makePaymentAction(requestBody, this.props.userId, this.props.location.applicationId, this.props.history)
  }

  onBackClick = () => {
    this.props.history.push(UIRoutes.PAYMENT_PLANS + this.props.location.applicationId)
  }

  render() {

    let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROVIDER_KEY)

    return (
      <div>
        <FormHeader />
        <div className="payment-gateway-page">
          <div className="container-block">
            <div className="payment-gateway-header">
              <div className="d-flex justify-content-between align-items-center">
                <div className="heading-panel mb-0">
                  <span className="heading-panel-text">
                    PAYMENT GATEWAY
                  </span>
                </div>
                {
                  this.props.location.planSelected
                    ?
                    <Button
                      type='submit'
                      parentClassName='make-payment-btn text-right'
                      className='btn btn-primary btn-small'
                      text={Messages.BACK}
                      onClick={this.onBackClick}
                    />
                    :
                    null
                }
              </div>
            </div>
            <div className="d-flex payment-main-panel">
              <div className="package-detail-block">
                <div className="mb-15">
                  <span className="large-font light-green-text font-600">Selected Package & details</span>
                </div>
                <div className="detail-container">
                  <div>
                    <span className="large-font black-text font-600 text-uppercase">{this.props.recommendedPlan.name + ' plan'}</span>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap mt-30">
                    <div className="features-block mt-0">
                      {
                        this.props.location.planId && !isEmpty(this.props.recommendedPlan)
                          ?
                          this.props.recommendedPlan.features.map((eachFeature, index) => {
                            return (
                              <div className="bullet-block" key={index}>
                                <div className="bullet-desc">
                                  <span className="bullet-desc-text font-600">
                                    {eachFeature.title}
                                  </span>
                                </div>
                              </div>
                            )
                          })
                          :
                          null
                      }
                    </div>
                    <div className="right-panel">
                      <div className="mb-15">
                        <span className="default-font light-green-text font-600">{moment().format(appConfig.DATE_FORMAT)}</span>
                      </div>
                      <div className="bullet-desc mt-50">
                        <span className="bullet-desc-text font-600">
                          Amount to be paid
                        </span>
                      </div>
                      <div className="pricing-panel">
                        <span className="pricing-panel-text NunitoSans-Black">
                          {
                            this.props.location.planId && !isEmpty(this.props.recommendedPlan)
                              ?
                              appConfig.CURRENCY_SIGN[this.props.recommendedPlan.currencies[0].currency] + ' ' + this.props.location.amount
                              :
                              null
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Elements stripe={stripePromise}>
                <StripeCardComponent
                  makeActualPayment={this.makePayment}
                />
              </Elements>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

StripePayment.propTypes = {}

const mapStateToProps = (state) => ({
  recommendedPlan: state.subscription.recommendedPlan,
  userId: state.auth.userId
})

export default connect(mapStateToProps, { makePaymentAction, getPlanDetails })(StripePayment)