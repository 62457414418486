import { GET_SUGGESTED_ADDRESS, REMOVE_SUGGESTED_ADDRESS } from '../constants/ActionTypes'

const initialState = {
  addressList: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUGGESTED_ADDRESS:
      return {
        ...state,
        addressList: action.payload.Items
      }
    case REMOVE_SUGGESTED_ADDRESS:
      return {
        ...state,
        addressList: []
      }
    default:
      return state
  }
}