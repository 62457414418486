import { RESET_DOCUMENT, VIEW_DOCUMENT } from '../constants/ActionTypes'

const initialState = {
  file: '',
  type: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case VIEW_DOCUMENT:
      return {
        file: action.payload.file,
        type: action.payload.type
      }
    case RESET_DOCUMENT: {
      return {
        file: '',
        type: ''
      }
    }
    default:
      return state
  }
}