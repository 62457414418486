import React, { Component } from 'react'
import ReactOTPInput from 'react-otp-input'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import ImageAssets from '../../utils/ImageAssets'
import { Labels, Messages } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'

class MobileNumberVerification extends Component {

  render() {

    let disabledButton = this.props.otp && (String(this.props.otp).length === appConfig.OTP)

    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="email-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="center">
            <img src={ImageAssets.popup.passwordIcon} alt="email-icon"></img>
          </div>
          <div className="text-center mt-30">
            <span className="default-font font-600">You have already registered with bliss</span>
          </div>
          <div className="form-group mt-20 text-center">
            <label className="default-font black-text font-600">
              {Labels.OTP_SENT_TO} <span className="small-font light-green-text link-text"> {this.props.otpSendTo}</span>
            </label>
            <ReactOTPInput
              numInputs={6}
              className='d-flex otp-input-panel justify-content-center'
              inputStyle='form-input otp-input'
              separator={<span>&nbsp;</span>}
              isInputNum={true}
              onChange={this.props.onOTPChange}
              value={this.props.otp}
            />
          </div>
          <div className="button-panel">
            <Button
              type='submit'
              className='btn btn-primary btn-small'
              text={Messages.CANCEL}
              onClick={this.props.onCancelClick}
            />
            <Button
              type='submit'
              parentClassName='submit-btn'
              className={'btn btn-primary btn-small' + (disabledButton ? '' : ' disabled')}
              disabled={!disabledButton}
              text={Messages.SUBMIT}
              onClick={this.props.onSubmitClick}
            />
          </div>
          <div className="text-center mt-20">
            <span className="small-font black-text">{Messages.DIDNT_RECEIVE_OTP}<span className="small-font light-green-text link-text" onClick={this.props.onResendOTPClick}> {Messages.RESEND_OTP}</span></span>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

MobileNumberVerification.propTypes = {}

export default MobileNumberVerification
