import axios from 'axios'
import { toast } from 'react-toastify'
import { GET_APPLICATION_COUNTERS } from '../constants/ActionTypes'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import setLoading from '../utils/LoadingManager'

export const getApplicationCounters = (userId) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.DASHBOARD.APPLICATION_COUNTERS + userId + '/application/counts')
    .then(res => {
      dispatch({
        type: GET_APPLICATION_COUNTERS,
        payload: res.data.result[0]
      })
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}