import React, { Component } from 'react'
import validator from 'validator'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import MaterialUITextField from '@material-ui/core/TextField'
import { InputIds, Labels, Placeholders, Messages, TooltipMessages } from '../../constants/StaticTexts'
import Button from '../common/Button'
import CustomDropDown from '../common/CustomDropDown'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import CustomDropDownWithSearchBox from '../common/CustomDropDownWithSearchBox'

class AboutResidence extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasMove: this.props.applicationData.hasMove,
      provinceTerritory: this.props.applicationData.provinceTerritory,
      dateOfMove: { value: this.props.applicationData.dateOfMove, error: null },
      sameHomeAddress: this.props.applicationData.sameHomeAddress,
      currentlyLive: this.props.applicationData.currentlyLive,
      immigrationStatus: this.props.applicationData.immigrationStatus
    }
  }

  onInputChange = (inputId) => (dropdownValue) => () => {
    if (inputId === InputIds.HAS_MOVE) {
      if (dropdownValue) {
        this.setState({ hasMove: dropdownValue })
      } else {
        this.setState({ hasMove: '' })
      }
    } else if (inputId === InputIds.SAME_HOME_ADDRESS) {
      if (dropdownValue) {
        this.setState({ sameHomeAddress: dropdownValue })
      } else {
        this.setState({ sameHomeAddress: '' })
      }
    } else if (inputId === InputIds.IMMIGRATION_STATUS) {
      if (dropdownValue) {
        this.setState({ immigrationStatus: dropdownValue })
      } else {
        this.setState({ immigrationStatus: '' })
      }
    }
  }

  onProvinceTerritoryDropdownChange = (value) => {
    this.setState({ provinceTerritory: value })
  }

  onCurrentlyLiveDropdownChange = (value) => {
    this.setState({ currentlyLive: value })
  }

  onDateChange = (inputId) => (value) => {
    if (inputId === InputIds.DATE_OF_MOVE) {
      if (value) {
        this.setState({
          dateOfMove: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.DATE_OF_MOVE_NOT_VALID
          }
        })
      } else {
        this.setState({
          dateOfMove: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onSaveClick = () => {
    this.props.onClick({
      hasMove: this.state.hasMove,
      provinceTerritory: this.state.provinceTerritory,
      dateOfMove: this.state.dateOfMove.value,
      sameHomeAddress: this.state.sameHomeAddress,
      currentlyLive: this.state.currentlyLive,
      immigrationStatus: this.state.immigrationStatus
    })
  }

  render() {

    let disabledButton = this.state.hasMove && this.state.provinceTerritory
    if (disabledButton && (this.state.hasMove === appConfig.HAS_MOVE[0])) {
      disabledButton = this.state.dateOfMove.value
    }

    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="step-heading">
            <span className="step-heading-text font-700">
              {Messages.YOUR_RESIDENCE}
            </span>
          </div>
          <div className="form-container">
            <div className="d-flex form-field-row">
              <div className="d-flex form-field align-items-center">
                <div className="tootltip-field">
                  <CustomDropDown
                    parentClassName='form-group'
                    labelText={Labels.HAS_MOVE}
                    labelClassName='default-font black-text font-600'
                    inputDivClassName='input-panel'
                    placeholder={Placeholders.SELECT}
                    disabled={this.props.disabled}
                    onChange={this.onInputChange(InputIds.HAS_MOVE)}
                    list={appConfig.HAS_MOVE}
                    value={this.state.hasMove}
                  />
                </div>
              </div>
            </div>
            {
              this.state.hasMove === appConfig.HAS_MOVE[0]
                ?
                <div className="d-flex form-field-row">
                  <div className="form-field">
                    <DatePicker
                      minDate={moment().utc().subtract(1, 'year').startOf('year')}
                      maxDate={moment().utc().subtract(1, 'year').endOf('year')}
                      inputFormat={appConfig.DATE_FORMAT}
                      label={Labels.PROVINCE_TERRITORY_CHANGE}
                      value={this.state.dateOfMove.value}
                      views={[ "year","month", "date" ]}
                      placeholder={Placeholders.DATE}
                      InputProps={{ className: 'input-block' }}
                      disabled={this.props.disabled}
                      renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block'  inputProps={{ ...props.inputProps,readOnly: true}}/>)}
                      onChange={this.onDateChange(InputIds.DATE_OF_MOVE)}
                    />
                  </div>
                </div>
                :
                null
            }
            <div className="d-flex form-field-row">
              <div className="d-flex form-field align-items-center">
                <div className="tootltip-field">
                  <CustomDropDownWithSearchBox
                    parentClassName='form-group'
                    labelText={Labels.PROVINCE_TERRITORY_LIVE}
                    labelClassName='default-font black-text font-600'
                    inputDivClassName='input-panel'
                    placeholder={Placeholders.SELECT}
                    tooltipText={TooltipMessages.NON_RESIDENT}
                    disabled={this.props.disabled}
                    onChange={this.onProvinceTerritoryDropdownChange}
                    list={Object.keys(appConfig.PROVINCE_LIST)}
                    value={this.state.provinceTerritory}
                  />
                </div>
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.SAME_HOME_ADDRESS}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  onChange={this.onInputChange(InputIds.SAME_HOME_ADDRESS)}
                  list={appConfig.SAME_HOME_ADDRESS}
                  value={this.state.sameHomeAddress}
                />
              </div>
            </div>
            <div className="d-flex form-field-row">
              <div className="d-flex form-field align-items-center">
                <div className="tootltip-field">
                  <CustomDropDownWithSearchBox
                    parentClassName='form-group'
                    labelText={Labels.CURRENTLY_LIVE}
                    labelClassName='default-font black-text font-600'
                    inputDivClassName='input-panel'
                    disabled={this.props.disabled}
                    tooltipText={TooltipMessages.CURRENTLY_LIVE}
                    placeholder={Placeholders.SELECT}
                    onChange={this.onCurrentlyLiveDropdownChange}
                    list={Object.keys(appConfig.PROVINCE_LIST)}
                    value={this.state.currentlyLive}
                  />
                </div>
              </div>
            </div>
            <div className="form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.IMMIGRATION_STATUS}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  onChange={this.onInputChange(InputIds.IMMIGRATION_STATUS)}
                  list={appConfig.IMMIGRATION_STATUS_LIST}
                  value={this.state.immigrationStatus}
                />
              </div>
            </div>
            
            <Button
              type='submit'
              parentClassName='mt-30'
              className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
              text={Messages.SAVE_AND_CONTINUE}
              disabled={!disabledButton}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

AboutResidence.propTypes = {}

export default AboutResidence
