import React, { Component } from 'react'

class Button extends Component {

  render() {
    return (
      <div className={this.props.parentClassName}>
        <button
          type={this.props.type}
          id={this.props.id}
          className={this.props.className}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          {this.props.text}
        </button>
      </div>
    )
  }
}

Button.propTypes = {}

export default Button