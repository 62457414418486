import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { Messages } from '../../constants/StaticTexts'

class ProfileDropDown extends React.Component {

  render() {
    return (
      <>
        <div className={this.props.parentClassName}>
          <div className={this.props.inputDivClassName}>
            <div className="input-block dropdown-block">
              <Dropdown>
                <Dropdown.Toggle className={(this.props.valueColorClassName ? this.props.valueColorClassName : '') + (this.props.disabled ? "dropdown-disable" : '')} disabled={this.props.disabled}>{this.props.value ? this.props.value : this.props.placeholder}</Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-basic-button" title={this.props.value}>
                  {
                    this.props.isAuthenticated
                      ?
                      <Dropdown.Item as={'span'} className="user-dropdown-item">
                        <div className="user-profile-div">
                          <span className="profile-name font-600 light-green-text d-flex">
                            {this.props.name}
                          </span>
                        </div>
                      </Dropdown.Item>
                      :
                      null
                  }
                  {
                    this.props.list.map((eachItem, index) => {
                      return (
                        <Dropdown.Item as={'span'} key={index}>
                          {
                            eachItem === Messages.PROFILE_DROPDOWN.LOGIN
                              ?
                              <Link to={UIRoutes.LOGIN} onClick={this.props.onClick}>{eachItem}</Link>
                              // <Link to={{ pathname: "https://app.financebliss.ca" }} target="_self">{eachItem}</Link>
                              :
                              eachItem === Messages.PROFILE_DROPDOWN.SIGNUP
                                ?
                                // <Link to={UIRoutes.REGISTER} onClick={this.props.onClick}>{eachItem}</Link>
                                <Link to={{ pathname: "https://app.financebliss.ca" }} target="_self">{eachItem}</Link>
                                :
                                eachItem === Messages.PROFILE_DROPDOWN.MY_PROFILE
                                  ?
                                  <Link to={UIRoutes.VIEW_MY_PROFILE} onClick={() => window.location.pathname === UIRoutes.VIEW_MY_PROFILE && window.location.reload()} key={Math.random()}>{eachItem}</Link>
                                  :
                                  eachItem === Messages.PROFILE_DROPDOWN.MY_APPLICATION
                                    ?
                                    <Link to={UIRoutes.VIEW_ALL_APPLICATION}>{eachItem}</Link>
                                    :
                                    eachItem === Messages.PROFILE_DROPDOWN.ADMINISTRATOR
                                      ?
                                      <Link to={UIRoutes.APPLICATIONS}>{eachItem}</Link>
                                      :
                                      <Link to={UIRoutes.LOGIN} onClick={this.props.onClick}>{eachItem}</Link>
                          }
                        </Dropdown.Item>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  name: state.auth.firstName + ' ' + state.auth.lastName
})
export default connect(mapStateToProps, {})(ProfileDropDown)