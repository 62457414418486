import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import { Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'

class DocumentUploadPopup extends Component {

  constructor(props) {
    super(props)
    this.state = {
    
    }
  }

  onClick = () => {

  }

  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open}>
        <DialogTitle id="customized-dialog-title">
        </DialogTitle>
        <DialogContent dividers>
          <div className="pt-30 text-center">
            <span className="upload-popup-heading-text large-font font-600 text-center">
              Your session is expired in {this.props.countdown} seconds
              </span>
          </div>
          <div className="text-center">
            <span className="upload-popup-heading-text large-font font-600 text-center">
              click continue to stay logged in
              </span>
          </div>
          <div className="center mt-20 mb-20">
            <img src={ImageAssets.authimage.sessionTimeOut} alt="email-icon"></img>
          </div>
          <div className="center pb-30">
            <div className="mr-10">
              <Button
                type='submit'
                className={'btn btn-primary btn-small'}
                text={Messages.LOGOUT}
                disabled={false}
                onClick={this.props.onLogout}
              />
            </div>
            <div className="ml-10">
              <Button
                type='submit'
                className={'btn btn-primary btn-small'}
                text={Messages.COUNTINUE}
                disabled={false}
                onClick={this.props.onContinue}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

DocumentUploadPopup.propTypes = {}

const mapStateToProps = (state) => ({
  documentTypesList: state.list.documentTypesList
})

export default connect(mapStateToProps, {})(DocumentUploadPopup)
