import React, { Component } from 'react'
import validator from 'validator'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { Messages, Labels, InputIds, Placeholders } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import Button from '../common/Button'
import TextField from '../common/TextField'
import { forgotPasswordAction } from '../../actions/authActions'
import { connect } from 'react-redux'
import { appConfig } from '../../constants/AppConfig'
import { Link } from 'react-router-dom'
import { UIRoutes } from '../../constants/FrontendRoutes'

class CreatePassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      emailOrPhone: { value: '', error: null }
    }
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          emailOrPhone: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) || validator.isMobilePhone(event.target.value, 'en-CA') ? null : ErrorMessages.EMAIL_OR_PHONE_NOT_VALID
          }
        })
      } else {
        this.setState({
          emailOrPhone: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  checkEnterKeyEvent = (disabledButton) => (event) => {
    if (disabledButton && (event.key === InputIds.ENTER || event.keyCode === 13)) {
      this.onGetOTPClick()
    }
  }

  onGetOTPClick = () => {
    let requestObject = {
      isNewPassword: true,
      emailOrPhone: validator.isNumeric(this.state.emailOrPhone.value) ? (appConfig.MOBILE_CODE + ' ' + this.state.emailOrPhone.value) : this.state.emailOrPhone.value
    }
    this.props.forgotPasswordAction(requestObject, this.props.history, InputIds.NEW)
  }

  render() {

    let disabledButton = this.state.emailOrPhone.value && !this.state.emailOrPhone.error

    return (
      <div className="login-signup-page">
        <div className="row no-gutters align-items-center">
          <div className="col-xl-5 col-md-5">
            <div className="center left-panel">
              <img src={ImageAssets.authimage.forgotPasswordImage} alt="login" className="image-responsive"></img>
            </div>
          </div>
          <div className="col-xl-7 col-md-7">
            <div className="center right-panel">
              <div className="form-block">
                <div className="center site-logo">
                  <img src={ImageAssets.common.siteLogo} alt="site-logo"></img>
                </div>
                <div className="text-center mt-15">
                  <span className="large-font dark-green-text font-600">
                    {Messages.CREATE_YOUR_PASSWORD}
                  </span>
                </div>
                <TextField
                  type='text'
                  parentClassName='form-group'
                  labelClassName='default-font black-text font-600 text-center'
                  labelText={Labels.ENTER_EMAIL_OR_PHONE}
                  className='form-input'
                  placeholder={Placeholders.EMAIL_OR_PHONE}
                  onChange={this.onInputChange(InputIds.EMAIL)}
                  onKeyPress={this.checkEnterKeyEvent(disabledButton)}
                  {...this.state.emailOrPhone}
                />
                <Button
                  parentClassName='center mt-30'
                  type='submit'
                  className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
                  text={Messages.GET_OTP}
                  disabled={!disabledButton}
                  onClick={this.onGetOTPClick}
                />
                <div className="text-center mt-15">
                  <span className="default-font black-text font-600">
                    {Messages.BACK_TO}
                    <Link to={UIRoutes.LOGIN} className="default-font light-green-text font-600 link-text"> {Messages.LOGIN}</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CreatePassword.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { forgotPasswordAction })(CreatePassword)
