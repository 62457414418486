import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Multiselect } from 'multiselect-react-dropdown'
import ImageAssets from '../../utils/ImageAssets'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { appConfig } from '../../constants/AppConfig'
import { getAllApplications, onDeleteApplication, onAdminEditApplication, getApplicationFilters } from '../../actions/applicationActions'
import { getApplicationCounters } from '../../actions/dashboardActions'
import { InputIds, Messages, Placeholders } from '../../constants/StaticTexts'
import { getPaymentStatusClassNames, getStatusClassNames } from '../../utils/Utils'
import { UIRoutes } from '../../constants/FrontendRoutes'
import CustomDropDown from '../common/CustomDropDown'
import TextField from '../common/TextField'
import moment from 'moment'
import map from 'lodash/map'
import Pagination from "react-js-pagination"
import MultiSelect from "react-multi-select-component";

class AdminApplicationsPanel extends Component {
  constructor(props) {
    super(props)
    let applicationfilterObj = JSON.parse(localStorage.getItem('applicationfilterObj'))
    this.state = {
      page: applicationfilterObj && applicationfilterObj.pageNo ? applicationfilterObj.pageNo : 1,
      limit: applicationfilterObj && applicationfilterObj.limit ? applicationfilterObj.limit : appConfig.DEFAULT_PAGINATION_OPTIONS[0],
      selected: null,
      search: applicationfilterObj && applicationfilterObj.filters.search ? applicationfilterObj.filters.search : '',
      filterBy: applicationfilterObj && applicationfilterObj.filterBy ? applicationfilterObj.filterBy : appConfig.TIME_FILTER[4],
      customDuration: {},
      selectedCities: applicationfilterObj && applicationfilterObj.filters.city ? applicationfilterObj.filters.city : [],
      selectedPaymentStatus: applicationfilterObj && applicationfilterObj.filters.paymentStatus ? applicationfilterObj.filters.paymentStatus : [],
      selectedApplicationStatus: applicationfilterObj && applicationfilterObj.filters.applicationStatus ? applicationfilterObj.filters.applicationStatus : [],
      sortDirection: applicationfilterObj && applicationfilterObj.sortingOrder ? applicationfilterObj.sortingOrder : appConfig.DEFAULT_SORTING,
      sortHeader: applicationfilterObj && applicationfilterObj.sortingName ? applicationfilterObj.sortingName : appConfig.DEFAULT_SORTING_FIELD
    }
  }

  componentDidMount() {
    let requestBody = {
      limit: this.state.limit,
      page: this.state.page,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
    this.props.getApplicationFilters(this.props.userId)
    if ([appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)) {
      this.props.getApplicationCounters(this.props.userId)
    }
  }

  onPageNumberClick = (pageNumber) => () => {
    let page
    if (pageNumber === InputIds.NEXT) {
      page = this.state.page + 1
    } else if (pageNumber === InputIds.PREVIOUS) {
      page = this.state.page - 1
    } else {
      page = pageNumber
    }
    let requestBody = {
      page: page,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.setState({ page: page }, this.storeFilterObj())
    this.props.getAllApplications(this.props.userId, requestBody)
  }

  onShowCardClick = (id) => () => {
    if (this.state.selected === id) {
      this.setState({ selected: null })
    } else {
      this.setState({ selected: id })
    }
  }

  onEditApplication = (applicationId) => () => {
    this.props.history.push(UIRoutes.TAX_APPLICATION + '/' + applicationId)
    // this.props.onAdminEditApplication(id, this.props.userId, this.props.history)
  }

  onDeleteApplication = (id, index) => () => {
    this.props.onDeleteApplication(this.props.userId, id, index)
  }

  onViewApplication = (applicationId) => () => {
    this.props.history.push(UIRoutes.APPLICATION + applicationId)
  }

  
  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.SEARCH) {
      if (event.target.value) {
        this.setState({ search: event.target.value, page: 1 }, this.storeFilterObj())
      } else {
        this.setState({ search: '', page: 1 }, this.storeFilterObj())
      }
    }
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: event.target.value },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
  }

  onTimeDropdownInputChange = (inputId) => (dropdownValue) => () => {
    if (inputId === InputIds.FILTER_TIME) {
      if (dropdownValue) {
        this.setState({ filterBy: dropdownValue, page: 1 })
      } else {
        this.setState({ filterBy: appConfig.TIME_FILTER[4], page: 1 })
      }
    }
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: dropdownValue,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
  }

  onLocationChange = (selectedValues) => {
    let selectedCities = []
    let selecteditems = []
    selectedValues.forEach((eachCity) => {
      selectedCities.push(eachCity.value)
      selecteditems.push(eachCity)
    })
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: selectedCities },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
    this.setState({ selectedCities: selecteditems, page: 1 }, () => this.storeFilterObj())
  }

  onPaymentStatusChange = (selectedValues) => {
    let selectedPaymentStatus = []
    let paymentStaus = []
    selectedValues.forEach((eachPaymentStatus) => {
      selectedPaymentStatus.push(eachPaymentStatus.value)
      paymentStaus.push(eachPaymentStatus)
    })
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: selectedPaymentStatus },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
    this.setState({ selectedPaymentStatus: paymentStaus, page: 1 }, () => this.storeFilterObj())
  }

  sortingOnClick = (input) => (event) => {
    if (event) {
      let requestBody = {
        page: 1,
        limit: this.state.limit,
        filterList: [
          { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
          { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
          { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
          { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
        ],
        filterBy: this.state.filterBy,
        isPagination: true,
      }
      if (input === 'name') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilterObj())
        this.props.getAllApplications(this.props.userId, requestBody)

      } else if (input === 'city') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilterObj())
        this.props.getAllApplications(this.props.userId, requestBody)
      } else if (input === 'applicationStatus') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilterObj())
        this.props.getAllApplications(this.props.userId, requestBody)
      } else if (input === 'createdAt') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilterObj())
        this.props.getAllApplications(this.props.userId, requestBody)
      }

    }
  }

  storeFilterObj = () => {
    let applicationfilterObj = {
      sortingName: this.state.sortHeader,
      sortingOrder: this.state.sortDirection,
      filterBy: this.state.filterBy,
      filters: {
        search: this.state.search,
        city: this.state.selectedCities,
        applicationStatus: this.state.selectedApplicationStatus,
        paymentStatus: this.state.selectedPaymentStatus
      },
      pageNo: this.state.page,
      limit: this.state.limit
    }
    localStorage.setItem('applicationfilterObj', JSON.stringify(applicationfilterObj))
  }


  onApplicationStatusChange = (selectedValues) => {
    let selectedApplicationStatus = []
    let applicationStatusItem = []
    selectedValues.forEach((eachApplicationStatus) => {
      selectedApplicationStatus.push(eachApplicationStatus.value)
      applicationStatusItem.push(eachApplicationStatus)
    })
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: selectedApplicationStatus }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
    this.setState({ selectedApplicationStatus: applicationStatusItem, page: 1 }, () => this.storeFilterObj())
  }

  onLimitChange = (limit) => () => {
    let requestBody = {
      page: 1,
      limit: limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.getAllApplications(this.props.userId, requestBody)
    this.setState({ limit: limit, page: 1 }, this.storeFilterObj())
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber });
    let page = pageNumber
    let requestBody = {
      page: page,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME, InputIds.REQUEST_BODY_FIELDS.APPLICATION_ID], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.CITY], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedCities, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.PAYMENT_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedPaymentStatus, 'value') },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.APPLICATION_STATUS], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: map(this.state.selectedApplicationStatus, 'value') }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.setState({ page: page }, () => this.storeFilterObj())
    this.props.getAllApplications(this.props.userId, requestBody)
  }

  render() {
    let pageNumbers = []
    for (let i = 1; i <= this.props.applicationList.totalPage; i++) {
      pageNumbers.push(i)
    }


    return (
      <div>
        <FormHeader page={Messages.APPLICATIONS} />
        <div className="admin-panel-page">
          <div className="container-block">
            {
              [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                ?
                <div className="admin-data-panel center justify-content-start flex-wrap">
                  <div className="data-block paid-data-block center justify-content-between mb-30">
                    <div><span className="block-title paid-color font-700">{Messages.PAID}</span></div>
                    <div><span className="block-count paid-color font-800">{this.props.paidApplications}</span></div>
                  </div>
                  <div className="data-block unpaid-data-block center justify-content-between mb-30">
                    <div><span className="block-title unpaid-color font-700">{Messages.UNPAID}</span></div>
                    <div><span className="block-count unpaid-color font-800">{this.props.unpaidApplications}</span></div>
                  </div>
                  <div className="data-block partially-paid-block center justify-content-between mb-30">
                    <div><span className="block-title partially-paid-color font-700">{Messages.PARTIALLY_PAID}</span></div>
                    <div><span className="block-count partially-paid-color font-800">{this.props.partiallyPaidApplications}</span></div>
                  </div>
                  <div className="data-block new-data-block center justify-content-between mb-30">
                    <div><span className="block-title new-color font-700">{Messages.NEW}</span></div>
                    <div><span className="block-count new-color font-800">{this.props.newApplications}</span></div>
                  </div>
                </div>
                :
                null
            }
            <div className="filter-panel d-flex">
              <TextField
                type='text'
                parentClassName='form-group flex1'
                labelClassName='default-font black-text'
                className='form-input mr-10'
                inputDivClassName='input-panel'
                placeholder={Placeholders.FILTER_BY_NAME_AND_ID}
                onChange={this.onInputChange(InputIds.SEARCH)}
                value={this.state.search}
              />
              <CustomDropDown
                parentClassName='form-group filter-dropdown'
                inputDivClassName='input-panel'
                onChange={this.onTimeDropdownInputChange(InputIds.FILTER_TIME)}
                list={appConfig.TIME_FILTER}
              // value={this.state.filterBy}
              />
            </div>
            <div className={"admin-table-card admin-application-card mt-30" + (this.props.role === appConfig.ROLE.ACCOUNTANT ? ' accountant-table-card' : '')}>
              <div className="table-header">
                <div className="table-row">
                  <div className="table-data-row">
                    <div className="cell cell-arrow"></div>
                    <div className="cell cell-name">
                      <div className="d-flex">
                        <div>
                          <span className="cell-header-data font-700">Name</span>
                        </div>
                        <div className="sorting-panel" onClick={this.sortingOnClick("name")}></div>
                      </div>
                    </div>
                    <div className="cell cell-contact">
                      <div>
                        <span className="cell-header-data font-700">Contact Details</span>
                      </div>
                    </div>

                    <div className="cell cell-location">
                      <div className="d-flex">
                        <div className="filter-multiselect-panel">
                          <span className="cell-header-data font-700">Location
                            <MultiSelect
                              className="multiselect-dropdown"
                              options={this.props.applicationFilters.cities}
                              selected={this.state.selectedCities}
                              onChange={this.onLocationChange}
                              ClearSelectedIcon="false"
                              value={this.state.selectedCities}
                            />
                          </span>
                          {/* <a href="/" className="ml-10"><img src={ImageAssets.adminpanel.filterIcon} alt="filter"></img></a> */}
                        </div>
                        <div className="sorting-panel" onClick={this.sortingOnClick("city")}></div>
                      </div>
                    </div>
                    <div className="cell cell-app-id">
                      <div className="d-flex">
                        <div>
                          <span className="cell-header-data font-700">Application Id</span>
                        </div>
                        <div className="sorting-panel" onClick={this.sortingOnClick("createdAt")}></div>
                      </div>
                    </div>
                    <div className="cell cell-app-date">
                      <div className="d-flex">
                        <div>
                          <span className="cell-header-data font-700">Application Date</span>
                        </div>
                        <div className="sorting-panel" onClick={this.sortingOnClick("createdAt")}></div>
                      </div>
                    </div>
                    <div className="cell cell-application">
                      <div className="d-flex">
                        <div className="filter-multiselect-panel">
                          <span className="cell-header-data font-700">Application Status
                            <MultiSelect
                              options={this.props.applicationFilters.applicationStatus}
                              onChange={this.onApplicationStatusChange}
                              selected={this.state.selectedApplicationStatus}
                              value={this.state.selectedApplicationStatus}
                            />
                          </span>
                          {/* <a href="/" className="ml-10"><img src={ImageAssets.adminpanel.filterIcon} alt="filter"></img></a> */}
                        </div>
                        <div className="sorting-panel" onClick={this.sortingOnClick("applicationStatus")}></div>
                      </div>
                    </div>
                    {
                      ![appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                        ?
                        <div className="cell cell-payment">
                          <div className="filter-multiselect-panel">
                            <span className="cell-header-data font-700">Payment Status
                              {/* <Multiselect
                                options={this.props.applicationFilters.paymentStatus}
                                showCheckbox={true}
                                onSelect={this.onPaymentStatusChange}
                                onRemove={this.onPaymentStatusChange}
                                displayValue={InputIds.NAME}
                                placeholder=''
                                avoidHighlightFirstOption={true}
                              /> */}
                              <MultiSelect
                                options={this.props.applicationFilters.paymentStatus}
                                onChange={this.onPaymentStatusChange}
                                selected={this.state.selectedPaymentStatus}
                                value={this.state.selectedPaymentStatus}
                              />
                            </span>
                            {/* <a href="/" className="ml-10"><img src={ImageAssets.adminpanel.filterIcon} alt="filter"></img></a> */}
                          </div>
                        </div>
                        :
                        null
                    }
                    <div className="cell cell-action">
                      <div>
                        <span className="cell-header-data font-700">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {
                  this.props.applicationList.list.length
                    ?
                    this.props.applicationList.list.map((eachApplication, index) => {
                      let showCard = this.state.selected === index
                      return (
                        <div className="table-row position-relative" key={index}>
                          <div className="table-data-row table-body-data-row">
                            <div className="cell cell-arrow">
                              <div className={"droparrow" + (showCard ? ' active' : '')} onClick={this.onShowCardClick(index)}></div>
                            </div>
                            <div className="cell cell-name">
                              <div>
                                <span className="cell-body-data font-600 meta-info">{eachApplication.firstName + ' ' + eachApplication.lastName}</span>
                              </div>
                            </div>
                            <div className="cell cell-contact">
                              <div className="email-panel contact-detail-panel">
                                <span className="cell-body-data font-600 meta-info">{eachApplication.email}</span>
                              </div>
                              <div className="phone-panel contact-detail-panel mt-5">
                                <span className="cell-body-subdata font-600">{eachApplication.phoneCode} {eachApplication.phoneNumber}</span>
                              </div>
                            </div>

                            {/* <div className="cell cell-sin">
                              <div>
                                <span className="cell-body-data font-600">{eachApplication.sin || appConfig.NO_VALUE}</span>
                              </div>
                            </div> */}
                            <div className="cell cell-location">
                              <div>
                                <span className="cell-body-data font-600 meta-info">{eachApplication.city}</span>
                              </div>
                              <div>
                                <span className="cell-body-subdata font-600">{eachApplication.province}</span>
                              </div>
                            </div>
                            <div className="cell cell-app-id">
                              <div>
                                <span className="cell-body-data font-600">{eachApplication.applicationId}</span>
                              </div>
                            </div>
                            <div className="cell cell-app-date">
                              <div>
                                <span className="cell-body-data font-600">{moment.utc(eachApplication.createdAt).format(appConfig.DATE_FORMAT)}</span>
                              </div>
                            </div>
                            <div className="cell cell-application">
                              <div className={"application-status-panel " + (getStatusClassNames(eachApplication.applicationStatus))}>
                                <span className="application-status font-700">{eachApplication.applicationStatus}</span>
                              </div>
                            </div>
                            {
                              ![appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                                ?
                                <div className="cell cell-payment">
                                  <div className={"color-label " + (getPaymentStatusClassNames(eachApplication.paymentStatus))}>
                                    <div className="label-text font-700">{eachApplication.paymentStatus}</div>
                                  </div>
                                </div>
                                :
                                null
                            }
                            <div className="cell cell-action">
                              <div className="d-flex">
                                <div className="action-btn view-btn" onClick={this.onViewApplication(eachApplication._id)}>
                                  <img src={ImageAssets.adminpanel.viewIcon} alt="view" />
                                </div>
                                {
                                  [appConfig.ROLE.USER].includes(this.props.role)
                                    ?
                                    <div className="action-btn edit-btn" onClick={this.onEditApplication(eachApplication._id)}>
                                      <img src={ImageAssets.adminpanel.editIcon} alt="edit" />
                                    </div>
                                    :
                                    null
                                }
                                {
                                  [appConfig.ROLE.ADMIN, appConfig.ROLE.OWNER_ADMIN].includes(this.props.role)
                                    ?
                                    <div className="action-btn view-btn" onClick={this.onDeleteApplication(eachApplication._id, index)}>
                                      <img src={ImageAssets.adminpanel.deleteIcon} alt="delete" />
                                    </div>
                                    :
                                    null
                                }
                              
                              </div>
                            </div>
                          </div>
                          {
                            showCard
                              ?
                              <div className="table-card">
                                <div className="table-card-item cell-sin">
                                  <div>
                                    <span className="cell-header-data font-700">SIN#</span>
                                  </div>
                                  <div className="mt-10">
                                    <span className="cell-body-data font-600">{eachApplication.sin}</span>
                                  </div>
                                </div>
                                <div className="table-card-item cell-location">
                                  <div>
                                    <span className="cell-header-data font-700">Location</span>
                                  </div>
                                  <div className="mt-10">
                                    <div>
                                      <span className="cell-body-data font-600">{eachApplication.city}</span>
                                    </div>
                                    <div>
                                      <span className="cell-body-subdata font-600">{eachApplication.province}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-card-item cell-app-date">
                                  <div>
                                    <span className="cell-header-data font-700">Application Date</span>
                                  </div>
                                  <div className="mt-10">
                                    <div>
                                      <span className="cell-body-data font-600">{moment(eachApplication.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                    </div>
                                  </div>
                                </div>
                                {
                                  ![appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                                    ?
                                    <div className="table-card-item cell-payment">
                                      <div>
                                        <span className="cell-header-data font-700">Payment Status</span>
                                      </div>
                                      <div className={"color-label mt-10 " + getPaymentStatusClassNames(eachApplication.paymentStatus)}>
                                        <div className="label-text font-700">{eachApplication.paymentStatus}</div>
                                      </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="table-card-item cell-application">
                                  <div>
                                    <span className="cell-header-data font-700">Application Status</span>
                                  </div>
                                  <div className={"application-status-panel mt-10 " + getStatusClassNames(eachApplication.applicationStatus)}>
                                    <span className="application-status font-700">{eachApplication.applicationStatus}</span>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                        </div>
                      )
                    })
                    :
                    <div className="no-data-panel center">
                      <div><img src={ImageAssets.common.tableNoDataImg} alt="nodata"></img></div>
                      <div className="mt-20"><span className="large-font font-600">No Data Found !</span></div>
                    </div>
                }
              </div>
            </div>
            <div className="center justify-content-end flex-wrap">
              <div className="mt-5">
                {
                  pageNumbers.length > 1
                    ?
                    <>
                      <div className="pagination-block">
                        <div className="left-arrow" onClick={this.onPageNumberClick(InputIds.PREVIOUS)}></div>
                        {/* {
                            pageNumbers.map((eachNumber, index) => {
                              return (
                                <div className={"number-block" + (this.state.page === (index + 1) ? ' active' : '')} key={index}>
                                  <span className="page-number font-700" onClick={this.onPageNumberClick(index + 1)}>
                                    {eachNumber}
                                  </span>
                                </div>
                              )
                            })
                          }
                          <div className="right-arrow" onClick={this.onPageNumberClick(InputIds.NEXT)}></div> */}

                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.props.applicationList.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          hideFirstLastPages='false'
                          prevPageText=""
                          nextPageText=""
                          activeClass="active"
                          itemClass="number-block"
                          itemClassPrev='left-arrow'
                          itemClassNext='right-arrow'
                        />
                      </div>
                    </>
                    :
                    null
                }
              </div>
              {
                this.props.applicationList.totalCount > 10
                  ?
                  <CustomDropDown
                    parentClassName="page-dropdown ml-30 mt-5"
                    onChange={this.onLimitChange}
                    list={appConfig.DEFAULT_PAGINATION_OPTIONS}
                    value={this.state.limit}
                  />
                  :
                  null
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  componentWillUnmount() {
    this.storeFilterObj()
  }
}



AdminApplicationsPanel.propTypes = {}

const mapStateToProps = (state) => ({
  role: state.auth.role,
  userId: state.auth.userId,
  applicationList: state.list.applicationList,
  applicationFilters: state.list.applicationFilters,
  paidApplications: state.counters.paidApplications,
  unpaidApplications: state.counters.unpaidApplications,
  partiallyPaidApplications: state.counters.partiallyPaidApplications,
  newApplications: state.counters.newApplications,
})

export default connect(mapStateToProps, { getAllApplications, onDeleteApplication, onAdminEditApplication, getApplicationFilters, getApplicationCounters })(AdminApplicationsPanel)
