import { DELETE_APPLICATION, DELETE_NEWSLETTER, DELETE_USER, UPDATE_USER_ROLE, GET_ALL_APPLICATION, GET_DOCUMENT_TYPES, GET_NEWSLETTER_LIST, GET_USER_LIST, INVALID_MEMBERS_LIST, RESET_INVALID_MEMBERS_LIST, SET_APPLICATION_FILTERS, ADD_OTHER_EMAIL_PHONE, GET_OTHER_EMAIL_PHONE, DELETE_OTHER_EMAIL_PHONE } from '../constants/ActionTypes'

const initialState = {
  applicationList: {
    list: [],
    currentPage: 1,
    totalPage: 0,
    totalCount: 0
  },
  usersList: {
    list: [],
    currentPage: 1,
    totalPage: 0,
    totalCount: 0
  },
  newsletterList: {
    list: [],
    currentPage: 1,
    totalPage: 0,
    totalCount: 0
  },
  invalidMembers: [],
  documentTypesList: [],
  applicationFilters: {
    cities: [],
    paymentStatus: [],
    applicationStatus: []
  },
  userRole: {

  },
  otherEmailPhoneNumber: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPLICATION:
      return {
        ...state,
        applicationList: {
          list: action.payload.data,
          totalPage: action.payload.totalPages,
          currentPage: action.currentPage,
          totalCount: action.payload.totalCount
        }
      }
    case DELETE_APPLICATION:
      let allApplication = state.applicationList.list
      allApplication.splice(action.payload, 1)
      return {
        ...state,
        applicationList: {
          ...state.applicationList,
          list: allApplication
        }
      }
    case GET_USER_LIST:
      return {
        ...state,
        usersList: {
          list: action.payload.data,
          totalPage: action.payload.totalPages,
          currentPage: action.currentPage,
          totalCount: action.payload.totalCount
        }
      }
    case DELETE_USER:
      let allUsers = state.usersList.list
      allUsers.splice(action.payload, 1)
      return {
        ...state,
        usersList: {
          ...state.usersList,
          list: allUsers
        }
      }

    case UPDATE_USER_ROLE:
      return {
        ...state,
        userRole: {

        }
      }
    case ADD_OTHER_EMAIL_PHONE:
      return {
        ...state,
      }

    case GET_OTHER_EMAIL_PHONE:
      return {
        ...state,
        otherEmailPhoneNumber: action.payload
      }

    case DELETE_OTHER_EMAIL_PHONE:
      return{
        ...state
      }

    case GET_NEWSLETTER_LIST:
      return {
        ...state,
        newsletterList: {
          list: action.payload.data,
          totalPage: action.payload.totalPages,
          currentPage: action.currentPage,
          totalCount: action.payload.totalCount
        }
      }
    case DELETE_NEWSLETTER:
      let allNewsletters = state.newsletterList.list
      allNewsletters.splice(action.payload, 1)
      return {
        ...state,
        newsletterList: {
          ...state.newsletterList,
          list: allNewsletters
        }
      }
    case INVALID_MEMBERS_LIST:
      return {
        ...state,
        invalidMembers: action.payload
      }
    case RESET_INVALID_MEMBERS_LIST:
      return {
        ...state,
        invalidMembers: []
      }
    case GET_DOCUMENT_TYPES:
      let documentTypes = {}
      action.payload.forEach((eachValue) => {
        documentTypes[eachValue._id] = eachValue.name
      })
      return {
        ...state,
        documentTypesList: documentTypes
      }
    case SET_APPLICATION_FILTERS:
      let allCities = []
      let allPaymentStatus = []
      let allApplicationStatus = []
      action.payload.cities.forEach((eachCity, index) => {
        // allCities.push({ name: eachCity, id: (index + 1) })
        allCities.push({ label: eachCity, value: eachCity, id: (index + 1) })
      })
      action.payload.paymentStatus.forEach((eachStatus, index) => {
        // allPaymentStatus.push({ name: eachStatus, id: (index + 1) })
        allPaymentStatus.push({ label: eachStatus, value: eachStatus, id: (index + 1) })
      })
      action.payload.applicationStatus.forEach((eachStatus, index) => {
        // allApplicationStatus.push({ name: eachStatus, id: (index + 1) })
        allApplicationStatus.push({ label: eachStatus, value: eachStatus, id: (index + 1) })
      })
      return {
        ...state,
        applicationFilters: {
          cities: allCities,
          paymentStatus: allPaymentStatus,
          applicationStatus: allApplicationStatus
        }
      }
    default:
      return state
  }
}