import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ImageAssets from '../../utils/ImageAssets';

class ChooseStatus extends Component {

  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="status-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="center">
            <img src={ImageAssets.popup.logoDesign} alt="logo"></img>
          </div>
          <div className="title-panel text-center mt-20">
            <span className="title-panel-text font-700">
              Choose how to get started
            </span>
          </div>
          <div className="select-block" onClick={this.props.onUsedClick}>
            <div className="row no-gutters align-items-center">
              <div className="col-sm-10 order-12 order-sm-1">
                <div className="select-block-title">
                  <span className="select-block-title-text default-font font-700">
                    I have used Bliss in the past
                  </span>
                </div>
                <div className="mt-10">
                  <span className="default-font light-gray-text font-700">
                    If you've filed with us in the past. We'll import your profiles from last year.
                  </span>
                </div>
              </div>
              <div className="col-sm-2 order-sm-12 order-1">
                <div className="center">
                  <img src={ImageAssets.popup.oldMember} alt="old-user"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="select-block" onClick={this.props.onNewClick}>
            <div className="row no-gutters align-items-center">
              <div className="col-sm-10 order-12 order-sm-1">
                <div className="select-block-title">
                  <span className="select-block-title-text default-font font-700">
                    I'm new to filing taxes with you
                  </span>
                </div>
                <div className="mt-10">
                  <span className="default-font light-gray-text font-700">
                    If you've never filed taxes with us before, we'll get you set up.
                  </span>
                </div>
              </div>
              <div className="col-sm-2 order-sm-12 order-1">
                <div className="center">
                  <img src={ImageAssets.popup.newMember} alt="old-user"></img>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

ChooseStatus.propTypes = {}

export default ChooseStatus
