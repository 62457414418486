import React, { Component } from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import TextField from './TextField'
import { InputIds, Labels, Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { newsletterAddAction } from '../../actions/newsletterActions'

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: { value: '', error: null }
    }
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
          }
        })
      } else {
        this.setState({
          email: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onNewsletterClick = () => {
    let requestBody = {
      email: this.state.email.value
    }
    this.props.newsletterAddAction(requestBody)
    this.setState({ email: { value: '', error: null } })
  }

  render() {

    let disabledButton = this.state.email.value && !this.state.email.error

    return (
      <div className="newsletter-section">
        <div className="container-block">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="newsletter-left-panel">
                <h1 className="font-900 newsletter-title">{Messages.NEWSLETTER}</h1>
                <p className="subscribe-text font-700 large-font mb-0">{Messages.SUBSCRIBE}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="newsletter-right-panel">
                <div className="newsletter-input-panel">
                  <TextField
                    type='email'
                    parentClassName='form-group'
                    inputDivClassName='input-panel'
                    placeholder={Labels.EMAIL_ADDRESS}
                    className='form-input newsletter-input'
                    onChange={this.onInputChange(InputIds.EMAIL)}
                    {...this.state.email}
                  />
                  <div className="envelop-icon" onClick={disabledButton ? this.onNewsletterClick : null}>
                    <img src={ImageAssets.home.newsletterImages.envelopeIcon} alt="envelope"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { newsletterAddAction })(Header)
