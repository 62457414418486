import { appConfig } from './AppConfig'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + appConfig.API_VERSION

export const APIRoutes = {
  USER: {
    REGISTER: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/signUp',
    LOGIN: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/login',
    FORGOT_PASSWORD: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/forgotPassword',
    SET_NEW_PASSWORD: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/resetPassword',
    GET_DETAILS: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    LIST: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    DELETE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    IMPORT: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    UPDATE_ROLE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    REFRESH_TOKEN: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    ADD_EMAIL_PHONE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    DELETE_EMAIL_PHONE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    VERIFY_EMAIL_PHONE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
    GET_EMAIL_PHONE: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/',
  },
  USER_DETAILS: apiEndpoint + '/details',
  AUTH: {
    CHECK_APPLICATION_EXIST: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/application/validate',
    PATIALLY_LOGIN: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/partially/login',
    PARTIALLY_LOGIN_OTP: apiEndpoint + '/user/' + appConfig.TENANT_ID + '/partially/login/otp'
  },
  APPLICATION: {
    DELETE: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    GET: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    CREATE: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    UPDATE: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    GET_ALL_APPLICATION: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    GET_SINGLE_APPLICATION: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    UPDATE_STATUS: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    UPDATE_SIN: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    GET_FILTERS: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    ASSIGN_BY_ADMIN: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    ASSIGN_TO_ACCOUNTANT: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
    GET_REFRANCE : apiEndpoint + '/tax/applications/bliss/'
  },
  DASHBOARD: {
    APPLICATION_COUNTERS: apiEndpoint + '/dashboard/' + appConfig.TENANT_ID + '/'
  },
  PAYMENT: {
    MAKE_PAYMENT: apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/',
  },
  SUBSCRIPTION: {
    PLANS: apiEndpoint + '/subscription/plan/' + appConfig.TENANT_ID + '/',
    TAX_OPTIONS: apiEndpoint + '/tax/complexity/' + appConfig.TENANT_ID + '/',
    RECOMMEND: apiEndpoint + '/tax/complexity/' + appConfig.TENANT_ID + '/',
    GET_PLAN: apiEndpoint + '/subscription/plan/' + appConfig.TENANT_ID + '/'
  },
  NEWSLETTER_SUBSCRIPTION: {
    ADD: apiEndpoint + '/email/subscribe/' + appConfig.TENANT_ID + '/send',
    DELETE: apiEndpoint + '/email/subscribe/' + appConfig.TENANT_ID + '/',
    LIST: apiEndpoint + '/email/subscribe/' + appConfig.TENANT_ID + '/'
  },
  COMMON: {
    CONSULTANT: apiEndpoint + '/consultant/' + appConfig.TENANT_ID + '/send',
    CONTACT_US: apiEndpoint + '/contact-us/' + appConfig.TENANT_ID + '/send'
  },
  DOCUMENT_TYPES: {
    GET: apiEndpoint + '/document-types/' + appConfig.TENANT_ID + '/'
  },
  CANADA_POST: {
    GET: process.env.REACT_APP_CANADA_POST_ENDPOINT
  },
  DOWNLOAD_ZIP: {
    GET:  apiEndpoint + '/tax/applications/' + appConfig.TENANT_ID + '/'
  }
}