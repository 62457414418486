import { GET_PLANS_LIST, GET_RECOMMENDED_PLAN, GET_SINGLE_PLAN, GET_TAX_OPTIONS_LIST } from '../constants/ActionTypes'

const initialState = {
  plans: [],
  taxOptions: [],
  selectedPackage: '',
  recommendedPlan: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLANS_LIST:
      let allPlans = []
      action.payload.forEach((eachPlan) => {
        if (!eachPlan.isCustom) {
          allPlans.push(eachPlan)
        }
      })
      return {
        ...state,
        plans: action.payload
      }
    case GET_TAX_OPTIONS_LIST:
      return {
        ...state,
        taxOptions: action.payload
      }
    case GET_RECOMMENDED_PLAN:
      return {
        ...state,
        selectedPackage: action.payload
      }
    case GET_SINGLE_PLAN:
      return {
        ...state,
        recommendedPlan: action.payload
      }
    default:
      return state
  }
}