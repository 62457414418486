import React, { Component } from 'react'
import { connect } from 'react-redux'
import FileViewer from 'react-file-viewer'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ImageAssets from '../../utils/ImageAssets'
import { appConfig } from '../../constants/AppConfig'
import store from '../../store'
import { RESET_DOCUMENT } from '../../constants/ActionTypes'

class ViewDocument extends Component {

  onCloseClick = () => {
    this.setState({ fileBase64: '' })
    store.dispatch({ type: RESET_DOCUMENT })
    this.props.onCancelClick()
  }

  render() {
    let type = ''
    if (appConfig.ALLOWED_FILE_TYPES.IMAGE.includes(this.props.fileType)) {
      type = 'jpeg'
    } else if (appConfig.ALLOWED_FILE_TYPES.PDF.includes(this.props.fileType)) {
      type = 'pdf'
    }else if (this.props.fileType === 'application/msword') {
      type = 'doc'
    } else if (appConfig.ALLOWED_FILE_TYPES.WORD.includes(this.props.fileType)) {
      type = 'docx'
    } else if (appConfig.ALLOWED_FILE_TYPES.EXCEL.includes(this.props.fileType)) {
      type = 'xlsx'
    }
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="document-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.onCloseClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
            <div>
              {
                this.props.fileLink
                 ?
                 this.props.fileType !== 'application/msword'
                  ?
                  <FileViewer
                    fileType={type}
                    filePath={this.props.fileLink}
                    key={'1'}
                  />
                  :
                  <div className="not-supported-panel center">
                    <div>
                      <img src={ImageAssets.taxform.notSupportedImg} alt="doc-file"></img>
                    </div>
                    <div className="mt-30">
                      <span>.DOC file is not supported.</span>
                    </div>
                    <div>
                      <span>Please check Downloaded file.</span>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
        </DialogContent>
      </Dialog>
    )
  }
}

ViewDocument.propTypes = {}

const mapStateToProps = (state) => ({
  fileLink: state.documentView.file,
  fileType: state.documentView.type
})

export default connect(mapStateToProps, {})(ViewDocument)
