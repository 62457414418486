import React, { Component } from 'react'
import validator from 'validator'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import MaterialUITextField from '@material-ui/core/TextField'
import TextField from '../../component/common/TextField'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { InputIds, Labels, Messages, Placeholders, TooltipMessages } from '../../constants/StaticTexts'
import Button from '../common/Button'
import CustomDropDown from '../common/CustomDropDown'
import { mobileNumberFormat } from '../../utils/Utils'
import { connect } from 'react-redux'

class YourFamily extends Component {

  constructor(props) {
    super(props)
    let dependents = []
    this.props.applicationData.dependants.forEach((eachDependent) => {
      dependents.push({
        firstName: { value: eachDependent.firstName, error: null },
        lastName: { value: eachDependent.lastName, error: null },
        sin: { value: eachDependent.sin, error: null },
        dob: { value: eachDependent.dob, error: null }
      })
    })
    this.state = {
      maritalStatus: this.props.applicationData.maritalStatus,
      dateOfMarriage: { value: this.props.applicationData.dateOfMarriage, error: null },
      spouseFirstname: { value: this.props.applicationData.spouseFirstname, error: null },
      spouseLastname: { value: this.props.applicationData.spouseLastname, error: null },
      spouseHasFilledReturnBefore: this.props.applicationData.spouseHasFilledReturnBefore,
      spouseFilingReturnFirstTime: this.props.applicationData.spouseFilingReturnFirstTime,
      spouseDateOfLanding: { value: this.props.applicationData.spouseDateOfLanding, error: null },
      spouseTaxYear: this.props.applicationData.spouseTaxYear,
      spouseSIN: { value: this.props.applicationData.spouseSIN, error: null },
      spouseDateOfBirth: { value: this.props.applicationData.spouseDateOfBirth, error: null },
      spouseEmail: { value: this.props.applicationData.spouseEmail, error: null },
      spouseContactNumber: { value: this.props.applicationData.spouseContactNumber, error: null },
      hasDependants: this.props.applicationData.hasDependants,
      dependantsNumber: this.props.applicationData.dependantsNumber,
      dependants: dependents,
      documentsDeleted: []
    }
  }

  onInputChange = (inputId, dependantNumber) => (dropdownValue) => (event) => {
    if (inputId === InputIds.MARITAL_STATUS) {
      let emptyFields = {
        dateOfMarriage: { value: null, error: null },
        spouseFirstname: { value: '', error: null },
        spouseLastname: { value: '', error: null },
        spouseSIN: { value: '', error: null },
        spouseDateOfBirth: { value: moment().utc().subtract(18, 'years'), error: null },
        spouseEmail: { value: '', error: null },
        spouseContactNumber: { value: '', error: null },
        spouseHasFilledReturnBefore: '',
        spouseFilingReturnFirstTime: '',
        spouseDateOfLanding: { value: moment().utc().subtract(1, 'year'), error: null },
        spouseTaxYear: ''
      }
      if (dropdownValue === "Unmarried") {
        let updateObject = { documentsDeleted: this.state.documentsDeleted || [] }
        this.props.applicationData.documents.forEach((eachDocument) => {
          if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
            updateObject['documentsDeleted'] = [...this.state.documentsDeleted, ...updateObject.documentsDeleted, eachDocument]
          }
        })
        this.setState({ ...updateObject })
      }else {
       emptyFields['dateOfMarriage'] = { value: moment().utc(), error: null }
         
      }

      if (dropdownValue) {
        this.setState({ maritalStatus: dropdownValue, ...emptyFields })
      } else {
        this.setState({ maritalStatus: '', ...emptyFields })
      }
    } else if (inputId === InputIds.SPOUSE_FIRSTNAME) {
      if (event.target.value) {
        this.setState({
          spouseFirstname: {
            value: event.target.value,
            error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.SPOUSE_FIRSTNAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          spouseFirstname: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.SPOUSE_LASTNAME) {
      if (event.target.value) {
        this.setState({
          spouseLastname: {
            value: event.target.value,
            error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.SPOUSE_LASTNAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          spouseLastname: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.HAS_SPOUSE_FILLED_RETURN_BEFORE) {
      if (dropdownValue) {
        this.setState({ spouseHasFilledReturnBefore: dropdownValue })
      }
    } else if (inputId === InputIds.HAS_SPOUSE_FILINF_RETURN_FOR_FIRST_TIME) {
      if (dropdownValue) {
        this.setState({ spouseFilingReturnFirstTime: dropdownValue })
      }
    } else if (inputId === InputIds.SPOUSE_TAX_YEAR) {
      if (dropdownValue) {
        this.setState({ spouseTaxYear: dropdownValue })
      } else {
        this.setState({ spouseTaxYear: '' })
      }
    }
    else if (inputId === InputIds.SPOUSE_SIN) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            spouseSIN: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SPOUSE_SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          spouseSIN: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.SPOUSE_EMAIL_ADDRESS) {
      if (event.target.value) {
        this.setState({
          spouseEmail: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.SPOUSE_EMAIL_NOT_VALID
          }
        })
      } else {
        this.setState({
          spouseEmail: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.SPOUSE_CONTACT_NUMBER) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)
        if (formatedNumber) {
          this.setState({
            spouseContactNumber: {
              value: formatedNumber,
              error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.SPOUSE_CONTACT_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          spouseContactNumber: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.HAS_DEPENDENTS) {
      if (dropdownValue) {
        this.setState({ hasDependants: dropdownValue, dependantsNumber: '', dependants: [] })
      } else {
        this.setState({ hasDependants: '' })
      }
    } else if (inputId === InputIds.DEPENDENTS_NUMBER) {
      if (dropdownValue) {
        let updateObject = { dependantsNumber: Number(dropdownValue), dependants: this.state.dependants || [], documentsDeleted: [] }
        let difference = this.state.dependantsNumber - updateObject.dependantsNumber
        if (difference > 0) {
          for (let i = 0; i < difference; i++) {
            updateObject.dependants.pop()
            let dependentNumber = updateObject.dependantsNumber + (i + 1)
            dependentNumber = appConfig.DOCUMENTS_FOR.DEPENDENT + ' ' + dependentNumber
            this.props.applicationData.documents.forEach((eachDocument) => {
              if (eachDocument.uploadedFor === dependentNumber) {
                updateObject['documentsDeleted'] = [...this.state.documentsDeleted, ...updateObject.documentsDeleted, eachDocument]
              }
            })
          }
        } else {
          for (let i = 0; i > difference; i--) {
            updateObject.dependants.push({
              firstName: { value: '', error: null },
              lastName: { value: '', error: null },
              sin: { value: '', error: null },
              dob: { value: moment(), error: null }
            })
          }
        }
        this.setState({ ...updateObject })
      } else {
        this.setState({ dependantsNumber: '' })
      }
    } else if (inputId === InputIds.DEPENDENT_FIRSTNAME) {
      let dependants = this.state.dependants
      if (event.target.value) {
        dependants[dependantNumber].firstName = {
          value: event.target.value,
          error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.DEPENDENT_FIRSTNAME_NOT_VALID
        }
        this.setState({ dependants: dependants })
      } else {
        dependants[dependantNumber].firstName = {
          value: '',
          error: null
        }
        this.setState({ dependants: dependants })
      }
    } else if (inputId === InputIds.DEPENDENT_LASTNAME) {
      let dependants = this.state.dependants
      if (event.target.value) {
        dependants[dependantNumber].lastName = {
          value: event.target.value,
          error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.DEPENDENT_LASTNAME_NOT_VALID
        }
        this.setState({ dependants: dependants })
      } else {
        dependants[dependantNumber].lastName = {
          value: '',
          error: null
        }
        this.setState({ dependants: dependants })
      }
    } else if (inputId === InputIds.DEPENDENT_SIN) {
      let dependants = this.state.dependants
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          dependants[dependantNumber].sin = {
            value: event.target.value,
            error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.DEPENDENT_SIN_NOT_VALID
          }
          this.setState({ dependants: dependants })
        }
      } else {
        dependants[dependantNumber].sin = {
          value: '',
          error: null
        }
        this.setState({ dependants: dependants })
      }
    }
  }

  onDateChange = (inputId, dependantNumber) => (value) => {
    if (inputId === InputIds.DATE_OF_MARRIAGE) {
      if (value) {
        this.setState({
          dateOfMarriage: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.DATE_OF_MARRIAGE_NOT_VALID
          }
        })
      } else {
        this.setState({
          dateOfMarriage: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.SPOUSE_DATE_OF_BIRTH) {
      if (value) {
        this.setState({
          spouseDateOfBirth: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.SPOUSE_DATE_OF_BIRTH_NOT_VALID
          }
        })
      } else {
        this.setState({
          spouseDateOfBirth: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.DEPENDENT_DATE_OF_BIRTH) {
      let dependants = this.state.dependants
      if (value) {
        dependants[dependantNumber].dob = {
          value: value,
          error: validator.isDate(new Date(value)) ? null : ErrorMessages.DEPENDENT_DOB_NOT_VALID
        }
        this.setState({ dependants: dependants })
      } else {
        dependants[dependantNumber].dob = {
          value: '',
          error: null
        }
        this.setState({ dependants: dependants })
      }
    } else if (inputId === InputIds.SPOUSE_DATE_OF_LANDING) {
      if (value) {
        this.setState({
          spouseDateOfLanding: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.DATE_OF_LANDING_NOT_VALID
          }
        })
      } else {
        this.setState({
          spouseDateOfLanding: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onSaveClick = () => {
    let dependants = []
    this.state.dependants.forEach((eachDependent, index) => {
      dependants.push({
        id: index + 1,
        firstName: eachDependent.firstName.value,
        lastName: eachDependent.lastName.value,
        dob: eachDependent.dob.value,
        sin: eachDependent.sin.value
      })
    })
    this.props.onClick({
      maritalStatus: this.state.maritalStatus,
      dateOfMarriage: this.state.dateOfMarriage.value,
      spouseFirstname: this.state.spouseFirstname.value,
      spouseLastname: this.state.spouseLastname.value,
      spouseHasFilledReturnBefore: this.state.spouseHasFilledReturnBefore,
      spouseFilingReturnFirstTime: this.state.spouseFilingReturnFirstTime,
      spouseDateOfLanding: this.state.spouseDateOfLanding.value,
      spouseTaxYear: this.state.spouseTaxYear,
      spouseSIN: this.state.spouseSIN.value,
      spouseDateOfBirth: this.state.spouseDateOfBirth.value,
      spouseEmail: this.state.spouseEmail.value,
      spouseContactNumber: this.state.spouseContactNumber.value,
      hasDependants: this.state.hasDependants,
      dependantsNumber: this.state.dependantsNumber,
      dependants: dependants,
      deletedDocuments: this.state.documentsDeleted
    })
  }

  render() {

    let disabledButton = this.state.maritalStatus
    if (disabledButton && (this.state.maritalStatus === appConfig.MARITAL_STATUS[0])) {
      disabledButton = this.state.dateOfMarriage.value && this.state.spouseFirstname.value && !this.state.spouseFirstname.error && this.state.spouseLastname.value && !this.state.spouseLastname.error && this.state.spouseHasFilledReturnBefore && this.state.spouseFilingReturnFirstTime && this.state.spouseDateOfBirth.value && !this.state.spouseDateOfBirth.error && this.state.spouseEmail.value && !this.state.spouseEmail.error && this.state.spouseContactNumber.value && !this.state.spouseContactNumber.error
      if (disabledButton && this.state.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0]) {
        disabledButton = disabledButton && this.state.spouseDateOfLanding.value
      } else if (disabledButton && this.state.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[1]) {
        disabledButton = disabledButton && this.state.spouseTaxYear
      }
    }
    if (disabledButton && (this.state.hasDependants === appConfig.HAS_DEPENDENTS[0])) {
      if (this.state.dependantsNumber) {
        this.state.dependants.forEach((eachDependent) => {
          disabledButton = disabledButton && eachDependent.firstName.value && !eachDependent.firstName.error && eachDependent.lastName.value && !eachDependent.lastName.error && eachDependent.dob.value && !eachDependent.dob.error
        })
      } else {
        disabledButton = false
      }
    }

    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="step-heading">
            <span className="step-heading-text font-700">
              {Messages.YOUR_FAMILY}
            </span>
          </div>
          <div className="form-container">
            <div className="d-flex form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.MARITAL_STATUS}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.MARITAL_STATUS}
                  onChange={this.onInputChange(InputIds.MARITAL_STATUS)}
                  list={appConfig.MARITAL_STATUS}
                  value={this.state.maritalStatus}
                />
              </div>
              {
                this.state.maritalStatus === appConfig.MARITAL_STATUS[0]
                  ?
                  <div className="form-field">
                    <DatePicker
                      disableFuture
                      inputFormat={appConfig.DATE_FORMAT}
                      label={Labels.DATE_OF_MARRIAGE}
                      views={[ "year","month", "date" ]}
                      value={this.state.dateOfMarriage.value}
                      placeholder={Placeholders.DATE}
                      disabled={this.props.disabled}
                      InputProps={{ className: 'input-block' }}
                      renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' inputProps={{ ...props.inputProps,readOnly: true}}    />)}
                      onChange={this.onDateChange(InputIds.DATE_OF_MARRIAGE)}
                    />
                  </div>
                  :
                  null
              }
            </div>
            {
              this.state.maritalStatus === appConfig.MARITAL_STATUS[0]
                ?
                <>
                  <div className="d-flex form-field-row">
                    <div className="form-field">
                      <TextField
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_FIRSTNAME}
                        labelClassName='default-font black-text font-600'
                        type='text'
                        className='form-input'
                        disabled={this.props.disabled}
                        placeholder={Placeholders.TYPE_HERE}
                        onChange={this.onInputChange(InputIds.SPOUSE_FIRSTNAME)}
                        {...this.state.spouseFirstname}
                      />
                    </div>
                    <div className="form-field">
                      <TextField
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_LASTNAME}
                        labelClassName='default-font black-text font-600'
                        type='text'
                        className='form-input'
                        disabled={this.props.disabled}
                        placeholder={Placeholders.TYPE_HERE}
                        onChange={this.onInputChange(InputIds.SPOUSE_LASTNAME)}
                        {...this.state.spouseLastname}
                      />
                    </div>
                  </div>
                  <div className="d-flex form-field-row">
                    <div className="form-field">
                      <CustomDropDown
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_FILLED_RETURN}
                        labelClassName='default-font black-text font-600'
                        placeholder={Placeholders.SELECT}
                        disabled={this.props.disabled}
                        onChange={this.onInputChange(InputIds.HAS_SPOUSE_FILLED_RETURN_BEFORE)}
                        list={appConfig.SPOUSE_FILLED_RETURN_BEFORE}
                        value={this.state.spouseHasFilledReturnBefore}
                      />
                    </div>
                  </div>
                  <div className="d-flex form-field-row">
                    <div className="form-field">
                      <CustomDropDown
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_FILING_RETURN_FOR_FIRST_TIME}
                        labelClassName='default-font black-text font-600'
                        placeholder={Placeholders.SELECT}
                        disabled={this.props.disabled}
                        onChange={this.onInputChange(InputIds.HAS_SPOUSE_FILINF_RETURN_FOR_FIRST_TIME)}
                        list={appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME}
                        value={this.state.spouseFilingReturnFirstTime}
                      />
                    </div>
                  </div>
                  {
                    this.state.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0]
                      ?
                      <div className="d-flex form-field-row">
                        <div className="form-field">
                          <DatePicker
                            disableFuture
                            inputFormat={appConfig.DATE_FORMAT}
                            label={Labels.DATE_OF_LANDING}
                            views={[ "year","month", "date" ]}
                            placeholder={Placeholders.DATE}
                            InputProps={{ className: 'input-block' }}
                            disabled={this.props.disabled}
                            renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' inputProps={{ ...props.inputProps,readOnly: true}} />)}
                            onChange={this.onDateChange(InputIds.SPOUSE_DATE_OF_LANDING)}
                            value={this.state.spouseDateOfLanding.value}
                          />
                        </div>
                      </div>
                      :
                      this.state.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[1]
                        ?
                        <div className="d-flex form-field-row">
                          <div className="form-field">
                            <CustomDropDown
                              parentClassName='form-group'
                              labelText={Labels.TAX_YEARS}
                              labelClassName='default-font black-text font-600'
                              placeholder={Placeholders.SELECT}
                              disabled={this.props.disabled}
                              onChange={this.onInputChange(InputIds.SPOUSE_TAX_YEAR)}
                              list={appConfig.NO_OF_TAX_YEARS}
                              value={this.state.spouseTaxYear}
                            />
                          </div>
                        </div>
                        :
                        null
                  }
                  <div className="d-flex form-field-row">
                    {
                      [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                        ?
                        <div className="form-field">
                          <TextField
                            parentClassName='form-group'
                            labelText={Labels.SPOUSE_SIN}
                            labelClassName='default-font black-text font-600'
                            type='text'
                            className='form-input'
                            inputDivClassName='input-panel'
                            placeholder={Placeholders.TYPE_HERE}
                            tooltipText={TooltipMessages.SIN_NUMBER}
                            onChange={this.onInputChange(InputIds.SPOUSE_SIN)}
                            {...this.state.spouseSIN}
                          />
                        </div>
                        :
                        null
                    }
                  </div>
                  <div className="d-flex form-field-row">
                    <div className="form-field">
                      <DatePicker
                        maxDate={moment().utc().subtract(18, 'years')}
                        inputFormat={appConfig.DATE_FORMAT}
                        label={Labels.SPOUSE_DATE_OF_BIRTH}
                        views={[ "year","month", "date" ]}
                        value={this.state.spouseDateOfBirth.value}
                        placeholder={Placeholders.DATE}
                        disabled={this.props.disabled}
                        InputProps={{ className: 'input-block' }}
                        renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' inputProps={{ ...props.inputProps,readOnly: true}} />)}
                        onChange={this.onDateChange(InputIds.SPOUSE_DATE_OF_BIRTH)}
                      />
                    </div>
                    <div className="form-field">
                      <TextField
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_EMAIL_ADDRESS}
                        labelClassName='default-font black-text font-600'
                        type='email'
                        className='form-input'
                        disabled={this.props.disabled}
                        placeholder={Placeholders.TYPE_HERE}
                        onChange={this.onInputChange(InputIds.SPOUSE_EMAIL_ADDRESS)}
                        {...this.state.spouseEmail}
                      />
                    </div>
                    <div className="form-field phonenumber-panel">
                      <div className="number-prefix">+1</div>
                      <TextField
                        parentClassName='form-group'
                        labelText={Labels.SPOUSE_CONTACT_NUMBER}
                        labelClassName='default-font black-text font-600'
                        type='text'
                        className='form-input'
                        disabled={this.props.disabled}
                        placeholder={Placeholders.MOBILE_NUMBER}
                        onChange={this.onInputChange(InputIds.SPOUSE_CONTACT_NUMBER)}
                        {...this.state.spouseContactNumber}
                      />
                    </div>
                  </div>
                </>
                :
                null
            }
            <div className="d-flex form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.HAVE_DEPENDENTS}
                  labelClassName='default-font black-text font-600'
                  placeholder={Placeholders.SELECT}
                  disabled={this.props.disabled}
                  onChange={this.onInputChange(InputIds.HAS_DEPENDENTS)}
                  list={appConfig.HAS_DEPENDENTS}
                  value={this.state.hasDependants}
                />
              </div>
              {
                this.state.hasDependants === appConfig.HAS_DEPENDENTS[0]
                  ?
                  <div className="form-field">
                    <CustomDropDown
                      parentClassName='form-group'
                      labelText={Labels.DEPENDENTS_NUMBER}
                      labelClassName='default-font black-text font-600'
                      placeholder={Placeholders.DEPENDENTS_NUMBER}
                      disabled={this.props.disabled}
                      onChange={this.onInputChange(InputIds.DEPENDENTS_NUMBER)}
                      list={appConfig.DEPENDENTS_NUMBER}
                      value={this.state.dependantsNumber}
                    />
                  </div>
                  :
                  null
              }
            </div>
            {
              this.state.dependantsNumber
                ?
                this.state.dependants.map((eachDependent, index) => {
                  return (
                    <div className="d-flex form-field-row" key={index}>
                      <div className="form-field">
                        <TextField
                          parentClassName='form-group'
                          labelText={Labels.DEPENDENT + ' ' + (index + 1) + ' ' + Labels.DEPENDENT_FIRSTNAME}
                          labelClassName='default-font black-text font-600'
                          type='text'
                          className='form-input'
                          disabled={this.props.disabled}
                          placeholder={Placeholders.TYPE_HERE}
                          onChange={this.onInputChange(InputIds.DEPENDENT_FIRSTNAME, index)}
                          {...eachDependent.firstName}
                        />
                      </div>
                      <div className="form-field">
                        <TextField
                          parentClassName='form-group'
                          labelText={Labels.DEPENDENT + ' ' + (index + 1) + ' ' + Labels.DEPENDENT_LASTNAME}
                          labelClassName='default-font black-text font-600'
                          type='text'
                          className='form-input'
                          disabled={this.props.disabled}
                          placeholder={Placeholders.TYPE_HERE}
                          onChange={this.onInputChange(InputIds.DEPENDENT_LASTNAME, index)}
                          {...eachDependent.lastName}
                        />
                      </div>
                      {
                        [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                          ?
                          <div className="form-field">
                            <TextField
                              parentClassName='form-group'
                              labelText={Labels.DEPENDENT + ' ' + (index + 1) + ' ' + Labels.DEPENDENT_SIN}
                              labelClassName='default-font black-text font-600'
                              type='text'
                              className='form-input'
                              placeholder={Placeholders.TYPE_HERE}
                              onChange={this.onInputChange(InputIds.DEPENDENT_SIN, index)}
                              {...eachDependent.sin}
                            />
                          </div>
                          :
                          null
                      }
                      <div className="form-field">
                        <DatePicker
                          disableFuture
                          inputFormat={appConfig.DATE_FORMAT}
                          label={Labels.DEPENDENT + ' ' + (index + 1) + ' ' + Labels.DEPENDENT_DATE_OF_BIRTH}
                          value={eachDependent.dob.value}
                          views={[ "year","month", "date" ]}
                          placeholder={Placeholders.DATE}
                          InputProps={{ className: 'input-block' }}
                          disabled={this.props.disabled}
                          renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' inputProps={{ ...props.inputProps,readOnly: true}} />)}
                          onChange={this.onDateChange(InputIds.DEPENDENT_DATE_OF_BIRTH, index)}
                        />
                      </div>
                    </div>
                  )
                })
                :
                null
            }
            <Button
              type='submit'
              parentClassName='mt-30'
              className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
              disabled={!disabledButton}
              text={Messages.SAVE_AND_CONTINUE}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

YourFamily.propTypes = {}

const mapStateToProps = (state) => ({
  role: state.auth.role
})

export default connect(mapStateToProps, {})(YourFamily)
