import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import setAuthToken from '../interceptors/SetAuthToken'
import { AUTH_SET_USER, AUTH_LOGOUT, ACCEPT_INFORMATIVE_MESSAGE, GET_USER_DETAILS } from '../constants/ActionTypes'
import { APIRoutes } from '../constants/BackendRoutes'
import { UIRoutes } from '../constants/FrontendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { appConfig } from '../constants/AppConfig'

export const registerAction = (body, history) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.USER.REGISTER, body)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
      history.push(UIRoutes.LOGIN)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const loginAction = (body, history, onError) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.USER.LOGIN, body)
    .then(res => {
      let { token, refreshToken,otherEmailPhoneNumber } = res.data.result
      setAuthToken(token)
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('otherEmailPhoneNumber', JSON.stringify(otherEmailPhoneNumber))
      dispatch({
        type: AUTH_SET_USER,
        payload: res.data.result
      })
      setLoading(false)
      if ([appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(res.data.result.role)) {
        history.push({ pathname: UIRoutes.APPLICATIONS })
      } else {
        history.push({ pathname: UIRoutes.VIEW_ALL_APPLICATION })
      }
    }).catch(err => {
      setLoading(false)
      onError(err)
      toast.error(getErrorMessage(err))
    })
}

export const forgotPasswordAction = (body, history, user) => () => {
  setLoading(true)
  axios.post(APIRoutes.USER.FORGOT_PASSWORD, body)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
      if (history) {
        history.push({ pathname: UIRoutes.SET_PASSWORD, otpSentTo: body.emailOrPhone, isFrom: user })
      }
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const setNewPasswordAction = (body, history) => () => {
  setLoading(true)
  axios.put(APIRoutes.USER.SET_NEW_PASSWORD, body)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
      history.push(UIRoutes.LOGIN)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const logoutUser = () => (dispatch) => {
  removeLocalStorage()
  dispatch({ type: AUTH_LOGOUT })
  setAuthToken(false)
}

export const removeLocalStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('userfilterObj')
  localStorage.removeItem('applicationfilterObj')
  localStorage.removeItem('refrancefilterObj')
  localStorage.removeItem('subscriptionfilterObj')
}

export const onRefreshTokenExpire = (dispatch) => {
  removeLocalStorage()
  dispatch({ type: AUTH_LOGOUT })
  setAuthToken(false)
}

export const setUser = (authData, dispatch) => {
  dispatch({
    type: AUTH_SET_USER,
    payload: authData
  })
  // setLoading(true)
  // axios.get(APIRoutes.USER.GET_DETAILS + authData._id + '/refreshToken/' + localStorage.refreshToken)
  //   .then(res => {
  //     let { token, refreshToken } = res.data.result
  //     setAuthToken(token)
  //     localStorage.setItem('token', token)
  //     localStorage.setItem('refreshToken', refreshToken)
  //     dispatch({
  //       type: AUTH_SET_USER,
  //       payload: res.data.result
  //     })
  //     setLoading(false)
  //   }).catch(err => {
  //     setLoading(false)
  //     toast.error(getErrorMessage(err))
  //   })
}

export const getUserDetails = (data, dispatch) => {
  dispatch({
    type: AUTH_SET_USER,
    payload: data
  })
}

export const sendOTP = (requestObject) => () => {
  setLoading(true)
  axios.post(APIRoutes.AUTH.PARTIALLY_LOGIN_OTP, requestObject)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const loginWithOTP = (requestObject) => () => {
  setLoading(true)
  axios.post(APIRoutes.AUTH.PATIALLY_LOGIN, requestObject)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const acceptInformativeMessage = () => dispatch => {
  dispatch({
    type: ACCEPT_INFORMATIVE_MESSAGE,
    payload: ''
  })
}

export const getUserDetail = (tokenUserId, userId) => dispatch => {

  setLoading(true)

  axios.get(APIRoutes.USER.GET_DETAILS + tokenUserId + '/' + userId)
    .then(res => {
      var response = { 'email': res.data.result.email, 'phoneNumber': res.data.result.phoneNumber, 'phoneCode': res.data.result.phoneCode }
      dispatch({
        type: GET_USER_DETAILS,
        payload: response
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
}