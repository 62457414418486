import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ImageAssets from '../../utils/ImageAssets'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { appConfig } from '../../constants/AppConfig'
import { usersListAction, deleteUserAction, importUserAction, updateUserRole } from '../../actions/userActions'
import { getRefrancesList } from '../../actions/applicationActions'
import { InputIds, Messages } from '../../constants/StaticTexts'
// import Button from '../common/Button'
import DownloadExcel from '../common/DownloadExcel'
// import TextField from '../common/TextField'
import CustomDropDown from '../common/CustomDropDown'
import TextField from '../common/TextField'
import Pagination from 'react-js-pagination'

class AdminReferences extends Component {

    constructor(props) {
        super(props)
        let refranceFilterObj = JSON.parse(localStorage.getItem('refranceFilterObj'))
        this.state = {
            page: refranceFilterObj && refranceFilterObj.pageNo ? refranceFilterObj.pageNo : 1,
            limit: refranceFilterObj && refranceFilterObj.limit ? refranceFilterObj.limit : appConfig.DEFAULT_PAGINATION_OPTIONS[0],
            selected: null,
            search: refranceFilterObj && refranceFilterObj.filters.search ? refranceFilterObj.filters.search : '',
            showAssignPopup: false,
            document: {
                type: '',
                file: '',
                fileType: '',
                fileSize: '',
                fileName: '',
            },
            currentUserId: '',
            role: '',
            filterBy: refranceFilterObj && refranceFilterObj.filterBy && refranceFilterObj.filterBy ? refranceFilterObj.filterBy : appConfig.TIME_FILTER[5],
            sortDirection: refranceFilterObj && refranceFilterObj.sortingOrder ? refranceFilterObj.sortingOrde : appConfig.DEFAULT_SORTING,
            sortHeader: refranceFilterObj && refranceFilterObj.sortingName ? refranceFilterObj.sortingName : appConfig.DEFAULT_SORTING_FIELD
        }
    }

    componentDidMount() {
        this.onPageNumberClick();
    }

    onPageNumberClick = () => {
        let filterList = [];
        if (this.state.search !== '') {
            filterList = [
                { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
            ]
        }
        const requestBody = {
            filterBy: this.state.filterBy,
            customDuration: {
                from: "",
                to: ""
            },
            page: this.state.page,
            limit: this.state.limit,
            filterList: filterList,
            sortHeader: this.state.sortHeader,
            sortDirection: this.state.sortDirection,
            isPagination: true
        }
        this.storeFilter()
        this.props.getRefrancesList(this.props.userId, this.props.match.params.id, requestBody)
    }

    onUserClick = (id) => () => {
        if (this.state.selected === id) {
            this.setState({ selected: null })
        } else {
            this.setState({ selected: id })
        }
    }
    // changePage = (val) => () => {
    //     this.setState({
    //         page: val
    //     }, () => {
    //         this.onPageNumberClick()
    //     })
    // }

    changePage = (val) => {
        this.setState({
            page: val
        }, () => {
            this.onPageNumberClick()
        })
    }

    onInputChange = (inputId) => (dropdownValue) => (event) => {
        if (inputId === InputIds.FILTER) {
            this.setState({
                search: event.target.value,
                page: 1
            }, () => {
                this.onPageNumberClick()
            })
        }
        else if (inputId === InputIds.FILTER_TIME) {
            this.setState({
                filterBy: dropdownValue,
                page: 1
            }, () => {
                this.onPageNumberClick()
            })
        }
        else if (inputId === InputIds.NEXT) {
            this.setState({
                page: this.state.page + 1,

            }, () => {
                this.onPageNumberClick()
            })
        }
        else if (inputId === InputIds.PREVIOUS) {
            this.setState({
                page: this.state.page - 1
            }, () => {
                this.onPageNumberClick()
            })
        }
    }
    onLimitChange = (limit) => () => {
        this.setState({ limit: limit, page: 1 }, () => {
            this.onPageNumberClick()
        })
    }

    onSortingClick = (input) => (e) => {

        if (e) {
            let filterList = [];
            if (this.state.search !== '') {
                filterList = [
                    { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
                ]
            }
            const requestBody = {
                filterBy: this.state.filterBy,
                customDuration: {
                    from: "",
                    to: ""
                },
                page: this.state.page,
                limit: this.state.limit,
                filterList: filterList,
                sortHeader: this.state.sortHeader,
                sortDirection: this.state.sortDirection,
                isPagination: true
            }
            if(input === 'name') {
                requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
                requestBody.sortHeader = input
                this.setState({
                    sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
                    sortHeader: input
                }, () => this.storeFilter())
                this.props.getRefrancesList(this.props.userId, this.props.match.params.id, requestBody)

            }else if (input === 'referenceDetails.name') {
                requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
                requestBody.sortHeader = input
                this.setState({
                    sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
                    sortHeader: input
                }, () => this.storeFilter())
                this.props.getRefrancesList(this.props.userId, this.props.match.params.id, requestBody)
            }else if (input === 'createdAt'){
                requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
                requestBody.sortHeader = input
                this.setState({
                    sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
                    sortHeader: input
                }, () =>  this.storeFilter())
                this.props.getRefrancesList(this.props.userId, this.props.match.params.id, requestBody)
            }
        }

    }

    storeFilter = () => {
        let refrancefilterObj = {
            sortingName: this.state.sortHeader,
            sortingOrder: this.state.sortDirection,
            filterBy: this.state.filterBy,
            filters: {
                search: this.state.search,
            },
            pageNo: this.state.page,
            limit: this.state.limit
        }
        localStorage.setItem('refrancefilterObj', JSON.stringify(refrancefilterObj))
    }

    render() {
        let pageNumbers = []
        for (let i = 1; i <= this.props.totalPages; i++) {
            pageNumbers.push(i)
        }
        return (
            <div>
                <FormHeader page={Messages.REFRENCES} />
                <div className="admin-panel-page">
                    <div className="container-block small-container-block">
                        <div className="filter-panel d-flex justify-content-end">
                            <TextField
                                type='text'
                                parentClassName='form-group flex1'
                                labelClassName='default-font black-text'
                                className='form-input mr-10'
                                inputDivClassName='input-panel'
                                placeholder='Filter By Applicant Name'
                                onChange={this.onInputChange(InputIds.FILTER)}
                                value={this.state.search}
                            />
                            <CustomDropDown
                                parentClassName='form-group filter-dropdown'
                                inputDivClassName='input-panel'
                                onChange={this.onInputChange(InputIds.FILTER_TIME)}
                                list={appConfig.TIME_FILTER}
                            />
                        </div>
                        <div className="admin-table-card admin-users-card mt-30 references-table-card">
                            <div className="table-header">
                                <div className="table-row">
                                    <div className="table-data-row">
                                        <div className="cell cell-arrow"></div>
                                        <div className="cell cell-app-id">
                                            <div className="d-flex">
                                                <div>
                                                    <span className="cell-header-data font-700">Application ID</span>
                                                </div>
                                                <div className="sorting-panel" onClick={this.onSortingClick("createdAt")}></div>
                                            </div>
                                        </div>
                                        <div className="cell cell-applicant">
                                            <div className="d-flex">
                                                <div>
                                                    <span className="cell-header-data font-700">Applicant Name</span>
                                                </div>
                                                <div className="sorting-panel" onClick={this.onSortingClick("name")}></div>
                                            </div>
                                        </div>
                                        <div className="cell cell-reference-name">
                                            <div className="d-flex">
                                                <div>
                                                    <span className="cell-header-data font-700">Reference Name</span>
                                                </div>
                                                <div className="sorting-panel" onClick={this.onSortingClick("referenceDetails.name")}></div>
                                            </div>
                                        </div>
                                        <div className="cell cell-reference-email">
                                            <div>
                                                <span className="cell-header-data font-700">Reference Email</span>
                                            </div>
                                        </div>
                                        <div className="cell cell-reference-date">
                                            <div className="d-flex">
                                                <div>
                                                    <span className="cell-header-data font-700">Reference Date</span>
                                                </div>
                                                <div className="sorting-panel" onClick={this.onSortingClick("createdAt")}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-body">
                                {
                                    this.props.totalCount
                                        ?
                                        this.props.refrancesList.map((eachUser, index) => {
                                            let showCard = this.state.selected === eachUser._id
                                            return (
                                                <div className="table-row position-relative" key={index}>
                                                    <div className="table-data-row table-body-data-row">
                                                        <div className="cell cell-arrow">
                                                            <div className={"droparrow" + (showCard ? ' active' : '')} onClick={this.onUserClick(eachUser._id)}></div>
                                                        </div>
                                                        <div className="cell cell-app-id">
                                                            <div>
                                                                <span className="cell-body-data font-600">{eachUser.applicationId}</span>
                                                            </div>
                                                        </div>
                                                        <div className="cell cell-applicant">
                                                            <div>
                                                                <span className="cell-body-data font-600">{eachUser.firstName} {eachUser.lastName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="cell cell-reference-name">
                                                            <div>
                                                                <span className="cell-body-data font-600">{eachUser.referenceDetails.name ? eachUser.referenceDetails.name : '-'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="cell cell-reference-email">
                                                            <div>
                                                                <span className="cell-body-data font-600 meta-info">{eachUser.referenceDetails.email ? eachUser.referenceDetails.email : '-'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="cell cell-reference-date">
                                                            <div>
                                                                <span className="cell-body-data font-600">{moment.utc(eachUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showCard
                                                            ?
                                                            <div className="table-card">
                                                                <div className="table-card-item cell-app-id">
                                                                    <div>
                                                                        <span className="cell-header-data font-700">Application ID</span>
                                                                    </div>
                                                                    <div className="mt-10">
                                                                        <span className="cell-body-data font-600">{eachUser.applicationId}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="table-card-item cell-reference-date">
                                                                    <div>
                                                                        <span className="cell-header-data font-700">Reference Email</span>
                                                                    </div>
                                                                    <div className="mt-10">
                                                                        <span className="cell-body-data font-600">{eachUser.referenceDetails.email ? eachUser.referenceDetails.email : '-'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="table-card-item cell-reference-date">
                                                                    <div>
                                                                        <span className="cell-header-data font-700">Date</span>
                                                                    </div>
                                                                    <div className="mt-10">
                                                                        <span className="cell-body-data font-600">{moment.utc(eachUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            )
                                        })
                                        :

                                        <div className="no-data-panel center">
                                            <div><img src={ImageAssets.common.tableNoDataImg} alt="nodata"></img></div>
                                            <div className="mt-20"><span className="large-font font-600">No Data Found !</span></div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="center justify-content-end">
                            {
                                this.props.totalPages > 1
                                    ?
                                    <div className="mr-30">
                                        <div className="pagination-block">
                                            <div className="page-panel">
                                                {/* <div className="left-arrow" onClick={this.onInputChange(InputIds.PREVIOUS)}></div>
                                                {
                                                    pageNumbers.map((eachNumber, index) => {
                                                        return (
                                                            <div className={"number-block" + (this.state.page === (index + 1) ? ' active' : '')} key={index}>
                                                                <span className="page-number font-700" onClick={this.changePage(index + 1)}>
                                                                    {eachNumber}
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="right-arrow" onClick={this.onInputChange(InputIds.NEXT)}></div> */}

                                                <Pagination
                                                    activePage={this.state.page}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.props.totalCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.changePage.bind(this)}
                                                    hideFirstLastPages='false'
                                                    prevPageText=""
                                                    nextPageText=""
                                                    activeClass="active"
                                                    itemClass="number-block"
                                                    itemClassPrev='left-arrow'
                                                    itemClassNext='right-arrow'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.props.totalCount > appConfig.DEFAULT_PAGINATION_OPTIONS[0]
                                    ?
                                    <CustomDropDown
                                        parentClassName="page-dropdown ml-30 mt-5"
                                        onChange={this.onLimitChange}
                                        list={appConfig.DEFAULT_PAGINATION_OPTIONS}
                                        value={this.state.limit}
                                    />
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
                <DownloadExcel />
                <Footer />
            </div>
        )
    }

    componentWillUnmount() {
    this.storeFilter()
    }
}

AdminReferences.propTypes = {}

const mapStateToProps = (state) => ({
    userId: state.auth.userId,
    usersList: state.list.usersList,
    refrancesList: state.taxApplication.refrancesList.data,
    totalPages: state.taxApplication.refrancesList.totalPages,
    totalCount: state.taxApplication.refrancesList.totalCount

})

export default connect(mapStateToProps, { usersListAction, deleteUserAction, importUserAction, updateUserRole, getRefrancesList })(AdminReferences)
