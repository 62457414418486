import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import CustomDropDown from '../common/CustomDropDown'
import { Placeholders, Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import { appConfig } from '../../constants/AppConfig'

class AssignRolePopup extends Component {

  render() {
    let disabledButton = this.props.role
    return (     
        <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="assign-popup">
          <DialogTitle id="customized-dialog-title">
            <div className="upload-popup-heading">
              <span className="upload-popup-heading-text font-700">
                Change Role
              </span>
            </div>
            <div className="cancel-btn">
              <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <div className="upload-popup">
              <div className="form-field-row mb-30">
                <div className="form-field">
                  <CustomDropDown
                    parentClassName='form-group'
                    labelText='Select Role'
                    labelClassName='default-font black-text font-600'
                    inputDivClassName='input-panel'
                    onChange={this.props.onChange}
                    placeholder={Placeholders.SELECT}
                    list={Object.values(appConfig.ASSIGNMENT_ROLE)}
                    value={this.props.role}
                  />
                </div>
              </div>
              <Button
                type='submit'
                parentClassName='yes-btn center'
                className={'btn btn-primary btn-small' + (disabledButton ? '' : ' disabled')}
                text={Messages.SUBMIT}
                disabled={!disabledButton}
                onClick={this.props.onSubmitClick}
              />
            </div>
          </DialogContent>
        </Dialog>    
    )
  }
}

AssignRolePopup.propTypes = {}

export default AssignRolePopup
