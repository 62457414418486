import React, { Component } from 'react'
import ReactOTPInput from 'react-otp-input'
import Button from '../common/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ImageAssets from '../../utils/ImageAssets'
import { Messages } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'

class EmailVerification extends Component {

  render() {
    // console.log(this.props)
    // let disabledOTPButton = this.props.email.value && !this.props.email.error
    let disabledButton = this.props.otp && (this.props.otp.length === appConfig.OTP)

    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.showOTP} className="email-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="center">
            <img src={ImageAssets.popup.emailIcon} alt="email-icon"></img>
          </div>
         
              <div>
                <div className="form-group mt-30 text-center">
                  <label className="default-font black-text font-600">
                    The OTP has been sent to 
                    <span className="small-font light-green-text"> {this.props.email ? this.props.email : this.props.phone}</span>
                    {/* <span className="small-font light-green-text"> {this.props.phone}</span> */}
                  </label>
                  <ReactOTPInput
                    numInputs={6}
                    className='d-flex otp-input-panel justify-content-center'
                    inputStyle='form-input otp-input'
                    separator={<span>&nbsp;</span>}
                    isInputNum={true}
                    onChange={this.props.onOTPChange}
                    value={this.props.otp}
                  />
                </div>
                <div className="button-panel">
                  <Button
                    type='submit'
                    className='btn btn-primary btn-small'
                    text={Messages.CANCEL}
                    onClick={this.props.onCancelClick}
                  />
                  <Button
                    type='submit'
                    parentClassName='submit-btn'
                    className={'btn btn-primary btn-small' + (disabledButton ? '' : ' disabled')}
                    text={Messages.SUBMIT}
                    disabled={!disabledButton}
                    onClick={this.props.onOTPSubmit}
                  />
                </div>
                <div className="text-center mt-20">
                  <span className="small-font black-text">You didn't receive OTP ? <span className="small-font light-green-text link-text" onClick={this.props.onGetOTPClick}> Resend OTP</span></span>
                </div>
              </div>
              
        </DialogContent>
      </Dialog>
    )
  }
}

EmailVerification.propTypes = {}

export default EmailVerification
