import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactOTPInput from 'react-otp-input'
import validator from 'validator'
import { connect } from 'react-redux'
import { UIRoutes } from '../../constants/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Labels, Messages, Placeholders, InputIds } from '../../constants/StaticTexts'
import TextField from '../common/TextField'
import Button from '../common/Button'
import { ErrorMessages } from '../../constants/ErrorMessages'
// import { RegexPattern } from '../../constants/RegexPattern'
import { loginAction, removeLocalStorage } from '../../actions/authActions'
import { APIRoutes } from '../../constants/BackendRoutes'
import setLoading from '../../utils/LoadingManager'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../utils/GetErrorMessage'
import { appConfig } from '../../constants/AppConfig'

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      //isPasswordVisible: true,
      //isShowPassword: false,
      isShowOTP: false,
      emailOrPhone: { value: '', error: null },
      otp: '',
      // password: { value: '', error: null }
    }
  }

  componentDidMount() {
    removeLocalStorage()
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          emailOrPhone: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) || validator.isMobilePhone(event.target.value, 'en-CA') ? null : ErrorMessages.EMAIL_OR_PHONE_NOT_VALID
          }
        })
      } else {
        this.setState({
          emailOrPhone: {
            value: '',
            error: null
          }
        })
      }
    }
    // else if (inputId === InputIds.PASSWORD) {
    //   if (event.target.value) {
    //     this.setState({
    //       password: {
    //         value: event.target.value,
    //         error: event.target.value.match(RegexPattern.PASSWORD) ? null : ErrorMessages.PASSWORD_NOT_VALID
    //       }
    //     })
    //   } else {
    //     this.setState({
    //       password: {
    //         value: '',
    //         error: null
    //       }
    //     })
    //   }
    // }
  }

  onPasswordVisibleClick = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
  }

  checkEnterKeyEvent = (disabledButton) => (event) => {
    if (disabledButton && (event.key === InputIds.ENTER || event.keyCode === 13)) {
      this.onLoginClick()
    }
  }

  onOTPChange = (value) => {
    this.setState({ otp: value })
  }

  onError = (err) => {
    this.setState({ otp: '' })
  }
  onLoginClick = () => {
    let requestObject = {
      emailOrPhone: this.state.emailOrPhone.value,
      // password: this.state.password.value,
      // check: InputIds.LOGIN_PARAMS.EMAIL
    }
    // if (this.state.isShowPassword && this.state.password.value) {
    //   requestObject['password'] = this.state.password.value
    //   requestObject['check'] = InputIds.LOGIN_PARAMS.PASSWORD
    // }
    if (this.state.isShowOTP && this.state.otp && this.state.otp.length === 6) {
      requestObject['otp'] = this.state.otp
      // requestObject['check'] = InputIds.LOGIN_PARAMS.NEW_PASSWORD
    }
    if (this.state.isShowOTP && this.state.otp && this.state.otp.length === 6) {
      requestObject['otp'] = this.state.otp
      // requestObject['check'] = InputIds.LOGIN_PARAMS.TWO_STEP_AUTH
    }
    if (!this.state.isShowOTP) {
      this.onSendOTP(requestObject)
    } else {
      this.props.loginAction(requestObject, this.props.history, (err) => this.onError(err))
    }
  }

  onResendOTPClick = () => {
    let requestObject = {
      emailOrPhone: this.state.emailOrPhone.value,
      // password: this.state.password && this.state.password.value ? this.state.password.value : '',
      // check: InputIds.LOGIN_PARAMS.EMAIL
    }
    this.onSendOTP(requestObject, 'RESEND')
  }

  onSendOTP = (requestObject, param) => {
    setLoading(true)
    axios.post(APIRoutes.USER.LOGIN, requestObject)
      .then(res => {
        this.setState({ isShowOTP: true })
        if (param === "RESEND") toast.success("OTP sent successfully")
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  }

  render() {

    let disabledButton = this.state.emailOrPhone.value && !this.state.emailOrPhone.error
    if (disabledButton && this.state.isShowPassword) {
      disabledButton = this.state.password.value && !this.state.password.error
      if (disabledButton && this.state.isShowOTP) {
        disabledButton = this.state.otp && (this.state.otp.length === appConfig.OTP)
      }
    }

    return (

      <div className="login-signup-page">
        <div className="row no-gutters align-items-center">
          <div className="col-xl-5 col-md-5">
            <div className="center left-panel">
              <img src={ImageAssets.authimage.loginImage} alt="login" className="image-responsive" />
            </div>
          </div>
          <div className="col-xl-7 col-md-7">
            <div>
              <Link to={UIRoutes.HOME} className="default-font black-text back-to-home-text">Home</Link>
            </div>
            <div className="center right-panel">
              <div className="form-block">
                <div className="center site-logo">
                  <img src={ImageAssets.common.siteLogo} alt="site-logo"></img>
                </div>
                <div className="text-center mt-15">
                  <span className="large-font dark-green-text font-600">
                    {Messages.PLEASE_LOGIN_TO_ACCOUNT}
                  </span>
                </div>
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.REGISTERED_EMAIL_OR_MOBILE}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  placeholder={Placeholders.EMAIL}
                  onChange={this.onInputChange(InputIds.EMAIL)}
                  onKeyPress={this.checkEnterKeyEvent(disabledButton)}
                  {...this.state.emailOrPhone}
                />
                {
                  <>
                    <div className="form-group">
                      {
                        this.state.isShowOTP
                          ?
                          <>
                            <label className="default-font black-text font-600 text-center">
                              {Labels.OTP_SENT_TO}<span className="default-font light-green-text">{this.state.emailOrPhone.value}</span>
                            </label>
                            <ReactOTPInput
                              numInputs={6}
                              className='d-flex otp-input-panel'
                              inputStyle='form-input otp-input'
                              separator={<span>&nbsp;</span>}
                              isInputNum={true}
                              onChange={this.onOTPChange}
                              value={this.state.otp}
                            />
                          </>
                          :
                          null
                      }
                    </div>

                  </>
                }
                {/* {
                  this.state.isShowPassword && !this.state.isShowOTP
                    ?
                    <div className="center justify-content-end mt-10">

                      <div className="text-right">
                        <Link to={UIRoutes.FORGOT_PASSWORD} className="link-text default-font light-green-text font-600">
                          {Messages.FORGOT_PASSWORD}
                        </Link>
                      </div>
                    </div>
                    :
                    null
                } */}
                <Button
                  parentClassName='center mt-30'
                  type='submit'
                  className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
                  text={this.state.isShowOTP ? Messages.LOGIN : Messages.NEXT}
                  disabled={!disabledButton}
                  onClick={this.onLoginClick}
                />
                {
                  this.state.isShowOTP
                    ?
                    <div className="center text-center mt-15">
                      <span className="center default-font black-text font-600">
                        {Messages.DIDNT_RECEIVE_OTP}
                        <span onClick={this.onResendOTPClick} className="center default-font light-green-text font-600 link-text ml-5">
                          {Messages.RESEND_OTP}
                          <img src={ImageAssets.authimage.rightArrow} alt="right" className="ml-5" />
                        </span>
                      </span>
                    </div>
                    :
                    <div className="text-center mt-15">
                      <span className="default-font black-text font-600">
                        {Messages.NOT_ACCOUNT}
                        <Link to={{ pathname: "https://app.financebliss.ca" }} target="_self" /* to={UIRoutes.REGISTER}  */ className="link-text default-font light-green-text font-600"> {Messages.REGISTER}</Link>
                      </span>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

Login.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { loginAction })(Login)
