import React, { Component } from 'react'
import moment from 'moment'
import validator from 'validator'
import { toast } from 'react-toastify'
import Button from '../common/Button'
import UploadDocumentPopup from '../screen-popup/DocumentUploadPopup'
import UpdatePaymentStatus from '../screen-popup/UpdatePaymentStatus'
import { getDocumentThumbnail, getStatusClassNames, getPaymentStatusClassNames, mobileNumberFormat, downloadDocument, getKeyByValue, downloadDoc } from '../../utils/Utils'
import FormHeader from '../common/FormHeader'
// import TextField from '../common/TextField'
// import {Placeholders } from '../../constants/StaticTexts'
import Footer from '../common/Footer'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { connect } from 'react-redux'
import { getSingleApplication, updateApplicationStatus, assignToAccountant, updateApplicationSin, downloadZip } from '../../actions/applicationActions'
import { getDocumentTypes } from '../../actions/documentTypesActions'
import { updatePaymentAdminAction, getPlansListAction, assignPaymentPlan } from '../../actions/paymentActions'
import { getDocument } from '../../actions/commonActions'
import { appConfig } from '../../constants/AppConfig'
import { EditSin, InputIds, Labels, Messages, Placeholders } from '../../constants/StaticTexts'
import CustomDropDown from '../common/CustomDropDown'
import ImageAssets from '../../utils/ImageAssets'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { updateDocuments } from '../../actions/applicationActions'
import store from '../../store'
import { DELETE_DOCUMENT } from '../../constants/ActionTypes'
import TextField from '../common/TextField'
import ViewDocument from '../screen-popup/ViewDocument'
import { Accordion, Card } from 'react-bootstrap'
import AssignApplicationPopup from '../screen-popup/AssignApplicationPopup'
import setLoading from '../../utils/LoadingManager'

// packageName
// createdAt
// paymentType
// amount

class ApplicationView extends Component {

  constructor(props) {
    super(props)



    this.state = {
      status: '',
      paymentStatus: '',
      uploadDocumentsPopup: false,
      updatePaymentStatus: false,
      viewDocumentPopup: false,
      assignAppPopup: false,
      editPhoneNo: '',
      editEmail: '',
      phone: { value: '', error: null },
      phoneData: [{
        phone: { value: '', error: null },
      }],
      emailData: [{
        email: { value: '', error: null },
      }],
      viewDoc: {
        link: '',
        fileType: ''
      },
      document: {
        type: '',
        file: '',
        fileType: '',
        fileSize: '',
        fileName: ''
      },
      documents: [],
      uplaodDocuments: [],
      documentsDelete: [],
      isEdit: false,
      isSpouseSin: false,

      paymentMethod: {
        method: '',
        amount: { value: '', error: null },
        notes: ''
      },
      planAssign: {
        plan: '',
        amount: { value: '', error: null }
      },
      applicantSin: { value: this.props.singleApplication.sin ? this.props.singleApplication.sin : '', error: null },
      spouseSin: { value: this.props.singleApplication.spouseSIN ? this.props.singleApplication.spouseSIN : '', error: null },
      dependentSin_1: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 0 ? this.props.singleApplication.familyDetails.dependants[0].sin : '', error: null },
      dependentSin_2: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 1 ? this.props.singleApplication.familyDetails.dependants[1].sin : '', error: null },
      dependentSin_3: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 2 ? this.props.singleApplication.familyDetails.dependants[2].sin : '', error: null },
      dependentSin_4: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 3 ? this.props.singleApplication.familyDetails.dependants[3].sin : '', error: null },
      dependentSin_5: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 4 ? this.props.singleApplication.familyDetails.dependants[4].sin : '', error: null },
      dependentSin_6: { value: this.props.singleApplication.familyDetails && this.props.singleApplication.familyDetails.dependants.length > 5 ? this.props.singleApplication.familyDetails.dependants[5].sin : '', error: null },
      activeBlockIndex: '',
      selectedAccountantId: '',
      editSin: '',
      isSave: false,
      notes: this.props.singleApplication.notes ? this.props.singleApplication.notes : '',
      hasError: false
    }
  }

  componentDidMount() {

    let applicationId = this.props.match.params.id

    let requestObject = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortHeader: appConfig.DEFAULT_SORTING_FIELD,
      sortDirection: appConfig.DEFAULT_SORTING,
      isPagination: false
    }
    this.setState({
      notes: ''
    })



    if ([appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)) {
      let requestBody = {
        page: 1,
        limit: appConfig.PAGINATION_LIMIT,
        filterList: [],
        sortDirection: 'ASC',
        isPagination: false
      }
      setTimeout(() => {
        this.props.getDocumentTypes(this.props.userId, requestObject, applicationId, requestBody)
      }, 1000)
      this.props.getPlansListAction(requestBody, this.props.userId)
    } else {
      this.props.getDocumentTypes(this.props.userId, requestObject, applicationId)
    }

  }

  handleVerifyEmailClick = index => {
    this.setState({ showOTP: true, emailVerifyIndex: index, emailOrPhone: "email" });
    // this.props.verifyOtherEmailPhone(this.props.userId,)
    let requestObject = {
      emailOrPhone: this.state.emailData[index].email.value,
    }
    if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
      requestObject['otp'] = this.state.otp
      // requestObject['check'] = InputIds.LOGIN_PARAMS.NEW_PASSWORD
    }
    if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
      requestObject['otp'] = this.state.otp
      // requestObject['check'] = InputIds.LOGIN_PARAMS.TWO_STEP_AUTH
    }
    if (!this.state.showOTP) {
      // this.onSendOTP(requestObject)
      // this.props.verifyOtherEmailPhone(this.props.userId, this.props.userId, this.props.userId)
      this.props.addOtherEmailPhone(this.props.userId, this.props.userId, requestObject)
    } else {
      // this.props.loginAction(requestObject, this.props.history)
      // this.props.verifyOtherEmailPhone(this.props.userId, this.props.userId, this.props.userId, requestObject)
      this.props.addOtherEmailPhone(this.props.userId, this.props.userId, requestObject)
    }

    // this.state.otherEmailPhoneNumber.map((data, index)=>{

    //     // if(this.state.emailData[index].email.value === data.email){
    //         // this.props.verifyOtherEmailPhone(this.props.userId, this.props.userId, requestObject)
    //         console.log("===========>",data.email);
    //     // }
    // })
    // console.log("otherEmailPhoneNumber",this.props.otherEmailPhoneNumber);

  }

  onEditPhoneNoClick = (val) => (e) => {
    let updateObj = {}
    if (e) {
      if (val === appConfig.CONTACT_FOR.ADMIN) {
        updateObj['notes'] = this.props.singleApplication.notes
      }
      this.setState({ editPhoneNo: val, ...updateObj })
    }
  }


  onEditEmailClick = (val) => (e) => {
    let updateObj = {}
    if (e) {
      if (val === appConfig.EMAIL_FOR.ADMIN) {
        updateObj['notes'] = this.props.singleApplication.notes
      }
      this.setState({ editEmail: val, ...updateObj })
    }
  }

  addContact = () => {
    const values = [...this.state.phoneData];
    if (values.length > 1) {
      return;
    }
    values.push({
      phone: { value: '', error: null },
    })
    this.setState({
      phoneData: values
    })
  };

  onAddEmail = () => {
    const values = [...this.state.emailData];
    if (values.length > 2) {
      return;
    }
    values.push({
      email: { value: '', error: null },
    })
    this.setState({
      emailData: values
    })
  };

  RemoveContact = index => {
    const values = [...this.state.phoneData];
    if (values.length === 1) this.setState({ editPhoneNo: '' })
    // this.state.editPhoneNo = '';
    if (values.length > 1) values.pop();
    this.setState({
      phoneData: values
    })
  };

  onRemoveEmail = index => {
    const values = [...this.state.emailData];
    if (values.length === 1) this.setState({ editEmail: '' })
    // this.state.editEmail = '';
    if (values.length > 1) values.pop();
    this.setState({
      emailData: values
    })
  };

  onInputChangeField = (inputId, index) => () => (event) => {
    if (inputId === InputIds.PHONE) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)

        if (formatedNumber) {
          const newaArray = [...this.state.phoneData];
          let newdata = {
            phone: {
              value: formatedNumber,
              error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
            }
          }
          newaArray[index] = newdata;
          this.setState({
            phoneData: [...newaArray],
          })
        }
      } else {
        const newaArray = [...this.state.phoneData];
        let newdata = {
          phone: {
            value: '',
            error: ''
          }
        }
        newaArray[index] = newdata;
        this.setState({
          phoneData: [...newaArray],
        })
      }
    } else if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        const newaArray = [...this.state.emailData];
        let newdata = {
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
          }
        }
        newaArray[index] = newdata;
        this.setState({
          emailData: [...newaArray],
        })
      } else {
        const newaArray = [...this.state.emailData];
        let newdata = {
          email: {
            value: '',
            error: ''
          }
        }
        newaArray[index] = newdata;
        this.setState({
          emailData: [...newaArray],
        })
      }
    }
  }

  onEditClick = () => {
    this.props.history.push(UIRoutes.TAX_APPLICATION + '/' + this.props.match.params.id)
  }

  onPayNowClick = () => {
    if (this.props.singleApplication.isRecommended) {
      this.props.history.push({ pathname: UIRoutes.STRIPE_PAYMENT, isRecommended: this.props.singleApplication.isRecommended, planId: this.props.singleApplication.packageId, applicationId: this.props.match.params.id, amount: this.props.singleApplication.unpaidAmount })
    } else {
      this.props.history.push(UIRoutes.PAYMENT_PLANS + this.props.match.params.id)
    }
  }

  onBackClick = () => {
    this.props.history.push(UIRoutes.VIEW_ALL_APPLICATION)
  }

  onStatusChange = (inputId) => (status) => () => {
    if (inputId === InputIds.APPLICATION_STATUS) {
      this.setState({ status: status })
      this.props.updateApplicationStatus(this.props.userId, this.props.match.params.id, status)
    } else if (inputId === InputIds.PAYMENT_STATUS) {
      if ((this.state.paymentStatus !== status) && (status === Object.keys(appConfig.PAYMENT_STATUS)[1]) && (this.props.singleApplication.paymentStatus !== Object.keys(appConfig.PAYMENT_STATUS)[1])) {
        this.setState({ updatePaymentStatus: true })
      }
    } else if (inputId === InputIds.PAYMENT_PLAN) {
      this.setState({ planAssign: { ...this.state.planAssign, plan: status } })
    }
  }

  onOpenPopupClick = () => {
    this.setState({ uploadDocumentsPopup: true })
  }

  onFileTypeChange = (value) => () => {
    let allDocuments = this.state.uplaodDocuments
    if (allDocuments.length > 0 && !this.state.document.type) {
      allDocuments.map(eachObj => {
        eachObj.type = value
        return (eachObj)
      })
    }
    this.setState({ document: { ...this.state.document, type: value, uplaodDocuments: allDocuments } })
  }
  onFileForChange = (value) => () => {
    let allDocuments = this.state.uplaodDocuments
    if (allDocuments.length > 0 && !this.state.document.type) {
      allDocuments.map(eachObj => {
        eachObj.uploadedFor = value
        return (eachObj)
      })
    }
    this.setState({ document: { ...this.state.document, uploadedFor: value, uplaodDocuments: allDocuments } })
  }
  onFileLoad = (event) => {
    event.preventDefault()
    var target = event.target || event.srcElement
    if (target.value.length !== 0) {
      let files = target.files
      let fileArray = []
      if (this.props.role === appConfig.ROLE.USER) {
        let filesLength = this.state.ownDocuments.length + files.length + this.state.uplaodDocuments.length
        if (filesLength === appConfig.MAX_LENGTH.DOCUMENTS) {
          toast.error(ErrorMessages.MAXIMUM_FILE_LIMIT_REACHED)
        }
      }
      for (let index in files) {
        // let hasError = false
        let reader = new FileReader()
        let file = files[index]
        if (typeof (file) === "object") {
          reader.onloadend = () => {
            if (appConfig.ALLOWED_FILE_TYPES.ALL.includes(file.type)) {
              if (appConfig.ALLOWED_FILE_SIZE > file.size) {
                let fileObject = {
                  file: reader.result,
                  fileType: file.type,
                  fileSize: file.size,
                  fileName: file.name,
                  type: this.state.document.type,
                  uploadedFor: this.state.document.uploadedFor
                }
                fileArray.push(fileObject)
                setTimeout(() => {
                  setLoading(true)
                  if (!this.state.hasError && (Number(index) + 1 === files.length) && (fileArray.length === files.length)) {
                    this.setState({ uplaodDocuments: [...this.state.uplaodDocuments, ...fileArray] })
                  }
                  setLoading(false)
                }, 1000)
              } else {
                this.setState({
                  hasError: true
                })
                toast.error(ErrorMessages.FILE_SIZE_NOT_ALLOWED)
              }
            } else {
              this.setState({
                hasError: true
              })
              toast.error(ErrorMessages.FILE_TYPE_NOT_ALLOWED)
            }
          }
          reader.readAsDataURL(file)
        }
      }
    }
  }

  onSelectedFileDelete = (items, index) => (e) => {
    if (e) {
      let allDocuments = this.state.uplaodDocuments
      allDocuments.splice(index, 1)
      this.setState({ document: { ...this.state.document, file: '', fileType: '', fileSize: '', fileName: '' }, uplaodDocuments: allDocuments })
    }

  }
  onNotesChange = (inputId) => (event) => {
    if (inputId === InputIds.NOTES) {
      this.setState({ notes: event.target.value })
    }
  }

  onSubmitClick = () => {
    let uplaodedItems = []
    this.state.uplaodDocuments.map(eachItem => {
      const object = {
        uploadLink: eachItem.file,
        fileName: eachItem.fileName,
        fileSize: eachItem.fileSize,
        type: eachItem.fileType,
        documentTypeId: getKeyByValue(this.props.documentTypesList, this.state.document.type),
        uploadedFor: appConfig.DOCUMENTS_FOR.ADMIN
      }
      uplaodedItems.push(object)
      return uplaodedItems
    })

    this.setState({
      //documents: [...this.state.documents, fileObject],
      documents: [...this.state.documents, ...uplaodedItems],
      document: { type: '', file: '', fileType: '', fileSize: '', fileName: '' },
      uploadDocumentsPopup: false,
      //isEdit: true,
      isSave: true,
      uplaodDocuments: [],
      hasError: false
    })
  }

  onEditDocumentsClick = () => {
    this.setState({ isEdit: true })
  }

  onUploadedFileDelete = (fileObject) => () => {
    store.dispatch({
      type: DELETE_DOCUMENT,
      payload: fileObject.fileName
    })
    this.setState({ documentsDelete: [...this.state.documentsDelete, fileObject] }, () => this.onSaveDocuments())
  }

  onLocalFileDelete = (index) => () => {
    index -= this.props.singleApplication.documents.length
    let allDocuments = this.state.documents
    allDocuments.splice(index, 1)
    this.setState({ documents: allDocuments, isSave: allDocuments.length > 0 ? true : false })
  }

  onCancelClick = () => {
    this.setState({
      uploadDocumentsPopup: false,
      updatePaymentStatus: false,
      viewDocumentPopup: false,
      assignAppPopup: false,
      selectedAccountantId: '',
      paymentMethod: { method: '', amount: { value: '', error: null }, notes: '' },
      document: { type: '', file: '', fileType: '', fileSize: '', fileName: '' },
      uplaodDocuments: [],
      hasError: false
    })
  }

  onSaveDocuments = () => {
    let requestBody = {
      step: 5,
      email: this.props.singleApplication.email,
      documentsDelete: this.state.documentsDelete,
      documents: [...this.props.singleApplication.documents, ...this.state.documents],
      depositInformation: {
        transit: this.props.singleApplication.transitInformation,
        institution: this.props.singleApplication.institutionInformation,
        account: this.props.singleApplication.accountInformation
      },
      notes: this.props.singleApplication.notes
    }
    this.props.updateDocuments(requestBody, this.props.userId, this.props.singleApplication.applicationId)
    this.setState({ isEdit: false, documents: [], documentsDelete: [], isSave: false })
  }

  onMethodChange = (method) => () => {
    this.setState({ paymentMethod: { ...this.state.paymentMethod, method: method } })
  }

  onViewDocumentPopupOpen = (link, type, fileName) => () => {
    this.props.getDocument(link, type)
    if (type === 'application/msword') {
      downloadDoc(link, fileName)
    }
    this.setState({ viewDocumentPopup: true, viewDoc: { link: link, fileType: type } })
  }
  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.AMOUNT) {
      if (event.target.value) {
        let amount = event.target.value
        this.setState({
          paymentMethod: {
            ...this.state.paymentMethod,
            amount: {
              value: Number(amount) <= this.props.singleApplication.unpaidAmount ? event.target.value : this.state.paymentMethod.amount.value,
              error: validator.isNumeric(event.target.value) ? null : ErrorMessages.AMOUNT_NOT_VALID
            }
          }
        })
      } else {
        this.setState({
          paymentMethod: {
            ...this.state.paymentMethod,
            amount: { value: '', error: null }
          }
        })
      }
    } else if (inputId === InputIds.DESCRIPTION) {
      if (event.target.value) {
        this.setState({
          paymentMethod: {
            ...this.state.paymentMethod,
            notes: event.target.value
          }
        })
      } else {
        this.setState({
          paymentMethod: {
            ...this.state.paymentMethod,
            notes: ''
          }
        })
      }
    } else if (inputId === InputIds.PALN_AMOUNT) {
      if (event.target.value) {
        this.setState({
          planAssign: {
            ...this.state.planAssign,
            amount: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) ? null : ErrorMessages.AMOUNT_NOT_VALID
            }
          }
        })
      } else {
        this.setState({
          planAssign: {
            ...this.state.planAssign,
            amount: {
              value: '',
              error: null
            }
          }
        })
      }
    } else if (inputId === EditSin.applicantSin) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            applicantSin: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          applicantSin: {
            value: '',
            error: null
          }
        })
      }

    } else if (inputId === EditSin.spouseSin) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            spouseSin: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          spouseSin: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === EditSin.dependentSin_1) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_1: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_1: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === EditSin.dependentSin_2) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_2: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_2: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === EditSin.dependentSin_3) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_3: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_3: {
            value: '',
            error: null
          }
        })
      }
    }
    else if (inputId === EditSin.dependentSin_4) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_4: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_4: {
            value: '',
            error: null
          }
        })
      }
    }
    else if (inputId === EditSin.dependentSin_5) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_5: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_5: {
            value: '',
            error: null
          }
        })
      }
    }
    else if (inputId === EditSin.dependentSin_6) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            dependentSin_6: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          dependentSin_6: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onPaymentSubmit = () => {
    let requestBody = {
      currency: appConfig.DEFAULT_CURRENCY,
      amount: this.state.paymentMethod.amount.value,
      paymentType: this.state.paymentMethod.method,
      notes: this.state.paymentMethod.notes
    }
    this.props.updatePaymentAdminAction(this.props.userId, this.props.match.params.id, requestBody)
    this.setState({
      paymentMethod: {
        method: '',
        amount: { value: '', error: null },
        notes: ''
      },
      updatePaymentStatus: false
    })
  }

  onPlanAssignClick = () => {
    let planName = this.state.planAssign.plan
    let requestBody = { isReset: false }
    if (planName !== appConfig.CUSTOM) {
      this.props.plans.forEach((eachPlan) => {
        if (planName.includes(eachPlan.name.toUpperCase())) {
          requestBody['packageId'] = eachPlan._id
          requestBody['amount'] = eachPlan.currencies[0].amount || this.state.planAssign.amount.value
        }
      })
    }
    this.props.assignPaymentPlan(this.props.userId, this.props.match.params.id, requestBody)
    this.setState({ planAssign: { plan: '', amount: { value: '', error: null } } })
  }

  onBlockChange = (id) => () => {
    if (this.state.activeBlockIndex === id) {
      this.setState({ activeBlockIndex: '' })
    } else {
      this.setState({ activeBlockIndex: id })
    }
  }

  onAssignClick = () => {
    this.setState({ assignAppPopup: true })
  }

  onSelectedUserChange = (accountantId) => {
    this.setState({ selectedAccountantId: accountantId })
  }

  onAssignApplicationSubmitClick = () => {
    let requestBody = {
      userId: this.state.selectedAccountantId,
      status: true
    }
    this.props.assignToAccountant(this.props.userId, this.props.match.params.id, requestBody)
    this.setState({ selectedAccountantId: '', assignAppPopup: false })
  }

  onEditSinClick = (val) => (e) => {
    let updateObj = {}
    if (e) {
      if (val === appConfig.SIN_FOR.NOTES) {
        updateObj['notes'] = this.props.singleApplication.notes
      }
      this.setState({ editSin: val, ...updateObj })
    }
  }

  onSaveSin = (val) => (e) => {
    if (e) {
      let obj = {};
      switch (val) {
        case appConfig.SIN_FOR.APPLICANT:
          obj = {
            sin: this.state.applicantSin.value,
            sinFor: 'applicant'
          }
          break;
        case appConfig.SIN_FOR.SPOUSE:
          obj = {
            sin: this.state.spouseSin.value,
            sinFor: 'spouse'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_1:
          obj = {
            sin: this.state.dependentSin_1.value,
            sinFor: '1'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_2:
          obj = {
            sin: this.state.dependentSin_2.value,
            sinFor: '2'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_3:
          obj = {
            sin: this.state.dependentSin_3.value,
            sinFor: '3'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_4:
          obj = {
            sin: this.state.dependentSin_4.value,
            sinFor: '4'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_5:
          obj = {
            sin: this.state.dependentSin_5.value,
            sinFor: '5'
          }
          break;
        case appConfig.SIN_FOR.DEPENDENT_6:
          obj = {
            sin: this.state.dependentSin_6.value,
            sinFor: '6'
          }
          break;
        case appConfig.SIN_FOR.NOTES:
          obj = {
            sin: this.state.notes,
            sinFor: 'notes'
          }
          break;
        default:
          break;
      }
      this.props.updateApplicationSin(this.props.userId, this.props.match.params.id, obj)
    }
    this.setState({
      editSin: ''
    })
  }

  cnacelDocument = () => {
    this.setState({
      isEdit: false
    })
  }


  downloadZipDocument = () => {
    this.props.downloadZip(this.props.userId, this.props.match.params.id)
  }

  render() {

    let allDocuments = {
      ownDocuments: [],
      spouseDocuments: [],
      dependant1Documents: [],
      dependant2Documents: [],
      dependant3Documents: [],
      dependant4Documents: [],
      dependant5Documents: [],
      dependant6Documents: [],
      adminDocuments: []
    }
    this.props.singleApplication.documents.forEach((eachDocument) => {
      if (eachDocument.uploadLink !== '') {
        if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
          allDocuments.ownDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
          allDocuments.spouseDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
          allDocuments.dependant1Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
          allDocuments.dependant2Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
          allDocuments.dependant3Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
          allDocuments.dependant4Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
          allDocuments.dependant5Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
          allDocuments.dependant6Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.ADMIN) {
          allDocuments.adminDocuments.push(eachDocument)
        }
      }
    })
    // this.props.singleApplication.documents.forEach((eachDocument) => {
    //   if (this.props.role !== appConfig.ROLE.USER) {
    //     if (eachDocument.isDocumentOwner) {
    //       adminDocuments.push(eachDocument)
    //     } else {
    //       ownDocuments.push(eachDocument)
    //     }
    //   } else {
    //     if (eachDocument.isUserDocument) {
    //       ownDocuments.push(eachDocument)
    //     } else {
    //       adminDocuments.push(eachDocument)
    //     }
    //   }
    // })
    allDocuments.adminDocuments.push(...this.state.documents)
    let plans = {}
    this.props.plans.forEach((eachPlan) => {
      let key = eachPlan.name.toUpperCase() + ' - ' + appConfig.CURRENCY_SIGN[eachPlan.currencies[0].currency] + ' ' + eachPlan.currencies[0].amount
      plans[key] = eachPlan.isCustom
    })
    return (
      <div>
        <FormHeader />
        <div className="user-panel-page">
          <div className="container-block">
            <div className="userpanel-header">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center mb-20 mr-10">
                  <div className="back-icon mr-30" onClick={this.onBackClick}></div>
                  <div><span className="large-font black-text font-700 text-capitalize">{this.props.singleApplication.firstname + ' ' + this.props.singleApplication.lastname}</span></div>
                </div>
                <div className="d-flex flex-wrap">
                  <Button
                    type='submit'
                    className='btn btn-primary btn-small btn-edit mb-20 mr-20'
                    onClick={this.onEditClick}
                    text={Messages.EDIT}
                  />
                  {
                    this.props.role !== appConfig.ROLE.ADMIN
                      ?
                      <div className="d-flex justify-content-end">
                        {
                          (this.props.role === appConfig.ROLE.USER) && this.props.singleApplication.isPayEnable
                            ?
                            <Button
                              type='submit'
                              parentClassName='mb-20 mr-20'
                              className={'btn btn-primary btn-small btn-pay-now' + (!this.props.singleApplication.isPayEnable ? ' disabled' : '')}
                              text={Messages.PAY_NOW}
                              disabled={!this.props.singleApplication.isPayEnable}
                              onClick={this.onPayNowClick}
                            />
                            :
                            null
                        }
                      </div>
                      :
                      null
                  }
                  <Button
                    type='button'
                    className={'btn btn-primary btn-medium btn-pdf mb-5' + (this.props.role === appConfig.ROLE.USER ? '' : ' mr-20')}
                    onClick={downloadDocument(this.props.singleApplication.applicationLink, this.props.singleApplication.displayAppId + '.pdf')}
                    text={Messages.EXPORT_AS_PDF}
                  />
                  {
                    [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role) && this.props.singleApplication.applicationStatus !== appConfig.APPLICATION_STATUS.Assigned
                      ?
                      <Button
                        type='button'
                        parentClassName="mb-20"
                        className='btn btn-primary btn-medium btn-assign-accountant mb-5'
                        text={Messages.ASSIGN_ACCOUNTANT}
                        onClick={this.onAssignClick}
                      />
                      :
                      null
                  }
                </div>
              </div>
            </div>

            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userview-data-panel">
                  <div className="row no-gutters">
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-5 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="default-font light-gray-text font-600 title-text">{Labels.FORM_PREVIEW.APPLICATION_ID}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-md-7 col-6">
                            <div className="data-panel form-field status-dropdown">
                              <span className="default-font data-text black-text font-600">{this.props.singleApplication.displayAppId}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-5 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="default-font light-gray-text font-600 title-text">{Labels.APPLICATION_DATE}</span>
                            </div>
                          </div>
                          <div className="col-xl-87 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="default-font data-text black-text font-600">{moment.utc(this.props.singleApplication.createdAt).format(appConfig.DATE_FORMAT)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section applicant-info">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-45 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="default-font light-gray-text font-600 title-text">{Labels.APPLICATION_STATUS}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-md-7 col-6">
                            <div className="data-panel">
                              {
                                [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                                  ?
                                  <div className="form-field status-dropdown">
                                    <CustomDropDown
                                      parentClassName='form-group'
                                      inputDivClassName='input-panel '
                                      valueColorClassName={getStatusClassNames(this.props.singleApplication.applicationStatus || this.state.status)}
                                      onChange={this.onStatusChange(InputIds.APPLICATION_STATUS)}
                                      list={Object.keys(appConfig.APPLICATION_STATUS)}
                                      value={this.props.singleApplication.applicationStatus || this.state.status}
                                    />
                                  </div>
                                  :

                                  <div className={"application-status-panel user-application-status " + getStatusClassNames(this.props.singleApplication.applicationStatus)}>
                                    <span className="application-status font-700">{this.props.singleApplication.applicationStatus}</span>
                                  </div>

                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.props.role === appConfig.ROLE.USER
                      ?
                      <div className="row no-gutters">
                        <div className="col-md-4">
                          <div className="field-row userpanel-payment-section">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="default-font light-gray-text font-600 title-text">{Labels.PAID_AMOUNT}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel form-field status-dropdown">
                                  <span className="default-font black-text font-600">$ {this.props.singleApplication.paidAmount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field-row userpanel-payment-section">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="default-font light-gray-text font-600 title-text">{Labels.UNPAID_AMOUNT}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel">
                                  <span className="default-font black-text font-600">$ {this.props.singleApplication.unpaidAmount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="field-row userpanel-payment-section applicant-info">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="default-font light-gray-text font-600 title-text">{Labels.PAYMENT_STATUS}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel">
                                  <div className={"color-label " + getPaymentStatusClassNames(this.props.singleApplication.paymentStatus)}>
                                    <span className="label-text small-font font-700">{this.props.singleApplication.paymentStatus}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
            </div>
            <div className="userpanel-header d-none">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center mb-20 mr-10">
                  <div className="back-icon mr-30" onClick={this.onBackClick}></div>
                  <div><span className="large-font black-text font-700 text-capitalize">{this.props.singleApplication.firstname + ' ' + this.props.singleApplication.lastname}</span></div>
                </div>
                <div className="d-flex flex-wrap">
                  <Button
                    type='button'
                    className='btn btn-primary btn-medium btn-pdf mr-20 mb-5'
                    onClick={downloadDocument(this.props.singleApplication.applicationLink, this.props.singleApplication.displayAppId + '.pdf')}
                    text={Messages.EXPORT_AS_PDF}
                  />
                  {
                    [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role) && this.props.singleApplication.applicationStatus !== appConfig.APPLICATION_STATUS.Assigned
                      ?
                      <Button
                        type='button'
                        parentClassName="mb-20"
                        className='btn btn-primary btn-medium btn-assign-accountant mb-5'
                        text={Messages.ASSIGN_ACCOUNTANT}
                        onClick={this.onAssignClick}
                      />
                      :
                      null
                  }
                </div>
              </div>
              {/* {
                (this.props.role === appConfig.ROLE.OWNER_ADMIN) && (!this.props.singleApplication.isPayEnable) && (this.props.singleApplication.paymentStatus !== Object.keys(appConfig.PAYMENT_STATUS)[1])
                  ?
                  <div className="information-message">
                    <span>Please assign fees again as user changed their marital status or CRA status.</span>
                  </div>
                  :
                  null
              }
              {
                (this.props.role === appConfig.ROLE.USER) && (this.props.singleApplication.isRecommended)
                  ?
                  <div className="information-message">
                    <span>Admin has assigned you fees. Please check unpaid amount </span>
                  </div>
                  :
                  null
              } */}
              <div className="row no-gutters">

                <div className="col-md-4">
                  <div className="applicant-info">
                    <div className="row no-gutters align-items-center h-100">
                      <div className="col-xl-4 col-6">
                        <div className="left-panel">
                          <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.APPLICATION_ID}</span>
                        </div>
                      </div>
                      <div className="col-xl-8 col-6">
                        <div className="right-panel">
                          <span className="default-font data-text black-text font-600">{this.props.singleApplication.displayAppId}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                      <div className="applicant-info">
                        <div className="row no-gutters align-items-center h-100">
                          <div className="col-xl-4 col-6">
                            <div className="left-panel">
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.NAME}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{this.props.singleApplication.firstname + ' ' + this.props.singleApplication.lastname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                <div className="col-md-4">
                  <div className="applicant-info">
                    <div className="row no-gutters align-items-center h-100">
                      <div className="col-xl-4 col-6">
                        <div className="left-panel">
                          <span className="default-font light-gray-text font-600">{Labels.APPLICATION_DATE}</span>
                        </div>
                      </div>
                      <div className="col-xl-8 col-6">
                        <div className="right-panel">
                          <span className="default-font data-text black-text font-600">{moment.utc(this.props.singleApplication.createdAt).format(appConfig.DATE_FORMAT)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="applicant-info">
                    <div className="row no-gutters align-items-center h-100">
                      <div className="col-xl-4 col-6">
                        <div className="left-panel">
                          <span className="default-font light-gray-text font-600">{Labels.APPLICATION_STATUS}</span>
                        </div>
                      </div>
                      <div className="col-xl-8 col-6">
                        {
                          [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                            ?
                            <div className="form-field status-dropdown">
                              <CustomDropDown
                                parentClassName='form-group'
                                inputDivClassName='input-panel '
                                valueColorClassName={getStatusClassNames(this.state.status || this.props.singleApplication.applicationStatus)}
                                onChange={this.onStatusChange(InputIds.APPLICATION_STATUS)}
                                list={Object.keys(appConfig.APPLICATION_STATUS)}
                                value={this.state.status || this.props.singleApplication.applicationStatus}
                              />
                            </div>
                            :
                            <div className="right-panel">
                              <div className={"application-status-panel user-application-status " + getStatusClassNames(this.props.singleApplication.applicationStatus)}>
                                <span className="application-status font-700">{this.props.singleApplication.applicationStatus}</span>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.role === appConfig.ROLE.USER
                  ?
                  <>
                    <div className="col-md-6">
                      <div className="applicant-info">
                        <div className="row no-gutters align-items-center h-100">
                          <div className="col-xl-5 col-6">
                            <div className="left-panel">
                              <span className="default-font light-gray-text font-600">{Labels.PAYMENT_STATUS}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="right-panel">
                              <div className={"application-status-panel user-application-status " + getStatusClassNames(this.props.singleApplication.paymentStatus)}>
                                <span className="application-status font-700">{this.props.singleApplication.paymentStatus}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="applicant-info">
                        <div className="row no-gutters align-items-center h-100">
                          <div className="col-xl-5 col-6">
                            <div className="left-panel">
                              <span className="default-font light-gray-text font-600">{Labels.PAID_AMOUNT}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="right-panel">
                              <div className="default-font black-text font-600">
                                <span className="application-status font-700">$ {this.props.singleApplication.paidAmount}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="applicant-info">
                        <div className="row no-gutters align-items-center h-100">
                          <div className="col-xl-5 col-6">
                            <div className="left-panel">
                              <span className="default-font light-gray-text font-600">{Labels.UNPAID_AMOUNT}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="right-panel">
                              <div className="default-font black-text font-600">
                                <span className="application-status font-700">{this.props.singleApplication.unpaidAmount}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : null}
              </div>
            </div>
            {
              [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                ?
                <div className="userpanel-view-section">
                  <div className="userpanel-block">
                    <div className="userpanel-heading payment">
                      <span className="userpanel-heading-text font-700">
                        {Messages.PAYMENT}
                      </span>
                    </div>
                    <div className="userview-data-panel">
                      <div className="row no-gutters">
                        <div className="col-lg-4">
                          <div className="field-row userpanel-payment-section">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.PAYMENT_STATUS}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel form-field status-dropdown">
                                  <CustomDropDown
                                    parentClassName='form-group w-100'
                                    inputDivClassName='input-panel'
                                    valueColorClassName={'color-label ' + getPaymentStatusClassNames(this.state.paymentStatus || this.props.singleApplication.paymentStatus)}
                                    onChange={this.onStatusChange(InputIds.PAYMENT_STATUS)}
                                    list={Object.keys(appConfig.PAYMENT_STATUS)}
                                    value={this.state.paymentStatus || this.props.singleApplication.paymentStatus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="field-row userpanel-payment-section">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.PAID_AMOUNT}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600">$ {this.props.singleApplication.paidAmount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="field-row userpanel-payment-section">
                            <div className="row no-gutters align-items-center">
                              <div className="col-xl-5 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.UNPAID_AMOUNT}</span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-md-7 col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600">$ {this.props.singleApplication.unpaidAmount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-lg-4">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.PAYMENT_PLAN}</span>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="d-flex align-items-center flex-wrap payment-plan-block">
                              <div className="form-field ml-20">
                                <CustomDropDown
                                  parentClassName='form-group payment-plan-dropdown'
                                  inputDivClassName='input-panel '
                                  onChange={this.onStatusChange(InputIds.PAYMENT_PLAN)}
                                  list={Object.keys(plans)}
                                  placeholder={Placeholders.SELECT}
                                  value={this.state.planAssign.plan}
                                />
                              </div>
                              {
                                this.state.planAssign.plan && plans[this.state.planAssign.plan]
                                  ?
                                  <TextField
                                    type='number'
                                    parentClassName='form-group ml-20 payment-plan-input'
                                    // labelText={Labels.AMOUNT}
                                    labelClassName='default-font black-text'
                                    inputDivClassName='input-panel'
                                    className='form-input'
                                    placeholder={Placeholders.ENTER_AMOUNT}
                                    onChange={this.onInputChange(InputIds.PALN_AMOUNT)}
                                    {...this.state.planAssign.amount}
                                  />
                                  :
                                  null
                              }
                              <div className="save-image-icon ml-15 mr-15">
                                {
                                  this.state.planAssign.plan
                                    ?
                                    (plans[this.state.planAssign.plan]) && (this.state.planAssign.amount.value && !this.state.planAssign.amount.error)
                                      ?
                                      <img src={ImageAssets.formsvg.saveDocumentsIcon} onClick={this.onPlanAssignClick} alt="save-icon" />
                                      :
                                      !plans[this.state.planAssign.plan]
                                        ?
                                        <img src={ImageAssets.formsvg.saveDocumentsIcon} onClick={this.onPlanAssignClick} alt="save-icon" />
                                        :
                                        null
                                    :
                                    null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
            {/* payment history section */}
            {
              [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.USER].includes(this.props.role) && (this.props.singleApplication.paymentTransactions.length)
                ?
                <div className="userpanel-view-section">
                  <div className="userpanel-block">
                    <div className="userpanel-heading payment-history">
                      <span className="userpanel-heading-text font-700">
                        {Messages.PAYMENT_HISTORY}
                      </span>
                    </div>
                    <div className="userview-data-panel payment-history-panel">
                      {
                        this.props.singleApplication.paymentTransactions.map((eachPayment, index) => {
                          return (
                            <div className="payment-history-block" key={index}>
                              <div className="row no-gutters">
                                <div className="col-xl-3 col-md-6 col-12">
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-6">
                                        <div className="title-panel">
                                          <span className="title-text font-600">Package Name</span>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="data-panel">
                                          <span className="data-text font-600 text-capitalize">{eachPayment.packageName || appConfig.NO_VALUE}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-12">
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-6">
                                        <div className="title-panel">
                                          <span className="title-text font-600">Date</span>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="data-panel">
                                          <span className="data-text font-600">{moment.utc(eachPayment.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-12">
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-6">
                                        <div className="title-panel">
                                          <span className="title-text font-600">Payment Mode</span>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="data-panel">
                                          <span className="data-text font-600">{eachPayment.paymentType}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-12">
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-6">
                                        <div className="title-panel">
                                          <span className="title-text font-600">Amount</span>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="data-panel">
                                          <span className="data-text font-600">{appConfig.CURRENCY_SIGN[eachPayment.currency] + ' ' + eachPayment.amount}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                :
                null
            }
            {/* about section */}
            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading about">
                  <span className="userpanel-heading-text font-700">
                    {Messages.ABOUT_YOU}
                  </span>
                </div>
                <div className="userview-data-panel">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.FILLED_RETURN}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600 text-capitalize">{this.props.singleApplication.hasReturnBefore}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.FILING_RETURN_FOR_FIRST_TIME}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.filingReturnFirstTime}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {
                        this.props.singleApplication.filingReturnFirstTime === appConfig.CRA_ACCOUNT_OPTIONS[0]
                          ?
                          <div className="field-row">
                            <div className="row no-gutters">
                              <div className="col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.FORM_PREVIEW.DATE_OF_LANDING}</span>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600">{moment(this.props.singleApplication.dateOfLanding).format(appConfig.DATE_FORMAT)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="field-row">
                            <div className="row no-gutters">
                              <div className="col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.FORM_PREVIEW.TAX_YEARS}</span>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600">{this.props.singleApplication.taxYears}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="userview-data-panel">
                  <div className="row no-gutters">
                    <div className="col-md-6 col-12">
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.FIRST_NAME}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.firstname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.LAST_NAME}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.lastname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                          ?
                          <div className="field-row userpanel-about-section">
                            <div className="row no-gutters">
                              <div className="col-xl-4 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.FORM_PREVIEW.SIN}</span>
                                </div>
                              </div>
                              <div className="col-xl-8 col-md-7 col-6">
                                <div className="data-panel sin-panel justify-content-between">
                                  {this.state.editSin === appConfig.SIN_FOR.APPLICANT
                                    ?
                                    <TextField
                                      type='text'
                                      parentClassName='form-group flex1 mr-10'
                                      labelClassName='default-font black-text'
                                      className='form-input'
                                      inputDivClassName='input-panel'
                                      placeholder={Placeholders.SIN}
                                      onChange={this.onInputChange(EditSin.applicantSin)}
                                      {...this.state.applicantSin}
                                      value={this.state.applicantSin.value || this.props.singleApplication.sin}
                                    />
                                    :
                                    <div>
                                      <span className="data-text font-600">{this.props.singleApplication.sin || appConfig.NO_VALUE}</span>
                                    </div>
                                  }
                                  <div className="d-flex justify-content-end icon-block">
                                    {this.state.editSin === appConfig.SIN_FOR.APPLICANT
                                      ?
                                      <div className="save-image-icon icon-panel ml-10">
                                        <div className={this.state.applicantSin.error ? "icon disabled" : ""}>
                                          <img src={ImageAssets.formsvg.saveDocumentsIcon} alt="save-icon" onClick={this.onSaveSin(appConfig.SIN_FOR.APPLICANT)} />
                                        </div>
                                        <div className="btn-tooltip">
                                          <span className="btn-tooltip-text">{Labels.SAVE}</span>
                                        </div>
                                      </div>
                                      :
                                      <div className="edit-image-icon icon-panel">

                                        <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditSinClick(appConfig.SIN_FOR.APPLICANT)} />

                                        <div className="btn-tooltip">
                                          <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          null
                      }
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.DATE_OF_BIRTH}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{moment(this.props.singleApplication.dateOfBirth).format(appConfig.DATE_FORMAT)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel h-100">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.CONTACT_NUMBER}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            {/* {this.state.phoneData.map((data, i) => { */}
                            {/* // return ( */}
                            <div className="data-panel justify-content-between">
                              {/* {this.state.editPhoneNo === appConfig.CONTACT_FOR.ADMIN
                                    ?
                                    <div className="phonenumber-panel" id="profile">
                                      <div className="number-prefix">+1</div>
                                      <TextField
                                        type='text'
                                        parentClassName='form-group flex1 mr-10'
                                        labelClassName='default-font black-text'
                                        className='form-input'
                                        inputDivClassName='input-panel'
                                        placeholder={Placeholders.MOBILE_NUMBER}
                                        onChange={this.onInputChangeField(InputIds.PHONE, i)}
                                        {...this.state.phoneData[i]}
                                        value={this.state.phoneData[i].phone.value}
                                      />
                                    </div>
                                    :
                                    <div><span className="data-text font-600">{appConfig.MOBILE_CODE + ' ' + mobileNumberFormat(this.props.singleApplication.phone)}</span></div>
                                  } */}
                              <div><span className="data-text font-600">{appConfig.MOBILE_CODE + ' ' + mobileNumberFormat(this.props.singleApplication.phone)}</span></div>

                              {/* <div className="d-flex justify-content-end icon-block">
                                    {this.state.editPhoneNo === appConfig.CONTACT_FOR.ADMIN
                                      ?
                                      <div className="d-flex">
                                        <div className="save-image-icon icon-panel ml-10">
                                          <div className={this.state.phone.error ? "icon disabled" : ""}>
                                            <img src={ImageAssets.formsvg.removeDocumentsIcon} alt="remove-icon" onClick={this.RemoveContact} />
                                          </div>
                                          <div className="btn-tooltip">
                                            <span className="btn-tooltip-text">{Labels.REMOVE}</span>
                                          </div>
                                        </div>
                                        {(this.state.phoneData.length === i + 1 && this.state.phoneData.length !== 2) ? <div className="save-image-icon icon-panel ml-10">
                                          <div className={this.state.phone.error ? "icon disabled" : ""}>
                                            <img src={ImageAssets.formsvg.addDocumentsIcon} alt="add-icon" onClick={this.addContact} />
                                          </div>
                                          <div className="btn-tooltip">
                                            <span className="btn-tooltip-text">{Labels.ADD}</span>
                                          </div>
                                        </div> : null}
                                        <div className="save-image-icon icon-panel ml-10">
                                          <Button
                                            type='submit'
                                            className={'btn btn-primary btn-xsmall'}
                                            text='Verify'
                                            disabled={this.state.isChecked ? false : true}
                                            onClick={this.handleButtonClick}
                                          />
                                        </div>
                                      </div>
                                      :
                                      <div className="d-flex justify-content-end icon-block">
                                        <div className="edit-image-icon icon-panel">
                                          <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditPhoneNoClick(appConfig.CONTACT_FOR.ADMIN)} />
                                          <div className="btn-tooltip">
                                            <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </div> */}
                            </div>
                            {/* // ) */}
                            {/* }) */}
                            {/* } */}
                            {/* {this.state.editPhoneNo === appConfig.CONTACT_FOR.ADMIN
                              ?
                              <div className='save-contact-panel text-right'>
                                <div className="save-image-icon icon-panel">
                                  <div>
                                    <Button
                                      type='submit'
                                      className={'btn btn-primary btn-xsmall'}
                                      text='Save'
                                      disabled={this.state.isChecked ? false : true}
                                      onClick={this.handleButtonClick}
                                    />
                                  </div>
                                </div>
                              </div>
                              :
                              ''
                            } */}
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel h-100">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.EMAIL}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            {/* {this.state.emailData.map((data, i) => { */}
                            {/* return ( */}
                            <div className="data-panel justify-content-between">
                              {/* {this.state.editEmail === appConfig.EMAIL_FOR.ADMIN
                                    ?
                                    <div>
                                      <TextField
                                        type='text'
                                        parentClassName='form-group flex1 mr-10'
                                        labelClassName='default-font black-text'
                                        className='form-input'
                                        inputDivClassName='input-panel'
                                        placeholder={Placeholders.CONTACT_US.EMAIL_ADDRESS}
                                        onChange={this.onInputChangeField(InputIds.EMAIL, i)}
                                        {...this.state.emailData[i]}
                                        value={this.state.emailData[i].email.value}
                                      />
                                    </div>
                                    :
                                    <div><span className="data-text font-600 meta-info">{this.props.singleApplication.email}</span></div>
                                  } */}
                              <div><span className="data-text font-600 meta-info">{this.props.singleApplication.email}</span></div>
                              {/* <div className="d-flex justify-content-end icon-block">
                                    {this.state.editEmail === appConfig.EMAIL_FOR.ADMIN
                                      ?
                                      <div className="d-flex">
                                        <div className="save-image-icon icon-panel ml-10">
                                          <div>
                                            <img src={ImageAssets.formsvg.removeDocumentsIcon} alt="remove-icon" onClick={this.onRemoveEmail} />
                                          </div>
                                          <div className="btn-tooltip">
                                            <span className="btn-tooltip-text">{Labels.REMOVE}</span>
                                          </div>
                                        </div>
                                        {(this.state.emailData.length === i + 1 && this.state.emailData.length !== 3) ?
                                          <div className="save-image-icon icon-panel ml-10">
                                            <div>
                                              <img src={ImageAssets.formsvg.addDocumentsIcon} alt="add-icon" onClick={this.onAddEmail} />
                                            </div>
                                            <div className="btn-tooltip">
                                              <span className="btn-tooltip-text">{Labels.ADD}</span>
                                            </div>
                                          </div>
                                          :
                                          null
                                        }
                                        <div className="save-image-icon icon-panel ml-10">
                                          <Button
                                            type='submit'
                                            className={'btn btn-primary btn-xsmall'}
                                            text='Verify'
                                            disabled={this.state.isChecked ? false : true}
                                            onClick={() => this.handleVerifyEmailClick(i)}
                                          />
                                        </div>
                                      </div>
                                      :
                                      <div className="d-flex justify-content-end icon-block">
                                        <div className="edit-image-icon icon-panel">
                                          <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditEmailClick(appConfig.EMAIL_FOR.ADMIN)} />
                                          <div className="btn-tooltip">
                                            <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </div> */}
                            </div>
                            {/* // ) */}
                            {/* // }) */}
                            {/* // } */}
                            {/* {this.state.editEmail === appConfig.EMAIL_FOR.ADMIN
                              ?
                              <div className='save-contact-panel text-right'>
                                <div className="save-image-icon icon-panel">
                                  <div>
                                    <Button
                                      type='submit'
                                      className={'btn btn-primary btn-xsmall'}
                                      text='Save'
                                      disabled={this.state.isChecked ? false : true}
                                      onClick={this.handleButtonClick}
                                    />
                                  </div>
                                </div>
                              </div>
                              :
                              ''
                            } */}
                          </div>
                        </div>
                      </div>
                      {
                        [appConfig.ROLE.USER].includes(this.props.role)
                          ?
                          <div className="field-row userpanel-about-section user-blank-section">
                            <div className="row no-gutters">
                              <div className="col-xl-4 col-md-5 col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600"></span>
                                </div>
                              </div>
                              <div className="col-xl-8 col-md-7 col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          null
                      }
                    </div>
                    <div className="col-md-6">
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.UNIT}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.unit || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.STREET_NUMBER}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.streetNumber || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.STREET_NAME}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.streetName}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.CITY}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.city}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.PROVINCE}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.province}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row userpanel-about-section">
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-md-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.POSTAL_CODE}</span>
                            </div>
                          </div>
                          <div className="col-xl-8 col-md-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.postalCode}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* family-section */}
            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading family">
                  <span className="userpanel-heading-text font-700">
                    {Messages.YOUR_FAMILY}
                  </span>
                </div>
                <div className="userview-data-panel">
                  <div className="icon-panel family">
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-6 col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-lg-7 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.MARITAL_STATUS_ON}</span>
                            </div>
                          </div>
                          <div className="col-lg-5 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.maritalStatus}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-lg-7 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.HAVE_DEPENDENTS}</span>
                            </div>
                          </div>
                          <div className="col-lg-5 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.hasDependants}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.props.singleApplication.maritalStatus === appConfig.MARITAL_STATUS[0]
                      ?
                      <>
                        <div className="row no-gutters">
                          <div className="col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.DATE_OF_MARRIAGE}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600">{moment(this.props.singleApplication.dateOfMarriage).format(appConfig.DATE_FORMAT)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_FIRST_NAME}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600">{this.props.singleApplication.spouseFirstname}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_LAST_NAME}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600 text-capitalize">{this.props.singleApplication.spouseLastname}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_FILLED_RETURN}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600 text-capitalize">{this.props.singleApplication.spouseHasFilledReturnBefore}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_FILING_RETURN_FOR_FIRST_TIME}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600">{this.props.singleApplication.spouseFilingReturnFirstTime}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 col-12">
                            {
                              this.props.singleApplication.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0]
                                ?
                                <div className="field-row">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600">{Labels.FORM_PREVIEW.DATE_OF_LANDING}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel">
                                        <span className="data-text font-600">{moment(this.props.singleApplication.spouseDateOfLanding).format(appConfig.DATE_FORMAT)}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="field-row">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600">{Labels.FORM_PREVIEW.TAX_YEARS}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel">
                                        <span className="data-text font-600">{this.props.singleApplication.spouseTaxYear}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            }
                          </div>
                          {
                            [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                              ?
                              <div className="col-md-6 col-12">
                                <div className="field-row">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_SIN}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel sin-panel justify-content-between">
                                        {this.state.editSin === appConfig.SIN_FOR.SPOUSE
                                          ?
                                          <TextField
                                            type='text'
                                            parentClassName='form-group flex1 mr-10'
                                            labelClassName='default-font black-text'
                                            className='form-input'
                                            inputDivClassName='input-panel'
                                            placeholder={Placeholders.SIN}
                                            onChange={this.onInputChange(EditSin.spouseSin)}
                                            {...this.state.spouseSin}
                                            value={this.state.spouseSin.value || this.props.singleApplication.spouseSIN}
                                          />
                                          :
                                          <div>
                                            <span className="data-text font-600">{this.props.singleApplication.spouseSIN || appConfig.NO_VALUE}</span>
                                          </div>
                                        }
                                        {this.state.editSin === appConfig.SIN_FOR.SPOUSE
                                          ?
                                          <div className="save-image-icon icon-panel ml-10">
                                            <div className={this.state.spouseSin.error ? "icon disabled" : ""}>
                                              <img src={ImageAssets.formsvg.saveDocumentsIcon} alt="save-icon" onClick={this.onSaveSin(appConfig.SIN_FOR.SPOUSE)} />
                                            </div>
                                            <div className="btn-tooltip">
                                              <span className="btn-tooltip-text">{Labels.SAVE}</span>
                                            </div>
                                          </div>
                                          :
                                          <div className="edit-image-icon icon-panel">
                                            <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditSinClick(appConfig.SIN_FOR.SPOUSE)} />
                                            <div className="btn-tooltip">
                                              <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                          <div className="col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_DATE_OF_BIRTH}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600">{moment(this.props.singleApplication.spouseDateOfBirth).format(appConfig.DATE_FORMAT)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="field-row">
                              <div className="row no-gutters">
                                <div className="col-lg-7 col-6">
                                  <div className="title-panel">
                                    <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_EMAIL_ADDRESS}</span>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-6">
                                  <div className="data-panel">
                                    <span className="data-text font-600 meta-info">{this.props.singleApplication.spouseEmail}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            [appConfig.ROLE.USER].includes(this.props.role)
                              ?
                              <div className="col-md-6 col-12">
                                <div className="field-row">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_CONTACT_NUMBER}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel">
                                        <span className="data-text font-600">{appConfig.MOBILE_CODE + ' ' + this.props.singleApplication.spouseContactNumber}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                              ?
                              <div className="col-md-6 col-12">
                                <div className="field-row">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600">{Labels.FORM_PREVIEW.SPOUSE_CONTACT_NUMBER}</span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel">
                                        <span className="data-text font-600">{appConfig.MOBILE_CODE + ' ' + this.props.singleApplication.spouseContactNumber}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            [appConfig.ROLE.USER].includes(this.props.role)
                              ?
                              <div className="col-md-6 col-12">
                                <div className="field-row user-blank-section">
                                  <div className="row no-gutters">
                                    <div className="col-lg-7 col-6">
                                      <div className="title-panel">
                                        <span className="title-text font-600"></span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className="data-panel">
                                        <span className="data-text font-600"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                        </div>
                      </>
                      :
                      null
                  }

                </div>
              </div>
              {
                this.props.singleApplication.hasDependants === appConfig.HAS_DEPENDENTS[0]
                  ?
                  <div className="userpanel-block mt-30">
                    <div className="userpanel-heading pl-0">
                      <span className="userpanel-heading-text userpanel-subheading-text font-700">
                        {Messages.DEPENDANT_DETAILS}
                      </span>
                    </div>
                    <div className="userview-data-panel dependants-detail-panel">
                      {
                        this.props.singleApplication.dependants.map((eachDependant, index) => {
                          return (
                            <div className="dependent-detail-row d-flex" key={index}>
                              <div className="number-block">
                                <span className="number">{index + 1}</span>
                              </div>
                              <div className="row no-gutters w-100">
                                <div className={"col-12 " + (this.props.role !== appConfig.ROLE.OWNER_ADMIN && this.props.role !== appConfig.ROLE.ADMIN ? 'col-md-6' : 'col-lg-4')}>
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-4">
                                        <div className="title-panel">
                                          <span className="title-text font-600">{Labels.FORM_PREVIEW.ONLY_NAME}</span>
                                        </div>
                                      </div>
                                      <div className="col-8">
                                        <div className="data-panel">
                                          <span className="data-text font-600">{eachDependant.firstName + ' ' + eachDependant.lastName}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={"col-12 " + (this.props.role !== appConfig.ROLE.OWNER_ADMIN && this.props.role !== appConfig.ROLE.ADMIN ? 'col-md-6' : 'col-lg-4')}>
                                  <div className="field-row">
                                    <div className="row no-gutters">
                                      <div className="col-4">
                                        <div className="title-panel">
                                          <span className="title-text font-600">{Labels.FORM_PREVIEW.DATE_OF_BIRTH}</span>
                                        </div>
                                      </div>
                                      <div className="col-8">
                                        <div className="data-panel">
                                          <span className="data-text font-600">{moment(eachDependant.dob).format(appConfig.DATE_FORMAT)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {
                                  [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                                    ?
                                    <div className="col-lg-4 col-12">
                                      <div className="field-row">
                                        <div className="row no-gutters">
                                          <div className="col-4">
                                            <div className="title-panel">
                                              <span className="title-text font-600">{Labels.FORM_PREVIEW.ONLY_SIN}</span>
                                            </div>
                                          </div>
                                          <div className="col-8">

                                            <div className="data-panel sin-panel justify-content-between">
                                              {this.state.editSin === appConfig.SIN_FOR['DEPENDENT_' + eachDependant.id]
                                                ?
                                                <TextField
                                                  type='text'
                                                  parentClassName='form-group flex1 mr-10'
                                                  labelClassName='default-font black-text'
                                                  className='form-input'
                                                  inputDivClassName='input-panel'
                                                  placeholder={Placeholders.SIN}
                                                  onChange={this.onInputChange('dependentSin_' + eachDependant.id)}
                                                  {...this.state['dependentSin_' + eachDependant.id]}
                                                  value={this.state['dependentSin_' + eachDependant.id].value || eachDependant.sin}
                                                />
                                                :
                                                <div>
                                                  <span className="data-text font-600">{eachDependant.sin || appConfig.NO_VALUE}</span>
                                                </div>
                                              }
                                              {this.state.editSin === appConfig.SIN_FOR['DEPENDENT_' + eachDependant.id]
                                                ?
                                                <div className="save-image-icon icon-panel ml-10">
                                                  <div className={this.state['dependentSin_' + eachDependant.id].error ? "icon disabled" : ""}>
                                                    <img src={ImageAssets.formsvg.saveDocumentsIcon} alt="save-icon" onClick={this.onSaveSin(appConfig.SIN_FOR['DEPENDENT_' + eachDependant.id])} />
                                                  </div>
                                                  <div className="btn-tooltip">
                                                    <span className="btn-tooltip-text">{Labels.SAVE}</span>
                                                  </div>
                                                </div>
                                                :
                                                <div className="edit-image-icon icon-panel">
                                                  <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditSinClick(appConfig.SIN_FOR['DEPENDENT_' + eachDependant.id])} />
                                                  <div className="btn-tooltip">
                                                    <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    null
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  :
                  null
              }
            </div>

            {/* residence section */}
            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading residence">
                  <span className="userpanel-heading-text font-700">
                    {Messages.YOUR_RESIDENCE}
                  </span>
                </div>
                <div className="userview-data-panel">
                  <div className="icon-panel residence">
                  </div>
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.HAS_MOVE}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.hasMove || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        this.props.singleApplication.hasMove === appConfig.HAS_MOVE[0]
                          ?
                          <div className="field-row">
                            <div className="row no-gutters">
                              <div className="col-6">
                                <div className="title-panel">
                                  <span className="title-text font-600">{Labels.FORM_PREVIEW.PROVINCE_TERRITORY_CHANGE}</span>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="data-panel">
                                  <span className="data-text font-600">{this.props.singleApplication.dateOfMove ? moment(this.props.singleApplication.dateOfMove).format(appConfig.DATE_FORMAT) : appConfig.NO_VALUE}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          null
                      }
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.PROVINCE_TERRITORY_LIVE}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.provinceTerritory || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.SAME_HOME_ADDRESS}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.sameHomeAddress || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.CURRENTLY_LIVE}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.currentlyLive || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.IMMIGRATION_STATUS}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.immigrationStatus || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CRA */}

            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading cra">
                  <span className="userpanel-heading-text font-700">
                    {Messages.CRA_MY_ACCOUNT}
                  </span>
                </div>
                <div className="userview-data-panel">
                  <div className="icon-panel residence">
                  </div>
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.CRA_MY_ACCOUNT}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.CRAAccount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.TOTAL_RENT_TAX}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.rentTaxAmount || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.INVESTING_RRSP_TFSA}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.isInvesting}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.CHARITABLE_DONATION}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.donationAmount || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-row">
                        <div className="row no-gutters">
                          <div className="col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.FORM_PREVIEW.MEDICAL_EXPENSE}</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.isMedicalExpenses}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* deposite info */}

            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading deposite">
                  <span className="userpanel-heading-text font-700">
                    {Messages.DIRECT_DEPOSITE}
                  </span>
                </div>
                <div className="userview-data-panel">
                  <div className="icon-panel residence">
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.TRANSIT}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.transitInformation || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.INSTITUTION}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.institutionInformation || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="field-row userpanel-payment-section">
                        <div className="row no-gutters align-items-center">
                          <div className="col-xl-5 col-6">
                            <div className="title-panel">
                              <span className="title-text font-600">{Labels.ACCOUNT}</span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-6">
                            <div className="data-panel">
                              <span className="data-text font-600">{this.props.singleApplication.accountInformation || appConfig.NO_VALUE}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* document-section */}
            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="center justify-content-between flex-wrap">
                  <div className="userpanel-heading upload mr-20 mb-10">
                    <span className="userpanel-heading-text font-700">
                      {Messages.DOCUMENTS}
                    </span>
                  </div>
                  <div className="export-btn-panel d-flex flex-wrap">
                    {
                      [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role) && this.props.singleApplication.documents && this.props.singleApplication.documents.length > 0
                        ?
                        <Button
                          type='button'
                          className='btn btn-primary btn-small mr-10 mb-10'
                          onClick={this.downloadZipDocument}
                          text={Messages.EXPORT_AS_ZIP}
                        />
                        :
                        null
                    }
                    {
                      [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                        ?
                        <Button
                          type="button"
                          className="btn btn-primary btn-small  mb-10"
                          text={Messages.UPLOAD_DOCS}
                          onClick={this.onOpenPopupClick}
                        />
                        :
                        null
                    }
                  </div>
                </div>
                {
                  allDocuments.ownDocuments.length === 0 && allDocuments.spouseDocuments.length === 0 && allDocuments.dependant1Documents.length === 0 && allDocuments.dependant2Documents.length === 0 && allDocuments.dependant3Documents.length === 0 && allDocuments.dependant4Documents.length === 0 && allDocuments.dependant5Documents.length === 0 && allDocuments.dependant6Documents.length === 0 && allDocuments.adminDocuments.length === 0 ?
                    <div className="no-data-panel center h-auto mt-10 no-document-panel">
                      <div><img src={ImageAssets.common.noDocumentsImg} alt="nodata" className="w-75 m-auto d-block"></img></div>
                      <div className="mt-20"><span className="large-font font-600">No Documents Found!</span></div>
                    </div>
                    : null
                }

                <div className="upload-accordion">
                  <Accordion defaultActiveKey="3">
                    {allDocuments.ownDocuments.length
                      ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '0' ? "active" : ''} onClick={this.onBlockChange('0')}>
                          <Accordion.Toggle variant="link" eventKey="0">
                            {Messages.APPLICANT_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.ownDocuments.length
                                  ?
                                  <div className="document-display-container d-flex mt-15">
                                    {
                                      allDocuments.ownDocuments.map((eachDocument, index) => {
                                        return (
                                          <div className="document-box" key={index}>
                                            <div className="document-type-name">
                                              <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              <div className="name-tooltip">
                                                <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              </div>
                                            </div>
                                            <div className='position-relative'>
                                              <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type, eachDocument.fileName)}></div>
                                              <div className="center document-block">
                                                <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                              </div>
                                              <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  :
                                  <div className="no-data-panel center h-auto pb-30">
                                    <div><img src={ImageAssets.common.noDocumentsImg} alt="nodata" className="w-75 m-auto d-block"></img></div>
                                    <div className="mt-20"><span className="large-font font-600">No Documents Found!</span></div>
                                  </div>
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :
                      null
                    }
                    {allDocuments.spouseDocuments.length
                      ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '1' ? "active" : ''} onClick={this.onBlockChange('1')}>
                          <Accordion.Toggle variant="link" eventKey="1">
                            {Messages.SPOUSE_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.spouseDocuments.length
                                  ?
                                  <div className="document-display-container d-flex mt-15">
                                    {
                                      allDocuments.spouseDocuments.map((eachDocument, index) => {
                                        return (
                                          <div className="mr-20 mb-20 document-box" key={index}>
                                            <div className="document-type-name">
                                              <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              <div className="name-tooltip">
                                                <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              </div>
                                            </div>
                                            <div className='position-relative'>
                                              <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type, eachDocument.fileName)}></div>
                                              <div className="center document-block">
                                                <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                              </div>
                                              <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  :
                                  <div className="no-data-panel center h-auto pb-30">
                                    <div><img src={ImageAssets.common.noDocumentsImg} alt="nodata" className="w-75 m-auto d-block"></img></div>
                                    <div className="mt-20"><span className="large-font font-600">No Documents Found!</span></div>
                                  </div>
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :
                      null
                    }
                    {allDocuments.dependant1Documents.length > 0 || allDocuments.dependant2Documents.length > 0 || allDocuments.dependant3Documents.length > 0 || allDocuments.dependant4Documents.length > 0 || allDocuments.dependant5Documents.length > 0 || allDocuments.dependant6Documents.length > 0
                      ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '2' ? "active" : ''} onClick={this.onBlockChange('2')}>
                          <Accordion.Toggle variant="link" eventKey="2">
                            {Messages.DEPENDENT_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              <div className="row">
                                {
                                  this.props.singleApplication.dependants.map((eachDependant, index) => {
                                    let dependent = 'dependant' + (index + 1) + 'Documents'
                                    return (
                                      <div className="col-lg-6 mb-20" key={index}>
                                        <div className="dependent-title">
                                          <span className="dependent-title-text font-700">
                                            Dependant {index + 1}'s Documents :
                                          </span>
                                        </div>
                                        {
                                          allDocuments[dependent].length
                                            ?
                                            <div className="document-display-container d-flex mt-15">
                                              {
                                                allDocuments[dependent].map((eachDocument, subIndex) => {
                                                  return (
                                                    <div className="document-box" key={subIndex}>
                                                      <div className="document-type-name">
                                                        <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                        <div className="name-tooltip">
                                                          <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                        </div>
                                                      </div>
                                                      <div className='position-relative'>
                                                        <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type, eachDocument.fileName)}></div>
                                                        <div className="center document-block">
                                                          <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                                        </div>
                                                        <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                                      </div>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </div>
                                            :
                                            null
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :
                      null
                    }
                    {allDocuments.adminDocuments.length
                      ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '3' ? "active" : ''} onClick={this.onBlockChange('3')}>
                          <Accordion.Toggle variant="link" eventKey="3">
                            Uploaded by Admin
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.adminDocuments.length
                                  ?
                                  <>
                                    <div className="d-flex justify-content-end icon-block">
                                      {
                                        !this.state.isEdit && !this.state.isSave && this.props.role !== appConfig.ROLE.USER
                                          ?

                                          <div className="edit-image-icon icon-panel">
                                            <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditDocumentsClick} />
                                            <div className="btn-tooltip">
                                              <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                            </div>
                                          </div>
                                          :
                                          this.state.isEdit && !this.state.isSave
                                            ?
                                            <div className="edit-image-icon icon-panel">
                                              <img src={ImageAssets.formsvg.cancelIcon} alt="edit-icon" onClick={this.cnacelDocument} />
                                              <div className="btn-tooltip">
                                                <span className="btn-tooltip-text">{Labels.EDIT}</span>
                                              </div>
                                            </div>
                                            :
                                            null
                                      }
                                      {
                                        this.state.isSave
                                          ?
                                          <div className="save-image-icon icon-panel ml-10">
                                            <img src={ImageAssets.formsvg.saveDocumentsIcon} alt="save-icon" onClick={this.onSaveDocuments} />
                                            <div className="btn-tooltip">
                                              <span className="btn-tooltip-text">{Labels.SAVE}</span>
                                            </div>
                                          </div>
                                          :
                                          null
                                      }
                                    </div>
                                    <div className="document-display-container d-flex mt-15">
                                      {
                                        allDocuments.adminDocuments.map((eachDocument, index) => {
                                          return (
                                            <div className="mr-20 mb-20 document-box" key={index}>
                                              <div className="document-type-name">
                                                <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                <div className="name-tooltip">
                                                  <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                </div>
                                              </div>
                                              <div className='position-relative'>
                                                <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type, eachDocument.fileName)}></div>
                                                <div className="center document-block">
                                                  <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                                </div>
                                                {
                                                  this.state.isEdit || this.state.isSave
                                                    ?
                                                    eachDocument.uploadLink.includes('https://')
                                                      ?
                                                      <div className="cancel-icon" onClick={this.onUploadedFileDelete(eachDocument)}></div>
                                                      :
                                                      <div className="cancel-icon" onClick={this.onLocalFileDelete(index)}></div>
                                                    :
                                                    <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                                }
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </>
                                  :
                                  <div className="no-data-panel center h-auto pb-30">
                                    <div><img src={ImageAssets.common.noDocumentsImg} alt="nodata" className="w-75 m-auto d-block"></img></div>
                                    <div className="mt-20"><span className="large-font font-600">No Documents Found!</span></div>
                                  </div>
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :
                      null
                    }
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="userpanel-view-section">
              <div className="userpanel-block">
                <div className="userpanel-heading notes center justify-content-between mb-20">
                  <div>
                    <span className="userpanel-heading-text font-700">
                      {Messages.NOTES}
                    </span>
                  </div>
                  <div className="d-flex justify-content-end icon-block">
                    {this.state.editSin === appConfig.SIN_FOR.NOTES
                      ?
                      <div className="save-image-icon icon-panel ml-10">
                        <img src={ImageAssets.formsvg.saveDocumentsIcon} alt="save-icon" onClick={this.onSaveSin(appConfig.SIN_FOR.NOTES)} />
                        <div className="btn-tooltip">
                          <span className="btn-tooltip-text">{Labels.SAVE}</span>
                        </div>
                      </div>
                      :
                      this.props.role !== appConfig.ROLE.USER
                        ?
                        <div className="edit-image-icon icon-panel">
                          <img src={ImageAssets.formsvg.editDocumentsIcon} alt="edit-icon" onClick={this.onEditSinClick(appConfig.SIN_FOR.NOTES)} />
                          <div className="btn-tooltip">
                            <span className="btn-tooltip-text">{Labels.EDIT}</span>
                          </div>
                        </div>
                        :
                        null
                    }
                  </div>
                </div>
                {this.state.editSin === appConfig.SIN_FOR.NOTES
                  ?
                  <div>
                    <div className="form-group">
                      <textarea className="form-input h-auto" placeholder="Enter Text" rows="5" onChange={this.onNotesChange(InputIds.NOTES)} value={this.state.notes || this.props.singleApplication.notes} ></textarea>
                    </div>
                  </div>
                  :
                  <div className="userview-data-panel notes-data-panel">
                    <div className="row">
                      <div className="col-lg-12">
                        <span className="data-text font-600">{this.props.singleApplication.notes || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

          </div>
        </div>
        <UploadDocumentPopup
          open={this.state.uploadDocumentsPopup}
          document={this.state.document}
          onFileTypeChange={this.onFileTypeChange}
          onFileForChange={this.onFileForChange}
          onFileLoad={this.onFileLoad}
          onFileDelete={this.onSelectedFileDelete}
          onCancelClick={this.onCancelClick}
          onSubmitClick={this.onSubmitClick}
          disabledButton={this.state.uplaodDocuments.length > 0 && this.state.document.type}
          applicationData={this.props.singleApplication}
          depedentCount={this.props.singleApplication.dependants && this.props.singleApplication.dependants.length > 0 ? this.props.singleApplication.dependants.length : 0}
          role={this.props.role}
          uplaodDocuments={this.state.uplaodDocuments}
        />
        <UpdatePaymentStatus
          open={this.state.updatePaymentStatus}
          {...this.state.paymentMethod}
          onCancelClick={this.onCancelClick}
          onMethodChange={this.onMethodChange}
          onInputChange={this.onInputChange}
          onSubmitClick={this.onPaymentSubmit}
          onDescriptionChange={this.onDescriptionChange}
        />
        {
          [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
            ?
            <AssignApplicationPopup
              open={this.state.assignAppPopup}
              selectedAccountantId={this.state.selectedAccountantId}
              onCancelClick={this.onCancelClick}
              onSelectedUserChange={this.onSelectedUserChange}
              onSubmitClick={this.onAssignApplicationSubmitClick}
            />
            :
            null
        }
        {
          this.state.viewDoc.link
            ?
            <ViewDocument
              open={this.state.viewDocumentPopup}
              fileType={this.state.viewDoc.fileType}
              link={this.state.viewDoc.link}
              onCancelClick={this.onCancelClick}
            />
            :
            null
        }
        <Footer />
      </div >
    )
  }
}

ApplicationView.propTypes = {}
const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  role: state.auth.role,
  singleApplication: state.taxApplication.singleApplication,
  documentTypesList: state.list.documentTypesList,
  plans: state.subscription.plans
})

export default connect(mapStateToProps, { getSingleApplication, updateApplicationStatus, updateDocuments, getDocumentTypes, updatePaymentAdminAction, getPlansListAction, assignPaymentPlan, getDocument, assignToAccountant, updateApplicationSin, downloadZip })(ApplicationView)
