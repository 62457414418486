export const ImageAssets = {
  loading: require('../component/images/common/loading.gif'),
  common: {
    siteLogo: require('../component/images/logo.png'),
    siteFooterLogo: require('../component/images/footer-logo.png'),
    toggleIcon: require('../component/images/svg-icon/menu-toggle.svg'),
    sampleDocument: require('../component/images/common/sample-document.png'),
    noDataImg: require('../component/images/common/no-data-img.png'),
    tableNoDataImg: require('../component/images/common/table-no-data-img.png'),
    noDocumentsImg: require('../component/images/common/no-document-img.png')
  },
  social: {
    facebookIcon: require('../component/images/svg-icon/facebook-icon.svg'),
    instagramIcon: require('../component/images/svg-icon/instagram-icon.svg'),
    googleIcon: require('../component/images/svg-icon/google-icon.svg'),
    twitterIcon: require('../component/images/svg-icon/twitter-icon.svg'),
    linkedinIcon: require('../component/images/svg-icon/linkedin-icon.svg')
  },
  authimage: {
    loginImage: require('../component/images/login-signup/login-image.png'),
    signupImage: require('../component/images/login-signup/signup-image.png'),
    forgotPasswordImage: require('../component/images/login-signup/forgot-password-image.png'),
    generateOtpImage: require('../component/images/login-signup/otp-image.png'),
    rightArrow: require('../component/images/svg-icon/right-arrow-icon.svg'),
    hideEyeImage: require('../component/images/svg-icon/hide-eye-icon.svg'),
    showEyeImage: require('../component/images/svg-icon/show-eye-icon.svg'),
    sessionTimeOut:  require('../component/images/login-signup/session-timeout.png')
  },
  paymentIcon: {
    slipIcon: require('../component/images/svg-icon/payment-slip-icon.svg'),
    pigibankIcon: require('../component/images/svg-icon/payment-pigibank-icon.svg'),
    medicalIcon: require('../component/images/svg-icon/payment-medical-icon.svg'),
    taxlistIcon: require('../component/images/svg-icon/payment-taxlist-icon.svg'),
    propertyIcon: require('../component/images/svg-icon/payment-property-icon.svg'),
    stockIcon: require('../component/images/svg-icon/payment-stock-icon.svg'),
    employeeIcon: require('../component/images/svg-icon/payment-employee-icon.svg'),
    guidanceIcon: require('../component/images/svg-icon/payment-guidance-icon.svg'),
    bulletcheckIcon: require('../component/images/svg-icon/bullet-check-icon.svg')
  },
  formsvg: {
    tooltipIcon: require('../component/images/svg-icon/tooltip-question-icon.svg'),
    uploadDocumentIcon: require('../component/images/svg-icon/upload-document-icon.svg'),
    selectFileIcon: require('../component/images/svg-icon/select-file-icon.svg'),
    saveDocumentsIcon: require('../component/images/svg-icon/save-icon.svg'),
    editDocumentsIcon: require('../component/images/svg-icon/edit-document-icon.svg'),
    addDocumentsIcon: require('../component/images/svg-icon/add-document-icon.svg'),
    removeDocumentsIcon: require('../component/images/svg-icon/remove-document-icon.svg'),
    deleteDocumentsIcon: require('../component/images/svg-icon/delete-document-icon.svg'),
    cloudIcon: require('../component/images/svg-icon/cloud-icon.svg'),
    cancelIcon: require('../component/images/svg-icon/cancel-small-icon.svg'),
    verifiedIcon: require('../component/images/svg-icon/verified.svg'),
    notVerifiedIcon: require('../component/images/svg-icon/not-verified.svg'),
    editIcon: require('../component/images/svg-icon/edit-document-icon.svg'),
    saveIcon: require('../component/images/svg-icon/save-icon.svg')
  },
  taxform: {
    pdfIcon: require('../component/images/tax-form/pdf-icon.png'),
    excelIcon: require('../component/images/tax-form/excel-icon.png'),
    docIcon: require('../component/images/tax-form/doc-icon.png'),
    imgIcon: require('../component/images/tax-form/img-icon.png'),
    uploaddocumentimg: require('../component/images/tax/upload-document-img.jpg'),
    notSupportedImg: require('../component/images/tax-form/not-supported-img.png')
  },
  errorpage: {
    errorImageUpper: require('../component/images/common/error-img1.png'),
    errorImageLower: require('../component/images/common/error-img2.png')
  },
  popup: {
    cancelIcon: require('../component/images/svg-icon/popup-cancel-icon.svg'),
    haveAccount: require('../component/images/popup/doyou-have-account-img.png'),
    emailIcon: require('../component/images/popup/email-icon.png'),
    logoDesign: require('../component/images/popup/logo-design.png'),
    newMember: require('../component/images/popup/new-user.png'),
    applicationConfirmationIcon: require('../component/images/svg-icon/application-confirmation-icon.svg'),
    oldMember: require('../component/images/popup/old-user.png'),
    passwordIcon: require('../component/images/popup/password-icon.png'),
    documnetIcon: require('../component/images/popup/document-icon.png')
  },
  adminpanel: {
    viewIcon: require('../component/images/svg-icon/view-icon.svg'),
    editIcon: require('../component/images/svg-icon/edit-icon.svg'),
    deleteIcon: require('../component/images/svg-icon/delete-icon.svg'),
    assignIcon: require('../component/images/svg-icon/assign-role-icon.svg'),
    filterIcon: require('../component/images/svg-icon/filter-icon.svg'),
    profileIcon: require('../component/images/svg-icon/my-profile-icon.svg'),
  },
  home: {
    slantGrayImg: require('../component/images/home/banner-gray-slant-img.png'),
    slantImg: require('../component/images/home/banner-slant-img.png'),
    aboutUsImg: require('../component/images/home/about-us-img.png'),
    lotusImg: require('../component/images/home/slider-lotus-img1.png'),
    bankerImg: require('../component/images/home/banner-banker-img.png'),
    waveImg: require('../component/images/home/wave-banner-img.png'),
    bannerSocialIcons: {
      facebookIcon: require('../component/images/svg-icon/banner-facebook-icon.svg'),
      instagramIcon: require('../component/images/svg-icon/banner-instagram-icon.svg'),
      twitterIcon: require('../component/images/svg-icon/banner-twitter-icon.svg'),
      linkedinIcon: require('../component/images/svg-icon/banner-linkedin-icon.svg')
    },
    bannerSliderImages: {
      sliderImg1: require('../component/images/home/banner-slider-img1.png'),
      sliderImg2: require('../component/images/home/banner-slider-img2.png'),
      sliderImg3: require('../component/images/home/banner-slider-img3.png'),
      sliderImg4: require('../component/images/home/banner-slider-img4.163f5789.png'),
    },
    serviceImages: {
      accountingServiceImg: require('../component/images/home/accounting-service-img.png'),
      taxPlanServiceImg: require('../component/images/home/taxPlan-service-img.png'),
      miscellaneousServiceImg: require('../component/images/home/miscellaneous-service-img.png'),
      consultingServiceImg: require('../component/images/home/consulting-service-img.png')
    },
    clientReview: {
      clientProfileImg: require('../component/images/home/client-profile-img.png')
    },
    newsletterImages: {
      envelopeIcon: require('../component/images/svg-icon/newsletter-email-icon.svg')
    },
    tooltipIcon: require('../component/images/svg-icon/tooltip-question-icon.svg'),    
    cloudIcon: require('../component/images/svg-icon/cloud-icon.svg')
  },
  tax: {
    banner: {
      bannerImg: require('../component/images/tax/tax-banner-img.png')
    },
    aboutBliss: {
      aboutImg: require('../component/images/tax/bliss-about-section.png')
    }
  },
  aboutUs: {
    slantImg: require('../component/images/about-us/inner-slant-img.png'),
    aboutUsImg: require('../component/images/about-us/about-us-img.png'),
    expertImg: require('../component/images/about-us/expert-img.png'),
    socialIcons: {
      facebookIcon: require('../component/images/svg-icon/expert-facebook-icon.svg'),
      twitterIcon: require('../component/images/svg-icon/expert-twitter-icon.svg'),
      googlePlusIcon: require('../component/images/svg-icon/expert-google-plus-icon.svg')
    }
  },
  services: {
    accountServiceImg: require('../component/images/services/account-service-img.png'),
    taxServiceImg: require('../component/images/services/tax-service-img.png'),
    consultingServiceImg: require('../component/images/services/consulting-service-img.png'),
    otherServiceImg: require('../component/images/services/other-service-img.png')
  },
  resources: {
    businessImg: require('../component/images/resources/business-resource.png'),
    individualsImg: require('../component/images/resources/individuals-resource.png'),
    govAgencyImg: require('../component/images/resources/gov-agency-resource.png')
  },
  contactUs: {
    mapImg: require('../component/images/contact-us/map-img.png')
  }
}

export default ImageAssets