import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ImageAssets from '../../utils/ImageAssets'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { appConfig } from '../../constants/AppConfig'
import { newsletterListAction, deleteNewsletterAction } from '../../actions/newsletterActions'
import { InputIds, Messages } from '../../constants/StaticTexts'
import CustomDropDown from '../common/CustomDropDown'
import Pagination from 'react-js-pagination'

class AdminSubscription extends Component {

  constructor(props) {
    super(props)
    let  subscriptionfilterObj = JSON.parse(localStorage.getItem('subscriptionfilterObj'))
    this.state = {
      page:  subscriptionfilterObj && subscriptionfilterObj.pageNo ? subscriptionfilterObj.pageNo : 1,
      limit: subscriptionfilterObj && subscriptionfilterObj.limit ?  subscriptionfilterObj.limit :  appConfig.DEFAULT_PAGINATION_OPTIONS[0],
      selectedId: null,
      sortDirection: subscriptionfilterObj && subscriptionfilterObj.sortingOrder ?   subscriptionfilterObj.sortingOrder : appConfig.DEFAULT_SORTING,
      sortHeader : subscriptionfilterObj && subscriptionfilterObj.sortingName ?   subscriptionfilterObj.sortingName :appConfig.DEFAULT_SORTING_FIELD
    }
  }

  componentDidMount() {
    let requestBody = {
      limit: this.state.limit,
      page: this.state.page,
      filterList: [],
      sortDirection: this.state.sortDirection ,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.newsletterListAction(this.props.userId, requestBody)
  }

  onPageNumberClick = (pageNumber)  => {
    let page
    if (pageNumber === InputIds.NEXT) {
      page = this.state.page + 1
    } else if (pageNumber === InputIds.PREVIOUS) {
      page = this.state.page - 1
    } else {
      page = pageNumber
    }
    let requestBody = {
      page: page,
      limit: this.state.limit,
      filterList: [],
      sortDirection: this.state.sortDirection ,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.setState({ page: page } ,() => this.storeFilter())
    this.props.newsletterListAction(this.props.userId, requestBody)
  }

  onUserClick = (selectedId) => () => {
    if (this.state.selectedId === selectedId) {
      this.setState({ selectedId: null })
    } else {
      this.setState({ selectedId: selectedId })
    }
  }

  onDeleteClick = (userId, index) => () => {
    this.props.deleteNewsletterAction(this.props.userId, userId, index)
  }

  onLimitChange = (limit) => () => {
    let requestBody = {
      page: this.state.page,
      limit: limit,
      filterList: [],
      sortDirection: this.state.sortDirection ,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.newsletterListAction(this.props.userId, requestBody)
    this.setState({ limit: limit, page: 1 } , this.storeFilter())
  }

  onSortingClick = (input ) => (event) => {
    if(event){
    let requestBody = {
      limit: this.state.limit,
      page: this.state.page,
      filterList: [],
      isPagination: true
    }
    if(input === 'createdAt') {
      requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
      requestBody.sortHeader=  input
       this.setState({
         sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
         sortHeader : input
       }, () => this.storeFilter())
       this.props.newsletterListAction(this.props.userId, requestBody)
     }
    }
  }

  storeFilter = () => {
    let subscriptionfilterObj = {
      sortingName: this.state.sortHeader,
      sortingOrder: this.state.sortDirection,
      pageNo: this.state.page,
      limit : this.state.limit
    }
    localStorage.setItem('subscriptionfilterObj' , JSON.stringify(subscriptionfilterObj))
  }

  render() {
    let pageNumbers = []
    for (let i = 1; i <= this.props.newsletterList.totalPage; i++) {
      pageNumbers.push(i)
    }

    return (
      <div>
        <FormHeader page={Messages.SUBSCRIPTION} />
        <div className="admin-panel-page">
          <div className="container-block small-container-block">
            <div className="admin-table-card admin-subscription-card">
              <div className="table-header">
                <div className="table-row">
                  <div className="table-data-row">
                    <div className="cell cell-arrow"></div>
                    <div className="cell cell-id">
                      <div>
                        <span className="cell-header-data font-700">Subscription ID</span>
                      </div>
                    </div>
                    <div className="cell cell-date">
                     <div className="d-flex">
                      <div>
                        <span className="cell-header-data font-700">Date</span>
                      </div>
                      <div className="sorting-panel" onClick={this.onSortingClick("createdAt")}></div>
                      </div>
                    </div>
                    <div className="cell cell-action">
                      <div className="text-center">
                        <span className="cell-header-data font-700">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {
                  this.props.newsletterList.list.length
                    ?
                    this.props.newsletterList.list.map((eachNewsletterUser, index) => {
                      let showCard = this.state.selectedId === index
                      return (
                        <div className="table-row position-relative" key={index}>
                          <div className="table-data-row table-body-data-row">
                            <div className="cell cell-arrow">
                              <div className={"droparrow" + (showCard ? ' active' : '')} onClick={this.onUserClick(index)}></div>
                            </div>
                            <div className="cell cell-id">
                              <div>
                                <span className="cell-body-data font-600 meta-info">{eachNewsletterUser.email}</span>
                              </div>
                            </div>
                            <div className="cell cell-date">
                              <div>
                                <span className="cell-body-data font-600">{moment.utc(eachNewsletterUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                              </div>
                            </div>
                            <div className="cell cell-action">
                              <div className="action-btn m-auto">
                                <img src={ImageAssets.adminpanel.deleteIcon} alt="delete" onClick={this.onDeleteClick(eachNewsletterUser._id, index)} />
                              </div>
                            </div>
                          </div>
                          {
                            showCard
                              ?
                              <div className="table-card">
                                <div className="table-card-item cell-date">
                                  <div>
                                    <span className="cell-header-data font-700">Date</span>
                                  </div>
                                  <div className="mt-10">
                                    <span className="cell-body-data font-600">{moment.utc(eachNewsletterUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                        </div>
                      )
                    })
                    :
                    <div className="no-data-panel center">
                      <div><img src={ImageAssets.common.tableNoDataImg} alt="nodata"></img></div>
                      <div className="mt-20"><span className="large-font font-600">No Data Found !</span></div>
                    </div>
                }
              </div>
            </div>
            <div className="center justify-content-end flex-wrap">
              <div className="mt-5">
                {
                  pageNumbers.length > 1
                    ?
                    <div className="pagination-block">
                      <div className="page-panel">
                        {/* <div className="left-arrow" onClick={this.onPageNumberClick(InputIds.PREVIOUS)}></div>
                        {
                          pageNumbers.map((eachNumber, index) => {
                            return (
                              <div className={"number-block" + (this.state.page === (index + 1) ? ' active' : '')} key={index}>
                                <span className="page-number font-700" onClick={this.onPageNumberClick(index + 1)}>
                                  {eachNumber}
                                </span>
                              </div>
                            )
                          })
                        }
                        <div className="right-arrow" onClick={this.onPageNumberClick(InputIds.NEXT)}></div> */}


                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.props.newsletterList.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.onPageNumberClick.bind(this)}
                          hideFirstLastPages='false'
                          prevPageText=""
                          nextPageText=""
                          activeClass="active"
                          itemClass ="number-block"
                          itemClassPrev='left-arrow'
                          itemClassNext='right-arrow' 
                        />
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              {
                this.props.newsletterList.totalCount > 10
                  ?
                  <CustomDropDown
                    parentClassName="page-dropdown ml-30 mt-5"
                    onChange={this.onLimitChange}
                    list={appConfig.DEFAULT_PAGINATION_OPTIONS}
                    value={this.state.limit}
                  />
                  :
                  null
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  componentWillUnmount() {
   this.storeFilter()
 }
}

AdminSubscription.propTypes = {}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  newsletterList: state.list.newsletterList
})

export default connect(mapStateToProps, { newsletterListAction, deleteNewsletterAction })(AdminSubscription)
