export const UIRoutes = {
  HOME: '/',
  TAX: '/tax',
  ABOUTUS: '/about-us',
  SERVICES: '/services',
  RESOURCES: '/resources',
  CONTACTUS: '/contact-us',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_PASSWORD: '/create-password',
  SET_PASSWORD: '/set-new-password',
  TAX_APPLICATION: '/tax-application',
  EDIT_TAX_APPLICATION: '/tax-application/:id',
  CONTACT_US: '/contact-us',
  VIEW_ALL_APPLICATION: '/view-all-application',
  VIEW_MY_PROFILE: '/view-my-profile',
  APPLICATION: '/application/',
  APPLICATION_ROUTE: '/application/:id',
  APPLICATIONS: '/applications',
  PAYMENT_PLANS: '/payment-plans/',
  PAYMENT_PLANS_ROUTE: '/payment-plans/:id',
  STRIPE_PAYMENT: '/stripe-payment',
  USERS: '/users',
  SUBSCRIPTION: '/subscriptions',
  REFRENCES: '/references'
}