import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ImageAssets from '../../utils/ImageAssets'
import CustomDropDown from '../common/CustomDropDown'
import { appConfig } from '../../constants/AppConfig'
import { Placeholders, Messages, InputIds, Labels } from '../../constants/StaticTexts'
import Button from '../common/Button'
import TextField from '../common/TextField'

class UpdatePaymentStatus extends Component {

  render() {

    let disabledButton = this.props.method && this.props.amount.value && !this.props.amount.error
    return (     
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open} className="update-payment-popup">
        <DialogTitle id="customized-dialog-title">
          <div className="text-center heading-title">
            <span className="large-font black-text font-600">
              Select payment method for the application.
            </span>
          </div>
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>          
          <CustomDropDown
            onChange={this.props.onMethodChange}
            list={appConfig.PAYMENT_METHOD}
            placeholder={Placeholders.SELECT}
            value={this.props.method}
          />
          <TextField
            type='text'
            parentClassName='form-group'
            labelText={Labels.AMOUNT}
            labelClassName='default-font black-text font-600'
            className='form-input'
            inputDivClassName='input-panel'
            onChange={this.props.onInputChange(InputIds.AMOUNT)}
            placeholder={Placeholders.ENTER_AMOUNT}
            {...this.props.amount}
          />
          <TextField
            type='text'
            parentClassName='form-group'
            labelText={Labels.DESCRIPTION}
            labelClassName='default-font black-text font-600'
            className='form-input'
            inputDivClassName='input-panel'
            onChange={this.props.onInputChange(InputIds.DESCRIPTION)}
            placeholder={Placeholders.ENTER_DESCRIPTION}
            value={this.props.notes}
          />
          <Button
            type='submit'
            parentClassName='mt-30 center'
            className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
            text={Messages.SUBMIT}
            onClick={this.props.onSubmitClick}
            disabled={!disabledButton}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

UpdatePaymentStatus.propTypes = {}

export default UpdatePaymentStatus
