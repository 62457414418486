import React, { Component } from 'react'
import { connect } from 'react-redux'
import remove from 'lodash/remove'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import Steps from './Steps'
import AboutYourself from './AboutYourself'
import YourFamily from './YourFamily'
import AboutResidence from './AboutResidence'
import CRAMyAccount from './CRAMyAccount'
import UploadDocuments from './UploadDocuments'
import FormPreview from './FormPreview'
import { increaseApplicationStep, getSingleApplication, removeApplicationSetup } from '../../actions/applicationActions'
import { appConfig } from '../../constants/AppConfig'
import { Messages } from '../../constants/StaticTexts'

class TaxApplication extends Component {

  componentDidMount() {
    if (this.props.isEditing) {
      let applicationId = this.props.match.params.id
      setTimeout(() => {
        this.props.getSingleApplication(this.props.userId, applicationId, true, 4, 0)
      },1000)
    }
  }

  increaseApplicationStep = (appData) => {
    // appData is having specific step data
    let currentStep = this.props.applicationData.currentStep + 1
    let step = this.props.applicationData.step + 1
    let stepData = {
      currentStep: currentStep
    }
    if (step === currentStep) {
      stepData['step'] = step
    }
    let docs = { documents: this.props.applicationData.documents }
    if (stepData.currentStep === 2) {
      let deletedDocumentsFileNames = appData.deletedDocuments.map(eachDocument => String(eachDocument.fileName))
      let availableDocs = this.props.applicationData.documents
      remove(availableDocs, eachDocument => deletedDocumentsFileNames.includes(eachDocument.fileName))
      docs = { documents: availableDocs }
    } else if (stepData.currentStep === 5) {
      docs = { documents: appData.documents }
    }
    this.props.increaseApplicationStep({ ...this.props.applicationData, ...appData, ...stepData, ...docs })
  }

  render() {

    let isDisabled = [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role)

    var pageToRender = [
      [<AboutYourself key={appConfig.APPLICATION_STEPS[0]} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} disabled={isDisabled} />],
      [<YourFamily key={appConfig.APPLICATION_STEPS[1]} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} disabled={isDisabled} />],
      [<AboutResidence key={appConfig.APPLICATION_STEPS[2]} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} disabled={isDisabled} />],
      [<CRAMyAccount key={appConfig.APPLICATION_STEPS[3]} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} disabled={isDisabled} />],
      [<UploadDocuments key={appConfig.APPLICATION_STEPS[4]} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} disabled={isDisabled}/>],
      [<FormPreview key={Messages.FORM_PREVIEW} onClick={this.increaseApplicationStep} applicationData={this.props.applicationData} history={this.props.history} isEdit={this.props.isEditing} />],
      [
        <div key="application-loading" style={{ minHeight: "100vh" }}>
        </div>
      ]
    ]

    return (
      <>
        <FormHeader />
        {
          this.props.applicationData.step !== 5
            ?
            <Steps
              isEditing={this.props.isEditing}
              step={this.props.applicationData.step}
              currentStep={this.props.applicationData.currentStep}
            />
            :
            null
        }
        {pageToRender[this.props.applicationData.currentStep]}
        <Footer />
      </>
    )
  }

  componentWillUnmount() {
    this.props.removeApplicationSetup()
  }
}

TaxApplication.propTypes = {}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  role: state.auth.role,
  applicationData: state.taxApplication.applicationSetup
})

export default connect(mapStateToProps, { increaseApplicationStep, getSingleApplication, removeApplicationSetup })(TaxApplication)
