import React, { Component } from 'react'
import ImageAssets from '../../utils/ImageAssets'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { appConfig } from '../../constants/AppConfig'
import { Messages } from '../../constants/StaticTexts'

class Resources extends Component {

  render() {
    return (
      <div>
        <Header page={Messages.RESOURCES} />
        <div className="banner-section inner-banner-section resources-banner-section">
          <div className="lotus-pattern-panel"><img src={ImageAssets.home.lotusImg} alt="lotus" className="image-responsive"></img></div>
          <div className="slant-img-panel"><img src={ImageAssets.aboutUs.slantImg} alt="slant" className="image-responsive"></img></div>
          <div className="container-block">
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="section-heading-panel">
                  <h1 className="banner-heading-text light-green-text font-900 mb-20">Resources</h1>
                  <h3 className="banner-subheading-text light-gray-text large-font">Whether you want to transform your business, advance your career, or simply find out more about what we are doing, this is the place</h3>
                </div>
              </div>
              <div className="col-lg-7 d-lg-block d-md-none d-none">
                <div className="banner-description text-right">
                  <h1 className="white-text font-900 banner-heading">Rich knowledge, always at your disposal.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-mobile-section resources-mobile-section inner-mobile-section d-lg-none d-md-block">
          <div className="banner-description">
            <h1 className="white-text font-900 banner-heading text-right mb-0">Rich knowledge, always at your disposal.</h1>
          </div>
        </div>
        <div className="resources-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="resource-data-panel">
                  <p className="resource-detail font-600">The internet is full of great sites talking about various financial aspects. But, trying to find the appropriate and reliable sites can be a frustrating experience. We have compiled a list of suitable websites based on various topics for your quick reference.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="resource-block">
                  <div className="default-data center business-block text-center">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="position-relative h-100">
                            <div className="overlay yellow-overlay"></div>
                            <img src={ImageAssets.resources.businessImg} alt="business" className="image-responsive w-100"></img>
                          </div>
                          <div className="resource-details-panel center">
                            <h3 className="font-900 resource-title white-text">Important dates for business</h3>
                            <p className="resource-detail font-600 white-text mt-30 mb-0">Information for entities who are incorporated as Corporation and or Sole Proprietor</p>
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <img src={ImageAssets.resources.businessImg} alt="business" className="image-responsive w-100"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hover-data">
                    <div className="resources-inner-details custom-scrollbar mt-15">
                      {
                        appConfig.RESOURCES_ROUTES.IMPORTANT_DATES_FOR_BUSINESS.map((eachSection, index) => {
                          return (
                            <div className="dates-block" key={index}>
                              <div className="mb-10">
                                <a href={eachSection.LINK} target='_blank' rel='noopener noreferrer' className="dates-block-title large-font font-700">
                                  {eachSection.NAME}
                                </a>
                              </div>
                              {
                                Array.isArray(eachSection.DESCRIPTION)
                                  ?
                                  eachSection.DESCRIPTION.map((eachDescription, subIndex) => {
                                    return (
                                      <div className="mb-10" key={subIndex}>
                                        <span className="default-font font-700 pr-20">
                                          {eachDescription.NAME}
                                        </span>
                                        <span className="default-font">
                                          {eachDescription.DESC}
                                        </span>
                                      </div>
                                    )
                                  })
                                  :
                                  <div><span className="default-font">{eachSection.DESCRIPTION}</span></div>
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="resource-block">
                  <div className="default-data center individuals-block text-center">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="position-relative h-100">
                            <div className="overlay blue-overlay"></div>
                            <img src={ImageAssets.resources.individualsImg} alt="individuals" className="image-responsive w-100"></img>
                          </div>
                          <div className="resource-details-panel center">
                            <h3 className="font-900 resource-title white-text">Important dates for individuals</h3>
                            <p className="resource-detail font-600 white-text mt-30 mb-0">Information for full time employees</p>
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <img src={ImageAssets.resources.individualsImg} alt="business" className="image-responsive w-100"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hover-data">
                    <div className="resources-inner-details custom-scrollbar mt-15">
                      {
                        appConfig.RESOURCES_ROUTES.IMPORTANT_DATES_FOR_INDIVIDUALS.map((eachSection, index) => {
                          return (
                            <div className="dates-block" key={index}>
                              <div className="mb-10">
                                <a href={eachSection.LINK} target='_blank' rel='noopener noreferrer' className="dates-block-title large-font font-700">
                                  {eachSection.NAME}
                                </a>
                              </div>
                              <div><span className="default-font">{eachSection.DESCRIPTION}</span></div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="resource-block">
                  <div className="default-data center gov-agency-block text-center">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="position-relative h-100">
                            <div className="overlay yellow-overlay"></div>
                            <img src={ImageAssets.resources.govAgencyImg} alt="govAgency" className="image-responsive w-100"></img>
                          </div>
                          <div className="resource-details-panel center">
                            <h3 className="font-900 resource-title white-text">Governmental Agencies</h3>
                            <p className="resource-detail font-600 white-text mt-30 mb-0">News and Information - General resources</p>
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <img src={ImageAssets.resources.govAgencyImg} alt="business" className="image-responsive w-100"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hover-data">
                    <div className="resources-inner-details custom-scrollbar mt-15">
                      {
                        appConfig.RESOURCES_ROUTES.GOVERNMENTAL_AGENCIES.map((eachSection, index) => {
                          return (
                            <div className="dates-block" key={index}>
                              <div><span className="large-font font-700">{eachSection.NAME}</span></div>
                              {
                                eachSection.OPTIONS.map((eachLink, subIndex) => {
                                  return (
                                    <div className="mt-10" key={subIndex}>
                                      <a href={eachLink.LINK} target='_blank' rel='noopener noreferrer' className="dates-block-title default-font">
                                        {eachLink.NAME}
                                      </a>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

Resources.propTypes = {}

export default Resources