import React, { Component } from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import ImageAssets from '../../utils/ImageAssets'
import Button from '../common/Button'
import TextField from '../common/TextField'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { InputIds, Labels, Messages, Placeholders } from '../../constants/StaticTexts'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { appConfig } from '../../constants/AppConfig'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { consultantAction } from '../../actions/commonActions'
import { mobileNumberFormat } from '../../utils/Utils'

class AboutUs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: { value: '', error: null },
      email: { value: '', error: null },
      phone: { value: '', error: null },
      message: { value: '', error: null }
    }
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.NAME) {
      if (event.target.value) {
        this.setState({
          name: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.NAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          name: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
          }
        })
      } else {
        this.setState({
          email: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.PHONE) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)
        this.setState({
          phone: {
            value: formatedNumber,
            error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
          }
        })
      } else {
        this.setState({
          phone: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.MESSAGE) {
      if (event.target.value) {
        this.setState({
          message: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.MESSAGE.MIN, max: appConfig.MAX_LENGTH.MESSAGE.MAX }) ? null : ErrorMessages.MESSAGE_NOT_VALID
          }
        })
      } else {
        this.setState({
          message: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onSubmitClick = () => {
    let requestBody = {
      name: this.state.name.value,
      email: this.state.email.value,
      contactNumber: this.state.phone.value,
      message: this.state.message.value
    }
    if (this.state.phone.value) {
      requestBody['contactNumber'] = appConfig.MOBILE_CODE + ' ' + this.state.phone.value
    }
    this.props.consultantAction(requestBody)
    this.setState({
      name: { value: '', error: null },
      email: { value: '', error: null },
      phone: { value: '', error: null },
      message: { value: '', error: null }
    })
  }

  render() {

    let disabledButton = this.state.name.value && !this.state.name.error && this.state.email.value && !this.state.email.error && this.state.message.value && !this.state.message.error && !this.state.phone.error

    return (
      <div>
        <Header page={Messages.ABOUT_US} />
        <div className="banner-section inner-banner-section aboutUs-banner-section">
          <div className="lotus-pattern-panel"><img src={ImageAssets.home.lotusImg} alt="lotus" className="image-responsive"></img></div>
          <div className="slant-img-panel"><img src={ImageAssets.aboutUs.slantImg} alt="slant" className="image-responsive"></img></div>
          <div className="container-block">
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="section-heading-panel">
                  <h1 className="banner-heading-text light-green-text font-900 mb-20">{Messages.ABOUT_US}</h1>
                  <h3 className="banner-subheading-text light-gray-text large-font">Whether you want to transform your business, advance your career, or simply find out more about what we are doing, this is the place</h3>
                </div>
              </div>
              <div className="col-lg-7 d-lg-block d-md-none d-none">
                <div className="banner-description text-right">
                  <h1 className="white-text font-900 banner-heading">We help entrepreneurs master their financial lives.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-mobile-section about-mobile-section inner-mobile-section d-lg-none d-md-block">
          <div className="banner-description">
            <h1 className="white-text font-900 banner-heading text-right mb-0">We help entrepreneurs master their financial lives.</h1>
          </div>
        </div>
        <div className="about-us-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading-panel display-lg-block text-center">
                  <h4 className="preheading-text mb-20">OUR MISSION</h4>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img-wrapper"><img src={ImageAssets.aboutUs.aboutUsImg} alt="aboutImg" className="image-responsive"></img></div>
              </div>
              <div className="col-lg-6">
                <div className="section-heading-panel display-lg-none">
                  <h4 className="preheading-text mb-20">OUR MISSION</h4>
                </div>
                <div className="mt-20 mr-60 about-us-descritpion">
                  <p className="description-text default-font">By taking advantage of our Team over 10 years diverse accounting expertise, not only can we add value to your financials, we can show you how and where you can improve your bottom line through forecasting, tax planning and bench-marking. Financials and returns explained. With clear vision of where you've been, you'll know where you're going.</p>
                  <p className="description-text default-font">We believe in the value of relationships. We view every client relationship like a partnership, and truly believe that our success is a result of your success.</p>
                  <p className="description-text default-font">With two offices conveniently located in Ottawa and Toronto, we are committed to providing close, personal attention to our clients. We take pride in giving you the assurance that the assistance you receive comes from years of advanced training, technical experience and financial acumen. Our continual investment of time and resources in professional continuing education, state-of-the-art computer technology and extensive business relationships is indicative of our commitment to excellence.</p>
                </div>
              </div>
            </div>
            <div className="about-main-panel d-none">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="about-panel expert-panel">
                    <div className="mb-15"><span className="about-title light-green-text font-700">Expert People</span></div>
                    <div><span className="about-details light-gray-text">At vero eos et accusamus etiusto odio praesentium.</span></div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="about-panel profit-panel">
                    <div className="mb-15"><span className="about-title light-green-text font-700">Fast Growing Process</span></div>
                    <div><span className="about-details light-gray-text">At vero eos et accusamus etiusto odio praesentium.</span></div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="about-panel creativity-panel">
                    <div className="mb-15"><span className="about-title light-green-text font-700">Creative Ideas</span></div>
                    <div><span className="about-details light-gray-text">At vero eos et accusamus etiusto odio praesentium.</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="counter-panel text-center">
                  <div className="center mb-20"><span className="white-text counter-count font-900">100</span><span className="white-text pl-10">k</span></div>
                  <div><span className="white-text counter-title font-700">Happy Clients</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="counter-panel text-center">
                  <div className="center mb-20"><span className="white-text counter-count font-900">250</span><span className="white-text pl-10">+</span></div>
                  <div><span className="white-text counter-title font-700">Projects Done</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="counter-panel text-center">
                  <div className="center mb-20"><span className="white-text counter-count font-900">20</span><span className="white-text pl-10">+</span></div>
                  <div><span className="white-text counter-title font-700">Expert People</span></div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="counter-panel text-center">
                  <div className="center mb-20"><span className="white-text counter-count font-900">100</span><span className="white-text pl-10">+</span></div>
                  <div><span className="white-text counter-title font-700">Portfolios</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="expert-section section-padding">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-heading-panel">
                  <h4 className="preheading-text mb-30">OUR EXPERT</h4>
                </div>
              </div>
            </div>
            <div className="row no-gutters align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    <div className="expert-img-wrapper"><img src={ImageAssets.aboutUs.expertImg} alt="expert" className="image-responsive w-100"></img></div>
                  </div>
                  <div className="col-lg-6">
                    <div className="expert-details-panel">
                      <div>
                        <div><span className="white-text font-800 expert-name">Krunal Dave</span></div>
                        <div className="mt-15"><span className="white-text font-700 expert-designation">FOUNDER</span></div>
                      </div>
                      <div><span className="white-text expert-goal-text default-font">Work to Serve. with Goal to Gain Experience and expand Network.</span></div>
                      <div className="expert-social-list d-flex">
                        <div className="mr-50"><a href={UIRoutes.HOME}><img src={ImageAssets.aboutUs.socialIcons.facebookIcon} alt="facebook"></img></a></div>
                        <div className="mr-50"><a href={UIRoutes.HOME}><img src={ImageAssets.aboutUs.socialIcons.twitterIcon} alt="facebook"></img></a></div>
                        <div><a href={UIRoutes.HOME}><img src={ImageAssets.aboutUs.socialIcons.googlePlusIcon} alt="facebook"></img></a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6">
                <div className="expert-description-panel">
                  <p>Krunal is Bookkeeper and active in a member of Accounting Pro-Advisor Team in Canada. He treats every client with respect and integrity. In addition, Krunal provides independent financial analysis as well as tax accounting and accounting services.</p>
                  <p>Krunal has more than 10 years’ experience in public practice. Most of those years were spent practicing in a multi-national accounting firm in the Canadian Tax department. During the years in public practice Krunal has acted as a financial as well as tax advisor for privately held small and medium sized enterprises. His focus throughout his career has always been to advise and assist organizations to save tax dollars. Krunal has spent his entire career working with Canadian income taxes and during the last nine years has specialized in Canadian corporate and personal income taxes.</p>
                  <p>Krunal has been active in the communities. He studied in Algonquin College and was involved with various events hosted by BAPS Canada and local charities in Ottawa.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="choose-us-section section-padding">
          <div className="container-block">
            <div className="row no-gutters">
              <div className="col-lg-5">
                <div className="section-left-panel">
                  <div className="section-heading-panel">
                    <h4 className="preheading-text mb-30">WHY CHOOSE US</h4>
                    <h3 className="heading-text font-800 mt-20">Your Success Is Our Reputation</h3>
                  </div>
                  <div className="feature-list mt-35">
                    <div className="feature-list-item">
                      <div className="mb-15 "><span className="black-text font-800 feature-title">Strong Martket Analysis</span></div>
                      {/* <div><span className="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></div> */}
                    </div>
                    <div className="feature-list-item">
                      <div className="mb-15 "><span className="black-text font-800 feature-title">Experience & Precision</span></div>
                      {/* <div><span className="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></div> */}
                    </div>
                    <div className="feature-list-item">
                      <div className="mb-15 "><span className="black-text font-800 feature-title">Experts About Business</span></div>
                      {/* <div><span className="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="consultation-form-panel">
                  <div><h5 className="font-800 black-text form-title">FREE CONSULTATION</h5></div>
                  <div className="consultation-form">
                    <TextField
                      type='text'
                      parentClassName='form-group'
                      labelText={Labels.ABOUT_US.FULLNAME}
                      labelClassName='default-font black-text'
                      inputDivClassName='input-panel'
                      placeholder={Placeholders.ABOUT_US.NAME}
                      className='form-input'
                      onChange={this.onInputChange(InputIds.NAME)}
                      {...this.state.name}
                    />
                    <TextField
                      type='email'
                      parentClassName='form-group'
                      labelText={Labels.ABOUT_US.EMAIL_ADDRESS}
                      labelClassName='default-font black-text'
                      inputDivClassName='input-panel'
                      placeholder={Placeholders.ABOUT_US.EMAIL}
                      className='form-input'
                      onChange={this.onInputChange(InputIds.EMAIL)}
                      {...this.state.email}
                    />
                    <div className="form-field phonenumber-panel">
                      <div className="number-prefix">+1</div>
                      <TextField
                        type='text'
                        parentClassName='form-group'
                        labelText={Labels.ABOUT_US.CONTACT_NUMBER}
                        labelClassName='default-font black-text'
                        inputDivClassName='input-panel'
                        placeholder={Placeholders.ABOUT_US.CONTACT}
                        className='form-input'
                        onChange={this.onInputChange(InputIds.PHONE)}
                        {...this.state.phone}
                      />
                    </div>
                    <TextField
                      type='text'
                      parentClassName='form-group'
                      labelText={Labels.ABOUT_US.MESSAGE}
                      labelClassName='default-font black-text'
                      inputDivClassName='input-panel'
                      placeholder={Placeholders.ABOUT_US.MESSAGE}
                      className='form-input'
                      onChange={this.onInputChange(InputIds.MESSAGE)}
                      {...this.state.message}
                    />
                    <Button
                      type='submit'
                      className={'btn btn-small btn-primary' + (disabledButton ? '' : ' disabled')}
                      disabled={!disabledButton}
                      text={Messages.SUBMIT}
                      onClick={this.onSubmitClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

AboutUs.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { consultantAction })(AboutUs)