import React, { Component } from 'react'
import validator from 'validator'
import OwlCarousel from 'react-owl-carousel3'
import axios from 'axios'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import ImageAssets from '../../utils/ImageAssets'
import Newsletter from '../common/Newsletter'
import Button from '../common/Button'
import { appConfig } from '../../constants/AppConfig'
import { UIRoutes } from '../../constants/FrontendRoutes'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { InputIds, Messages } from '../../constants/StaticTexts'
import DoYouHaveAccount from '../screen-popup/DoYouHaveAccount'
// import EmailVerification from '../screen-popup/EmailVerification'
import ChooseStatus from '../screen-popup/ChooseStatus'
import { ErrorMessages } from '../../constants/ErrorMessages'
import setLoading from '../../utils/LoadingManager'
import { APIRoutes } from '../../constants/BackendRoutes'
import { getErrorMessage } from '../../utils/GetErrorMessage'
import setAuthToken from '../../interceptors/SetAuthToken'
import store from '../../store'
import { AUTH_LOGOUT, AUTH_SET_USER } from '../../constants/ActionTypes'

const clientReviews = {
  loop: false,
  margin: 20,
  nav: true,
  mouseDrag: false,
  touchDrag: true,
  dots: false,
  autoplay: false,
  smartSpeed: 1500,
  stagePadding: 10,
  autoplayHoverPause: false,
  center: false,
  navText: [],
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 1
    },
    768: {
      items: 2
    },
    992: {
      items: 2
    },
    1199: {
      items: 2
    },
    1280: {
      items: 2
    },
    1440: {
      items: 2,
      center: false
    }
  }
}

const bannerSlider = {
  loop: true,
  margin: 0,
  nav: true,
  mouseDrag: false,
  touchDrag: true,
  dots: false,
  navText: [],
  autoplay: true,
  smartSpeed: 2000,
  responsive: {
    0: {
      items: 1
    },
    1440: {
      items: 1
    }
  }
}

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      haveAccount: false,
      verifyAccount: false,
      haveUseInPast: false,
      email: { value: '', error: null },
      otp: '',
      showOTP: false
    }
  }

  onCancelClick = () => {
    this.setState({
      haveAccount: false,
      verifyAccount: false,
      email: { value: '', error: null },
      otp: '',
      showOTP: false,
      haveUseInPast: false
    })
  }

  onAboutUsClick = () => {
    this.props.history.push(UIRoutes.ABOUTUS)
  }

  onServicesClick = () => {
    this.props.history.push(UIRoutes.SERVICES)
  }

  onStartClick = () => {
    if (this.props.isPartialLogin) {
      this.setState({ haveAccount: true })
    } else {
      this.props.history.push(UIRoutes.TAX_APPLICATION)
    }
  }

  onHaveAccountNoClick = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    store.dispatch({ type: AUTH_LOGOUT })
    this.props.history.push(UIRoutes.REGISTER)
  }

  onHaveAccountYesClick = () => {
    this.setState({ haveAccount: false, verifyAccount: true })
    this.props.history.push(UIRoutes.LOGIN)
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) || validator.isMobilePhone(event.target.value, 'en-CA') ? null : ErrorMessages.EMAIL_OR_PHONE_NOT_VALID
          },
          showOTP: false,
          otp: ''
        })
      } else {
        this.setState({
          email: {
            value: '',
            error: null
          },
          showOTP: false,
          otp: ''
        })
      }
    }
  }

  onGetOTPClick = () => {
    let requestObject = {
      emailOrPhone: validator.isNumeric(this.state.email.value) ? (appConfig.MOBILE_CODE + ' ' + this.state.email.value) : this.state.email.value
    }
    setLoading(true)
    axios.post(APIRoutes.AUTH.PARTIALLY_LOGIN_OTP, requestObject)
      .then(res => {
        setLoading(false)
        this.setState({ showOTP: true, otp: '' })
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  }

  onOTPChange = (otpValue) => {
    this.setState({ otp: otpValue })
  }

  onOTPSubmit = () => {
    let requestObject = {
      emailOrPhone: validator.isNumeric(this.state.email.value) ? (appConfig.MOBILE_CODE + ' ' + this.state.email.value) : this.state.email.value,
      otp: this.state.otp
    }
    setLoading(true)
    axios.post(APIRoutes.AUTH.PATIALLY_LOGIN, requestObject)
      .then(res => {
        setLoading(false)
        this.setState({ verifyAccount: false, showOTP: false, haveUseInPast: true })
        let { token, refreshToken } = res.data.result
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        setAuthToken(token)
        store.dispatch({
          type: AUTH_SET_USER,
          payload: res.data.result
        })
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  }

  onUsedClick = () => {
    this.props.history.push(UIRoutes.VIEW_ALL_APPLICATION)
  }

  onNewClick = () => {
    this.setState({ haveUseInPast: false })
    this.props.history.push(UIRoutes.TAX_APPLICATION)
  }

  render() {
    return (
      <div>
        <Header page={Messages.HOME} />
        <div className="banner-section">
          {/* <div className="lotus-pattern-panel"><img src={ImageAssets.home.lotusImg} alt="lotus" className="image-responsive w-100"></img></div> */}
          <div className="banner-wave-panel"><img src={ImageAssets.home.waveImg} alt="wave-img" className="image-responsive w-100"></img></div>
          <div className="row align-items-end no-gutters">
            <div className="col-lg-12">
              <OwlCarousel
                className="banner-carousel owl-carousel owl-theme"
                {...bannerSlider}
              >
                <div className="banner-slider-img-block">
                  <div className="h-100 banner-img-block">
                    <img src={ImageAssets.home.bannerSliderImages.sliderImg1} alt="slider-img" className="image-responsive" />
                  </div>
                  <div className="slider-description-panel">
                    <div className="row">
                      <div className="col-lg-6">
                      </div>
                      <div className="col-lg-6">
                        <div className="banner-description">
                          <h1 className="font-900 banner-heading">{Messages.HOME_PAGE.ACCOUNTING_AND_TAX}</h1>
                          <div className="banner-subheading-panel">
                            <div>
                              <p className="large-font mt-30 mb-30 banner-subheading">{appConfig.HOME_BANNER}</p>
                            </div>
                            <Button
                              type='button'
                              className='btn btn-large btn-primary'
                              text={Messages.GET_STARTED}
                              onClick={this.onStartClick}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-slider-img-block price-plan-slider-imgblock">
                  <div className="h-100 banner-img-block center">
                    <img src={ImageAssets.home.bannerSliderImages.sliderImg4} alt="slider-img" className="image-responsive h-auto m-auto package-img" onClick={this.onStartClick} />
                  </div>
                  <div className="slider-description-panel left-description-panel">
                    <div className="row">
                      <div className="col-lg-6">
                        {/* <div className="banner-description">
                          <h1 className="font-900 banner-heading">Accurate, easy, secure.</h1>
                          <h3 className="large-font light-green-text slider-subheading font-700">File with confidence your return is done right.</h3>
                          <div className="banner-subheading-panel">
                            <div className="banner-feature-list mt-30 mb-50">
                              <div className="banner-feature-list-item"><span className="large-font">Easy to use</span></div>
                              <div className="banner-feature-list-item"><span className="large-font">Maximum Refund</span></div>
                              <div className="banner-feature-list-item"><span className="large-font">Secure & Private</span></div>
                            </div>
                            <Button
                              type='button'
                              className='btn btn-large btn-primary'
                              text={Messages.GET_STARTED}
                              onClick={this.onStartClick}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-6">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-slider-img-block">
                  <div className="h-100 banner-img-block">
                    <img src={ImageAssets.home.bannerSliderImages.sliderImg3} alt="slider-img" className="image-responsive" />
                  </div>
                  <div className="slider-description-panel left-description-panel last-description-panel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="banner-description">
                          <h1 className="font-900 banner-heading mb-25">File your tax return today</h1>
                          <h3 className="large-font light-green-text slider-subheading font-700">This year, enjoy doing your taxes. Seriously.</h3>
                          <div className="banner-subheading-panel mt-50">
                            <Button
                              type='button'
                              className='btn btn-large btn-primary'
                              text={Messages.GET_STARTED}
                              onClick={this.onStartClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* <div className="banner-mobile-section d-lg-none d-md-block">
          <div className="banner-description">
            <h1 className="white-text font-900 banner-heading">{Messages.HOME_PAGE.ACCOUNTING_AND_TAX}</h1>
            <div className="banner-subheading-panel">
              <div>
                <p className="white-text large-font mt-30 mb-30 banner-subheading">{appConfig.HOME_BANNER}</p>
              </div>
              <Button
                type='button'
                className='btn btn-large btn-primary'
                text={Messages.GET_STARTED}
                onClick={this.onStartClick}
              />
            </div>
          </div>
          <OwlCarousel
            className="banner-carousel owl-carousel owl-theme"
            {...bannerSlider}
          >
            <div className="banner-slider-img-block">
              <div className="h-100"><img src={ImageAssets.home.bannerSliderImages.sliderImg1} alt="slider-img" className="image-responsive"></img></div>
            </div>
            <div className="banner-slider-img-block">
              <div className="h-100"><img src={ImageAssets.home.bannerSliderImages.sliderImg2} alt="slider-img" className="image-responsive"></img></div>
            </div>
            <div className="banner-slider-img-block">
              <div className="h-100"><img src={ImageAssets.home.bannerSliderImages.sliderImg3} alt="slider-img" className="image-responsive"></img></div>
            </div>
          </OwlCarousel>
        </div> */}
        <div className="about-us-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading-panel display-lg-block text-center">
                  <h4 className="preheading-text mb-20">{Messages.HOME_PAGE.WHO_WE_ARE}</h4>
                  <h3 className="heading-text font-800">{Messages.HOME_PAGE.LEAD_COMPANY}</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img src={ImageAssets.home.aboutUsImg} alt="aboutImg" className="image-responsive m-auto d-block"></img>
              </div>
              <div className="col-lg-6">
                <div className="section-heading-panel display-lg-none">
                  <h4 className="preheading-text mb-20">{Messages.HOME_PAGE.WHO_WE_ARE}</h4>
                  <h3 className="heading-text font-800">{Messages.HOME_PAGE.LEAD_COMPANY}</h3>
                </div>
                <div className="mt-20 mr-60 about-us-descritpion">
                  <p className="description-text default-font">{appConfig.HOME_SECOND_BANNER_1}</p>
                  <p className="description-text default-font">{appConfig.HOME_SECOND_BANNER_2}</p>
                </div>
                <Button
                  type='button'
                  className='btn btn-medium btn-primary mt-20'
                  text={Messages.READ_MORE}
                  onClick={this.onAboutUsClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="services-section section-padding">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-heading-panel center justify-content-between mb-30 flex-wrap">
                  <div className="section-heading-panel">
                    <h4 className="preheading-text">{Messages.HOME_PAGE.WHAT_WE_OFFER}</h4>
                    <h3 className="heading-text font-800 mt-20">{Messages.HOME_PAGE.OUR_SERVICES}</h3>
                  </div>
                  <Button
                    type='button'
                    className='btn btn-medium btn-primary'
                    text={Messages.EXPLORE_MORE}
                    onClick={this.onServicesClick}
                  />
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-6">
                <div className="mr-8">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div className="service-panel mr-8">
                        <img src={ImageAssets.home.serviceImages.accountingServiceImg} alt="service" className="image-responsive w-100"></img>
                        <div className="service-details">
                          <div><span className="service-title white-text font-800">Accounting</span></div>
                          <div className="service-description-panel mt-20">
                            <div>
                              <p className="white-text service-desc-text">
                                Providing financial information to our clients in a timely, well-organized and accurate manner is a commitment that we feel cannot be compromised.
                              </p>
                            </div>
                            <div>
                              <a href={UIRoutes.HOME} className="font-800 light-green-text link-text">
                                VIEW MORE</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="service-panel ml-8">
                        <img src={ImageAssets.home.serviceImages.taxPlanServiceImg} alt="service" className="image-responsive w-100"></img>
                        <div className="service-details">
                          <div><span className="service-title white-text font-800">Tax Planning</span></div>
                          <div className="service-description-panel mt-20">
                            <div>
                              <p className="white-text service-desc-text">By tracking on new tax laws and legislation, we are able to identify key tax planning opportunities that minimize both your current and future tax liabilities.</p>
                            </div>
                            <div><a href={UIRoutes.HOME} className="font-800 light-green-text link-text">VIEW MORE </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-panel">
                        <img src={ImageAssets.home.serviceImages.miscellaneousServiceImg} alt="service" className="image-responsive w-100"></img>
                        <div className="service-details">
                          <div><span className="service-title white-text font-800">Miscellaneous</span></div>
                          <div className="service-description-panel mt-20">
                            <div>
                              <p className="white-text service-desc-text">We feel our ability to provide such a variety of services reflects our versatility and competency, as well as our dedication to quality client service.</p>
                            </div>
                            <div><a href={UIRoutes.HOME} className="font-800 light-green-text link-text">VIEW MORE </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-panel ml-8">
                  <img src={ImageAssets.home.serviceImages.consultingServiceImg} alt="service" className="image-responsive w-100"></img>
                  <div className="service-details">
                    <div><span className="service-title white-text font-800">Consulting</span></div>
                    <div className="service-description-panel mt-20">
                      <div>
                        <p className="white-text service-desc-text">We can help your business identify areas negatively affecting profitability and growth and develop solutions that are practical and technically sound.</p>
                      </div>
                      <div><a href={UIRoutes.HOME} className="font-800 light-green-text link-text">VIEW MORE </a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-section section-padding">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading-panel text-right">
                  <h4 className="preheading-text">TESTIMONIALS</h4>
                  <h3 className="heading-text font-800 mt-20 ml-auto">+500 Clients Love Us</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <OwlCarousel
                  className="client-reviews-carousel owl-carousel owl-theme"
                  {...clientReviews}
                >
                  {
                    appConfig.TESTIMONIALS.map((eachTestimonial, index) => {
                      return (
                        <div className="client-review-panel" key={index}>
                          <div className="review-panel">
                            <p className="client-review-text font-600">{eachTestimonial.DESCRIPTION}</p>
                          </div>
                          <div className="client-info d-flex align-items-center mt-50">
                            <div className="client-img-panel center mr-25">
                              <div className="client-name-char">
                                <span className="white-text large-font font-600">{eachTestimonial.USER_CHAR}</span>
                              </div>
                              {/* <img src={ImageAssets.home.clientReview.clientProfileImg} alt="profileImg" className="image-responsive"></img> */}
                            </div>
                            <div className="client-detail-panel">
                              <div><span className="client-name default-font font-600">{eachTestimonial.NAME}</span></div>
                              {/* <div className="mt-5"><span className="client-role small-font">{eachTestimonial.DESIGNATION}</span></div> */}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
        <DoYouHaveAccount
          open={this.state.haveAccount}
          onNoClick={this.onHaveAccountNoClick}
          onYesClick={this.onHaveAccountYesClick}
          onCancelClick={this.onCancelClick}
        />
        {/* <EmailVerification
          open={this.state.verifyAccount}
          email={this.state.email}
          showOTP={this.state.showOTP}
          onInputChange={this.onInputChange}
          onGetOTPClick={this.onGetOTPClick}
          onOTPChange={this.onOTPChange}
          onOTPSubmit={this.onOTPSubmit}
          onCancelClick={this.onCancelClick}
          otp={this.state.otp}
        /> */}
        <ChooseStatus
          open={this.state.haveUseInPast}
          onCancelClick={this.onCancelClick}
          onUsedClick={this.onUsedClick}
          onNewClick={this.onNewClick}
        />
        <Footer />
      </div>
    )
  }
}

Home.propTypes = {}

const mapStateToProps = (state) => ({
  isPartialLogin: state.auth.partialLogin
})

export default connect(mapStateToProps, {})(Home)