import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImageAssets from '../../utils/ImageAssets'

class GlobalLoading extends Component {
  render() {
    if (!this.props.loading) { return null }

    return (
      <div className='text-center loadWrapper d-flex justify-content-center align-items-center flex-column loadingImage'>
        <span className='loader'>
          <img alt='Loading' src={ImageAssets.loading} className='rotate-center' />
        </span>
      </div>
    )
  }
}

GlobalLoading.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = (state) => ({
  loading: state.loading.isLoading
})

export default connect(mapStateToProps)(GlobalLoading)