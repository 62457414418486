import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { appConfig } from '../../constants/AppConfig'
import Footer from '../common/Footer'
import FormHeader from '../common/FormHeader'
import { getAllApplications } from '../../actions/applicationActions'
import { getStatusClassNames } from '../../utils/Utils'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { Labels, Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import Button from '../common/Button'

class ViewApplication extends Component {

  componentDidMount() {
    let requestBody = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortDirection: 'ASC',
      isPagination: false
    }
    this.props.getAllApplications(this.props.userId, requestBody)
  }

  onApplicationClick = (applicationId) => () => {
    this.props.history.push(UIRoutes.APPLICATION + applicationId)
  }

  onButtonClick = () => {
    this.props.history.push(UIRoutes.TAX_APPLICATION)
  }

  render() {
    return (
      <div>
        <FormHeader />
        <div className="view-application-page">
          <div className="container-block">
            <div className="center justify-content-between applicatant-info-panel flex-wrap">
              <div className="applicant-Name">
                <span className="large-font light-gray-text font-600 pr-40">{Messages.NAME}</span>
                <span className="large-font black-text font-600">{this.props.firstName + ' ' + this.props.lastName}</span>
              </div>
              <div>
                <Button
                  type='submit'
                  className='btn btn-medium btn-primary btn-create-application'
                  text={Messages.CREATE_APPLICATION}
                  /* onClick={this.onButtonClick} */
                  onClick={() => window.open('https://app.financebliss.ca', '_self')}
                />
              </div>
            </div>

            {
              this.props.applicationList.list.length
                ?
                <div className="application-container">
                  <div className="row">
                    {
                      this.props.applicationList.list.map((eachApplication, index) => {
                        return (
                          <div className="col-xl-4 col-md-6" key={index}>
                            <div className="application-info-block" onClick={this.onApplicationClick(eachApplication._id)}>
                              {/* <div className="notification-info"><span className="white-text font-700">i</span></div> */}
                              <div className="row no-gutters">
                                <div className="col-xl-8 col-sm-9">
                                  <div className="left-panel">
                                    <div className="data-block">
                                      <div className="application-info-header">
                                        <span className="application-info-header-text font-600">{Labels.APPLICATION_ID}</span>
                                      </div>
                                      <div className="application-data-panel">
                                        <span className="application-data font-600">{eachApplication.applicationId}</span>
                                      </div>
                                    </div>
                                    <div className="data-block mt-30">
                                      <div className="application-info-header">
                                        <span className="application-info-header-text font-600">{Labels.APPLICATION_DATE}</span>
                                      </div>
                                      <div className="application-data-panel">
                                        <span className="application-data font-600">{moment.utc(eachApplication.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-sm-3">
                                  <div className={"application-status-panel " + getStatusClassNames(eachApplication.applicationStatus)}>
                                    <span className="application-status font-700">{eachApplication.applicationStatus}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                :
                <div className="no-data-panel center">
                  <div><img src={ImageAssets.common.noDataImg} alt="nodata"></img></div>
                  <div className="mt-20"><span className="large-font font-600">No Application Found!</span></div>
                </div>
            }
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

ViewApplication.propTypes = {}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  applicationList: state.list.applicationList
})

export default connect(mapStateToProps, { getAllApplications })(ViewApplication)
