import React, { Component } from 'react'
// import { Link } from "react-router-dom";  
// import { Messages } from '../../constants/StaticTexts';
import { acceptInformativeMessage } from '../../actions/authActions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from './Button'
import { connect } from 'react-redux';
import ImageAssets from '../../utils/ImageAssets';

class AcceptCookie extends Component {


  constructor(props) {
    super(props)
    this.state = {
      privacyPolicy: true,
      isChecked: false
    }
  }

  handleButtonClick = () => {
    localStorage.setItem("acceptPrivacy", JSON.stringify(true));
    this.onClose();
  }
  onClose = () => {
    this.setState({
      privacyPolicy: false
    })
  }
  onEnterManualClick = (event) => {
    this.setState({ isChecked: event.target.checked })
  }
  render() {
    return (
      <>
        {
          localStorage.acceptPrivacy
            ?
            null
            :
            <Dialog aria-labelledby="customized-dialog-title" open={this.state.privacyPolicy}>
              <DialogTitle id="customized-dialog-title">
                <div className="d-flex justify-content-end cancel-btn">
                  <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.onClose} />
                </div>
              </DialogTitle>
              <DialogContent dividers>
                <section id="privcyMessage" className="cookie-message pt-30 pb-30">
                  <div id="cookieConsent">
                    <p className="small-font">Bliss Accounting provides accounting, tax and consulting services to both individuals and businesses. We give the same attention to all clients, no matter how large or small. The accounting, tax preparation, and other financial services we provide are tailored to the unique needs of each client, ensuring the best possible results and maximized returns. Our firm remains on the cutting edge of tax law and financial trends. We are well-informed and continually adapt to the ever-changing world of tax law and accounting.</p>
                    <p className="small-font">This initiative is lead by Bliss Accounting makes every effort to ensure that the
                    information contained in this system is accurate. Links from this system to internal pages and/or
                    external web sites are believed to be relevant and up-to-date however we do not accept any
                    responsibility for information contained on external web sites.
                    </p>
                    <p className="small-font">All trademarks, brands, logo or other listed or used on this platform belongs to the
                respective organization or company.</p>
                  </div>
                  <div className="custom-checkbox mt-35">
                    <label>
                      <input type="checkbox" name="check" className="custom-control-input form-control" onChange={this.onEnterManualClick} />
                      <span className="checkbox-span"></span> Don't show me again
                    </label>
                  </div>
                </section>
                <div className="center pb-30">
                  <div className="mr-10">
                    <Button
                      type='submit'
                      className={'btn btn-primary btn-small btn-acknowledge'}
                      text='I acknowledge'
                      disabled={this.state.isChecked ? false : true}
                      onClick={this.handleButtonClick}
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
        }
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  addressList: state.address.addressList
})

export default connect(mapStateToProps, { acceptInformativeMessage })(AcceptCookie)