import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { GET_SUGGESTED_ADDRESS } from '../constants/ActionTypes'

export const getAddressList = (searchTerm) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.CANADA_POST.GET + searchTerm + process.env.REACT_APP_CANADA_POST_PARAMS)
    .then(res => {
      dispatch({
        type: GET_SUGGESTED_ADDRESS,
        payload: res.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}
