import './component/css/bootstrap.min.css'
import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import RedirectProfileRoute from './interceptors/RedirectProfileRoute'
import PrivateRoute from './interceptors/PrivateRoute'
import OwnerAdminPrivateRoute from './interceptors/OwnerAdminPrivateRoute'
import ManagementPrivateRoute from './interceptors/ManagementPrivateRoute'
import UserPrivateRoute from './interceptors/UserPrivateRoute'
import ScrollToTop from './interceptors/ScrollToTop'
import './interceptors/RefreshToken'
import setAuthToken from './interceptors/SetAuthToken'
import store from './store'
import './component/scss/_styles.scss'
import { logoutUser, setUser } from './actions/authActions'
import GlobalLoading from './component/common/GlobalLoading'
import { UIRoutes } from './constants/FrontendRoutes'
import Login from './component/users/Login'
import Home from './component/home/Home'
// import Register from './component/users/Register'
import ViewApplication from './component/view-application/ViewApplication'
import PageNotFound from './component/common/PageNotFound'
import AdminApplicationsPanel from './component/admin-panel/AdminApplicationsPanel'
import TaxApplication from './component/application/TaxApplication'
import PaymentPlans from './component/payment-plans/PaymentPlans'
import ApplicationView from './component/application-view/ApplicationView'
import StripePayment from './component/stripe-payment/StripePayment'
import ForgotPassword from './component/users/ForgotPassword'
import SetNewPassword from './component/users/SetNewPassword'
import Tax from './component/tax/Tax'
import AboutUs from './component/about-us/AboutUs'
import Services from './component/services/Services'
import Resources from './component/resources/Resources'
import Contactus from './component/contact-us/Contactus'
import AdminUsersPanel from './component/admin-users-panel/AdminUsersPanel'
import AdminSubscription from './component/admin-subscription/AdminSubscription'
import { getDocumentTypesOnRefresh } from './actions/documentTypesActions'
import { appConfig } from './constants/AppConfig'
import CreatePassword from './component/users/CreatePassword'
import AdminReferences from './component/admin-references/AdminReferences'
import MyProfile from './component/my-profile/MyProfile'

// get logged in user details
if (localStorage.token) {
  var token = localStorage.token
  let tokenData = jwt_decode(token)
  let currentTime = Date.now() / 1000
  if (tokenData.exp < currentTime) {
    store.dispatch(logoutUser())
    // Redirect to login
    window.location.href = UIRoutes.LOGIN
  } else {
    setAuthToken(token)
    let authData = {
      _id: tokenData.id,
      isAuthenticated: true,
      email: tokenData.email,
      firstName: tokenData.firstName,
      lastName: tokenData.lastName,
      role: tokenData.role,
      partialLogin: tokenData.partialLogin
    }
    let requestObject = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortHeader: appConfig.DEFAULT_SORTING_FIELD,
      sortDirection: appConfig.DEFAULT_SORTING,
      isPagination: false
    }
    getDocumentTypesOnRefresh(authData._id, requestObject, store.dispatch)
    setUser(authData, store.dispatch)
  }
}

// display toast message position
toast.configure({
  autoClose: 5000,
  position: 'bottom-right',
  hideProgressBar: true,
  pauseOnFocusLoss: false
})

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <Router>
            <ScrollToTop>
              <Switch>
                <Route exact path={UIRoutes.HOME} component={Home} />
                <Route exact path={UIRoutes.TAX} component={Tax} />
                <Route exact path={UIRoutes.ABOUTUS} component={AboutUs} />
                <Route exact path={UIRoutes.SERVICES} component={Services} />
                <Route exact path={UIRoutes.RESOURCES} component={Resources} />
                <Route exact path={UIRoutes.CONTACTUS} component={Contactus} />
                <RedirectProfileRoute exact path={UIRoutes.LOGIN} component={Login} />
                <RedirectProfileRoute exact path={UIRoutes.REGISTER} component={() => { window.location = 'https://app.financebliss.ca'; return null; }} /* component={ <Register/>} */ />
                <RedirectProfileRoute exact path={UIRoutes.FORGOT_PASSWORD} component={ForgotPassword} />
                <RedirectProfileRoute exact path={UIRoutes.CREATE_PASSWORD} component={CreatePassword} />
                <RedirectProfileRoute exact path={UIRoutes.SET_PASSWORD} component={SetNewPassword} />
                <Route exact path={UIRoutes.TAX_APPLICATION} component={() => { window.location = 'https://app.financebliss.ca'; return null; }} /* component={TaxApplication} */ />
                <PrivateRoute exact path={UIRoutes.EDIT_TAX_APPLICATION} component={TaxApplication} isEditing={true} />
                <UserPrivateRoute exact path={UIRoutes.PAYMENT_PLANS_ROUTE} component={PaymentPlans} />
                <Route exact path={UIRoutes.STRIPE_PAYMENT} component={StripePayment} />
                <UserPrivateRoute exact path={UIRoutes.VIEW_ALL_APPLICATION} component={ViewApplication} />
                <PrivateRoute exact path={UIRoutes.VIEW_MY_PROFILE} component={MyProfile} />
                {/* <OwnerAdminPrivateRoute exact path={UIRoutes.VIEW_MY_PROFILE} component={MyProfile} /> */}
                <ManagementPrivateRoute exact path={UIRoutes.APPLICATIONS} component={AdminApplicationsPanel} />
                <OwnerAdminPrivateRoute exact path={UIRoutes.USERS} component={AdminUsersPanel} />
                <OwnerAdminPrivateRoute exact path={UIRoutes.SUBSCRIPTION} component={AdminSubscription} />
                <OwnerAdminPrivateRoute exact path={UIRoutes.REFRENCES} component={AdminReferences} />
                <PrivateRoute exact path={UIRoutes.APPLICATION_ROUTE} component={ApplicationView} />
                <Route component={PageNotFound} />
              </Switch>
              <GlobalLoading />
            </ScrollToTop>
          </Router>
        </LocalizationProvider>
      </Provider>
    )
  }
}
if (process.env.NODE_ENV === "production")
  console.log = function no_console() { };
export default App
