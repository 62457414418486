import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { onRefreshTokenExpire } from '../actions/authActions'
import { APIRoutes } from '../constants/BackendRoutes'
import store from '../store'
import setAuthToken from './SetAuthToken'

axios.interceptors.response.use(null, (error) => {
  if (
    error.config &&
    error.response?.status === 401 &&
    !error.config.__isRetry
  ) {
    return new Promise((resolve, reject) => {
      refreshToken(axios, error.config)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  return Promise.reject(error)
})

const refreshToken = (axios, config) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.token
    let refreshToken = localStorage.refreshToken
    let tokenData = jwt_decode(token)
    axios.get(APIRoutes.USER.REFRESH_TOKEN + tokenData.id + '/refreshToken/' + refreshToken)
      .then((res) => {
        let { token, refreshToken } = res.data.result
        setAuthToken(token)
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        config.headers.Authorization = token
        axios
          .request(config)
          .then((result) => {
            return resolve(result)
          })
          .catch((err) => {
            return reject(err)
          })
      }).catch((err) => {
        if (err.response.status === 403) {
          onRefreshTokenExpire(store.dispatch)
        }
      })
  })
}