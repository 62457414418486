import { GET_APPLICATION_COUNTERS } from '../constants/ActionTypes'
import { Messages } from '../constants/StaticTexts'

const initialState = {
  paidApplications: 0,
  unpaidApplications: 0,
  partiallyPaidApplications: 0,
  newApplications: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATION_COUNTERS:
      let paidApplications = 0
      let unpaidApplications = 0
      let partiallyPaidApplications = 0
      let newApplications = 0
      for (let eachType of action.payload.paymentStatus) {
        if (eachType.name === Messages.PAID) { paidApplications = eachType.count }
        if (eachType.name === Messages.UNPAID) { unpaidApplications = eachType.count }
        if (eachType.name === Messages.PARTIALLY_PAID) { partiallyPaidApplications = eachType.count }
      }
      for (let eachType of action.payload.applicationsStatus) {
        if (eachType.name === Messages.NEW) { newApplications = eachType.count }
      }
      return {
        ...state,
        paidApplications: paidApplications,
        unpaidApplications: unpaidApplications,
        partiallyPaidApplications: partiallyPaidApplications,
        newApplications: newApplications
      }
    default:
      return state
  }
}