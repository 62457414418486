import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageAssets from '../../utils/ImageAssets'

class TextField extends Component {

  render() {
    return (
      <div className={this.props.parentClassName}>
        {
          this.props.labelText
            ?
            <div className={this.props.labelDivClassName}>
              <label className={this.props.labelClassName}>
                {this.props.labelText}
              </label>
            </div>
            :
            null
        }
        <div className={this.props.inputDivClassName}>
          <div className="input-block">
            <input
              type={this.props.type}
              placeholder={this.props.placeholder}
              id={this.props.id}
              className={this.props.className}
              value={this.props.value}
              onChange={this.props.onChange({ 'id': this.props.id })}
              autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
              onKeyDown={this.props.onKeyDown}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onKeyPress={this.props.onKeyPress}
              disabled={this.props.disabled}
              maxLength={this.props.maxLength}
            />
          </div>
          {
            this.props.tooltipText
              ?
              <div className="tootltip-container ml-20 mt-10">
                <div className="tootltip-icon">
                  <img src={ImageAssets.formsvg.tooltipIcon} alt="tooltip-icon"></img>
                  <div className="tooltip-text">
                    <span className="tooltip-text-panel">
                      {this.props.tooltipText}
                    </span>
                  </div>
                </div>
              </div>
              :
              null
          }
        </div>
        {
          !this.props.email
          ?
          null
          :
          <div className='badge badge-danger'>{this.props.email.error}</div>
        }
        {
          !this.props.phone
          ?
          null
          :
          <div className='badge badge-danger'>{this.props.phone.error}</div>
        }
        {
          !this.props.error
            ?
            null
            :
            <div className='badge badge-danger'>{this.props.error}</div>
        }
      </div>
    )
  }
}

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default TextField