import React, { Component } from 'react'
import Button from '../common/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ImageAssets from '../../utils/ImageAssets'
import { Messages } from '../../constants/StaticTexts'

class DoYouHaveAccount extends Component {

  render() {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={this.props.open}>
        <DialogTitle id="customized-dialog-title">
          <div className="d-flex justify-content-end cancel-btn">
            <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.props.onCancelClick} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row no-gutters">
            <div className="col-lg-8 col-sm-6">
              <div className="title-panel">
                <span className="title-panel-text font-700">
                  Do you have an account with Bliss ?
                </span>
              </div>
              <div className="button-panel">
                <Button
                  type='submit'
                  parentClassName='no-btn'
                  className='btn btn-primary btn-small'
                  text={Messages.NO}
                  // onClick={this.props.onNoClick}
                  onClick={() => window.open("https://app.financebliss.ca", '_self')}
                />
                <Button
                  type='submit'
                  parentClassName='yes-btn'
                  className='btn btn-primary btn-small'
                  text={Messages.YES}
                  onClick={this.props.onYesClick}
                // onClick={() => window.open("https://app.financebliss.ca", '_self')}
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex justify-content-end right-image-block">
                <img src={ImageAssets.popup.haveAccount} alt="have-account" className="image-responsive mr-0"></img>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

DoYouHaveAccount.propTypes = {}

export default DoYouHaveAccount
