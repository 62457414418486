import { ADMIN_EDIT_APPLICATION, APPLICATION_UPDATE, CHANGE_STEP, DELETE_DOCUMENT, EDIT_APPLICATION, GET_SINGLE_APPLICATION, REMOVE_APPLICATION_SETUP , GET_REFRANCES_LIST } from '../constants/ActionTypes'
import moment from 'moment'
import findIndex from 'lodash/findIndex'

let defaultApplicationSetup = {
  currentStep: 0,
  step: 0,
  userId: '',
  applicationId: '',
  // step 1 details
  hasReturnBefore: '',
  filingReturnFirstTime: '',
  dateOfLanding: moment().utc().subtract(1, 'year'),
  taxYears: '',
  firstname: '',
  lastname: '',
  sin: '',
  dateOfBirth: moment().subtract(18, 'years'),
  unit: '',
  streetNumber: '',
  streetName: '',
  province: '',
  city: '',
  postalCode: '',
  email: '',
  phone: '',
  // step 2 details
  maritalStatus: '',
  dateOfMarriage: null,
  spouseFirstname: '',
  spouseLastname: '',
  spouseHasFilledReturnBefore: '',
  spouseFilingReturnFirstTime: '',
  spouseDateOfLanding: moment().utc().subtract(1, 'year'),
  spouseTaxYear: '',
  spouseSIN: '',
  spouseDateOfBirth: moment().utc().subtract(18, 'years'),
  spouseEmail: '',
  spouseContactNumber: '',
  hasDependants: '',
  dependantsNumber: '',
  dependants: [],
  // step 3 details
  hasMove: '',
  provinceTerritory: '',
  dateOfMove: moment().utc().subtract(1, 'year'),
  sameHomeAddress: '',
  currentlyLive: '',
  immigrationStatus: '',
  // step 4 details
  CRAAccount: '',
  rentTaxAmount: '',
  isInvesting: '',
  donationAmount: '',
  isMedicalExpenses: '',
  // step 5 details
  transitInformation: '',
  institutionInformation: '',
  accountInformation: '',
  documents: [],
  deletedDocuments: [],
  notes: '',
  referenceDetails: {
    email: '',
    name : ''
  }
}

const initialState = {
  applicationSetup: defaultApplicationSetup,
  singleApplication: { documents: [], paymentTransactions: [], dependants: [] },
  refrancesList :  []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case APPLICATION_UPDATE:
      return {
        ...state,
        applicationSetup: {
          ...state.applicationSetup,
          ...action.payload
        }
      }
    case GET_SINGLE_APPLICATION:
      return {
        ...state,
        singleApplication: action.payload
      }
    case EDIT_APPLICATION:
      let newApplicationData = {
        ...action.payload,
        step: action.step,
        currentStep: action.currentStep
      }
      return {
        ...state,
        applicationSetup: newApplicationData
      }
    case ADMIN_EDIT_APPLICATION:
      return {
        ...state,
        applicationSetup: {
          ...action.payload,
          step: 4,
          currentStep: 0
        }
      }
    case REMOVE_APPLICATION_SETUP:
      return {
        ...state,
        applicationSetup: defaultApplicationSetup
      }
    case CHANGE_STEP:
      return {
        ...state,
        applicationSetup: {
          ...state.applicationSetup,
          ...action.payload
        }
      }
    case DELETE_DOCUMENT:
      let allDocuments = state.singleApplication.documents
      let index = findIndex(allDocuments, { fileName: action.payload })
      allDocuments.splice(index, 1)
      return {
        ...state,
        singleApplication: {
          ...state.singleApplication,
          documents: allDocuments
        }
      }
    case GET_REFRANCES_LIST  :
     return {
       ...state,
       refrancesList : action.payload
     }
    default:
      return state
  }
}