import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ImageAssets from '../../utils/ImageAssets'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { appConfig } from '../../constants/AppConfig'
import { usersListAction, deleteUserAction, importUserAction, updateUserRole } from '../../actions/userActions'
import { InputIds, Messages, Placeholders } from '../../constants/StaticTexts'
// import Button from '../common/Button'
import DownloadExcel from '../common/DownloadExcel'
import store from '../../store'
import { RESET_INVALID_MEMBERS_LIST } from '../../constants/ActionTypes'
import TextField from '../common/TextField'
import CustomDropDown from '../common/CustomDropDown'
import AssignRolePopup from '../screen-popup/AssignRolePopup'
import Pagination from 'react-js-pagination'
import { UIRoutes } from '../../constants/FrontendRoutes'
import MultiSelect from 'react-multi-select-component'

class AdminUsersPanel extends Component {

  constructor(props) {
    super(props)
    let userFilterObj = JSON.parse(localStorage.getItem('userfilterObj'))

    this.state = {
      page: userFilterObj && userFilterObj.pageNo ? userFilterObj.pageNo : 1,
      limit: userFilterObj && userFilterObj.limit ? userFilterObj.limit : appConfig.DEFAULT_PAGINATION_OPTIONS[0],
      selected: null,
      search: userFilterObj && userFilterObj.filters.search ? userFilterObj.filters.search : '',
      filterBy: userFilterObj && userFilterObj.filterBy ? userFilterObj.filterBy : appConfig.TIME_FILTER[4],
      showAssignPopup: false,
      document: {
        type: '',
        file: '',
        fileType: '',
        fileSize: '',
        fileName: '',
      },
      currentUserId: '',
      role: '',
      sortDirection: userFilterObj && userFilterObj.sortingOrder ? userFilterObj.sortingOrder : appConfig.DEFAULT_SORTING,
      sortHeader: userFilterObj && userFilterObj.sortingName ? userFilterObj.sortingName : appConfig.DEFAULT_SORTING_FIELD,
      selectedUserType: userFilterObj && userFilterObj.selectedUserType ? userFilterObj.selectedUserType : []
    }
  }

  componentDidMount() {
    let requestBody = {
      limit: this.state.limit,
      page: this.state.page,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.usersListAction(this.props.userId, requestBody)
  }

  getUserTypeList = () => {
    let allUserTypes = []
    Object.values(appConfig.ASSIGNMENT_ROLE).forEach((eachType, index) => {
      allUserTypes.push({ label: eachType, value: eachType, id: (index + 1) })
    })
    return allUserTypes;
  }

  onUserTypeChange = (selectedValues) => {
    let selectedUserType = []
    let userTypeItem = []
    selectedValues.forEach((eachApplicationStatus) => {
      selectedUserType.push(eachApplicationStatus.value)
      userTypeItem.push(eachApplicationStatus)
    })
    let requestBody = {
      limit: this.state.limit,
      page: this.state.page,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: "" },
        { columnName: [InputIds.REQUEST_BODY_FIELDS.ROLE], type: InputIds.REQUEST_BODY_FIELDS.FILTERNAME, value: selectedUserType }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.usersListAction(this.props.userId, requestBody)
    this.setState({ selectedUserType: userTypeItem, page: 1 },  () => this.storeFilter())
  }

  onPageNumberClick = (pageNumber) => {
    let page
    if (pageNumber === InputIds.NEXT) {
      page = this.state.page + 1
    } else if (pageNumber === InputIds.PREVIOUS) {
      page = this.state.page - 1
    } else {
      page = pageNumber
    }
    let requestBody = {
      page: page,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.setState({ page: page }, () => this.storeFilter())
    this.props.usersListAction(this.props.userId, requestBody)
  }

  onUserClick = (id) => () => {
    if (this.state.selected === id) {
      this.setState({ selected: null })
    } else {
      this.setState({ selected: id })
    }
  }

  onDeleteUserClick = (userId, userIndex) => () => {
    this.props.deleteUserAction(this.props.userId, userId, userIndex)
  }

  onSortingClick = (input) => (e) => {
    if (e) {
      let requestBody = {
        limit: this.state.limit,
        page: this.state.page,
        filterList: [
          { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
        ],
        filterBy: this.state.filterBy,
        sortDirection: this.state.sortDirection,
        sortHeader: this.state.sortHeader,
        isPagination: true
      }
      if (input === 'name') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilter())
        this.props.usersListAction(this.props.userId, requestBody)
      } else if (input === 'createdAt') {
        requestBody.sortDirection = this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        requestBody.sortHeader = input
        this.setState({
          sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC',
          sortHeader: input
        }, () => this.storeFilter())
        this.props.usersListAction(this.props.userId, requestBody)
      }
    }
  }

  onFileUpload = (event) => {
    store.dispatch({ type: RESET_INVALID_MEMBERS_LIST })
    event.preventDefault()
    let formData = new FormData()
    formData.append('file', event.target.files[0])
    this.props.importUserAction(this.props.userId, formData)
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.FILTER) {
      if (event.target.value) {
        this.setState({ search: event.target.value, page: 1 }, this.storeFilter())
      } else {
        this.setState({ search: '', page: 1 }, this.storeFilter())
      }
    }
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: event.target.value }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.usersListAction(this.props.userId, requestBody)
  }

  onTimeDropdownInputChange = (inputId) => (dropdownValue) => () => {
    if (inputId === InputIds.FILTER_TIME) {
      if (dropdownValue) {
        this.setState({ filterBy: dropdownValue, page: 1 }, this.storeFilter())
      } else {
        this.setState({ filterBy: appConfig.TIME_FILTER[4], page: 1 }, this.storeFilter())
      }
    }
    let requestBody = {
      page: 1,
      limit: this.state.limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
      ],
      filterBy: dropdownValue,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.usersListAction(this.props.userId, requestBody)
  }

  onAssignClick = (userId) => () => {
    this.setState({ showAssignPopup: true, currentUserId: userId })
  }

  onCancelClick = () => {
    this.onResetState()
  }

  onResetState = () => {
    this.setState({ showAssignPopup: false, currentUserId: '', role: '' })
  }

  onSubmitClick = () => {
    this.onResetState()
    this.props.updateUserRole(this.props.userId, this.state.currentUserId, this.state.role)
  }

  onRoleChange = (value) => () => {
    this.setState({ role: value })
  }

  onLimitChange = (limit) => () => {
    let requestBody = {
      page: 1,
      limit: limit,
      filterList: [
        { columnName: [InputIds.REQUEST_BODY_FIELDS.FIRSTNAME, InputIds.REQUEST_BODY_FIELDS.LASTNAME], type: InputIds.REQUEST_BODY_FIELDS.SEARCH, value: this.state.search }
      ],
      filterBy: this.state.filterBy,
      sortDirection: this.state.sortDirection,
      sortHeader: this.state.sortHeader,
      isPagination: true
    }
    this.props.usersListAction(this.props.userId, requestBody)
    this.setState({ limit: limit, page: 1 }, () => this.storeFilter())
  }

  storeFilter = () => {
    let userfilterObj = {
      sortingName: this.state.sortHeader,
      sortingOrder: this.state.sortDirection,
      filterBy: this.state.filterBy,
      filters: {
        search: this.state.search,
      },
      pageNo: this.state.page,
      limit: this.state.limit,
      selectedUserType: this.state.selectedUserType
    }
    localStorage.setItem('userfilterObj', JSON.stringify(userfilterObj))
  }

  onViewProfile = (eachUser) => () => {
    this.props.history.push(UIRoutes.VIEW_MY_PROFILE, { id: eachUser._id, firstName: eachUser.firstName, lastName: eachUser.lastName })
  }

  render() {

    let pageNumbers = []
    for (let i = 1; i <= this.props.usersList.totalPage; i++) {
      pageNumbers.push(i)
    }

    return (
      <div>
        <FormHeader page={Messages.USERS} />
        <div className="admin-panel-page">
          <div className="container-block small-container-block">
            <div className="filter-panel d-flex">
              <TextField
                type='text'
                parentClassName='form-group flex1'
                labelClassName='default-font black-text'
                className='form-input mr-10'
                inputDivClassName='input-panel'
                placeholder={Placeholders.FILTER_BY_NAME}
                onChange={this.onInputChange(InputIds.FILTER)}
                value={this.state.search}
              />
              <CustomDropDown
                parentClassName='form-group filter-dropdown'
                inputDivClassName='input-panel'
                onChange={this.onTimeDropdownInputChange(InputIds.FILTER_TIME)}
                list={appConfig.TIME_FILTER}
              />
            </div>
            {/* <div className="text-right mb-30 position-relative import-user-btn-panel">
              <div><input type="file" className="file-input" accept='.XLS,.xls,.XLSX,.xlsx,.CSV,.csv' onClick={(event) => { event.target.value = null }} onChange={this.onFileUpload} /></div>
              <Button
                type='button'
                className='btn btn-small btn-primary btn-import'
                text={Messages.IMPORT_USERS}
              />
            </div> */}
            <div className="admin-table-card admin-users-card mt-30">
              <div className="table-header">
                <div className="table-row">
                  <div className="table-data-row">
                    <div className="cell cell-arrow"></div>
                    <div className="cell cell-name">
                      <div className="d-flex">
                        <div>
                          <span className="cell-header-data font-700">Name</span>
                        </div>
                        <div className="sorting-panel" onClick={this.onSortingClick("name")}></div>
                      </div>
                    </div>
                    <div className="cell cell-user-type">
                      <div className="filter-multiselect-panel">
                            <span className="cell-header-data font-700">User Type
                              <MultiSelect
                                className="multiselect-dropdown"
                                options={this.getUserTypeList()}
                                selected={this.state.selectedUserType}
                                onChange={this.onUserTypeChange}
                                value={this.state.selectedUserType}
                              />
                            </span>
                          </div>
                    </div>
                    <div className="cell cell-contact">
                      <div>
                        <span className="cell-header-data font-700">Contact Details</span>
                      </div>
                    </div>
                    <div className="cell cell-date">
                      <div className="d-flex">
                        <div>
                          <span className="cell-header-data font-700">Date</span>
                        </div>
                        <div className="sorting-panel" onClick={this.onSortingClick("createdAt")}></div>
                      </div>
                    </div>
                    <div className="cell cell-files-assigned">
                      <div>
                        <span className="cell-header-data font-700">Files Assigned</span>
                      </div>
                    </div>
                    <div className="cell cell-action">
                      <div>
                        <span className="cell-header-data font-700">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {
                  this.props.usersList.list.length
                    ?
                    this.props.usersList.list.map((eachUser, index) => {
                      let showCard = this.state.selected === eachUser._id
                      return (
                        <div className="table-row position-relative" key={index}>
                          <div className="table-data-row table-body-data-row">
                            <div className="cell cell-arrow">
                              <div className={"droparrow" + (showCard ? ' active' : '')} onClick={this.onUserClick(eachUser._id)}></div>
                            </div>
                            <div className="cell cell-name">
                              <div>
                                <span className="cell-body-data font-600">{eachUser.firstName + ' ' + eachUser.lastName}</span>
                              </div>
                            </div>
                            <div className="cell cell-user-type">
                              <div>
                                <span className="cell-body-data font-600">{eachUser.role}</span>
                              </div>
                            </div>
                            <div className="cell cell-contact">
                              <div className="email-panel contact-detail-panel">
                                <span className="cell-body-data font-600 meta-info">{eachUser.email}</span>
                              </div>
                              <div className="phone-panel contact-detail-panel mt-5">
                                <span className="cell-body-subdata font-600">{eachUser.phoneCode + ' ' + eachUser.phoneNumber}</span>
                              </div>
                            </div>
                            <div className="cell cell-date">
                              <div>
                                <span className="cell-body-data font-600">{moment.utc(eachUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                              </div>
                            </div>
                            <div className="cell cell-files-assigned">
                              <div>
                                <span className="cell-body-data font-600">{eachUser.fileCount ? eachUser.fileCount : '-'}</span>
                              </div>
                            </div>
                            <div className="cell cell-action">
                              <div className="d-flex">
                                <div className="action-btn mr-10">
                                  <img src={ImageAssets.adminpanel.deleteIcon} alt="delete" onClick={this.onDeleteUserClick(eachUser._id, index)} />
                                </div>
                                <div className="action-btn mr-5">
                                  <img src={ImageAssets.adminpanel.assignIcon} alt="assign" onClick={this.onAssignClick(eachUser._id)} />
                                </div>
                                <div className="action-btn" onClick={this.onViewProfile(eachUser)}>
                                  <img src={ImageAssets.adminpanel.profileIcon} alt="view profile" />
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            showCard
                              ?
                              <div className="table-card">
                                <div className="table-card-item cell-user-type">
                                  <div>
                                    <span className="cell-header-data font-700">User Type</span>
                                  </div>
                                  <div className="mt-10">
                                    <span className="cell-body-data font-600">{eachUser.role}</span>
                                  </div>
                                </div>
                                <div className="table-card-item cell-contact">
                                  <div>
                                    <span className="cell-header-data font-700">Contact Details</span>
                                  </div>
                                  <div className="mt-10">
                                    <div className="email-panel contact-detail-panel">
                                      <span className="cell-body-data font-600 meta-info">{eachUser.email}</span>
                                    </div>
                                    <div className="phone-panel contact-detail-panel mt-5">
                                      <span className="cell-body-subdata font-600">{eachUser.phoneCode + ' ' + eachUser.phoneNumber}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="table-card-item cell-date">
                                  <div>
                                    <span className="cell-header-data font-700">Date</span>
                                  </div>
                                  <div className="mt-10">
                                    <span className="cell-body-data font-600">{moment.utc(eachUser.createdAt).format(appConfig.DATE_FORMAT)}</span>
                                  </div>
                                </div>
                                <div className="table-card-item cell-files-assigned">
                                  <div>
                                    <span className="cell-header-data font-700">Files Assigned</span>
                                  </div>
                                  <div className="mt-10">
                                    <span className="cell-body-data font-600">{eachUser.fileCount}</span>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                        </div>
                      )
                    })
                    :
                    <div className="no-data-panel center">
                      <div><img src={ImageAssets.common.tableNoDataImg} alt="nodata"></img></div>
                      <div className="mt-20"><span className="large-font font-600">No Data Found !</span></div>
                    </div>
                }
              </div>
            </div>
            <div className="center justify-content-end flex-wrap">
              <div className="mt-5">
                {
                  pageNumbers.length > 1
                    ?
                    <div className="pagination-block">
                      <div className="page-panel">
                        {/* <div className="left-arrow" onClick={this.onPageNumberClick(InputIds.PREVIOUS)}></div>
                        {
                          pageNumbers.map((eachNumber, index) => {
                            return (
                              <div className={"number-block" + (this.state.page === (index + 1) ? ' active' : '')} key={index}>
                                <span className="page-number font-700" onClick={this.onPageNumberClick(index + 1)}>
                                  {eachNumber}
                                </span>
                              </div>
                            )
                          })
                        }
                        <div className="right-arrow" onClick={this.onPageNumberClick(InputIds.NEXT)}></div> */}
                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.props.usersList.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.onPageNumberClick.bind(this)}
                          hideFirstLastPages='false'
                          prevPageText=""
                          nextPageText=""
                          activeClass="active"
                          itemClass="number-block"
                          itemClassPrev='left-arrow'
                          itemClassNext='right-arrow'
                        />
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              {
                this.props.usersList.totalCount > 10
                  ?
                  <CustomDropDown
                    parentClassName="page-dropdown ml-30 mt-5"
                    onChange={this.onLimitChange}
                    list={appConfig.DEFAULT_PAGINATION_OPTIONS}
                    value={this.state.limit}
                  />
                  :
                  null
              }
              <AssignRolePopup
                open={this.state.showAssignPopup}
                role={this.state.role}
                onChange={this.onRoleChange}
                onCancelClick={this.onCancelClick}
                onSubmitClick={this.onSubmitClick}
                disabledButton={true}
              />
            </div>
          </div>
        </div>
        <DownloadExcel />
        <Footer />
      </div>
    )
  }

  componentWillUnmount() {
    this.storeFilter()
  }
}

AdminUsersPanel.propTypes = {}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  usersList: state.list.usersList
})

export default connect(mapStateToProps, { usersListAction, deleteUserAction, importUserAction, updateUserRole })(AdminUsersPanel)
