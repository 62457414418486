import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import {
  DELETE_USER, GET_USER_LIST, INVALID_MEMBERS_LIST, UPDATE_USER_ROLE, ADD_OTHER_EMAIL_PHONE,
  DELETE_OTHER_EMAIL_PHONE, GET_OTHER_EMAIL_PHONE
} from '../constants/ActionTypes'

export const usersListAction = (userId, requestObject) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.USER.LIST + userId + '/list', requestObject)
    .then(res => {
      dispatch({
        type: GET_USER_LIST,
        payload: res.data.result,
        currentPage: requestObject.page
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteUserAction = (userId, deleteUserId, userIndex) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.USER.DELETE + userId + '/' + deleteUserId)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: DELETE_USER,
        payload: userIndex
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updateUserRole = (loggedInUserId, userId, role) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.USER.UPDATE_ROLE + loggedInUserId + '/' + userId + '/role/' + role)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: UPDATE_USER_ROLE,
        payload: res
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const importUserAction = (userId, requestObject) => (dispatch) => {
  setLoading(true)

  axios.post(APIRoutes.USER.IMPORT + userId + '/import', requestObject)
    .then(res => {
      toast.success(res.data.message)
      if (res.data.result.invalidDataCount) {
        dispatch({
          type: INVALID_MEMBERS_LIST,
          payload: res.data.result.invalidData
        })
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const addOtherEmailPhone = (tenantUserId, userId, requestBody,onSuccess,  onError) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.USER.ADD_EMAIL_PHONE + tenantUserId + '/other/email-phone/' + userId, requestBody)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: ADD_OTHER_EMAIL_PHONE,
        payload: res
      })
      setLoading(false)
      onSuccess(res)
    }).catch(err => {
      setLoading(false)
      onError(err)
      toast.error(getErrorMessage(err))
    })
}

export const deleteOtherEmailPhone = (tenantUserId, userId, Id, requestBody, onSuccess) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.USER.DELETE_EMAIL_PHONE + tenantUserId + '/other/email-phone/' + userId + '/' + Id, requestBody)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: DELETE_OTHER_EMAIL_PHONE,
        payload: res
      })
      onSuccess()
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // toast.error(getErrorMessage(err))
    })
}

export const verifyOtherEmailPhone = (tenantUserId, userId, Id, requestBody , onSuccess) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.USER.VERIFY_EMAIL_PHONE + tenantUserId + '/other/email-phone/verify/' + userId + '/' + Id, requestBody)
    .then(res => {
      toast.success(res.data.message)
      // dispatch({
      //   type: VERIFY_OTHER_EMAIL_PHONE,
      //   payload: res
      // })
      setLoading(false)
      onSuccess();
      }).catch(err => {
        console.log("verifyOtherEmailPhone",err)
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getOtherEmailPhone = (tenantUserId, userId) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.USER.GET_EMAIL_PHONE + tenantUserId + '/other/get/email-phone/' + userId)
    .then(res => {
      // toast.success(res.data.message)
      dispatch({
        type: GET_OTHER_EMAIL_PHONE,
        payload: res.data.result.otherEmailPhoneNumber
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}
