import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { InputIds } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssets'
import TextField from './TextField'

class CustomDropDownWithSearchBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  onSearch = (inputId) => () => (event) => {
    this.setState({ search: event.target.value })
  }

  onOptionClick = (selectionValue) => () => {
    this.setState({ search: '' })
    this.props.onChange(selectionValue)
  }

  render() {
    return (
      <div className={this.props.parentClassName}>
        {
          this.props.labelText
            ?
            <div className={this.props.labelDivClassName}>
              <label className={this.props.labelClassName}>
                {this.props.labelText}
              </label>
            </div>
            :
            null
        }
        <div className={this.props.inputDivClassName}>
          <div className="input-block dropdown-block">
            <Dropdown>
              <Dropdown.Toggle className={(this.props.valueColorClassName ? this.props.valueColorClassName : '') + (this.props.disabled ? "dropdown-disable" : '')} disabled={this.props.disabled}>{this.props.value ? this.props.value : this.props.placeholder}</Dropdown.Toggle>
              <Dropdown.Menu id="dropdown-basic-button" title={this.props.value}>
                <TextField
                  autoFocus
                  type='text'
                  placeholder='Search here'
                  className='form-input country search-dropdown-input'
                  onChange={this.onSearch(InputIds.SEARCH)}
                  value={this.state.search}
                />
                {
                  this.props.list.map((eachSelection, index) => {
                    return (
                      eachSelection.toLowerCase().includes(this.state.search.toLowerCase().trim())
                        ?
                        <Dropdown.Item onClick={this.onOptionClick(eachSelection)} key={index}>{eachSelection}</Dropdown.Item>
                        :
                        null
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {
            this.props.tooltipText
              ?
              <div className="tootltip-container ml-20 mt-10">
                <div className="tootltip-icon">
                  <img src={ImageAssets.formsvg.tooltipIcon} alt="tooltip-icon"></img>
                  <div className="tooltip-text">
                    <span className="tooltip-text-panel">
                      {this.props.tooltipText}
                    </span>
                  </div>
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
    )
  }
}

export default CustomDropDownWithSearchBox
