import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { UIRoutes } from '../../constants/FrontendRoutes'
import ImageAssets from '../../utils/ImageAssets'
import { Messages } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'
import { logoutUser } from '../../actions/authActions'
import ProfileDropDown from './ProfileDropDown'

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = { showMenu: false }
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  onLogoutClick = () => {
    this.props.logoutUser()
  }

  render() {
    let dropdownOptions = []
    if (!this.props.isAuthenticated) {
      dropdownOptions.push(Messages.PROFILE_DROPDOWN.LOGIN)
      dropdownOptions.push(Messages.PROFILE_DROPDOWN.SIGNUP)
    } else {
      if (this.props.role === appConfig.ROLE.OWNER_ADMIN || this.props.role === appConfig.ROLE.ADMIN) {
        // dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_PROFILE)
        dropdownOptions.push(Messages.PROFILE_DROPDOWN.ADMINISTRATOR)
      } else {
        dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_APPLICATION)
      }
      dropdownOptions.push(Messages.PROFILE_DROPDOWN.MY_PROFILE)
      dropdownOptions.push(Messages.PROFILE_DROPDOWN.LOGOUT)
    }
    return (
      <div className="header-section">
        <div className="container-block">
          <div className="navbar-panel center justify-content-between">
            <Link to={UIRoutes.HOME} className="logo-panel">
              <img src={ImageAssets.common.siteLogo} alt="logo" />
            </Link>
            <div className="d-flex align-items-center">
              {/* {
                this.props.isAuthenticated
                  ?
                  <Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="btn btn-small btn-primary login-mob-btn">{Messages.LOGOUT}</Link>
                  :
                  <Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="btn btn-small btn-primary login-mob-btn">{Messages.LOGIN}</Link>
              } */}
              {/* <div className="toggle-menu ml-20" onClick={this.toggleMenu}>
                <img src={ImageAssets.common.toggleIcon} alt="toggleIcon" />
              </div> */}
            </div>
            <div className="d-flex align-items-center">
              <div className="toggle-menu mr-20" onClick={this.toggleMenu}>
                <img src={ImageAssets.common.toggleIcon} alt="toggleIcon" />
              </div>
              <div className={'nav-panel d-flex align-items-center' + (this.state.showMenu ? ' open-menu' : '')}>
                <Link to={UIRoutes.HOME} className={"nav-link default-font" + (this.props.page === Messages.HOME ? ' active-link' : '')}>{Messages.HOME}</Link>
                <Link to={UIRoutes.ABOUTUS} className={"nav-link default-font" + (this.props.page === Messages.ABOUT_US ? ' active-link' : '')}>{Messages.ABOUT_US}</Link>
                <Link to={UIRoutes.SERVICES} className={"nav-link default-font" + (this.props.page === Messages.SERVICES ? ' active-link' : '')}>{Messages.SERVICES}</Link>
                <Link to={UIRoutes.RESOURCES} className={"nav-link default-font" + (this.props.page === Messages.RESOURCES ? ' active-link' : '')}>{Messages.RESOURCES}</Link>
                <Link to={UIRoutes.TAX} className={"nav-link default-font" + (this.props.page === Messages.TAX ? ' active-link' : '')}>{Messages.TAX}</Link>
                <Link to={UIRoutes.CONTACTUS} className={"nav-link default-font" + (this.props.page === Messages.CONTACT_US ? ' active-link' : '')}>{Messages.CONTACT_US}</Link>

                {/* {
                this.props.isAuthenticated
                  ?
                  <>
                    {
                      this.props.role === appConfig.ROLE.OWNER_ADMIN
                        ?
                        <Link to={UIRoutes.APPLICATIONS} className="nav-link default-font">{Messages.ADMIN_PANEL}</Link>
                        :
                        null
                    }
                    <Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="btn btn-small btn-primary">{Messages.LOGOUT}</Link>
                  </>
                  :
                  null
              } */}
              </div>
              <ProfileDropDown
                parentClassName='profile-dropdown'
                onClick={this.onLogoutClick}
                list={dropdownOptions}
                isAuthenticated = {this.props.isAuthenticated}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role
})

export default connect(mapStateToProps, { logoutUser })(Header)
