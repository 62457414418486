import { combineReducers } from 'redux'
import { AUTH_LOGOUT } from '../constants/ActionTypes'
import authReducer from './authReducer'
import loadingReducer from './loadingReducer'
import applicationReducer from './applicationReducer'
import subscriptionReducer from './subscriptionReducer'
import listReducer from './listReducer'
import addressReducer from './addressReducer'
import counterReducer from './counterReducer'
import viewDocumentReducer from './viewDocumentReducer'

const appReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  taxApplication: applicationReducer,
  subscription: subscriptionReducer,
  list: listReducer,
  address: addressReducer,
  counters: counterReducer,
  documentView: viewDocumentReducer
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    let newState = { ...state }
    newState.auth = {
      emailConfirmed: true,
      isAuthenticated: false,
      email: '',
      userId: '',
      partialLogin: true
    }
    state = { ...newState }
    return appReducer(state, action)
  }
  return appReducer(state, action)
}

export default rootReducer
