import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { UIRoutes } from '../constants/FrontendRoutes'
import { GET_PLANS_LIST, GET_RECOMMENDED_PLAN, GET_SINGLE_PLAN, GET_TAX_OPTIONS_LIST } from '../constants/ActionTypes'
import { getSingleApplicationForDocument } from './applicationActions'

export const makePaymentAction = (requestBody, userId, applicationId, history) => () => {
  setLoading(true)
  axios.put(APIRoutes.PAYMENT.MAKE_PAYMENT + userId + '/' + applicationId + '/payment', requestBody)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
      history.push(UIRoutes.VIEW_ALL_APPLICATION)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getPlansListAction = (requestBody, userId) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.SUBSCRIPTION.PLANS + userId + '/list', requestBody)
    .then(res => {
      dispatch({
        type: GET_PLANS_LIST,
        payload: res.data.result.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getPlanList = (requestBody, userId , dispatch) => {
  setLoading(true)
    axios.post(APIRoutes.SUBSCRIPTION.PLANS + userId + '/list', requestBody)
    .then(res => {
      dispatch({
        type: GET_PLANS_LIST,
        payload: res.data.result.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getTaxOptionsListAction = (requestBody, userId) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.SUBSCRIPTION.TAX_OPTIONS + userId + '/list', requestBody)
    .then(res => {
      dispatch({
        type: GET_TAX_OPTIONS_LIST,
        payload: res.data.result.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getTaxSelectedComplexityAction = (requestBody, userId) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.SUBSCRIPTION.RECOMMEND + userId + '/recommend', requestBody)
    .then(res => {
      dispatch({
        type: GET_RECOMMENDED_PLAN,
        payload: res.data.result.packageId
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const updatePaymentAdminAction = (userId, applicationId, requestBody) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.PAYMENT.MAKE_PAYMENT + userId + '/' + applicationId + '/payment', requestBody)
    .then(res => {
      toast.success(res.data.message)
      getSingleApplicationForDocument(userId, applicationId, dispatch)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const assignPaymentPlan = (userId, applicationId, requestBody) => (dispatch) => {
  setLoading(true)
  axios.put(APIRoutes.APPLICATION.ASSIGN_BY_ADMIN + userId + '/' + applicationId + '/payment/recommend', requestBody)
    .then(res => {
      toast.success(res.data.message)
      getSingleApplicationForDocument(userId, applicationId, dispatch)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const getPlanDetails = (userId, planId) => (dispatch) => {
  setLoading(true)
  axios.get(APIRoutes.SUBSCRIPTION.GET_PLAN + userId + '/' + planId)
    .then(res => {
      dispatch({
        type: GET_SINGLE_PLAN,
        payload: res.data.result
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}