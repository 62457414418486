import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel3'
import { connect } from 'react-redux'
import Button from '../common/Button'
import { appConfig } from '../../constants/AppConfig'
import FormHeader from '../common/FormHeader'
import Footer from '../common/Footer'
import { getPlansListAction, getTaxOptionsListAction, getTaxSelectedComplexityAction } from '../../actions/paymentActions'
import { Messages } from '../../constants/StaticTexts'
import { UIRoutes } from '../../constants/FrontendRoutes'

const paymentPlans = {
  loop: false,
  margin: 20,
  nav: false,
  mouseDrag: false,
  touchDrag: true,
  dots: true,
  autoplay: false,
  smartSpeed: 1500,
  stagePadding: 5,
  autoplayHoverPause: false,
  center: false,
  navText: [],
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 1
    },
    650: {
      items: 2
    },
    768: {
      items: 2
    },
    992: {
      items: 3
    },
    1199: {
      items: 3
    },
    1280: {
      items: 3
    },
    1440: {
      items: 3,
      center: false
    }
  }
}

class PaymentPlans extends Component {

  constructor(props) {
    super(props)
    this.state = {
      taxOptions: [],
      selectedPlan: null
    }
  }

  componentDidMount() {
    let plansRequestBody = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortDirection: 'ASC',
      isPagination: false
    }
    this.props.getPlansListAction(plansRequestBody, this.props.userId)
    let taxOptionsRequestBody = {
      page: 1,
      limit: appConfig.PAGINATION_LIMIT,
      filterList: [],
      sortDirection: 'ASC',
      isPagination: false
    }
    this.props.getTaxOptionsListAction(taxOptionsRequestBody, this.props.userId)
    let requestBody = {
      selected: []
    }
    this.props.getTaxSelectedComplexityAction(requestBody, this.props.userId)
  }

  onTaxOptionClick = (index) => () => {
    let allTaxOptions = this.state.taxOptions
    if (allTaxOptions.includes(index)) {
      let currentIndex = allTaxOptions.indexOf(index)
      allTaxOptions.splice(currentIndex, 1)
    } else {
      allTaxOptions.push(index)
    }
    this.setState({ taxOptions: allTaxOptions, selectedPackage: null })
    let requestBody = {
      selected: []
    }
    this.props.taxOptions.forEach((eachOption, index) => {
      if (this.state.taxOptions.includes(index)) {
        requestBody.selected.push(eachOption._id)
      }
    })
    this.props.getTaxSelectedComplexityAction(requestBody, this.props.userId)
  }

  onPlanClick = (index) => () => {
    this.setState({ selectedPlan: index })
  }

  onMakePaymentClick = () => {
    let planId = this.props.plans[this.state.selectedPlan]._id
    let amount = this.props.plans[this.state.selectedPlan].currencies[0].amount
    this.props.history.push({ pathname: UIRoutes.STRIPE_PAYMENT, planId: planId, applicationId: this.props.match.params.id, amount: amount, planSelected: true })
  }

  render() {

    let disabledButton = this.state.selectedPlan === null ? false : true

    let plansToDisplay = []

    for (let [index, eachPlan] of this.props.plans.entries()) {
      if (!eachPlan.isCustom) {
        let price = appConfig.CURRENCY_SIGN[eachPlan.currencies[0].currency] + ' ' + eachPlan.currencies[0].amount
        let recommendedPlan = this.props.selectedPackage === eachPlan._id
        plansToDisplay.push(
          <div className={"plan-block" + (this.state.selectedPlan === index ? ' active' : '')} key={index} onClick={this.onPlanClick(index)}>
            {
              recommendedPlan
                ?
                <p className="recommend-block-text font-700">
                  {appConfig.RECOMMENDED}
                </p>
                :
                null
            }
            <div className="plan-title text-center">
              <span className="plan-title-text NunitoSans-Black">
                {eachPlan.name}
              </span>
            </div>
            <div className="pricing-panel text-center">
              <span className="pricing-panel-text NunitoSans-Black">
                {price}
              </span>
            </div>
            <div className="subheading-panel text-center">
              <span className="subheading-panel-text">Features & Benefits</span>
            </div>
            <div className="features-block">
              {
                eachPlan.features.map((eachFeature, subIndex) => {
                  return (
                    <div className="bullet-block" key={subIndex}>
                      <div className="bullet-title">
                        <span className="bullet-title-text font-600">
                          {eachFeature.title}
                        </span>
                      </div>
                      <div className="bullet-desc">
                        <span className="bullet-desc-text font-600">
                          {eachFeature.description}
                        </span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <Button
              type='submit'
              parentClassName='plan-btn mt-30'
              className='btn btn-primary btn-large'
              text={price}
            />
          </div>
        )
      }
    }

    return (
      <div>
        <FormHeader />
        <div className="payment-plans">
          <div className="container-block">
            <div className="category-block">
              <div className="heading-block">
                <div className="subheading-panel text-center">
                  <span className="subheading-panel-text">Canadian Tax Software</span>
                </div>
                <div className="heading-panel text-center">
                  <span className="heading-panel-text black-text font-700">Tell us about yourself, and we'll recommend the right solution</span>
                </div>
                <div className="subheading-panel text-center">
                  <span className="subheading-panel-text">Select all that apply</span>
                </div>
              </div>
              <div className="categories-container">
                <div className="container-block">
                  <div className="categories-panel">
                    {
                      this.props.taxOptions.map((eachOption, index) => {
                        return (
                          <div className={"select-category-block" + (this.state.taxOptions.includes(index) ? ' active' : '')} key={index} onClick={this.onTaxOptionClick(index)}>
                            <div className="image-block center">
                              <img src={eachOption.profileImg} alt="tax-slips" />
                            </div>
                            <div className="title-block text-center">
                              <span className="title-block-text font-600">
                                {eachOption.name}
                              </span>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            {
              plansToDisplay.length
                ?
                <div className="plan-section">
                  <div className="heading-panel text-center">
                    <span className="heading-panel-text black-text font-700">Compare Products</span>
                  </div>
                  <OwlCarousel
                    className="plans-panel owl-carousel owl-theme"
                    {...paymentPlans}
                  >
                    {plansToDisplay}
                  </OwlCarousel>
                </div>
                :
                null
            }
            <Button
              type='submit'
              parentClassName='make-payment-btn text-right'
              className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
              text={Messages.MAKE_PAYMENT}
              disabled={!disabledButton}
              onClick={this.onMakePaymentClick}
            />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

PaymentPlans.propTypes = {}

const mapStateToProps = (state) => ({
  plans: state.subscription.plans,
  taxOptions: state.subscription.taxOptions,
  userId: state.auth.userId,
  selectedPackage: state.subscription.selectedPackage
})

export default connect(mapStateToProps, { getPlansListAction, getTaxOptionsListAction, getTaxSelectedComplexityAction })(PaymentPlans)