import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logoutUser } from '../../actions/authActions'
import ImageAssets from '../../utils/ImageAssets'
import { UIRoutes } from '../../constants/FrontendRoutes'
import { Messages } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'
import SessionTimeout from './SessionTimeout'
import AcceptCookie from './AcceptCookie'
import PrivacyPolicy from './PrivacyPolicy'
class Footer extends Component {

  onLogoutClick = () => {
    this.props.logoutUser()
  }

  render() {
    return (
      <div className="footer-section">
        <SessionTimeout isAuthenticated={this.props.isAuthenticated} />
        <div className="footer-upper-panel">
          <div className="container-block">
            <div className="row no-gutters">
              <div className="col-lg-4">
                <div className="about-us-panel">
                  <div><span className="footer-title font-700 light-green-text default-font">About Us</span></div>
                  <div className="mt-15">
                    <span className="about-description small-font">Bringing clear sight to you all your Banking, Finance and Taxation. We are leading to make our skills work for you to align all your Financial portfolio. Whether you want to transform your business, advance your career, or simply find out more about what we are doing, this is the place </span>
                  </div>
                  <div className="contact-panel">
                    <div className="contact-info address-info">
                      <span className="small-font white-text">Ottawa, ON, Canada</span>
                    </div>
                    <div className="d-flex">
                      <div className="contact-info phone-info">
                        <a href={UIRoutes.HOME} className="white-text small-font">+1(613)-327-8131</a>
                      </div>
                      <div className="contact-info toll-free another-number">
                        <a href={UIRoutes.HOME} className="white-text small-font">+1-877-360-0526</a>
                      </div>
                    </div>
                    <div className="contact-info fax-info">
                      <a href={UIRoutes.HOME} className="white-text small-font">+1-877-360-0526</a>
                    </div>
                    <div className="contact-info email-info">
                      <a href={UIRoutes.HOME} className="small-font white-text">taxplanning@blissaccounting.ca</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-8">
                <div className="footer-links-panel">
                  <div className="row no-gutters">
                    <div className="col-6">
                      <div className="footer-links-title mb-20">
                        <span className="default-font font-600 white-text">Quick Links</span>
                      </div>
                      <div className="footer-links-list">
                        {
                          this.props.isPartialLogin
                            ?
                            <>
                              <div><Link to={{ pathname: "https://app.financebliss.ca" }} target="_self" /* to={UIRoutes.REGISTER} onClick={this.onLogoutClick} */ className="footer-link">{Messages.REGISTER}</Link></div>
                              <div><Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="footer-link">{Messages.LOGIN}</Link></div>
                            </>
                            :
                            null
                          // <div><Link to={UIRoutes.LOGIN} onClick={this.onLogoutClick} className="footer-link">{Messages.LOGOUT}</Link></div>
                        }
                        <div><Link to={UIRoutes.RESOURCES} className="footer-link">{Messages.RESOURCES}</Link></div>
                        <div><Link to={UIRoutes.CONTACTUS} className="footer-link">{Messages.CONTACT_US}</Link></div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="footer-links-title mb-20">
                        <span className="default-font font-600 white-text">Useful Links</span>
                      </div>
                      <div className="footer-links-list">
                        <div><Link to={UIRoutes.ABOUTUS} className="footer-link">About Us</Link></div>
                        <div><Link to={UIRoutes.SERVICES} className="footer-link">Services</Link></div>
                        <div><Link to={UIRoutes.TAX} className="footer-link">Tax</Link></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="social-icons-panel">
                  <div className="social-title mb-20">
                    <span className="default-font white-text font-600">Social Links</span>
                  </div>
                  <div className="social-links-list d-flex">
                    <div><a href={appConfig.SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank" className="social-link" rel="noopener noreferrer"><img src={ImageAssets.social.facebookIcon} alt="facebook"></img></a></div>
                    <div><a href={appConfig.SOCIAL_MEDIA_LINKS.GOOGLE} target="_blank" rel="noopener noreferrer" className="social-link"><img src={ImageAssets.social.googleIcon} alt="google"></img></a></div>
                    <div><a href={appConfig.SOCIAL_MEDIA_LINKS.TWITTER} target="_blank" className="social-link" rel="noopener noreferrer"><img src={ImageAssets.social.twitterIcon} alt="facebook"></img></a></div>
                    <div><a href={appConfig.SOCIAL_MEDIA_LINKS.LINKEDIN} target="_blank" className="social-link" rel="noopener noreferrer"><img src={ImageAssets.social.linkedinIcon} alt="facebook"></img></a></div>
                    <div><a href={appConfig.SOCIAL_MEDIA_LINKS.INSTAGRAM} target="_blank" className="social-link" rel="noopener noreferrer"><img src={ImageAssets.social.instagramIcon} alt="facebook"></img></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-lower-panel">
          <div className="container-block">
            <div className="row no-gutters align-items-center">
              <div className="col-lg-6 col-sm-6 col-5">
                <div className="mr-20"><img src={ImageAssets.common.siteFooterLogo} alt="footerLogo" className="image-responsive"></img></div>
              </div>
              <div className="col-lg-6 col-sm-6 col-7">
                <div className="pl-10">
                  <div className="text-right">
                    <span className="white-text small-font">&copy; 2022 Bliss, All Rights Reserved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AcceptCookie />
        <PrivacyPolicy />
      </div>
    )
  }
}

Footer.propTypes = {}

const mapStateToProps = (state) => ({
  isPartialLogin: state.auth.partialLogin,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logoutUser })(Footer)