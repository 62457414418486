import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Button from './Button'
import { Messages } from '../../constants/StaticTexts'

const StripeCardComponent = (props) => {

  let [state, setState] = useState({ stripeError: '' })

  let stripe = useStripe()
  let elements = useElements()

  let handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) { return }

    let cardElement = elements.getElement(CardElement)

    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setState({ stripeError: error.message })
    } else {
      props.makeActualPayment(paymentMethod.id)
    }
  }

  let onCardNumberChange = () => {
    setState({ stripeError: '' })
  }

  return (
    <div className="package-detail-block">
      <div className="mb-15">
        <span className="large-font light-green-text font-600">Payment Details</span>
      </div>
      <div className="detail-container">
        <form onSubmit={handleSubmit}>
          <CardElement style={{ width: 'auto !important' }} onChange={onCardNumberChange} />
          <span>{state.stripeError}</span>
          <Button
            parentClassName='make-payment-btn text-right'
            className='btn btn-primary btn-small'
            text={Messages.SUBMIT}
            disabled={!stripe}
          />
        </form>
      </div>
    </div>
  )
}

StripeCardComponent.propTypes = {}

export default StripeCardComponent