import React, { Component } from 'react'
import { Messages } from '../../constants/StaticTexts';
import { acceptInformativeMessage } from '../../actions/authActions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../common/Button'
import { connect } from 'react-redux';
import ImageAssets from '../../utils/ImageAssets';

class AcceptCookie extends Component {
  constructor(props) {
    super(props)
    this.state = {
      acceptCookie: false
    }
  }
  handleButtonClick = () => {
    localStorage.setItem("acceptCookie", JSON.stringify(true));
    this.setState({
      acceptCookie: false
    })
  }
  onOpen = () => {
    this.setState({
      acceptCookie: true
    })
  }
  onClose = () => {
    this.setState({
      acceptCookie: false
    })
  }
  render() {
    return (
      <>
        {
          localStorage.acceptCookie
            ?
            null
            :
            <>
              <div className="policy-pane text-center">
                <span onClick={this.onOpen} className="white-text text-center policy-text">This website
                 uses cookies. We use cookies to ensure you get the best experience. Agree to our policy.</span>
              </div>
              <Dialog aria-labelledby="customized-dialog-title" open={this.state.acceptCookie}>
                <DialogTitle id="customized-dialog-title">
                  <div className="d-flex justify-content-end cancel-btn">
                    <img src={ImageAssets.popup.cancelIcon} alt="sample-document" onClick={this.onClose} />
                  </div>
                </DialogTitle>
                <DialogContent dividers>
                  <section id="cookieMessage" className="cookie-message pt-30 mb-30">
                    <div id="cookieConsent"><span className="small-font">We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us
                    improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie
                    to identify you, you would not have to log in a password more than once, thereby saving time while on our
                    site. Cookies can also enable us to track and target the interests of our users to enhance their experience
            on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</span>
                    </div>
                  </section>
                  <div className="center pb-30">
                    <div className="mr-10">
                      <Button
                        type='submit'
                        className={'btn btn-primary btn-small'}
                        text={Messages.ACCEPT}
                        disabled={false}
                        onClick={this.handleButtonClick}
                      />
                    </div>
                    <div className="ml-10">

                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
        }
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  addressList: state.address.addressList
})

export default connect(mapStateToProps, { acceptInformativeMessage })(AcceptCookie)