import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { UIRoutes } from '../constants/FrontendRoutes'

const PrivateRoute = ({ component: Component, auth, isEditing, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated
        ?
        <Component {...props} isEditing={isEditing} />
        :
        <Redirect to={UIRoutes.LOGIN} />
    }
  />
)

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)