import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import Button from '../common/Button'
import { Messages, Labels } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'
import { getDocumentThumbnail, mobileNumberFormat, getStatusClassNames, getPaymentStatusClassNames, downloadDocument, downloadDoc } from '../../utils/Utils'
import { getDocument } from '../../actions/commonActions'
import { UIRoutes } from '../../constants/FrontendRoutes'
import ApplicationConfirmation from '../screen-popup/ApplicationConfirmation'
import { getSingleApplication } from '../../actions/applicationActions'
import ViewDocument from '../screen-popup/ViewDocument'
import { Accordion, Card } from 'react-bootstrap'
import ImageAssets from '../../utils/ImageAssets'

class FormPreview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      applicationConfirmation: true,
      viewDocumentPopup: false,
      viewDoc: {
        link: '',
        fileType: ''
      },
      activeBlockIndex: ''
    }
  }

  componentDidMount() {
    this.props.getSingleApplication(this.props.applicationData.userId, this.props.applicationData.applicationId, true, 5, 5)
    window.scrollTo(0, 0)
  }

  onPayNowClick = () => {
    if (this.props.applicationData.isRecommended) {
      this.props.history.push({ pathname: UIRoutes.STRIPE_PAYMENT, isRecommended: this.props.applicationData.isRecommended, planId: this.props.applicationData.packageId, applicationId: this.props.applicationData.applicationId, amount: this.props.applicationData.unpaidAmount })
    } else {
      this.props.history.push(UIRoutes.PAYMENT_PLANS + this.props.applicationData.applicationId)
    }
  }

  onCancelClick = () => {
    this.setState({
      viewDocumentPopup: false,
    })
  }

  onPayLaterClick = () => {
    if ([appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)) {
      this.props.history.push(UIRoutes.APPLICATIONS)
    } else {
      this.props.history.push(UIRoutes.VIEW_ALL_APPLICATION)
    }
  }

  onViewDocumentPopupOpen = (link, type , fileName) => () => {
    this.props.getDocument(link, type)
    if ( type === 'application/msword') {
      downloadDoc(link, fileName)
    }
    this.setState({ viewDocumentPopup: true, viewDoc: { link: link, fileType: type } })
  }

  onOkClick = () => {
    this.setState({ applicationConfirmation: false })
  }

  onBlockChange = (id) => () => {
    if (this.state.activeBlockIndex === id) {
      this.setState({ activeBlockIndex: '' })
    } else {
      this.setState({ activeBlockIndex: id })
    }
  } 

  render() {
    let allDocuments = {
      ownDocuments: [],
      spouseDocuments: [],
      dependant1Documents: [],
      dependant2Documents: [],
      dependant3Documents: [],
      dependant4Documents: [],
      dependant5Documents: [],
      dependant6Documents: [],
      adminDocuments: []
    }
    this.props.applicationData.documents.forEach((eachDocument) => {
      if (eachDocument.uploadLink !== '') {
        if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.APPLICANT) {
          allDocuments.ownDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.SPOUSE) {
          allDocuments.spouseDocuments.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_1) {
          allDocuments.dependant1Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_2) {
          allDocuments.dependant2Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_3) {
          allDocuments.dependant3Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_4) {
          allDocuments.dependant4Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_5) {
          allDocuments.dependant5Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.DEPENDENT_6) {
          allDocuments.dependant6Documents.push(eachDocument)
        } else if (eachDocument.uploadedFor === appConfig.DOCUMENTS_FOR.ADMIN) {
          allDocuments.adminDocuments.push(eachDocument)
        }
      }
      // if (this.props.role !== appConfig.ROLE.USER) {
      //   if (eachDocument.isDocumentOwner) {
      //     adminDocuments.push(eachDocument)
      //   } else {
      //     ownDocuments.push(eachDocument)
      //   }
      // } else {
      //   if (eachDocument.isUserDocument) {
      //     ownDocuments.push(eachDocument)
      //   } else {
      //     adminDocuments.push(eachDocument)
      //   }
      // }
    })
    return (
      <div className="form-preview-page">
        <div className="container-block">
          <div className="center justify-content-between flex-wrap">
            <div className="heading-panel mr-10 mb-0">
              <span className="heading-panel-text font-700">
                {Messages.FORM_PREVIEW}
              </span>
              {/* <Button
                type='button'
                className='btn btn-primary btn-medium'
                onClick={downloadDocument(this.props.applicationData.applicationLink, this.props.applicationData.displayAppId + '.pdf')}
                text={Messages.EXPORT_AS_PDF}
              /> */}
            </div>
            <div className="heading-right-section">
              <div className="application-block mb-10">
                <div className="row align-items-center no-gutters">
                  <div className="col-7">
                    <div className="status-text">
                      <div className="small-font font-700">{Messages.APPLICATION_STATUS}</div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className={"color-label " + (getStatusClassNames(this.props.applicationData.applicationStatus))}>
                      <div className="label-text small-font font-700">{this.props.applicationData.applicationStatus}</div>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.role !== appConfig.ROLE.ACCOUNTANT ?
                <div className="payment-block">
                  <div className="row align-items-center no-gutters">
                    <div className="col-7">
                      <div className="status-text">
                        <div className="small-font font-700">{Messages.PAYMENT_STATUS}</div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className={"color-label " + (getPaymentStatusClassNames(this.props.applicationData.paymentStatus))}>
                        <div className="label-text small-font font-700">{this.props.applicationData.paymentStatus}</div>
                      </div>
                    </div>
                  </div>
                </div>
                : null}
            </div>
          </div>
          <div className="mt-50">
            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text about">{Messages.ABOUT_YOU}</span>
              </div>
              <div className="data-block">
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.ID}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.displayAppId}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.FILLED_RETURN}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.hasReturnBefore}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.FILING_RETURN_FOR_FIRST_TIME}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.filingReturnFirstTime}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.props.applicationData.filingReturnFirstTime === appConfig.CRA_ACCOUNT_OPTIONS[0]
                    ?
                    <div className="data-row">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div>
                            <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DATE_OF_LANDING}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="right-panel">
                            <span className="default-font black-text font-600">{moment(this.props.applicationData.dateOfLanding).format(appConfig.DATE_FORMAT)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="data-row">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div>
                            <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.TAX_YEARS}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="right-panel">
                            <span className="default-font black-text font-600">{this.props.applicationData.taxYears}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                }
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.NAME}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.firstname + ' ' + this.props.applicationData.lastname}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.role)
                    ?
                    <div className="data-row">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div>
                            <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SIN}</span> 
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="right-panel">
                            <span className="default-font black-text font-600">{this.props.applicationData.sin || appConfig.NO_VALUE}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                }
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DATE_OF_BIRTH}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{moment(this.props.applicationData.dateOfBirth).format(appConfig.DATE_FORMAT)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.UNIT}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.unit || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.STREET_NUMBER}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.streetNumber || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.STREET_NAME}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.streetName}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.CITY}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.city}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.PROVINCE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.province}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.POSTAL_CODE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.postalCode}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.EMAIL}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.CONTACT_NUMBER}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{appConfig.MOBILE_CODE + ' ' + mobileNumberFormat(this.props.applicationData.phone)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* family */}

            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text family">{Messages.YOUR_FAMILY}</span>
              </div>
              <div className="data-block">
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.MARITAL_STATUS_ON}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.maritalStatus}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.props.applicationData.maritalStatus === appConfig.MARITAL_STATUS[0]
                    ?
                    <>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DATE_OF_MARRIAGE}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{moment(this.props.applicationData.dateOfMarriage).format(appConfig.DATE_FORMAT)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_NAME}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{this.props.applicationData.spouseFirstname + ' ' + this.props.applicationData.spouseLastname}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_FILLED_RETURN}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{this.props.applicationData.spouseHasFilledReturnBefore}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_FILING_RETURN_FOR_FIRST_TIME}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{this.props.applicationData.spouseFilingReturnFirstTime}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        this.props.applicationData.spouseFilingReturnFirstTime === appConfig.SPOUSE_FILING_RETURN_FOR_FIRST_TIME[0]
                          ?
                          <div className="data-row">
                            <div className="row no-gutters">
                              <div className="col-md-6">
                                <div>
                                  <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DATE_OF_LANDING}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="right-panel">
                                  <span className="default-font black-text font-600">{moment(this.props.applicationData.spouseDateOfLanding).format(appConfig.DATE_FORMAT)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="data-row">
                            <div className="row no-gutters">
                              <div className="col-md-6">
                                <div>
                                  <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.TAX_YEARS}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="right-panel">
                                  <span className="default-font black-text font-600">{this.props.applicationData.spouseTaxYear}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                      {
                        [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                          ?
                          <div className="data-row">
                            <div className="row no-gutters">
                              <div className="col-md-6">
                                <div>
                                  <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_SIN}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="right-panel">
                                  <span className="default-font black-text font-600">{this.props.applicationData.spouseSIN || appConfig.NO_VALUE}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          null
                      }
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_DATE_OF_BIRTH}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{moment(this.props.applicationData.spouseDateOfBirth).format(appConfig.DATE_FORMAT)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_CONTACT_NUMBER}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{appConfig.MOBILE_CODE + ' ' + this.props.applicationData.spouseContactNumber}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div>
                              <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SPOUSE_EMAIL_ADDRESS}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-panel">
                              <span className="default-font black-text font-600">{this.props.applicationData.spouseEmail}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    null
                }
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DEPENDANTS}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.hasDependants || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.props.applicationData.dependants.map((eachDependant, index) => {
                    return (
                      <div key={index}>
                        <div className="data-row">
                          <div className="row no-gutters">
                            <div className="col-md-6">
                              <div>
                                <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DEPEND + ' ' + (index + 1) + ' ' + Labels.FORM_PREVIEW.NAME}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="right-panel">
                                <span className="default-font black-text font-600">{eachDependant.firstName + ' ' + eachDependant.lastName}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN].includes(this.props.role)
                            ?
                            <div className="data-row">
                              <div className="row no-gutters">
                                <div className="col-md-6">
                                  <div>
                                    <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DEPEND + ' ' + (index + 1) + ' ' + Labels.FORM_PREVIEW.SIN}</span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="right-panel">
                                    <span className="default-font black-text font-600">{eachDependant.sin || appConfig.NO_VALUE}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            null
                        }
                        <div className="data-row">
                          <div className="row no-gutters">
                            <div className="col-md-6">
                              <div>
                                <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.DEPEND + ' ' + (index + 1) + ' ' + Labels.FORM_PREVIEW.DATE_OF_BIRTH}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="right-panel">
                                <span className="default-font black-text font-600">{moment(eachDependant.dob).format(appConfig.DATE_FORMAT)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
           
            {/* residence */}

            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text residence">{Messages.YOUR_RESIDENCE}</span>
              </div>
              <div className="data-block">
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.HAS_MOVE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.hasMove}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.props.applicationData.hasMove === appConfig.HAS_MOVE[0]
                    ?
                    <div className="data-row">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div>
                            <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.PROVINCE_TERRITORY_CHANGE}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="right-panel">
                            <span className="default-font black-text font-600">{this.props.applicationData.dateOfMove ? moment(this.props.applicationData.dateOfMove).format(appConfig.DATE_FORMAT) : appConfig.NO_VALUE}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                }
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.PROVINCE_TERRITORY_LIVE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.provinceTerritory}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.SAME_HOME_ADDRESS}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.sameHomeAddress || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.CURRENTLY_LIVE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.currentlyLive || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.IMMIGRATION_STATUS}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.immigrationStatus}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CRA */}
            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text cra">{Messages.CRA_MY_ACCOUNT}</span>
              </div>
              <div className="data-block">
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.CRA_MY_ACCOUNT}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.CRAAccount}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.TOTAL_RENT_TAX}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.rentTaxAmount || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.INVESTING_RRSP_TFSA}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.isInvesting}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.CHARITABLE_DONATION}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.donationAmount || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.MEDICAL_EXPENSE}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.isMedicalExpenses}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* direct deposit */}
            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text deposite">{Messages.DIRECT_DEPOSITE}</span>
              </div>
              <div className="data-block">
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.TRANSIT}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.transitInformation || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.INSTITUTION}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.institutionInformation || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-row">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <div>
                        <span className="default-font light-gray-text font-600">{Labels.FORM_PREVIEW.ACCOUNT}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="right-panel">
                        <span className="default-font black-text font-600">{this.props.applicationData.accountInformation || appConfig.NO_VALUE}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* upload */}
            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text upload">{Labels.FORM_PREVIEW.DOCUMENTS}</span>
              </div>
              {
                allDocuments.ownDocuments.length === 0 && allDocuments.spouseDocuments.length === 0 && allDocuments.dependant1Documents.length === 0 && allDocuments.dependant2Documents.length === 0 && allDocuments.dependant3Documents.length === 0 && allDocuments.dependant4Documents.length === 0 && allDocuments.dependant5Documents.length === 0 && allDocuments.dependant6Documents.length === 0 && allDocuments.adminDocuments.length === 0 ?
                  <div className="no-data-panel center h-auto pt-30">
                    <div><img src={ImageAssets.common.noDocumentsImg} alt="nodata" className="w-75 m-auto d-block"></img></div>
                    <div className="mt-20"><span className="large-font font-600">No Documents Found!</span></div>
                  </div>
                  : null
              }
              {/* document preview accordion */}
              <div className="upload-accordion">
                <Accordion defaultActiveKey="0">
                  {
                    allDocuments.ownDocuments.length > 0 ?
                      < Card >
                        <Card.Header className={this.state.activeBlockIndex === '0' ? "active" : ''} onClick={this.onBlockChange('0')}>
                          <Accordion.Toggle variant="link" eventKey="0">
                            {Messages.APPLICANT_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.ownDocuments.length
                                  ?
                                  <div className="document-display-container d-flex">
                                    {
                                      allDocuments.ownDocuments.map((eachDocument, index) => {
                                        return (
                                          <div className="document-box" key={index}>
                                            <div className="document-type-name">
                                              <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              <div className="name-tooltip">
                                                <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              </div>
                                            </div>
                                            <div className='position-relative'>
                                              <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type ,eachDocument.fileName)}></div>
                                              <div className="center document-block">
                                                <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                              </div>
                                              <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      : null
                  }
                  {
                    allDocuments.spouseDocuments.length > 0 ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '1' ? "active" : ''} onClick={this.onBlockChange('1')}>
                          <Accordion.Toggle variant="link" eventKey="1">
                            {Messages.SPOUSE_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.spouseDocuments.length
                                  ?
                                  <div className="document-display-container d-flex">
                                    {
                                      allDocuments.spouseDocuments.map((eachDocument, index) => {
                                        return (
                                          <div className="document-box" key={index}>
                                            <div className="document-type-name">
                                              <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              <div className="name-tooltip">
                                                <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              </div>
                                            </div>
                                            <div className='position-relative'>
                                              <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type , eachDocument.fileName)}></div>
                                              <div className="center document-block">
                                                <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                              </div>
                                              <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      : null
                  }
                  {
                    allDocuments.dependant1Documents.length > 0 || allDocuments.dependant2Documents.length > 0 || allDocuments.dependant3Documents.length > 0 || allDocuments.dependant4Documents.length > 0 || allDocuments.dependant5Documents.length > 0 || allDocuments.dependant6Documents.length > 0
                      ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '2' ? "active" : ''} onClick={this.onBlockChange('2')}>
                          <Accordion.Toggle variant="link" eventKey="2">
                            {Messages.DEPENDENT_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body className="custom-scrollbar">
                            <div className="row">
                              {
                                this.props.applicationData.dependants.map((eachDependant, index) => {
                                  let dependent = 'dependant' + (index + 1) + 'Documents'
                                  return (
                                    <div className="col-lg-6 mb-20" key={index}>
                                      <div className="dependent-title">
                                        <span className="dependent-title-text font-700">
                                          Dependant {index + 1}'s Documents :
                                    </span>
                                      </div>
                                      <div className="upload-block">
                                        {
                                          allDocuments[dependent].length
                                            ?
                                            <div className="document-display-container d-flex">
                                              {
                                                allDocuments[dependent].map((eachDocument, index) => {
                                                  return (
                                                    <div className="document-box" key={index}>
                                                      <div className="document-type-name">
                                                        <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                        <div className="name-tooltip">
                                                          <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                                        </div>
                                                      </div>
                                                      <div className='position-relative'>
                                                        <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type , eachDocument.fileName)}></div>
                                                        <div className="center document-block">
                                                          <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                                        </div>
                                                        <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                                      </div>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </div>
                                            :
                                            null
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      : null
                  }
                  {
                    allDocuments.adminDocuments.length > 0 ?
                      <Card>
                        <Card.Header className={this.state.activeBlockIndex === '3' ? "active" : ''} onClick={this.onBlockChange('3')}>
                          <Accordion.Toggle variant="link" eventKey="3">
                            {Messages.ADMIN_DOCUMENTS}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body className="custom-scrollbar">
                            <div className="upload-block">
                              {
                                allDocuments.adminDocuments.length
                                  ?
                                  <div className="document-display-container d-flex">
                                    {
                                      allDocuments.adminDocuments.map((eachDocument, index) => {
                                        return (
                                          <div className="document-box" key={index}>
                                            <div className="document-type-name">
                                              <span className="document-type-name-text meta-info">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              <div className="name-tooltip">
                                                <span className="name-tooltip-text">{this.props.documentTypesList[eachDocument.documentTypeId]}</span>
                                              </div>
                                            </div>
                                            <div className='position-relative'>
                                              <div className="image-overlay" onClick={this.onViewDocumentPopupOpen(eachDocument.uploadLink, eachDocument.type , eachDocument.fileName)}></div>
                                              <div className="center document-block">
                                                <img src={getDocumentThumbnail(eachDocument.type)} alt="tax-document" />
                                              </div>
                                              <Button type="button" className="download-icon" onClick={downloadDocument(eachDocument.uploadLink, eachDocument.fileName)} />
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      : null
                  }
                </Accordion>
              </div>
            </div>
            
            {/* notes */}
            <div className="preview-block">
              <div className="block-heading">
                <span className="block-heading-text large-font font-600 light-green-text notes">{Messages.NOTES}</span>
              </div>
              <div className="data-block notes-block">
                <div className="data-row">
                  <div className="row">
                    <div className="col-lg-12">
                      <span className="default-font black-text font-600">{this.props.applicationData.notes || appConfig.NO_VALUE}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            {
              this.props.role === appConfig.ROLE.USER && this.props.applicationData.isPayEnable
                ?
                <div className="d-flex button-panel">
                  <div>
                    <Button
                      type='submit'
                      parentClassName='mt-30'
                      className='btn btn-primary btn-small'
                      text={Messages.PAY_LATER}
                      onClick={this.onPayLaterClick}
                    />
                  </div>
                  <div className="pay-btn">
                    <Button
                      type='submit'
                      parentClassName='mt-30'
                      className={'btn btn-primary btn-small' + (!this.props.applicationData.isPayEnable ? ' disabled' : '')}
                      disabled={!this.props.applicationData.isPayEnable}
                      text={Messages.PAY_NOW}
                      onClick={this.onPayNowClick}
                    />
                  </div>
                </div>
                :
                <div className="center justify-content-start">
                  <Button
                    type='button'
                    parentClassName='application-btn mt-30'
                    className='btn btn-primary btn-medium'
                    text={Messages.VIEW_APPLICATIONS}
                    onClick={this.onPayLaterClick}
                  />
                </div>
            }
            <div className="pdf-btn">
              <Button
                type='button'
                parentClassName='mt-30'
                className='btn btn-primary btn-small ml-30 small-font btn-pdf'
                onClick={downloadDocument(this.props.applicationData.applicationLink, this.props.applicationData.displayAppId + '.pdf')}
                text={Messages.EXPORT_AS_PDF}
              />
            </div>
          </div>
          {
            (this.props.role === appConfig.ROLE.USER) && (!this.props.isEdit)
              ?
              <ApplicationConfirmation
                open={this.state.applicationConfirmation}
                onOkClick={this.onOkClick}
              />
              :
              null
          }
        </div>
        {
          this.state.viewDoc.link
            ?
            <ViewDocument
              open={this.state.viewDocumentPopup}
              fileType={this.state.viewDoc.fileType}
              link={this.state.viewDoc.link}
              onCancelClick={this.onCancelClick}
            />
            :
            null
        }
      </div >
    )
  }
}

FormPreview.propTypes = {}

const mapStateToProps = (state) => ({
  role: state.auth.role,
  applicationData: state.taxApplication.applicationSetup,
  documentTypesList: state.list.documentTypesList
})

export default connect(mapStateToProps, { getSingleApplication, getDocument })(FormPreview)
