import React, { Component } from 'react'
import validator from 'validator'
import moment from 'moment'
import axios from 'axios'
import { DatePicker } from '@material-ui/pickers'
import MaterialUITextField from '@material-ui/core/TextField'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import TextField from '../../component/common/TextField'
import { ErrorMessages } from '../../constants/ErrorMessages'
import { InputIds, Labels, Messages, Placeholders, TooltipMessages } from '../../constants/StaticTexts'
import Button from '../common/Button'
import { appConfig } from '../../constants/AppConfig'
// import CustomDropDown from '../common/CustomDropDown'
import MobileNumberVerification from '../screen-popup/MobileNumberVerification'
import SessionSelection from '../screen-popup/SessionSelection'
import setLoading from '../../utils/LoadingManager'
import { APIRoutes } from '../../constants/BackendRoutes'
import { getErrorMessage } from '../../utils/GetErrorMessage'
import { increaseApplicationStep } from '../../actions/applicationActions'
import { getAddressList } from '../../actions/canadaPostLocationAction'
import { generatePropsObject, mobileNumberFormat } from '../../utils/Utils'
import setAuthToken from '../../interceptors/SetAuthToken'
import store from '../../store'
import { AUTH_SET_USER, REMOVE_SUGGESTED_ADDRESS } from '../../constants/ActionTypes'
import CustomDropDownWithSearchBox from '../common/CustomDropDownWithSearchBox'
import CustomDropDown from '../common/CustomDropDown'

class AboutYourself extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasReturnBefore: this.props.applicationData.hasReturnBefore,
      filingReturnFirstTime: this.props.applicationData.filingReturnFirstTime,
      dateOfLanding: { value: this.props.applicationData.dateOfLanding, error: null },
      taxYears: this.props.applicationData.taxYears,
      firstname: { value: this.props.applicationData.firstname, error: null },
      lastname: { value: this.props.applicationData.lastname, error: null },
      sin: { value: this.props.applicationData.sin, error: null },
      dateOfBirth: { value: this.props.applicationData.dateOfBirth, error: null },
      address: { value: '', error: null },
      unit: { value: this.props.applicationData.unit, error: null },
      streetNumber: { value: this.props.applicationData.streetNumber, error: null },
      streetName: { value: this.props.applicationData.streetName, error: null },
      province: this.props.applicationData.province,
      city: this.props.applicationData.city,
      postalCode: { value: this.props.applicationData.postalCode, error: null },
      email: { value: this.props.applicationData.email, error: null },
      phone: { value: this.props.applicationData.phone, error: null },
      referenceName: { value: this.props.applicationData.referenceDetails && this.props.applicationData.referenceDetails.name ? this.props.applicationData.referenceDetails.name : '', error: null },
      referenceEmail: { value: this.props.applicationData.referenceDetails && this.props.applicationData.referenceDetails.email ? this.props.applicationData.referenceDetails.email : '', error: null },
      enterManual: false,
      mobileNumberVerification: false,
      sessionSelection: false,
      applicationId: '',
      userId: '',
      otpSendTo: '',
      otp: '',
    }
  }

  componentDidUpdate() {
    let { applicationData } = this.props
    if (applicationData.province && !this.state.province && (this.state.province !== applicationData.province)) {
      this.setState({
        hasReturnBefore: applicationData.hasReturnBefore,
        filingReturnFirstTime: applicationData.filingReturnFirstTime,
        dateOfLanding: { value: applicationData.dateOfLanding, error: null },
        taxYears: applicationData.taxYears,
        firstname: { value: applicationData.firstname, error: null },
        lastname: { value: applicationData.lastname, error: null },
        sin: { value: applicationData.sin, error: null },
        dateOfBirth: { value: applicationData.dateOfBirth, error: null },
        address: { value: '', error: null },
        unit: { value: applicationData.unit, error: null },
        streetNumber: { value: applicationData.streetNumber, error: null },
        streetName: { value: applicationData.streetName, error: null },
        province: applicationData.province,
        city: applicationData.city,
        postalCode: { value: applicationData.postalCode, error: null },
        email: { value: applicationData.email, error: null },
        phone: { value: applicationData.phone, error: null },
        applicationId: applicationData.applicationId,
        userId: applicationData.userId,
        referenceName: { value: this.props.applicationData.referenceDetails && this.props.applicationData.referenceDetails.name ? this.props.applicationData.referenceDetails.name : '', error: null },
        referenceEmail: { value: this.props.applicationData.referenceDetails && this.props.applicationData.referenceDetails.email ? this.props.applicationData.referenceDetails.email : '', error: null },
      })
    }
  }

  onInputChange = (inputId) => (dropdownValue) => (event) => {
    if (inputId === InputIds.FILLED_RETURN_BEFORE) {
      if (dropdownValue) {
        this.setState({ hasReturnBefore: dropdownValue })
      }
    } else if (inputId === InputIds.FILING_RETURN_FOR_FIRST_TIME) {
      if (dropdownValue) {
        this.setState({ filingReturnFirstTime: dropdownValue, taxYears: '', dateOfLanding: { value: this.props.applicationData.dateOfLanding, error: null } })
      }
    } else if (inputId === InputIds.TAX_YEARS) {
      if (dropdownValue) {
        this.setState({ taxYears: dropdownValue })
      } else {
        this.setState({ taxYears: '' })
      }
    } else if (inputId === InputIds.FIRSTNAME) {
      if (event.target.value) {
        this.setState({
          firstname: {
            value: event.target.value,
            error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.FIRSTNAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          firstname: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.LASTNAME) {
      if (event.target.value) {
        this.setState({
          lastname: {
            value: event.target.value,
            error: validator.trim(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.LASTNAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          lastname: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.SIN) {
      if (event.target.value) {
        if (Number(event.target.value) && (String(event.target.value.length) <= appConfig.MAX_LENGTH.SIN)) {
          this.setState({
            sin: {
              value: event.target.value,
              error: validator.isNumeric(event.target.value) && validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.SIN, max: appConfig.MAX_LENGTH.SIN }) ? null : ErrorMessages.SIN_IS_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          sin: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.ADDRESS) {
      if (event.target.value) {
        this.setState({ address: { value: event.target.value, error: null } })
      } else {
        this.setState({
          address: { value: '', error: null },
          unit: { value: '', error: null },
          streetNumber: { value: '', error: null },
          streetName: { value: '', error: null },
          province: '',
          city: '',
          postalCode: { value: '', error: null }
        })
      }
      this.props.getAddressList(event.target.value)
    } else if (inputId === InputIds.UNIT) {
      if (event.target.value) {
        this.setState({
          unit: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { max: appConfig.MAX_LENGTH.UNIT }) ? null : ErrorMessages.UNIT_IS_NOT_VALID
          }
        })
      } else {
        this.setState({
          unit: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.STREET_NUMBER) {
      if (event.target.value) {
        this.setState({
          streetNumber: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { max: appConfig.MAX_LENGTH.STREET_NUMBER }) ? null : ErrorMessages.STREET_NUMBER_NOT_VALID
          }
        })
      } else {
        this.setState({
          streetNumber: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.STREET_NAME) {
      if (event.target.value) {
        this.setState({
          streetName: {
            value: event.target.value,
            error: event.target.value ? null : ErrorMessages.STREET_NAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          streetName: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.POSTAL_CODE) {
      if (event.target.value) {
        this.setState({
          postalCode: {
            value: event.target.value,
            error: validator.isPostalCode(event.target.value, 'CA') ? null : ErrorMessages.POSTAL_CODE_NOT_VALID
          }
        })
      } else {
        this.setState({
          postalCode: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
          }
        })
      } else {
        this.setState({
          email: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.PHONE) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)
        if (formatedNumber) {
          this.setState({
            phone: {
              value: formatedNumber,
              error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          phone: {
            value: '',
            error: null
          }
        })
      }
    }
    else if (inputId === InputIds.PHONE) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)
        if (formatedNumber) {
          this.setState({
            phone: {
              value: formatedNumber,
              error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
            }
          })
        }
      } else {
        this.setState({
          phone: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.REFRENCES_NAME) {
      if (event.target.value) {
        this.setState({
          referenceName: {
            value: event.target.value,
            error: null
          }
        })
      } else {
        this.setState({
          referenceName: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.REFRENCES_EMAIL) {
      if (event.target.value) {
        this.setState({
          referenceEmail: {
            value: event.target.value,
            error: event.target.value ? validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID : null
          }
        })

      } else {
        this.setState({
          referenceEmail: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.DATE_OF_BIRTH) {
      if (event.target.value) {
        this.setState({
          dateOfBirth: {
            value: event.target.value,
            error: validator.isDate(new Date(event.target.value)) ? null : ErrorMessages.DATE_OF_BIRTH_NOT_VALID
          }
        })
      } else {
        this.setState({
          dateOfBirth: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onAddressSelect = (addressObject) => () => {
    let unitNumberName = addressObject.Text.split(' ')
    let unitNumber = unitNumberName[0].split('-')
    let unit = ''
    let street = ''
    if (unitNumber.length > 1) {
      unit = unitNumber[0]
      street = unitNumber[1]
    } else {
      street = unitNumber[0]
    }
    unitNumberName.shift()
    let cityProvincePostal = addressObject.Description.split(',')
    if (!unitNumberName.join(' ') || !appConfig.PROVINCE_OBJECT[cityProvincePostal[1].trim()] || !cityProvincePostal[0].trim() || !cityProvincePostal[2].trim()) {
      this.setState({ address: { value: addressObject.Text + ', ' + addressObject.Description, error: ErrorMessages.ADDRESS_NOT_VALID } })
    } else {
      this.setState({
        address: { value: addressObject.Text + ', ' + addressObject.Description, error: null },
        unit: { value: unit, error: null },
        streetNumber: { value: street, error: null },
        streetName: { value: unitNumberName.join(' '), error: null },
        province: appConfig.PROVINCE_OBJECT[cityProvincePostal[1].trim()],
        city: cityProvincePostal[0].trim(),
        postalCode: { value: cityProvincePostal[2].trim(), error: null }
      })
    }
    store.dispatch({ type: REMOVE_SUGGESTED_ADDRESS })
  }

  onProvinceDropdownChange = (value) => {
    this.setState({ province: value, city: '' })
  }

  onCityDropdownChange = (value) => {
    this.setState({ city: value })
  }

  onDateChange = (inputId) => (value) => {
    if (inputId === InputIds.DATE_OF_LANDING) {
      if (value) {
        this.setState({
          dateOfLanding: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.DATE_OF_LANDING_NOT_VALID
          }
        })
      } else {
        this.setState({
          dateOfLanding: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.DATE_OF_BIRTH) {
      if (value) {
        this.setState({
          dateOfBirth: {
            value: value,
            error: validator.isDate(new Date(value)) ? null : ErrorMessages.DATE_OF_BIRTH_NOT_VALID
          }
        })
      } else {
        this.setState({
          dateOfBirth: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onSaveClick = () => {
    if (this.props.auth.email) {
      this.onStepUpdate()
    } else {
      setLoading(true)
      let requestBody = {
        firstName: this.state.firstname.value,
        lastName: this.state.lastname.value,
        email: this.state.email.value,
        phoneCode: appConfig.MOBILE_CODE,
        phoneNumber: this.state.phone.value
      }
      axios.post(APIRoutes.AUTH.CHECK_APPLICATION_EXIST, requestBody)
        .then(res => {
          if (res.data.code === 200) {
            this.setState({
              mobileNumberVerification: true,
              applicationId: res.data.result.applicationId,
              userId: res.data.result._id,
              otpSendTo: res.data.result.sendTo
            })
          } else if (res.data.code === 201) {
            // save token & refresh token & proceed as new user
            let { token, refreshToken } = res.data.result
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            let setAuthUser = {
              isAuthenticated: true,
              email: res.data.result.email,
              firstName: res.data.result.firstName,
              lastName: res.data.result.lastName,
              _id: res.data.result._id,
              role: res.data.result.role,
              partialLogin: res.data.result.partialLogin || false
            }
            store.dispatch({
              type: AUTH_SET_USER,
              payload: setAuthUser
            })
            this.setState({ userId: res.data.result._id })
            setAuthToken(token)
            this.onStepUpdate()
          }
          setLoading(false)
        }).catch(err => {
          setLoading(false)
          toast.error(getErrorMessage(err))
        })
    }
  }

  onResendOTPClick = () => {
    this.setState({ otp: '' })
    this.onSaveClick()
  }

  onCancelClick = () => {
    this.setState({ mobileNumberVerification: false, sessionSelection: false })
  }

  onOTPChange = (value) => {
    this.setState({ otp: value })
  }

  onOTPSubmitClick = () => {
    setLoading(true)
    let requestBody = {
      emailOrPhone: this.state.otpSendTo,
      otp: this.state.otp
    }
    axios.post(APIRoutes.AUTH.PATIALLY_LOGIN, requestBody)
      .then(res => {
        let { token, refreshToken } = res.data.result
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        setAuthToken(token)
        store.dispatch({
          type: AUTH_SET_USER,
          payload: res.data.result
        })
        if (this.state.applicationId) {
          this.setState({
            userId: res.data.result._id,
            mobileNumberVerification: false,
            sessionSelection: true
          })
        } else {
          this.setState({ userId: res.data.result._id })
          this.onStepUpdate(res.data.result._id)
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toast.error(getErrorMessage(err))
      })
  }

  onSessionSelectionClick = (step) => () => {
    if (step) {
      setLoading(true)
      axios.delete(APIRoutes.APPLICATION.DELETE + this.state.userId + '/' + this.state.applicationId)
        .then(res => {
          this.onStepUpdate()
          setLoading(false)
        }).catch(err => {
          setLoading(false)
          toast.error(getErrorMessage(err))
        })
    } else {
      setLoading(true)
      axios.get(APIRoutes.APPLICATION.GET + this.state.userId + '/' + this.state.applicationId)
        .then(res => {
          this.setState({ sessionSelection: false })
          let propsObject = generatePropsObject(res.data.result)
          propsObject['step'] = 1
          propsObject['userId'] = this.state.userId
          propsObject['applicationId'] = this.state.applicationId
          this.props.increaseApplicationStep(propsObject)
          setLoading(false)
        }).catch(err => {
          setLoading(false)
          toast.error(getErrorMessage(err))
        })
    }
  }

  onEnterManualClick = (id) => (event) => {
    if (id === InputIds.ENTER_MANUAL) {
      this.setState({ enterManual: event.target.checked })
    }
  }

  onStepUpdate = (userId = null) => {
    this.props.onClick({
      userId: userId ? userId : (this.state.userId ? this.state.userId : this.props.auth.userId),
      hasReturnBefore: this.state.hasReturnBefore,
      filingReturnFirstTime: this.state.filingReturnFirstTime,
      dateOfLanding: this.state.dateOfLanding.value,
      taxYears: this.state.taxYears,
      firstname: this.state.firstname.value,
      lastname: this.state.lastname.value,
      sin: this.state.sin.value,
      dateOfBirth: this.state.dateOfBirth.value,
      unit: this.state.unit.value,
      streetNumber: this.state.streetNumber.value,
      streetName: this.state.streetName.value,
      province: this.state.province,
      city: this.state.city,
      postalCode: this.state.postalCode.value,
      email: this.state.email.value,
      phone: this.state.phone.value,
      referenceDetails: {
        name: this.state.referenceName.value,
        email: this.state.referenceEmail.value
      }
    })
  }

  render() {
    let disabledButton = this.state.hasReturnBefore && this.state.filingReturnFirstTime && this.state.firstname.value && !this.state.firstname.error && this.state.lastname.value && !this.state.lastname.error && this.state.dateOfBirth.value && !this.state.dateOfBirth.error && !this.state.unit.error && !this.state.streetNumber.error && this.state.streetName.value && !this.state.streetName.error && this.state.province && this.state.city && this.state.postalCode.value && !this.state.postalCode.error && this.state.email.value && !this.state.email.error && this.state.phone.value && !this.state.phone.error
    if (disabledButton && this.state.filingReturnFirstTime === appConfig.FILING_RETURN_FOR_FIRST_TIME[0]) {
      disabledButton = disabledButton && this.state.dateOfLanding.value
    } else if (disabledButton && this.state.filingReturnFirstTime === appConfig.FILING_RETURN_FOR_FIRST_TIME[1]) {
      disabledButton = disabledButton && this.state.taxYears
    }


    return (
      <div className="tax-form">
        <div className="container-block">
          <div className="step-heading">
            <span className="step-heading-text font-700">
              {Messages.ABOUT_YOU}
            </span>
          </div>
          <div className="form-container">
            <div className="d-flex form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.FILLED_RETURN}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  onChange={this.onInputChange(InputIds.FILLED_RETURN_BEFORE)}
                  list={appConfig.FILLED_RETURN_BEFORE}
                  value={this.state.hasReturnBefore}
                />
              </div>
            </div>
            <div className="d-flex form-field-row">
              <div className="form-field">
                <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.FILING_RETURN_FOR_FIRST_TIME}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.SELECT}
                  onChange={this.onInputChange(InputIds.FILING_RETURN_FOR_FIRST_TIME)}
                  list={appConfig.CRA_ACCOUNT_OPTIONS}
                  value={this.state.filingReturnFirstTime}
                />
              </div>
            </div>
            {
              this.state.filingReturnFirstTime === appConfig.CRA_ACCOUNT_OPTIONS[0]
                ?
                <div className="d-flex form-field-row">
                  <div className="form-field">
                    <DatePicker
                      disableFuture
                      inputFormat={appConfig.DATE_FORMAT}
                      label={Labels.DATE_OF_LANDING}
                      placeholder={Placeholders.DATE}
                      views={["year", "month", "date"]}
                      InputProps={{ className: 'input-block' }}
                      disabled={this.props.disabled}
                      renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' inputProps={{ ...props.inputProps, readOnly: true }} />)}
                      onChange={this.onDateChange(InputIds.DATE_OF_LANDING)}
                      value={this.state.dateOfLanding.value}
                    />
                  </div>
                </div>
                :
                this.state.filingReturnFirstTime === appConfig.CRA_ACCOUNT_OPTIONS[1]
                  ?
                  <div className="d-flex form-field-row">
                    <div className="form-field">
                      <CustomDropDown
                        parentClassName='form-group'
                        labelText={Labels.TAX_YEARS}
                        labelClassName='default-font black-text font-600'
                        placeholder={Placeholders.SELECT}
                        disabled={this.props.disabled}
                        onChange={this.onInputChange(InputIds.TAX_YEARS)}
                        list={appConfig.NO_OF_TAX_YEARS}
                        value={this.state.taxYears}
                      />
                    </div>
                  </div>
                  :
                  null
            }
            <div className="d-flex form-field-row">
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.FIRSTNAME}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.FIRSTNAME}
                  onChange={this.onInputChange(InputIds.FIRSTNAME)}
                  {...this.state.firstname}
                />
              </div>
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.LASTNAME}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.LASTNAME}
                  onChange={this.onInputChange(InputIds.LASTNAME)}
                  {...this.state.lastname}
                />
              </div>
            </div>
            <div className="d-flex form-field-row">
              {
                [appConfig.ROLE.OWNER_ADMIN, appConfig.ROLE.ADMIN, appConfig.ROLE.ACCOUNTANT].includes(this.props.auth.role)
                  ?
                  <div className="form-field">
                    <TextField
                      parentClassName='form-group'
                      labelText={Labels.SIN}
                      labelClassName='default-font black-text font-600'
                      type='text'
                      className='form-input'
                      inputDivClassName='input-panel'
                      placeholder={Placeholders.TYPE_HERE}
                      onChange={this.onInputChange(InputIds.SIN)}
                      {...this.state.sin}
                    />
                  </div>
                  :
                  null
              }
              <div className="form-field">
                <DatePicker
                  maxDate={moment().utc().subtract(18, 'years')}
                  inputFormat={appConfig.DATE_FORMAT}
                  label={Labels.DATE_OF_BIRTH}
                  value={this.state.dateOfBirth.value}
                  views={["year", "month", "date"]}
                  placeholder={Placeholders.DATE}
                  disabled={this.props.disabled}
                  InputProps={{ className: 'input-block datepicker-block' }}
                  renderInput={(props => <MaterialUITextField {...props} helperText='' className='form-input datepicker-block' 
                  inputProps={{ ...props.inputProps,readOnly: true}} />)}
                  onChange={this.onDateChange(InputIds.DATE_OF_BIRTH)}
                />
              </div>

              {/* <div  className="form-field">
              <TextField
                    parentClassName='form-group flex1'
                    labelText={Labels.DATE_OF_BIRTH}
                    labelClassName='default-font black-text font-600'
                    type='date'
                    className='form-input'
                    inputDivClassName='input-panel address-panel'
                    disabled={this.props.disabled || this.state.enterManual}
                    placeholder={Placeholders.TYPE_HERE_SUGGESTION}
                    value={this.state.dateOfBirth.value}
                    onChange={this.onInputChange(InputIds.DATE_OF_BIRTH)}
                    {...this.state.dateOfBirth}
                  />
              </div> */}

            </div>
            <div className="form-field-row">
              <div className="form-field address-field">
                <div className="d-flex">
                  <TextField
                    parentClassName='form-group flex1'
                    labelText={Labels.ADDRESS}
                    labelClassName='default-font black-text font-600'
                    type='text'
                    className='form-input'
                    inputDivClassName='input-panel address-panel'
                    disabled={this.props.disabled || this.state.enterManual}
                    placeholder={Placeholders.TYPE_HERE_SUGGESTION}
                    onChange={this.onInputChange(InputIds.ADDRESS)}
                    {...this.state.address}
                  />
                  <div className="custom-checkbox ml-20 mt-35">
                    <label className="center">
                      <input type="checkbox" name="check" className="custom-control-input form-control" onChange={this.onEnterManualClick(InputIds.ENTER_MANUAL)} checked={this.state.enterManual ? true : false} disabled={this.props.disabled} />
                      <span className="checkbox-span"></span> Enter Manually
                    </label>
                  </div>
                </div>
                {
                  this.props.addressList.length
                    ?
                    <div className="address-suggestion-list">
                      {
                        this.props.addressList.map((eachAddress, index) => {
                          return (
                            <div className="suggestion-item" key={index} onClick={this.onAddressSelect(eachAddress)}><span className="small-font">{eachAddress.Text + ', ' + eachAddress.Description}</span></div>
                          )
                        })
                      }
                    </div>
                    :
                    null
                }
              </div>
            </div>

            <div className="d-flex form-field-row">
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.UNITS}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled || !this.state.enterManual}
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.UNIT)}
                  {...this.state.unit}
                />
              </div>
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.STREET_NUMBER}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled || !this.state.enterManual}
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.STREET_NUMBER)}
                  {...this.state.streetNumber}
                />
              </div>
              <div className="d-flex form-field align-items-center">
                <div className="tootltip-field">
                  <TextField
                    parentClassName='form-group'
                    labelText={Labels.STREET_NAME}
                    labelClassName='default-font black-text font-600'
                    type='text'
                    className='form-input'
                    inputDivClassName='input-panel'
                    disabled={this.props.disabled || !this.state.enterManual}
                    placeholder={Placeholders.TYPE_HERE}
                    onChange={this.onInputChange(InputIds.STREET_NAME)}
                    tooltipText={TooltipMessages.STREET_NAME}
                    {...this.state.streetName}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex form-field-row align-items-center">
              <div className="form-field">
                {/* <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.PROVINCE}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.PROVINCE}
                  onChange={this.onInputChange(InputIds.PROVINCE)}
                  list={Object.keys(appConfig.PROVINCE_LIST)}
                  value={this.state.province}
                /> */}
                <CustomDropDownWithSearchBox
                  parentClassName='form-group'
                  labelText={Labels.PROVINCE}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled || !this.state.enterManual}
                  placeholder={Placeholders.PROVINCE}
                  onChange={this.onProvinceDropdownChange}
                  list={Object.keys(appConfig.PROVINCE_LIST)}
                  value={this.state.province}
                />
              </div>
              <div className="form-field">
                {/* <CustomDropDown
                  parentClassName='form-group'
                  labelText={Labels.CITY}
                  labelClassName='default-font black-text font-600'
                  placeholder={Placeholders.CITY}
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled ? true : (this.state.province ? false : true)}
                  onChange={this.onInputChange(InputIds.CITY)}
                  list={appConfig.PROVINCE_LIST[this.state.province] || []}
                  value={this.state.city}
                /> */}
                <CustomDropDownWithSearchBox
                  parentClassName='form-group'
                  labelText={Labels.CITY}
                  labelClassName='default-font black-text font-600'
                  inputDivClassName='input-panel'
                  disabled={(this.props.disabled || !this.state.enterManual) ? true : (this.state.province ? false : true)}
                  placeholder={Placeholders.CITY}
                  onChange={this.onCityDropdownChange}
                  list={appConfig.PROVINCE_LIST[this.state.province] || []}
                  value={this.state.city}
                />
              </div>
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.POSTAL_CODE}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled || !this.state.enterManual}
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.POSTAL_CODE)}
                  {...this.state.postalCode}
                />
              </div>
            </div>
            <div className="d-flex form-field-row">
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.EMAIL}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.EMAIL)}
                  {...this.state.email}
                />
              </div>
              <div className="form-field phonenumber-panel">
                <div className="number-prefix">+1</div>
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.MOBILE_NUMBER}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  disabled={this.props.disabled}
                  placeholder={Placeholders.MOBILE_NUMBER}
                  onChange={this.onInputChange(InputIds.PHONE)}
                  {...this.state.phone}
                />
              </div>
            </div>
            <div className="d-flex form-field-row">
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.RFERENCE_NAME}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.REFRENCES_NAME)}
                  {...this.state.referenceName}
                />
              </div>
              <div className="form-field">
                <TextField
                  parentClassName='form-group'
                  labelText={Labels.RFERENCE_EMAIL}
                  labelClassName='default-font black-text font-600'
                  type='text'
                  className='form-input'
                  inputDivClassName='input-panel'
                  placeholder={Placeholders.TYPE_HERE}
                  onChange={this.onInputChange(InputIds.REFRENCES_EMAIL)}
                  {...this.state.referenceEmail}
                />
              </div>
            </div>
            <Button
              type='submit'
              parentClassName='mt-30'
              className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
              disabled={!disabledButton}
              text={Messages.SAVE_AND_CONTINUE}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
        <MobileNumberVerification
          open={this.state.mobileNumberVerification}
          onSubmitClick={this.onOTPSubmitClick}
          onCancelClick={this.onCancelClick}
          otpSendTo={this.state.otpSendTo}
          onOTPChange={this.onOTPChange}
          onResendOTPClick={this.onResendOTPClick}
          otp={this.state.otp}
        />
        <SessionSelection
          open={this.state.sessionSelection}
          onSubmitClick={this.onSessionSelectionClick}
          onCancelClick={this.onCancelClick}
        />
      </div >
    )
  }
}

AboutYourself.propTypes = {}

const mapStateToProps = (state) => ({
  auth: state.auth,
  addressList: state.address.addressList
})

export default connect(mapStateToProps, { increaseApplicationStep, getAddressList })(AboutYourself)
