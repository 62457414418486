import axios from 'axios'
import { toast } from 'react-toastify'
import setLoading from '../utils/LoadingManager'
import { APIRoutes } from '../constants/BackendRoutes'
import { getErrorMessage } from '../utils/GetErrorMessage'
import { DELETE_NEWSLETTER, GET_NEWSLETTER_LIST } from '../constants/ActionTypes'

export const newsletterAddAction = (requestObject) => () => {
  setLoading(true)
  axios.post(APIRoutes.NEWSLETTER_SUBSCRIPTION.ADD, requestObject)
    .then(res => {
      toast.success(res.data.message)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const newsletterListAction = (userId, requestObject) => (dispatch) => {
  setLoading(true)
  axios.post(APIRoutes.NEWSLETTER_SUBSCRIPTION.LIST + userId + '/list', requestObject)
    .then(res => {
      dispatch({
        type: GET_NEWSLETTER_LIST,
        payload: res.data.result,
        currentPage: requestObject.page
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}

export const deleteNewsletterAction = (newsletterId, deleteNewsletterId, newsletterIndex) => (dispatch) => {
  setLoading(true)
  axios.delete(APIRoutes.NEWSLETTER_SUBSCRIPTION.DELETE + newsletterId + '/' + deleteNewsletterId)
    .then(res => {
      toast.success(res.data.message)
      dispatch({
        type: DELETE_NEWSLETTER,
        payload: newsletterIndex
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast.error(getErrorMessage(err))
    })
}