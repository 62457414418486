import React, { Component } from 'react'
import validator from 'validator'
import { connect } from 'react-redux'
import ImageAssets from '../../utils/ImageAssets'
import TextField from '../common/TextField'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { InputIds, Placeholders, Messages } from '../../constants/StaticTexts'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import Button from '../common/Button'
import { contactUsAction } from '../../actions/commonActions'
import { mobileNumberFormat } from '../../utils/Utils'

class Contactus extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: { value: '', error: null },
      email: { value: '', error: null },
      phone: { value: '', error: null },
      message: { value: '', error: null }
    }
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.NAME) {
      if (event.target.value) {
        this.setState({
          name: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.NAME_NOT_VALID
          }
        })
      } else {
        this.setState({
          name: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        this.setState({
          email: {
            value: event.target.value,
            error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
          }
        })
      } else {
        this.setState({
          email: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.PHONE) {
      if (event.target.value) {
        let formatedNumber = mobileNumberFormat(event.target.value)
        this.setState({
          phone: {
            value: formatedNumber,
            error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
          }
        })
      } else {
        this.setState({
          phone: {
            value: '',
            error: null
          }
        })
      }
    } else if (inputId === InputIds.MESSAGE) {
      if (event.target.value) {
        this.setState({
          message: {
            value: event.target.value,
            error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.MESSAGE.MIN, max: appConfig.MAX_LENGTH.MESSAGE.MAX }) ? null : ErrorMessages.MESSAGE_NOT_VALID
          }
        })
      } else {
        this.setState({
          message: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onContactUsClick = () => {
    let requestBody = {
      name: this.state.name.value,
      email: this.state.email.value,
      contactNumber: this.state.phone.value,
      message: this.state.message.value
    }
    if (this.state.phone.value) {
      requestBody['contactNumber'] = appConfig.MOBILE_CODE + ' ' + this.state.phone.value
    }
    this.props.contactUsAction(requestBody)
    this.setState({
      name: { value: '', error: null },
      email: { value: '', error: null },
      phone: { value: '', error: null },
      message: { value: '', error: null }
    })
  }

  render() {

    let disabledButton = this.state.name.value && !this.state.name.error && this.state.email.value && !this.state.email.error && this.state.message.value && !this.state.message.error

    return (
      <div>
        <Header page={Messages.CONTACT_US} />
        <div className="banner-section inner-banner-section contactus-banner-section">
          <div className="lotus-pattern-panel"><img src={ImageAssets.home.lotusImg} alt="lotus" className="image-responsive"></img></div>
          <div className="slant-img-panel"><img src={ImageAssets.aboutUs.slantImg} alt="slant" className="image-responsive"></img></div>
          <div className="container-block">
            <div className="row align-items-end">
              <div className="col-lg-5">
                <div className="section-heading-panel">
                  <h1 className="banner-heading-text light-green-text font-900 mb-20">Contact Us</h1>
                  <h3 className="banner-subheading-text light-gray-text large-font">Whether you want to transform your business, advance your career, or simply find out more about what we are doing, this is the place</h3>
                </div>
              </div>
              <div className="col-lg-7 d-lg-block d-md-none d-none">
                <div className="banner-description text-right">
                  <h1 className="white-text font-900 banner-heading">How Can We Work Together?</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-mobile-section contactus-mobile-section inner-mobile-section d-lg-none d-md-block">
          <div className="banner-description">
            <h1 className="white-text font-900 banner-heading text-right mb-0">How Can We Work Together?</h1>
          </div>
        </div>
        <div className="contact-section">
          <div className="container-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="get-in-touch-panel">
                  <div className="section-heading-panel">
                    <h4 className="preheading-text mb-30">KNOW US BETTER</h4>
                    <h2 className="heading-text font-800 mt-20">Get In Touch</h2>
                  </div>
                  <div>
                    <p className="mt-20">Whether you want to transform your business, advance your career, or simply find out more about what we’re doing, this is the place. Use the fields below and we’ll get your questions to the right person.</p>
                  </div>
                  <div className="contact-details-panel">
                    <div className="contact-item address-item">
                      <div><span className="large-font font-700">4915 Abbott Street East,</span></div>
                      <div><span className="large-font font-700">Stittsville, ON - K2V 0M6</span></div>
                    </div>
                    <div className="contact-item email-item">
                      <span className="large-font font-700">taxplanning@blissaccounting.ca</span>
                    </div>
                    <div className="contact-item phone-item">
                      <div className="pt-5"><span className="large-font font-700">+1(613)-327-8131</span></div>                    
                    </div>
                    <div className="contact-item fax-item">
                      <div className="pt-5"><span className="large-font font-700">+1-877-360-0526</span></div>                     
                    </div>
                    <div className="contact-item office-hours-item">
                      <div className="pt-5">
                        <div><span className="large-font font-700">Mon - Fri</span></div>
                        <div><span className="small-font">10:00 am - 06:00 pm</span></div>
                      </div>
                      <div className="pt-5 ml-50">
                        <div><span className="large-font font-700">Sat - Sun</span></div>
                        <div><span className="small-font">11:00 am - 02:00 pm</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-panel">
                  <div><span className="large-font font-800">Drop Us a Line</span></div>
                  <div className="contact-form mt-25">
                    <TextField
                      type='text'
                      parentClassName='form-group'
                      className='form-input'
                      placeholder={Placeholders.CONTACT_US.FULLNAME}
                      onChange={this.onInputChange(InputIds.NAME)}
                      {...this.state.name}
                    />
                    <TextField
                      type='text'
                      parentClassName='form-group'
                      className='form-input'
                      placeholder={Placeholders.CONTACT_US.EMAIL_ADDRESS}
                      onChange={this.onInputChange(InputIds.EMAIL)}
                      {...this.state.email}
                    />
                    <div className="form-field phonenumber-panel">
                      <div className="number-prefix">+1</div>
                      <TextField
                        type='text'
                        parentClassName='form-group'
                        className='form-input'
                        placeholder={Placeholders.CONTACT_US.CONTACT_NUMBER}
                        onChange={this.onInputChange(InputIds.PHONE)}
                        {...this.state.phone}
                      />
                    </div>
                    <TextField
                      type='text'
                      parentClassName='form-group'
                      className='form-input'
                      placeholder={Placeholders.CONTACT_US.MESSAGE}
                      onChange={this.onInputChange(InputIds.MESSAGE)}
                      {...this.state.message}
                    />
                    <Button
                      parentClassName='form-group'
                      className={'btn btn-small btn-primary' + (disabledButton ? '' : ' disabled')}
                      disabled={!disabledButton}
                      text={Messages.SUBMIT_NOW}
                      onClick={this.onContactUsClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="map-panel">
                  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.384395951395!2d-75.89556558423766!3d45.28045355370594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccdffbd76d248a5%3A0x53274eef5a95e4b4!2s4915%20Abbott%20St%20E%2C%20Stittsville%2C%20ON%20K2S%201E7%2C%20Canada!5e0!3m2!1sen!2sin!4v1613455945052!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

Contactus.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { contactUsAction })(Contactus)