import React from 'react'
import { Dropdown } from 'react-bootstrap'
import ImageAssets from '../../utils/ImageAssets'

class CustomDropDown extends React.Component {

  render() {
    return (
      <div className={this.props.parentClassName}>
        {
          this.props.labelText
            ?
            <div className={this.props.labelDivClassName}>
              <label className={this.props.labelClassName}>
                {this.props.labelText}
              </label>
            </div>
            :
            null
        }
        <div className={this.props.inputDivClassName}>
          <div className="input-block dropdown-block">
            <Dropdown>
              <Dropdown.Toggle className={(this.props.valueColorClassName ? this.props.valueColorClassName : '') + (this.props.disabled ? "dropdown-disable" : '')} disabled={this.props.disabled}>{this.props.value ? this.props.value : this.props.placeholder}</Dropdown.Toggle>
              <Dropdown.Menu id="dropdown-basic-button" title={this.props.value}>
                {
                  this.props.list.map((eachProvince, index) => {
                    return (
                      <Dropdown.Item onClick={this.props.onChange(eachProvince)} key={index}>{eachProvince}</Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {
            this.props.tooltipText
              ?
              <div className="tootltip-container ml-20 mt-10">
                <div className="tootltip-icon">
                  <img src={ImageAssets.formsvg.tooltipIcon} alt="tooltip-icon"></img>
                  <div className="tooltip-text">
                    <span className="tooltip-text-panel">
                      {this.props.tooltipText}
                    </span>
                  </div>
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
    )
  }
}

export default CustomDropDown