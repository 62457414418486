import React, { Component } from 'react'
import FormHeader from '../common/FormHeader'
import { Messages, Labels, Placeholders, InputIds } from '../../constants/StaticTexts'
import { connect } from 'react-redux'
import validator from 'validator'
import jwt_decode from 'jwt-decode'
import { getUserDetail } from '../../actions/authActions'
import { verifyOtherEmailPhone, addOtherEmailPhone, getOtherEmailPhone, deleteOtherEmailPhone } from '../../actions/userActions'
import { mobileNumberFormat } from '../../utils/Utils'
import TextField from '../common/TextField'
import ImageAssets from '../../utils/ImageAssets'
import { appConfig } from '../../constants/AppConfig'
import { ErrorMessages } from '../../constants/ErrorMessages'
import Button from '../common/Button'
import OtherEmailVerification from '../screen-popup/OtherEmailVerification'
import setLoading from '../../utils/LoadingManager'
import axios from 'axios'
import { APIRoutes } from '../../constants/BackendRoutes'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../utils/GetErrorMessage'
class MyProfile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isAddFlow: true,
            editPhoneNo: '',
            editEmail: '',
            phone: { value: '', error: null },
            email: { value: '', error: null },
            phoneData: [{
                phone: { value: '', edit: false, error: null },
            }],
            emailData: [{
                email: { value: '', edit: false, error: null },
            }],
            showOTP: false,
            phoneVerifyIndex: 0,
            emailVerifyIndex: 0,
            otp: '',
            emailOrPhone: '',
            // otherEmailPhoneNumber: [],
            showData: false,
            otherEmailId: 0,
            userId: props.location.state ? props.location.state.id : props.userId,
            subUserName: props.location.state ? props.location.state.firstName + ' ' + props.location.state.lastName : undefined,
            addEmail: false,
            emailDisabledButton: true,
            phoneDisabledButton: true,
            disabledAddNewEmail: true

        }
    }

    componentDidMount() {
        let token = localStorage.token
        // let refreshToken = localStorage.refreshToken
        let tokenData = jwt_decode(token)
        this.props.getUserDetail(tokenData.id, this.state.userId);
        // this.state.otherEmailPhoneNumber = JSON.parse(localStorage.getItem('otherEmailPhoneNumber'));
        // let otherEmail = localStorage.getItem('otherEmailPhoneNumber')
        // const data = otherEmail !== 'undefined' ? JSON.parse(otherEmail) : [];

        // this.setState({ otherEmailPhoneNumber:  data})
        this.props.getOtherEmailPhone(this.props.userId, this.state.userId)
    }


    onEditPhoneNoClick = (val, i) => (e) => {
        let updateObj = {}
        if (this.props.phoneCode && mobileNumberFormat(this.props.phoneNumber)) {
            const data = [...this.state.phoneData];
            data[i] = { phone: { ...data[i].phone, edit: true } }
            this.setState({ phoneData: data })
        }
        if (e) {
            if (val === appConfig.CONTACT_FOR.ADMIN) {
                updateObj['notes'] = this.props.singleApplication.notes
            }
            this.setState({ editPhoneNo: val, ...updateObj })
        }
    }
    onEditEmailClick = (val, i) => (e) => {
        let updateObj = {}
        if (this.props.email) {
            const data = [...this.state.emailData];
            data[i] = { email: { ...data[i].email, edit: true } }
            this.setState({ emailData: data })
        }
        if (e) {
            if (val === appConfig.EMAIL_FOR.ADMIN) {
                updateObj['notes'] = this.props.singleApplication.notes
            }
            this.setState({ editEmail: val, ...updateObj })
        }
    }

    onInputChange = (inputId, index) => () => (event) => {
        if (inputId === InputIds.PHONE) {

            if (event.target.value) {
                let formatedNumber = mobileNumberFormat(event.target.value)
                if (formatedNumber) {
                    const newaArray = [...this.state.phoneData];
                    let newdata = {
                        phone: {
                            value: formatedNumber,
                            edit: true,
                            error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID
                        }
                    }
                    newaArray[index] = newdata;
                    this.setState({
                        phoneData: [...newaArray],
                        phoneVerifyIndex: index,
                        phoneDisabledButton: !validator.isMobilePhone(formatedNumber, 'en-CA')
                    })
                }
            } else {
                const newaArray = [...this.state.phoneData];
                let newdata = {
                    phone: {
                        value: '',
                        edit: true,
                        error: ''
                    }
                }
                newaArray[index] = newdata;
                this.setState({
                    phoneData: [...newaArray],
                })
            }
        } else if (inputId === InputIds.EMAIL) {
            if (event.target.value) {
                const newaArray = [...this.state.emailData];
                let newdata = {
                    email: {
                        value: event.target.value,
                        edit: true,
                        error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID
                    }
                }
                newaArray[index] = newdata;
                this.setState({
                    emailData: [...newaArray],
                    emailVerifyIndex: index,
                    emailDisabledButton: !validator.isEmail(event.target.value)
                })
            } else {
                const newaArray = [...this.state.emailData];
                let newdata = {
                    email: {
                        value: '',
                        edit: true,
                        error: ''
                    }
                }
                newaArray[index] = newdata;
                this.setState({
                    emailData: [...newaArray],
                })
            }
        }
    }

    addContact = (i) => {
        const values = [...this.state.phoneData];
        if (values.length > 2) {
            return;
        }

        values.push({
            phone: { value: '', edit: true, error: null },
        })
        this.setState({
            phoneData: values,
            // editPhoneNo: appConfig.CONTACT_FOR.ADMIN
        })
    };

    addEmail = (i) => {
        const values = [...this.state.emailData];
        if (values.length > 2) {
            return;
        }
        values.push({
            email: { value: '', edit: true, error: null },
        })
        this.setState({
            emailData: values,
            addEmail: true
        })
    };

    RemoveContact = (i) => {
        const values = [...this.state.phoneData];
        if (values.length === 1) this.setState({ editPhoneNo: '' })
        // this.state.editPhoneNo = '';
        if (values.length > 1) values.pop();
        this.setState({
            phoneData: values,
            phoneVerifyIndex: i - 1,
        })
    };
    RemoveEmail = (i) => {
        const values = [...this.state.emailData];
        let newValue = values;
        if (values.length === 1) this.setState({ editEmail: '' })
        // this.state.editEmail = '';
        if (values.length > 1) newValue = values.filter((item, index) => index !== i);
        this.setState({
            emailData: newValue,
            emailVerifyIndex: i - 1,
            addEmail: false
        })
    };


    onSuccess = (res, param) => {
        this.setState({ showOTP: true });
        if (param) window.location.reload();
    }

    onError = (err) => {
        this.setState({ showOTP: false });
    }

    handleAddEmailClick = index => {
        this.setState({ emailVerifyIndex: index, emailOrPhone: "email", disabledAddNewEmail: false });
        this.setState({ isAddFlow: true });
        let requestObject = {
            emailOrPhone: this.state.emailData[index].email.value,
        }
        if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
            requestObject['otp'] = this.state.otp
            // requestObject['check'] = InputIds.LOGIN_PARAMS.NEW_PASSWORD
        }
        if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
            requestObject['otp'] = this.state.otp
            // requestObject['check'] = InputIds.LOGIN_PARAMS.TWO_STEP_AUTH
        }
        if (!this.state.showOTP) {
            // this.onSendOTP(requestObject)
            // this.props.verifyOtherEmailPhone(this.props.userId, this.props.userId, this.props.userId)
            this.props.addOtherEmailPhone(this.props.userId, this.state.userId, requestObject, (res) => this.onSuccess(res), (err) => this.onError(err))
        } else {
            // this.props.loginAction(requestObject, this.props.history)
            // this.props.verifyOtherEmailPhone(this.props.userId, this.props.userId, this.props.userId, requestObject)
            this.props.addOtherEmailPhone(this.props.userId, this.state.userId, requestObject, (res) => this.onSuccess(res), (err) => this.onError(err))
        }
    }

    deleteEmailPhone = (index, email) => {
        let requestObject = {
            emailOrPhone: email
        }
        this.props.deleteOtherEmailPhone(this.props.userId, this.state.userId, index, requestObject, () => window.location.reload())
    }

    handleOnlyVerifyEmail = index => {
        this.setState({ isAddFlow: false });
        this.setState({ showOTP: true, emailOrPhone: "email", otherEmailId: index });
        // this.props.verifyOtherEmailPhone(this.props.userId, this.state.userId, index);
        setLoading(true)
        axios.put(APIRoutes.USER.VERIFY_EMAIL_PHONE + this.props.userId + '/other/email-phone/verify/' + this.state.userId + '/' + index)
            .then(res => {
                toast.success(res.data.message)
                setLoading(false)
            }).catch(err => {
                console.log("verifyOtherEmailPhone", err)
                setLoading(false)
                toast.error(getErrorMessage(err))
            })
    }

    handleOnlyVerifyPhone = index => {
        this.setState({ isAddFlow: false });
        this.setState({ showOTP: true, emailOrPhone: "email", otherEmailId: index });
        // this.props.verifyOtherEmailPhone(this.props.userId, this.state.userId, index);
        setLoading(true)
        axios.put(APIRoutes.USER.VERIFY_EMAIL_PHONE + this.props.userId + '/other/email-phone/verify/' + this.state.userId + '/' + index)
            .then(res => {
                toast.success(res.data.message)
                setLoading(false)
            }).catch(err => {
                console.log("verifyOtherEmailPhone", err)
                setLoading(false)
                toast.error(getErrorMessage(err))
            })
    }
    handleAddPhoneClick = index => {
        this.setState({ showOTP: true, phoneVerifyIndex: index, emailOrPhone: "phone" });
        let requestObject = {
            // emailOrPhone:'+1 ' + this.state.phoneData[this.state.phoneVerifyIndex].phone.value,
            emailOrPhone: appConfig.MOBILE_CODE + ' ' + this.state.phoneData[index].phone.value.replace(/-/g, ''),

        }
        if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
            requestObject['otp'] = this.state.otp
            // requestObject['check'] = InputIds.LOGIN_PARAMS.NEW_PASSWORD
        }
        if (this.state.showOTP && this.state.otp && this.state.otp.length === 6) {
            requestObject['otp'] = this.state.otp
            // requestObject['check'] = InputIds.LOGIN_PARAMS.TWO_STEP_AUTH
        }
        if (!this.state.showOTP) {
            // this.onSendOTP(requestObject)
            this.props.addOtherEmailPhone(this.props.userId, this.state.userId, requestObject, (res) => this.onSuccess(res), (err) => this.onError(err))
        } else {
            // this.props.loginAction(requestObject, this.props.history)
            this.props.addOtherEmailPhone(this.props.userId, this.state.userId, requestObject, (res) => this.onSuccess(res), (err) => this.onError(err))
        }
    }

    handleSaveClick = () => {
        this.setState({ showData: true })
    }

    onOTPChange = (otpValue) => {
        this.setState({ otp: otpValue })
    }

    onOptSuccess = () => {
        window.location.reload();

    }

    onOTPSubmit = (param) => {
        let requestObject;
        if (this.state.isAddFlow) {
            requestObject = {
                'emailOrPhone': this.state.emailOrPhone === "email" ?
                    this.state.emailData[this.state.emailVerifyIndex].email.value :
                    (appConfig.MOBILE_CODE + ' ' + this.state.phoneData[this.state.phoneVerifyIndex].phone.value.replace(/-/g, '')),
                otp: this.state.otp
            }
            this.props.addOtherEmailPhone(this.props.userId, this.state.userId, requestObject, (res) => this.onSuccess(res, 'FROMOTP'), (err) => this.onError(err))
        } else {
            requestObject = {
                otp: this.state.otp
            }
            this.props.verifyOtherEmailPhone(this.props.userId, this.state.userId, this.state.otherEmailId, requestObject, () => this.onOptSuccess())
        }
    }



    onCancelClick = () => {
        window.location.reload();
        // this.props.getOtherEmailPhone(this.props.userId, this.props.userId)
        // this.setState({
        //     haveAccount: false,
        //     verifyAccount: false,
        //     email: { value: '', error: null },
        //     otp: '',
        //     showOTP: false,
        //     haveUseInPast: false,
        //     showData: false,

        // })
    }
    render() {
        const ohterEmails = this.props.otherEmailPhoneNumber.filter((el) => el.email);
        const otherPhone = this.props.otherEmailPhoneNumber.filter((el) => el.phoneNumber)
        return (
            <div>
                <FormHeader />
                <div className="user-panel-page">
                    <div className="container-block">
                        <div className="userpanel-view-section">
                            <div className="userpanel-block">
                                <div className="userpanel-heading about">
                                    <span className="userpanel-heading-text font-700">
                                        {this.state.subUserName ? 'About of ' + this.state.subUserName : Messages.ABOUT_YOU}
                                    </span>
                                </div>
                                <div className="userview-data-panel">
                                    <div className="row no-gutters">
                                        <div className="col-md-12">
                                            <div className="field-row userpanel-about-section">
                                                <div className="row no-gutters">
                                                    <div className="col-md-6 col-6">
                                                        <div className="title-panel h-100">
                                                            <span className="title-text font-600">{Labels.FORM_PREVIEW.CONTACT_NUMBER}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-6">
                                                        {this.state.phoneData.map((data, i) => {
                                                            return (
                                                                <div className="data-panel justify-content-between flex-wrap" key={i}>
                                                                    <div className="d-flex align-items-center">
                                                                        {this.state.phoneData[i].phone.edit
                                                                            ?
                                                                            <div className="phonenumber-panel" id="profile">
                                                                                <div className="number-prefix">+1</div>
                                                                                <TextField
                                                                                    type='text'
                                                                                    parentClassName='form-group flex1 mr-10 pl-10'
                                                                                    labelClassName='default-font black-text'
                                                                                    className='form-input pl-20'
                                                                                    inputDivClassName='input-panel'
                                                                                    placeholder={Placeholders.MOBILE_NUMBER}
                                                                                    onChange={this.onInputChange(InputIds.PHONE, i)}
                                                                                    {...this.state.phoneData[i]}
                                                                                    value={this.state.phoneData[i].phone.value}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div> <span className="data-text font-600">{this.props.phoneCode + ' ' + mobileNumberFormat(this.props.phoneNumber)}</span></div>
                                                                        }
                                                                        {/* <div>
                                                                            <img src={ImageAssets.formsvg.editIcon} className="ml-10" alt="edit-icon" onClick={this.onEditPhoneNoClick(appConfig.CONTACT_FOR.ADMIN, i)} />
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="d-flex justify-content-end icon-block pt-5">

                                                                        <div className="d-flex">
                                                                            {i === 0 ? '' :
                                                                                <div className="d-flex">
                                                                                    <div className="save-image-icon icon-panel ml-10">
                                                                                        <div className={this.state.phone.error ? "icon disabled" : ""}>
                                                                                            <img src={ImageAssets.formsvg.removeDocumentsIcon} alt="remove-icon" onClick={() => this.RemoveContact(i)} />
                                                                                        </div>
                                                                                        <div className="btn-tooltip">
                                                                                            <span className="btn-tooltip-text">{Labels.REMOVE}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="save-image-icon icon-panel ml-10">
                                                                                        <Button
                                                                                            type='submit'
                                                                                            className={'btn btn-primary btn-xsmall'}
                                                                                            text='Verify'
                                                                                            onClick={() => this.handleAddPhoneClick(i)}
                                                                                            disabled={this.state.phoneDisabledButton}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {(this.state.phoneData.length === i + 1 && this.state.phoneData.length !== 2 && otherPhone.length < 1) ?
                                                                                <div className="save-image-icon icon-panel ml-10">
                                                                                    <div className={this.state.phone.error ? "icon disabled" : ""}>
                                                                                        <Button
                                                                                            type='submit'
                                                                                            className={'btn btn-primary btn-xsmall'}
                                                                                            text='Add New'
                                                                                            onClick={this.addContact}
                                                                                        />
                                                                                    </div>
                                                                                </div> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                        {otherPhone.map((data, i) => {
                                                            return (
                                                                <div className="d-flex align-items-center w-100 pr-20" key={i}>
                                                                    {data.phoneNumber ?
                                                                        <div className="data-panel justify-content-between w-100">
                                                                            {
                                                                                <div className="d-flex">
                                                                                    <div className='overflow-hidden'><span className="data-text font-600 meta-info">{this.props.phoneCode + ' ' + mobileNumberFormat(data.phoneNumber)}</span></div>
                                                                                    <div>
                                                                                        <img src={ImageAssets.formsvg.notVerifiedIcon} className="ml-10" alt="verify-icon" />
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : null}
                                                                    <div>
                                                                        {
                                                                            data.phoneNumber && data.isVerified ?
                                                                                <div className="save-image-icon icon-panel ml-10 d-flex" >
                                                                                    <img className='ml-10' src={ImageAssets.formsvg.deleteDocumentsIcon} onClick={() => this.deleteEmailPhone(data._id)} alt="verify-icon" />
                                                                                    <div className="btn-tooltip">
                                                                                        <span className="btn-tooltip-text">{Labels.DELETE}</span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {
                                                                                        data.phoneNumber && !data.isVerified
                                                                                            ?
                                                                                            <div className="save-image-icon ml-10 d-flex" >
                                                                                                <div className='icon-panel'>
                                                                                                    <img src={ImageAssets.formsvg.deleteDocumentsIcon} onClick={() => this.deleteEmailPhone(data._id, this.props.phoneCode + ' ' + data.phoneNumber)} alt="verify-icon" />
                                                                                                    <div className="btn-tooltip">
                                                                                                        <span className="btn-tooltip-text">{Labels.DELETE}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Button
                                                                                                    type='submit'
                                                                                                    className={'btn btn-primary btn-xsmall ml-10'}
                                                                                                    text='Verify'
                                                                                                    onClick={() => this.handleOnlyVerifyPhone(data._id)}
                                                                                                />

                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field-row userpanel-about-section">
                                                <div className="row no-gutters">
                                                    <div className="col-md-6 col-6">
                                                        <div className="title-panel h-100">
                                                            <span className="title-text font-600">{Labels.FORM_PREVIEW.EMAIL}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {/* primary data */}
                                                        <div className="data-panel justify-content-between flex-wrap">
                                                            <div><span className="data-text font-600 meta-info">{this.props.email}</span></div>
                                                            <div className="icons-panel center">
                                                                {/* <div className="save-image-icon icon-panel ml-10">
                                                                    <Button
                                                                        type='submit'
                                                                        className={'btn btn-primary btn-xsmall'}
                                                                        text='Verify'
                                                                    />
                                                                </div> */}
                                                                {!this.state.addEmail && ohterEmails.length === 0 &&
                                                                    <div className="ml-10 ">
                                                                        <Button
                                                                            type='submit'
                                                                            className={'btn btn-primary btn-xsmall'}
                                                                            text='Add New'
                                                                            onClick={this.addEmail}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/*  */}

                                                        {/* dynamic data */}
                                                        {/* <div className="data-panel justify-content-between flex-wrap">
                                                            <div><span className="data-text font-600 meta-info">seema@yopmail.com</span></div>
                                                            <div className="icons-panel">
                                                                <div className="save-image-icon icon-panel ml-10">
                                                                    <div>
                                                                        <img src={ImageAssets.formsvg.removeDocumentsIcon} alt="add-icon" />
                                                                    </div>
                                                                    <div className="btn-tooltip">
                                                                        <span className="btn-tooltip-text">{Labels.REMOVE}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {ohterEmails.map((data, i) => {
                                                            return (
                                                                <div className="d-flex align-items-center w-100 pr-20" key={i}>
                                                                    {data.email ?
                                                                        <div className="data-panel justify-content-between w-100">
                                                                            {
                                                                                <div className="d-flex">
                                                                                    <div>
                                                                                        {this.state.editEmail === appConfig.EMAIL_FOR.ADMIN
                                                                                            ?
                                                                                            <div className='overflow-hidden'><span className="data-text font-600 meta-info">{data.email}</span></div>
                                                                                            :
                                                                                            <div className='overflow-hidden'><span className="data-text font-600 meta-info">{data.email}</span></div>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            data.email && data.isVerified
                                                                                                ?
                                                                                                <img src={ImageAssets.formsvg.verifiedIcon} alt="verify-icon" />
                                                                                                :
                                                                                                <img src={ImageAssets.formsvg.notVerifiedIcon} className="ml-10" alt="verify-icon" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : null}
                                                                    <div>
                                                                        {
                                                                            data.email && data.isVerified ?
                                                                                <div className="save-image-icon icon-panel ml-10 d-flex" >
                                                                                    <img className='ml-10' src={ImageAssets.formsvg.deleteDocumentsIcon} onClick={() => this.deleteEmailPhone(data._id, data.email)} alt="verify-icon" />
                                                                                    <div className="btn-tooltip">
                                                                                        <span className="btn-tooltip-text">{Labels.DELETE}</span>
                                                                                    </div>
                                                                                    {(this.state.emailData.length === i + 1 && this.state.emailData.length !== 3 && ohterEmails.length < 2) ?
                                                                                        <div className="ml-10">
                                                                                            <Button
                                                                                                type='submit'
                                                                                                parentClassName={'w-86'}
                                                                                                className={'btn btn-primary btn-xsmall'}
                                                                                                text='Add New'
                                                                                                onClick={this.addEmail}
                                                                                            />
                                                                                        </div> : ''}

                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {
                                                                                        data.email && !data.isVerified
                                                                                            ?
                                                                                            <div className="save-image-icon icon-panel ml-10 d-flex" >
                                                                                                <img className='ml-10' src={ImageAssets.formsvg.deleteDocumentsIcon} onClick={() => this.deleteEmailPhone(data._id, data.email)} alt="verify-icon" />
                                                                                                <div className="btn-tooltip">
                                                                                                    <span className="btn-tooltip-text">{Labels.DELETE}</span>
                                                                                                </div>
                                                                                                <Button
                                                                                                    type='submit'
                                                                                                    className={'btn btn-primary btn-xsmall ml-10'}
                                                                                                    text='Verify'
                                                                                                    onClick={() => this.handleOnlyVerifyEmail(data._id)}
                                                                                                />
                                                                                                {(this.state.emailData.length === i + 1 && this.state.emailData.length !== 3 && ohterEmails.length < 2) ?
                                                                                                    <div className="ml-10">
                                                                                                        <Button
                                                                                                            type='submit'
                                                                                                            parentClassName={'w-86'}
                                                                                                            className={'btn btn-primary btn-xsmall w-86'}
                                                                                                            text='Add New'
                                                                                                            onClick={this.addEmail}
                                                                                                        />
                                                                                                    </div> : ''}
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>

                                                                        }
                                                                    </div>

                                                                </div>
                                                            )
                                                        })

                                                        }
                                                        {/*  */}

                                                        {/* add panel */}
                                                        {this.state.addEmail ?
                                                            <div>
                                                                {this.state.emailData.map((data, i) => {
                                                                    return (
                                                                        <div>
                                                                            {this.state.emailData[i].email.edit ?
                                                                                <div className="data-panel justify-content-between flex-wrap" key={i}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        {ohterEmails.length < 2 ?
                                                                                            <div>
                                                                                                <TextField
                                                                                                    type='text'
                                                                                                    parentClassName='form-group flex1 mr-10'
                                                                                                    labelClassName='default-font black-text'
                                                                                                    className='form-input'
                                                                                                    inputDivClassName='input-panel'
                                                                                                    placeholder={Placeholders.EMAIL}
                                                                                                    onChange={this.onInputChange(InputIds.EMAIL, i)}
                                                                                                    {...this.state.emailData[i]}
                                                                                                    value={this.state.emailData[i].email.value}
                                                                                                />
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {/* <div>
                                                                                    <img src={ImageAssets.formsvg.editIcon} className="ml-10" alt="edit-icon" onClick={this.onEditEmailClick(appConfig.CONTACT_FOR.ADMIN, i)} />
                                                                                </div> */}
                                                                                    </div>


                                                                                    <div className="d-flex justify-content-end icon-block pt-5">
                                                                                        <div className="d-flex">
                                                                                            {/* {i === 0 ? '' : */}
                                                                                            <div className="save-image-icon icon-panel ml-10">
                                                                                                <div className={this.state.email.error ? "icon disabled" : ""}>
                                                                                                    <img src={ImageAssets.formsvg.removeDocumentsIcon} alt="add-icon" onClick={() => this.RemoveEmail(i)} />
                                                                                                </div>
                                                                                                <div className="btn-tooltip">
                                                                                                    <span className="btn-tooltip-text">{Labels.REMOVE}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* } */}
                                                                                            {/* {ohterEmails.length < 2 && */}
                                                                                            <div className="save-image-icon icon-panel ml-10">
                                                                                                <Button
                                                                                                    type='submit'
                                                                                                    className={'btn btn-primary btn-xsmall'}
                                                                                                    text='Verify'
                                                                                                    onClick={() => this.handleAddEmailClick(i)}
                                                                                                    disabled={this.state.emailDisabledButton}
                                                                                                />
                                                                                            </div>
                                                                                            {/* } */}
                                                                                            {/* {(this.state.emailData.length === i + 1 && this.state.emailData.length !== 3 && ohterEmails.length < 1) ?
                                                                                            <div className="save-image-icon icon-panel ml-10">
                                                                                                <div className={this.state.email.error ? "icon disabled" : ""}>
                                                                                                    <Button
                                                                                                        type='submit'
                                                                                                        className={'btn btn-primary btn-xsmall'}
                                                                                                        text='Add New'
                                                                                                        onClick={this.addEmail}
                                                                                                        disabled={this.state.disabledAddNewEmail}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            :null } */}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                            : null}
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OtherEmailVerification
                    open={this.state.verifyAccount}
                    email={this.state.emailData[this.state.emailVerifyIndex].email.value}
                    phone={this.state.phoneData[this.state.phoneVerifyIndex].phone.value}
                    showOTP={this.state.showOTP}
                    onInputChange={this.onInputChange}
                    onGetOTPClick={this.onGetOTPClick}
                    onOTPChange={this.onOTPChange}
                    onOTPSubmit={this.onOTPSubmit}
                    onCancelClick={this.onCancelClick}
                    otp={this.state.otp}
                />
            </div>
        )
    }
}

MyProfile.propTypes = {}
const mapStateToProps = (state) => ({
    userId: state.auth.userId,
    otherEmailPhoneNumber: state.list.otherEmailPhoneNumber,
    auth: state.auth,
    list: state.list,
    role: state.auth.role,
    singleApplication: state.taxApplication.singleApplication,
    documentTypesList: state.list.documentTypesList,
    plans: state.subscription.plans,
    applicationData: state.taxApplication.applicationSetup,
    email: state.auth.email,
    phoneNumber: state.auth.phoneNumber,
    phoneCode: state.auth.phoneCode
})

export default connect(mapStateToProps, { getUserDetail, verifyOtherEmailPhone, addOtherEmailPhone, getOtherEmailPhone, deleteOtherEmailPhone })(MyProfile)
