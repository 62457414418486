import React, { Component } from 'react'
import ReactOTPInput from 'react-otp-input'
import { connect } from 'react-redux'
import ImageAssets from '../../utils/ImageAssets'
import { InputIds, Labels, Placeholders, Messages } from '../../constants/StaticTexts'
import { RegexPattern } from '../../constants/RegexPattern'
import { ErrorMessages } from '../../constants/ErrorMessages'
import TextField from '../common/TextField'
import { appConfig } from '../../constants/AppConfig'
import Button from '../common/Button'
import { forgotPasswordAction, setNewPasswordAction } from '../../actions/authActions'
import { UIRoutes } from '../../constants/FrontendRoutes'

class SetNewPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      otpValue: '',
      password: { value: '', error: null },
      isPasswordVisible: true
    }
  }

  componentDidMount() {
    if (!this.props.location.otpSentTo) {
      this.props.history.push(UIRoutes.FORGOT_PASSWORD)
    }
  }

  onOTPChange = (value) => {
    this.setState({ otpValue: value })
  }

  onInputChange = (inputId) => () => (event) => {
    if (inputId === InputIds.PASSWORD) {
      if (event.target.value) {
        this.setState({
          password: {
            value: event.target.value,
            error: event.target.value.match(RegexPattern.PASSWORD) ? null : ErrorMessages.PASSWORD_NOT_VALID
          }
        })
      } else {
        this.setState({
          password: {
            value: '',
            error: null
          }
        })
      }
    }
  }

  onResendOTPClick = () => {
    let requestObject = {
      emailOrPhone: this.props.location.otpSentTo
    }
    this.setState({ otpValue: '' })
    this.props.forgotPasswordAction(requestObject)
  }

  onPasswordVisibleClick = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
  }

  checkEnterKeyEvent = (disabledButton) => (event) => {
    if (disabledButton && (event.key === InputIds.ENTER || event.keyCode === 13)) {
      this.onSetPasswordClick()
    }
  }

  onSetPasswordClick = () => {
    let requestObject = {
      emailOrPhone: this.props.location.otpSentTo,
      otp: this.state.otpValue,
      password: this.state.password.value
    }
    this.props.setNewPasswordAction(requestObject, this.props.history)
  }

  render() {

    let disabledButton = this.state.otpValue && (String(this.state.otpValue).length === appConfig.OTP) && this.state.password.value && !this.state.password.error

    return (
      <div className="login-signup-page">
        <div className="row no-gutters align-items-center">
          <div className="col-xl-5 col-md-5">
            <div className="center left-panel">
              <img src={ImageAssets.authimage.generateOtpImage} alt="set-new-password" className="image-responsive"></img>
            </div>
          </div>
          <div className="col-xl-7 col-md-7">
            <div className="center right-panel">
              <div className="form-block">
                <div className="center site-logo">
                  <img src={ImageAssets.common.siteLogo} alt="site-logo"></img>
                </div>
                <div className="text-center mt-15">
                  <span className="large-font dark-green-text font-600">
                    {Messages.ENTER_OTP}
                  </span>
                </div>
                <div className="form-group">
                  <label className="default-font black-text font-600 text-center">
                    {Labels.OTP_SENT_TO}<span className="default-font light-green-text">{this.props.location.otpSentTo}</span>
                  </label>
                  <ReactOTPInput
                    numInputs={6}
                    className='d-flex otp-input-panel'
                    inputStyle='form-input otp-input'
                    separator={<span>&nbsp;</span>}
                    isInputNum={true}
                    onChange={this.onOTPChange}
                    value={this.state.otpValue}
                  />
                </div>
                <div className={"password-input-panel position-relative" + (this.state.isPasswordVisible ? '' : ' show-password')}>
                  <TextField
                    parentClassName='form-group'
                    labelText={this.props.location.isFrom ? Labels.ENTER_PASSWORD : Labels.NEW_PASSWORD}
                    labelClassName='default-font black-text font-600'
                    type={this.state.isPasswordVisible ? 'password' : 'text'}
                    className='form-input'
                    autoComplete='new-password'
                    placeholder={Placeholders.PASSWORD}
                    onChange={this.onInputChange(InputIds.PASSWORD)}
                    onKeyPress={this.checkEnterKeyEvent(disabledButton)}
                    {...this.state.password}
                  />
                  {
                    this.state.isPasswordVisible
                      ?
                      <img src={ImageAssets.authimage.hideEyeImage} onClick={this.onPasswordVisibleClick} className="visibility-icon hide-eye-icon" alt="hideShow" />
                      :
                      <img src={ImageAssets.authimage.showEyeImage} onClick={this.onPasswordVisibleClick} className="visibility-icon show-eye-icon" alt="hideShow" />
                  }
                </div>
                <Button
                  parentClassName='center mt-30'
                  type='submit'
                  className={'btn btn-primary btn-large' + (disabledButton ? '' : ' disabled')}
                  disabled={!disabledButton}
                  text={Messages.SUBMIT}
                  onClick={this.onSetPasswordClick}
                />
                <div className="center text-center mt-15">
                  <span className="center default-font black-text font-600">
                    {Messages.DIDNT_RECEIVE_OTP}
                    <span onClick={this.onResendOTPClick} className="center default-font light-green-text font-600 link-text ml-5">
                      {Messages.RESEND_OTP}
                      <img src={ImageAssets.authimage.rightArrow} alt="right" className="ml-5" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SetNewPassword.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { forgotPasswordAction, setNewPasswordAction })(SetNewPassword)
